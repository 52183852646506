import React, { useState, useRef, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "../../utils/constants";
import { Spinner, SpinnerSize } from "@fluentui/react";
// import { mergeStyleSets } from "@fluentui/react/lib/Styling";

// const classNames = mergeStyleSets({
//     address: {
//         marginTop: 0,
//         marginBottom: 24,
//         fontWeight: 400,
//         color: "#000",
//         display: "flex",
//     },
//     addressTitle: {
//         fontWeight: 400,
//         color: "#000",
//         fontSize: 14,
//         marginRight: 24,
//     },
//     addressContent: {
//         fontWeight: 700,
//         fontSize: 12,
//         maxWidth: 340,
//         overflowWrap: "break-word",
//         textTransform: "uppercase",
//     },
// });

// const center = {
//     lat: 55.7689,
//     lng: 12.5863,
// };

const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

const MapAddressComponent = ({ target, marker, height, width }) => {
    const [libraries] = useState(["places"]);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const mapContainerStyle = {
        height,
        width
    };

    const mapRef = useRef(null);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    if (loadError) return "Error...";
    if (!isLoaded)
        return <Spinner size={SpinnerSize.large} label="Loading..." />;

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            onLoad={onMapLoad}
            zoom={15}
            center={marker}
            options={options}
        >
            {marker && <Marker key={target} position={marker} />}
        </GoogleMap>
    );
};

export default MapAddressComponent;
