// @ts-nocheck
// Import libraries
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { SearchBox, Stack } from "@fluentui/react";
import {
  ScrollablePane,
  ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";

// Import utils
import { searchBoxStyles } from "../../../../utils/theme";
import {
  workingRoleOptions,
  SOURCE_API,
  stationOptions,
  COLOR_CONS_HEX,
} from "../../../../utils/constants";

// Import redux
import { changeShowOtherSections } from "../../../../redux/app/app.actions";

// Import types
import { TPlan, User } from "../../../../types";
import { AllUserData } from "../../../../types/custom";
import { TPlanHorizontalPositionMap } from "../../../../types/planner";
import { TFindStaffSchedule } from "../../../../utils/utils";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

const classNames = mergeStyleSets({
  Persona: {
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  station: {
    margin: 5,
    width: 25,
    height: 14,
    color: "#868685",
    background: "#DFDFDF",
    textAlign: "center",
    fontSize: 11,
    lineHeight: 14,
    borderRadius: 4,
    padding: 2,
  },
  favoriteDriver: {
    position: "absolute",
    marginLeft: "212px",
  },
  verifiedProjectSkill: {
    position: "absolute",
    left: "200px",
    top: "19px",
  },
  bar: {
    position: "absolute",
    left: "59px",
    cursor: "pointer",
    top: "47px",
  },
  draw: {
    position: "absolute",
    // left: "245px",
    color: COLOR_CONS_HEX.BLUE,
    cursor: "pointer",
    top: "43px",
    left: "85px",
  },
});

const bookedStateStyle = (state: EBOOKED): React.CSSProperties => ({
  position: "absolute",
  marginLeft: "235px",
  fontSize: "12px",
  textAlign: "right",
  borderRadius: "50%",
  color: state === BOOKED_STATE.PARTIAL ? "#F57C00" : "#d50000",
  backgroundColor: state === BOOKED_STATE.PARTIAL ? "#F57C00" : "#d50000",
});

export type EBOOKED = "NOT" | "PARTIAL" | "FULLY";

export const BOOKED_STATE: {
  [k: string]: EBOOKED;
} = {
  NOT: "NOT",
  PARTIAL: "PARTIAL",
  FULLY: "FULLY",
};

export type TDisplay = AllUserData & { roleDisplay: string };

export type TDisplayList = TDisplay[];

type PopupSupervisorsProps = {
  allPlansData: TPlan[];
  target: string;
  toggleTeaching: () => void;
  staffData: User[];
  roles: string[];
  workers: AllUserData[];
  findNearestPossibleTime: (
    staffPlanning: AllUserData,
    horizontalPositionMap: TPlanHorizontalPositionMap,
    roles: string
  ) => { start: string; end: string } | boolean;
  onClickDraw: (user: TDisplay, role: string) => void;
  findStaffSchedule: TFindStaffSchedule;
  onAdd: (user: TDisplay, role: string) => void;
  horizontalPositionMap: TPlanHorizontalPositionMap;
  projectId: string;
  propName: string;
  otherSectionsStaff: User[];
};

const PopupSupervisors = ({
  allPlansData,
  target,
  toggleTeaching,
  onAdd,
  roles,
  staffData,
  otherSectionsStaff,
  workers,
  findNearestPossibleTime,
  horizontalPositionMap,
  findStaffSchedule,
  projectId,
  propName,
  onClickDraw,
}: PopupSupervisorsProps) => {
  const dispatch = useDispatch();
  const [displayList, setDisplayList] = useState<TDisplayList>([]);
  const showOtherSections = useSelector(
    // @ts-ignore
    (state) => state.app.showOtherSections[propName]
  ) as boolean;
  const startCalendar = horizontalPositionMap.startText.split(".").reverse();
  const endCalendar = horizontalPositionMap.endText.split(".").reverse();
  const startCalendarDate = new Date(
    +("20" + startCalendar[0]),
    +startCalendar[1] - 1,
    +startCalendar[2]
  );
  const endCalendarDate = new Date(
    +("20" + endCalendar[0]),
    +endCalendar[1] - 1,
    +endCalendar[2]
  );
  const [searchValue, setSearchValue] = useState("");

  const currentStaffData = useMemo(() => {
    return showOtherSections
      ? [...staffData, ...otherSectionsStaff]
      : staffData;
  }, [showOtherSections, staffData, otherSectionsStaff]);

  useEffect(() => {
    const rolesWorking = workingRoleOptions.filter((item) =>
      roles.includes(item.role)
    );

    const tempList: (User & { roleDisplay: string })[] = [...currentStaffData]
      .filter(
        (item) =>
          rolesWorking.some((role) =>
            item.workingRole.includes(role.key.toString())
          ) &&
          `${item.firstName} ${item.lastName}`
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      )
      .map((item) => ({
        ...item,
        roleDisplay:
          rolesWorking.find((role) =>
            item.workingRole.includes(role.key.toString())
          )?.text || "",
      }));

    setDisplayList(tempList);
  }, [roles, showOtherSections, searchValue, currentStaffData]);

  const checkStaffBookedCondition = (staffId: string): EBOOKED => {
    let staffPlanning = workers.find((staff) => staff.id === staffId);
    if (!staffPlanning) {
      const staffSchedule = findStaffSchedule(
        staffId,
        projectId,
        propName,
        allPlansData
      )?.filter(
        (item) =>
          new Date(item.start.slice(0, -1)) <= endCalendarDate &&
          new Date(item.end.slice(0, -1)) >= startCalendarDate
      );

      const serviceSchedules = getCrewServiceSchedule(staffId, machines).filter(
        (item) => {
          return (
            new Date(item.start.slice(0, -1)) <= endCalendarDate &&
            new Date(item.end.slice(0, -1)) >= startCalendarDate
          );
        }
      );

      const staffVacation =
        currentStaffData
          .find((staff) => staff.userId === staffId)
          ?.starfVacations?.filter(
            (item) =>
              new Date(item.start.slice(0, -1)) <= endCalendarDate &&
              new Date(item.end.slice(0, -1)) >= startCalendarDate
          ) || [];
      staffPlanning = {
        id: staffId,
        array: [],
        staffSchedule,
        staffVacation,
        serviceSchedules,
      };
    } else {
      staffPlanning.serviceSchedules = getCrewServiceSchedule(
        staffId,
        machines
      ).filter((item) => {
        return (
          new Date(item.start.slice(0, -1)) <= endCalendarDate &&
          new Date(item.end.slice(0, -1)) >= startCalendarDate
        );
      });
    }
    if (
      !staffPlanning.staffSchedule?.length &&
      !staffPlanning.staffVacation?.length &&
      !staffPlanning.array?.length &&
      !staffPlanning.serviceSchedules.length
    )
      return BOOKED_STATE.NOT;
    const possibleTime = findNearestPossibleTime(
      staffPlanning,
      horizontalPositionMap,
      "staff"
    );
    if (possibleTime) return BOOKED_STATE.PARTIAL;
    return BOOKED_STATE.FULLY;
  };

  return (
    <TeachingBubble
      target={`#${target}`}
      hasSmallHeadline={true}
      onDismiss={toggleTeaching}
      closeButtonAriaLabel="Close"
    >
      <SearchBox
        styles={searchBoxStyles}
        placeholder="Søg"
        clearButtonProps={{
          style: {
            backgroundColor: "#FFFFFF",
            marginLeft: "20px",
          },
        }}
        value={searchValue}
        onChange={(_, value) => setSearchValue(value || "")}
      />
      <hr
        style={{
          width: "145%",
          marginLeft: "-50px",
          marginTop: "-2px",
          opacity: 0.5,
        }}
      />

      <div
        style={{
          height: propName === "managers" ? 240 : 200,
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ScrollablePane
          style={{ height: 200 }}
          scrollbarVisibility={ScrollbarVisibility.auto}
        >
          {/* vertical */}
          <Stack tokens={{ childrenGap: 10 }}>
            {displayList &&
              displayList.map((user) => (
                <div
                  key={`worker-${user.userId}`}
                  className={classNames.Persona}
                  style={{
                    opacity: `${
                      propName !== "managers" &&
                      checkStaffBookedCondition(user.userId) ===
                        BOOKED_STATE.FULLY
                        ? 0.4
                        : 1
                    }`,
                    cursor: `${
                      propName !== "managers" &&
                      checkStaffBookedCondition(user.userId) ===
                        BOOKED_STATE.FULLY
                        ? "context-menu"
                        : ""
                    }`,
                    paddingBottom: 15,
                    position: "relative",
                  }}
                  onClick={() => {
                    // const roleWorking = workingRoleOptions.filter((item) =>
                    //   roles.includes(item.role)
                    // );
                    // const role = roleWorking.find((item) =>
                    //   user.workingRole.split(";").includes("" + item.key)
                    // ).role;
                    // propName !== "managers"
                    //   ? checkStaffBookedCondition(user.userId) !==
                    //       BOOKED_STATE.FULLY && onAdd(user, role)
                    //   : onAdd(user, role);
                  }}
                >
                  {" "}
                  <Persona
                    text={`${user?.firstName} ${user?.lastName}`}
                    secondaryText={user.roleDisplay}
                    size={PersonaSize.size40}
                    imageUrl={
                      user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ""
                    }
                  />
                  <Image
                    src={`${window.location.origin}/img/bar.svg`}
                    imageFit={ImageFit.cover}
                    alt="place staff"
                    className={classNames.bar}
                    onClick={() => {
                      const roleWorking = workingRoleOptions.filter((item) =>
                        roles.includes(item.role)
                      );
                      const role =
                        roleWorking.find((item) =>
                          user.workingRole.split(";").includes("" + item.key)
                        )?.role || "";
                      propName !== "managers"
                        ? checkStaffBookedCondition(user.userId) !==
                            BOOKED_STATE.FULLY && onAdd(user, role)
                        : onAdd(user, role);
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faPencil as IconDefinition}
                    className={classNames.draw}
                    onClick={() => {
                      const roleWorking = workingRoleOptions.filter((item) =>
                        roles.includes(item.role)
                      );
                      const role =
                        roleWorking.find((item) =>
                          user.workingRole.split(";").includes("" + item.key)
                        )?.role || "";

                      propName !== "managers"
                        ? checkStaffBookedCondition(user.userId) !==
                            BOOKED_STATE.FULLY &&
                          onClickDraw &&
                          onClickDraw(user, role)
                        : onClickDraw && onClickDraw(user, role);
                    }}
                  />
                  {propName !== "managers" &&
                    [BOOKED_STATE.PARTIAL, BOOKED_STATE.FULLY].includes(
                      checkStaffBookedCondition(user.userId)
                    ) && (
                      <FontAwesomeIcon
                        style={{
                          ...bookedStateStyle(
                            checkStaffBookedCondition(user.userId)
                          ),
                        }}
                        icon={faCircle as IconDefinition}
                      />
                    )}
                  <div className={classNames.station}>
                    {stationOptions[user.techDepartment]?.options.find(
                      (option) => option.key === user.techArea
                    )?.shortcutText ?? ""}
                  </div>
                </div>
              ))}
          </Stack>
        </ScrollablePane>
        <div
          style={{
            margin: "220px auto 20px auto",
            color: "rgb(0, 108, 173)",
            cursor: "pointer",
            display: propName === "managers" ? "unset" : "hidden",
          }}
          onClick={() =>
            dispatch(changeShowOtherSections(!showOtherSections, propName))
          }
        >
          {showOtherSections ? "Hide" : "Show"} other sections
        </div>
      </div>
    </TeachingBubble>
  );
};

export default PopupSupervisors;
