import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
  Persona,
  PersonaInitialsColor,
  PersonaSize,
} from "@fluentui/react/lib/Persona";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { faPowerOff, faSyncAlt } from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addMonths, differenceInDays } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { setTableSort } from "../../../redux/app/app.actions";
import {
  getMachines,
  onChangeFilterStaffRole,
} from "../../../redux/machines/machines.actions";
import {
  changeSectionFilter,
  getStaffs,
  getUsersMissingInfo,
  syncUser,
} from "../../../redux/user/user.actions";
import {
  SKILLS_QUERY_KEY,
  SOURCE_API,
  techAreaOptions,
  techDepartmentOptionsStaffPage,
  workingRoleOptions,
} from "../../../utils/constants";
import { defaultButtonStyles, primaryButtonStyles } from "../../../utils/theme";
import { copyAndSort, hasEditRight } from "../../../utils/utils";
import {
  ColorIndicator,
  MachineIconWithBadge,
  TooltipForText,
} from "../../common/index";
import IconWithBadge from "../../common/IconWithBadge";
import Badge from "../../common/Badge";
import { MachineTableTechDepartmentInfoCell } from "../machines/index";
import { StaffControl } from "./";
import { getSkills } from "../../../redux/skill/skill.actions";
import { buttonStyle } from "../../common/calendar/ButtonStyles";

const commonStationSelectorButton = {
  height: 20,
  borderRadius: 24,
  color: "#ffffff",
  background: "#006CAD",
  padding: "4px 16px",
  fontSize: 14,
  lineHeight: 20,
  margin: "8px 4px",
  cursor: "pointer",
  selectors: {
    ":hover": {
      color: "#DBDBDB",
    },
  },
};

const iconStyle = {
  // marginRight: 2,
  fontSize: 16,
};

const classNames = mergeStyleSets({
  alignItemRow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  button: [
    commonStationSelectorButton,
    {
      color: "#868685",
      background: "#DFDFDF",
      selectors: {
        ":hover": {
          color: "#DFDFDF",
          background: "#868685",
        },
      },
    },
  ],
  buttonSelected: commonStationSelectorButton,
  unSelectedIcon: {
    ...iconStyle,
    color: "#006CAD",
  },
  selectedIcon: {
    ...iconStyle,
    color: "#fff",
  },
});

const WrapperText = ({ inactive, children }) => (
  <div style={{ color: inactive ? "#868685" : "#000" }}>{children}</div>
);

const NOT_SORTING_FIELDS = [
  "equipment",
  "action",
  "generalSkills",
  "machineSkills",
];

const StaffTable = ({
  getStaffs,
  techDepartmentDefaultFilter,
  selectedTechDepartmentFilter,
  staffRoleDefaultFilters,
  syncUser,
  changeSectionFilter,
  tableSort,
  getMachines,
}) => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const queryClient = useQueryClient();
  const [showInactivePeople, setShowInactivePeople] = useState(true);
  const staffRole = useSelector(
    (state) => state?.machine?.filter.staffRoleStaffPage,
  );
  const { data: staffData } = useQuery("staffs", () => getStaffs(), {
    refetchOnWindowFocus: false,
  });

  const { data: machinesData } = useQuery(
    "machines",
    () => getMachines(dispatch),
    {
      initialData: [],
    },
  );

  const { mutate: mutateSyncUsers, isLoading: isLoadingSyncData } = useMutation(
    syncUser,
    {
      onSuccess: (data) => {
        if (data) queryClient.invalidateQueries("staffs");
      },
    },
  );

  const [listData, setListData] = useState(staffData || []);
  const techDepartmentFilter = selectedTechDepartmentFilter
    ? techDepartmentOptionsStaffPage.map((item) => ({
        ...item,
        isOn: selectedTechDepartmentFilter.includes(item.key),
      }))
    : techDepartmentOptionsStaffPage.map((item) => ({
        ...item,
        isOn: item.key === techDepartmentDefaultFilter,
      }));
  const techDepartmentFilterRef = useRef(null);
  if (
    !techDepartmentFilterRef.current ||
    JSON.stringify(techDepartmentFilter) !==
      JSON.stringify(techDepartmentFilterRef.current)
  ) {
    techDepartmentFilterRef.current = techDepartmentFilter;
  }

  const onChangeSectionFilter = (key) => {
    const tempArray = techDepartmentFilter
      .filter((item) => item.isOn)
      .map((item) => item.key);
    tempArray.indexOf(key) === -1
      ? tempArray.push(key)
      : tempArray.splice(tempArray.indexOf(key), 1);
    changeSectionFilter(tempArray);
  };

  useEffect(() => {
    if (staffData) {
      let newListDisplay = staffData
        .filter(
          (staff) =>
            staffRole.some(
              (role) =>
                role.isOn &&
                staff.workingRole.replace("-1", "").includes(role.key),
            ) &&
            techDepartmentFilter.some(
              (role) => role.isOn && role.key === staff.techDepartment,
            ) &&
            (showInactivePeople || !staff.inactive),
        )
        .sort((a, b) => (!a.inactive && b.inactive ? -1 : 0));

      if (tableSort.staffTable) {
        const { columnName, isSortedDescending } = tableSort.staffTable;
        newListDisplay = copyAndSort(
          newListDisplay,
          columnName === "name" ? "firstName" : columnName,
          isSortedDescending,
        );
        setColumns((prevColumns) =>
          prevColumns.map((col) => {
            col.isSorted = col.key === columnName;
            if (col.isSorted) {
              col.isSortedDescending = isSortedDescending;
            }
            return col;
          }),
        );
      }
      setListData(newListDisplay);
    }
  }, [
    techDepartmentFilterRef.current,
    staffRole,
    staffData,
    showInactivePeople,
  ]);

  const onClickSorting = (e, column) => {
    if (NOT_SORTING_FIELDS.includes(column.fieldName)) {
      return;
    }
    let isSortedDescending = column.isSortedDescending;
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    dispatch(
      setTableSort({
        key: "staffTable",
        value: { columnName: column.fieldName, isSortedDescending },
      }),
    );
    let sortedItems = copyAndSort(
      listData,
      column.fieldName === "name" ? "firstName" : column.fieldName,
      isSortedDescending,
    );
    setListData(sortedItems);
    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        col.isSorted = col.key === column.key;
        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }
        return col;
      }),
    );
  };

  const initColumns = [
    {
      data: "string",
      fieldName: "name",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: true,
      key: "name",
      maxWidth: 250,
      minWidth: 150,
      name: "NAVN",
    },
    {
      data: "string",
      fieldName: "workingRole",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "workingRole",
      maxWidth: 400,
      minWidth: 100,
      name: "ROLLE",
    },
    {
      data: "string",
      fieldName: "aadGroups",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "aadGroups",
      maxWidth: 400,
      minWidth: 299,
      name: "AAD Groups",
    },
    {
      data: "string",
      fieldName: "email",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "email",
      maxWidth: 400,
      minWidth: 200,
      name: "EMAIL",
    },
    {
      data: "string",
      fieldName: "inactive",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "inactive",
      maxWidth: 144,
      minWidth: 40,
      name: "STATUS",
    },
    {
      data: "string",
      fieldName: "techDepartment",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "techDepartment",
      maxWidth: 144,
      minWidth: 40,
      name: "SEKTION",
    },
    {
      data: "string",
      fieldName: "techArea",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "disponent",
      maxWidth: 100,
      minWidth: 40,
      name: "DISPONENT",
    },
    // {
    //     data: 'number',
    //     fieldName: 'equipment',
    //     isPadded: true,
    //     isResizable: true,
    //     isRowHeader: true,
    //     key: 'equipment',
    //     maxWidth: 70,
    //     minWidth: 40,
    //     name: 'MASKINER',
    // },
    {
      data: "string",
      fieldName: "machineSkills",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: true,
      key: "machineSkills",
      maxWidth: 250,
      minWidth: 100,
      name: "Maskine Skills",
    },
    {
      data: "string",
      fieldName: "generalSkills",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: true,
      key: "generalSkills",
      maxWidth: 250,
      minWidth: 100,
      name: "General Skills",
    },
    // {
    //   data: "number",
    //   fieldName: "equipment",
    //   isPadded: true,
    //   isResizable: true,
    //   isRowHeader: true,
    //   key: "equipment",
    //   maxWidth: 70,
    //   minWidth: 40,
    //   name: "MASKINER",
    // },
    {
      data: "number",
      fieldName: "action",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "action",
      maxWidth: 60,
      minWidth: 40,
      name: "ACTION",
    },
  ];

  const [columns, setColumns] = useState(initColumns);

  const history = useHistory();

  const renderGeneralSkillWarning = (
    fieldContent,
    fieldName,
    expiryFieldName,
  ) => {
    const arr = [];

    if (
      fieldContent
        ?.filter((el) => !el.inactive)
        ?.some(
          (fieldContentItem) =>
            (fieldContentItem[expiryFieldName] === -1
              ? Infinity
              : differenceInDays(
                  addMonths(
                    new Date(fieldContentItem[fieldName]),
                    fieldContentItem[expiryFieldName],
                  ),
                  new Date(),
                )) < 0,
        )
    ) {
      arr.push(
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          color={"#F57C00"}
          style={{
            width: "24px",
            height: "24px",
          }}
        />,
      );
    }

    if (
      fieldContent
        ?.filter((el) => !el.inactive)
        ?.some((fieldContentItem) => {
          const diff =
            fieldContentItem[expiryFieldName] === -1
              ? Infinity
              : differenceInDays(
                  addMonths(
                    new Date(fieldContentItem[fieldName]),
                    fieldContentItem[expiryFieldName],
                  ),
                  new Date(),
                );

          return diff >= 0 && diff < 30;
        })
    ) {
      arr.push(
        <FontAwesomeIcon
          icon={faCircleExclamation}
          color={"#E3B505"}
          style={{
            width: "24px",
            height: "24px",
          }}
        />,
      );
    }

    return arr;
  };

  const renderExpiryWarning = (fieldContent, fieldName) => {
    const arr = [];

    if (
      fieldContent
        ?.filter((el) => !el.inactive)
        ?.some(
          (fieldContentItem) =>
            differenceInDays(
              new Date(fieldContentItem[fieldName]),
              new Date(),
            ) < 0,
        )
    ) {
      arr.push(
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          color={"#F57C00"}
          style={{
            width: "24px",
            height: "24px",
          }}
        />,
      );
    }

    if (
      fieldContent
        ?.filter((el) => !el.inactive)
        ?.some((fieldContentItem) => {
          const diff = differenceInDays(
            new Date(fieldContentItem[fieldName]),
            new Date(),
          );
          return diff >= 0 && diff < 30;
        })
    ) {
      arr.push(
        <FontAwesomeIcon
          icon={faCircleExclamation}
          color={"#E3B505"}
          style={{
            width: "24px",
            height: "24px",
          }}
        />,
      );
    }

    return arr;
  };

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName || ""];
    const personaProperties = {
      size: PersonaSize.size32,
      imageUrl: item.imageUrl ? `${SOURCE_API}${item.imageUrl}` : "",
      text: `${item?.firstName} ${item?.lastName ?? ""}`,
    };

    if (item.inactive) {
      personaProperties.initialsColor = PersonaInitialsColor.coolGray;
      personaProperties.styles = {
        primaryText: {
          color: "#868685",
        },
      };
    }

    switch (column.key) {
      case "action":
        return allowEdit ? (
          <StaffControl staff={item} history={history} allowInactive={true} />
        ) : item.userId === currentUserId ? (
          <StaffControl staff={item} history={history} allowInactive={false} />
        ) : null;
      // case 'equipment':
      //     return item.inactive ? null : (
      //         <div style={{ display: 'flex', justifyContent: 'center' }}>
      //             <Link to={`/map-machine/${item.userId}`}>
      //                 <MachineIconWithBadge count={item?.machineFavorites?.length} />
      //             </Link>
      //         </div>
      //     );
      case "equipment":
        return item.inactive ? null : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={`/map-machine/${item.userId}`}>
              <MachineIconWithBadge count={item?.machineFavorites?.length} />
            </Link>
          </div>
        );
      case "color":
        return <ColorIndicator colorCode={item.color} />;
      case "name":
        return <Persona {...personaProperties} />;
      case "workingRole":
        const roles =
          item.workingRole !== "-1" &&
          item.workingRole
            .split(";")
            .filter((item) => item !== "-1")
            .map((key) => workingRoleOptions[key]?.text)
            .join(", ");
        return (
          <div className={classNames.alignItemRow} style={{ width: 300 }}>
            <WrapperText inactive={item.inactive}>
              {roles || "UNKNOWN ROLE"}
            </WrapperText>
          </div>
        );
      case "disponent":
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>
              {techAreaOptions.find(
                (techArea) => techArea.key === item.techArea,
              )?.text ?? "UNKNOWN"}
            </WrapperText>
          </div>
        );
      case "aadGroups":
        if (item.aadGroups) {
          const arr = item.aadGroups.map((aadGroup) => (
            <Badge key={aadGroup} text={aadGroup} />
          ));

          return <div>{arr}</div>;
        }
        return <Badge text={item.aadGroups ? item.aadGroups : "None"} />;
      case "email":
        return <WrapperText inactive={item.inactive}>{item.email}</WrapperText>;
      case "inactive":
        return <Badge text={item.inactive ? "Inactive" : "Active"} />;
      case "techDepartment":
        return (
          <MachineTableTechDepartmentInfoCell
            techDepartments={item.techDepartment.toString()}
          />
        );
      case "generalSkills":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <span>
              <Link to={`/map-machine/${item.userId}?skill=general`}>
                <IconWithBadge
                  iconImgUrl={"./img/bookBlue.svg"}
                  count={fieldContent?.length || 0}
                />
              </Link>
            </span>
            {renderGeneralSkillWarning(
              fieldContent,
              "acquired",
              "expiryPeriod",
            )}
          </div>
        );
      case "machineSkills":
        const machineSkillMachineId = (item.machineSkills || []).map(
          (macSkill) => macSkill.machineId,
        );

        const generalSkillIds = (item.generalSkills || []).map(
          (skill) => skill.skillId,
        );

        const machines = machinesData.filter((machineData) => {
          return machineSkillMachineId.includes(machineData.machineId);
        });

        const allRequiredSkills = machines.reduce(
          (prev, cur) => [...prev, ...cur.requiredSkills],
          [],
        );

        const isRed = !allRequiredSkills.every((skillId) =>
          generalSkillIds.includes(skillId),
        );

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <span>
              <Link to={`/map-machine/${item.userId}`}>
                <MachineIconWithBadge
                  isRed={isRed}
                  count={fieldContent?.length || 0}
                />
              </Link>
            </span>
            {/* {renderExpiryWarning(fieldContent, "introMachine")} */}
            {renderExpiryWarning(
              fieldContent,
              "reCertification",
              item?.generalSkills || [],
            )}
          </div>
        );
      default:
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>{fieldContent}</WrapperText>
          </div>
        );
    }
  };
  let listShow = [];
  if (listData && Array.isArray(listData)) {
    listShow = listData;
  }

  return (
    <div>
      <Stack horizontal="true" tokens={{ childrenGap: 8 }} wrap>
        {techDepartmentFilter.map((option, index) => (
          <div
            key={option["key"]}
            className={
              option.isOn ? classNames.buttonSelected : classNames.button
            }
            onClick={() => onChangeSectionFilter(option["key"])}
          >
            {option["text"]}
          </div>
        ))}
      </Stack>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px 0",
          width: "calc(100% - 100px)",
        }}
      >
        <Stack horizontal="true" tokens={{ childrenGap: 2 }} wrap>
          {staffRole.map((option, index) => (
            <TooltipForText key={option["key"]} text={option.text} noWidth>
              <div
                className={
                  option.isOn ? classNames.buttonSelected : classNames.button
                }
                onClick={() => {
                  dispatch(
                    onChangeFilterStaffRole(index, staffRole, null, true),
                  );
                }}
              >
                <FontAwesomeIcon icon={option.icon} />
              </div>
            </TooltipForText>
          ))}
        </Stack>
        {allowEdit && (
          <Stack
            horizontal
            horizontalAlign="end"
            tokens={{ childrenGap: 20 }}
            className={classNames.buttonStack}
          >
            <TooltipForText
              text={
                showInactivePeople
                  ? "Showing inactive people"
                  : "Hiding inactive people"
              }
              noWidth
            >
              <DefaultButton
                styles={buttonStyle(showInactivePeople, "", 0)}
                onClick={() => {
                  setShowInactivePeople((val) => !val);
                }}
              >
                <FontAwesomeIcon
                  icon={faPowerOff}
                  className={
                    showInactivePeople
                      ? classNames.selectedIcon
                      : classNames.unSelectedIcon
                  }
                />
              </DefaultButton>
            </TooltipForText>
            <DefaultButton
              disabled={isLoadingSyncData}
              styles={defaultButtonStyles}
              onClick={() => {
                mutateSyncUsers();
              }}
            >
              <FontAwesomeIcon icon={faSyncAlt} style={{ marginRight: 8 }} />{" "}
              Sync AAD
            </DefaultButton>
            <PrimaryButton
              text="OPRET NY"
              onClick={() => {
                history.push("/new-staff");
              }}
              styles={primaryButtonStyles}
            />
          </Stack>
        )}
      </div>
      <DetailsList
        items={listShow}
        compact={false}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderItemColumn={renderItemColumn}
        onColumnHeaderClick={onClickSorting}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  techDepartmentDefaultFilter:
    state?.user?.user?.defaultFilter?.techDepartmentFilter,
  selectedTechDepartmentFilter: state?.user?.user?.techDepartmentFilter,
  tableSort: state?.app?.tableSort,
});

export default connect(mapStateToProps, {
  getStaffs,
  getUsersMissingInfo,
  syncUser,
  changeSectionFilter,
  getMachines,
})(StaffTable);
