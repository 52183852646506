import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DetailsList,
  DetailsListLayoutMode,
  SelectionMode,
} from "@fluentui/react/lib/DetailsList";
import {
  Persona,
  PersonaSize,
  PersonaInitialsColor,
} from "@fluentui/react/lib/Persona";
import { Link, useHistory } from "react-router-dom";
import {
  workingRoleOptions,
  techAreaOptions,
  SOURCE_API,
} from "../../../utils/constants";
import {
  ColorIndicator,
  MachineIconWithBadge,
  TooltipForText,
} from "../../common/index";
import { copyAndSort, hasEditRight } from "../../../utils/utils";
import { MachineTableTechDepartmentInfoCell } from "../machines/index";
import { StaffControl } from "./";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { setTableSort } from "../../../redux/app/app.actions";
import IconWithBadge from "../../common/IconWithBadge";
import { differenceInDays } from "date-fns";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "../../common/Badge";
import { getMachines } from "../../../redux/machines/machines.actions";
import { useQuery } from "react-query";
import { DefaultButton } from "@fluentui/react";
import { buttonStyle } from "../../common/calendar/ButtonStyles";
import { faPowerOff } from "@fortawesome/pro-regular-svg-icons";

const commonStationSelectorButton = {
  height: 20,
  borderRadius: 24,
  color: "#ffffff",
  background: "#006CAD",
  padding: "4px 16px",
  fontSize: 14,
  lineHeight: 20,
  margin: "8px 4px",
  cursor: "pointer",
  selectors: {
    ":hover": {
      color: "#DBDBDB",
    },
  },
};

const classNames = mergeStyleSets({
  alignItemRow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  button: [
    commonStationSelectorButton,
    {
      color: "#868685",
      background: "#DFDFDF",
      selectors: {
        ":hover": {
          color: "#DFDFDF",
          background: "#868685",
        },
      },
    },
  ],
  buttonSelected: commonStationSelectorButton,
  unSelectedIcon: {
    color: "#006CAD",
    fontSize: 16,
  },
  selectedIcon: {
    color: "#fff",
    fontSize: 16,
  },
});

const WrapperText = ({ inactive, children }) => (
  <div style={{ color: inactive ? "#868685" : "#000" }}>{children}</div>
);

const NOT_SORTING_FIELDS = [
  "equipment",
  "action",
  "machineSkills",
  "generalSkills",
];

const MissingInfoStaffTable = ({
  usersMissingInfoData,
  toggleShowActiveUsers,
  showingInactiveUsers,
}) => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const tableSort = useSelector((state) => state.app.tableSort);
  const allowEdit = hasEditRight(userRoles);
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const [listData, setListData] = useState(usersMissingInfoData || []);

  const { data: machinesData } = useQuery(
    "machines",
    () => getMachines()(dispatch),
    {
      initialData: [],
    },
  );

  useEffect(() => {
    if (usersMissingInfoData) {
      let newListDisplay = usersMissingInfoData.sort((a, b) =>
        !a.inactive && b.inactive ? -1 : 0,
      );
      if (tableSort.missingInfoStaffTable) {
        const { columnName, isSortedDescending } =
          tableSort.missingInfoStaffTable;
        newListDisplay = copyAndSort(
          newListDisplay,
          columnName === "name" ? "firstName" : columnName,
          isSortedDescending,
        );
        setColumns((prevColumns) =>
          prevColumns.map((col) => {
            col.isSorted = col.key === columnName;
            if (col.isSorted) {
              col.isSortedDescending = isSortedDescending;
            }
            return col;
          }),
        );
      }
      setListData(newListDisplay);
    }
  }, [usersMissingInfoData]);

  const onClickSorting = (e, column) => {
    if (NOT_SORTING_FIELDS.includes(column.fieldName)) {
      return;
    }
    let isSortedDescending = column.isSortedDescending;
    if (column.isSorted) {
      isSortedDescending = !isSortedDescending;
    }
    dispatch(
      setTableSort({
        key: "missingInfoStaffTable",
        value: { columnName: column.fieldName, isSortedDescending },
      }),
    );
    let sortedItems = copyAndSort(
      listData,
      column.fieldName === "name" ? "firstName" : column.fieldName,
      isSortedDescending,
    );
    setListData(sortedItems);
    setColumns((prevColumns) =>
      prevColumns.map((col) => {
        col.isSorted = col.key === column.key;
        if (col.isSorted) {
          col.isSortedDescending = isSortedDescending;
        }
        return col;
      }),
    );
  };

  const initColumns = [
    {
      data: "string",
      fieldName: "name",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: true,
      key: "name",
      maxWidth: 250,
      minWidth: 150,
      name: "NAVN",
    },
    {
      data: "string",
      fieldName: "workingRole",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "workingRole",
      maxWidth: 250,
      minWidth: 100,
      name: "ROLLE",
    },
    {
      data: "string",
      fieldName: "aadGroups",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "aadGroups",
      maxWidth: 400,
      minWidth: 200,
      name: "AAD Groups",
    },
    {
      data: "string",
      fieldName: "email",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "email",
      maxWidth: 400,
      minWidth: 200,
      name: "EMAIL",
    },
    {
      data: "string",
      fieldName: "inactive",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "inactive",
      maxWidth: 144,
      minWidth: 40,
      name: "STATUS",
    },
    {
      data: "string",
      fieldName: "techDepartment",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "techDepartment",
      maxWidth: 144,
      minWidth: 40,
      name: "SEKTION",
    },
    {
      data: "string",
      fieldName: "techArea",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "disponent",
      maxWidth: 100,
      minWidth: 40,
      name: "DISPONENT",
    },
    // {
    //     data: 'number',
    //     fieldName: 'equipment',
    //     isPadded: true,
    //     isResizable: true,
    //     isRowHeader: true,
    //     key: 'equipment',
    //     maxWidth: 70,
    //     minWidth: 40,
    //     name: 'MASKINER',
    // },
    {
      data: "string",
      fieldName: "machineSkills",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: true,
      key: "machineSkills",
      maxWidth: 250,
      minWidth: 100,
      name: "Maskine Skills",
    },
    {
      data: "string",
      fieldName: "generalSkills",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      isSortedDescending: true,
      key: "generalSkills",
      maxWidth: 250,
      minWidth: 100,
      name: "General Skills",
    },
    // {
    //   data: "number",
    //   fieldName: "equipment",
    //   isPadded: true,
    //   isResizable: true,
    //   isRowHeader: true,
    //   key: "equipment",
    //   maxWidth: 70,
    //   minWidth: 40,
    //   name: "MASKINER",
    // },
    {
      data: "number",
      fieldName: "action",
      isPadded: true,
      isResizable: true,
      isRowHeader: true,
      key: "action",
      maxWidth: 60,
      minWidth: 40,
      name: "ACTION",
    },
  ];

  const [columns, setColumns] = useState(initColumns);

  const history = useHistory();

  const renderExpiryWarning = (fieldContent, fieldName) => {
    const arr = [];

    if (
      fieldContent
        ?.filter((el) => !el.inactive)
        ?.some(
          (fieldContentItem) =>
            differenceInDays(
              new Date(fieldContentItem[fieldName]),
              new Date(),
            ) < 0,
        )
    ) {
      arr.push(
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          color={"#F57C00"}
          style={{
            width: "24px",
            height: "24px",
          }}
        />,
      );
    }

    if (
      fieldContent
        ?.filter((el) => !el.inactive)
        ?.some((fieldContentItem) => {
          const diff = differenceInDays(
            new Date(fieldContentItem[fieldName]),
            new Date(),
          );
          return diff >= 0 && diff < 30;
        })
    ) {
      arr.push(
        <FontAwesomeIcon
          icon={faCircleExclamation}
          color={"#E3B505"}
          style={{
            width: "24px",
            height: "24px",
          }}
        />,
      );
    }

    return arr;
  };

  const renderItemColumn = (item, index, column) => {
    const fieldContent = item[column.fieldName || ""];
    const personaProperties = {
      size: PersonaSize.size32,
      imageUrl: item.imageUrl ? `${SOURCE_API}${item.imageUrl}` : "",
      text: `${item?.firstName} ${item?.lastName ?? ""}`,
    };

    if (item.inactive) {
      personaProperties.initialsColor = PersonaInitialsColor.coolGray;
      personaProperties.styles = {
        primaryText: {
          color: "#868685",
        },
      };
    }

    switch (column.key) {
      case "action":
        return allowEdit ? (
          <StaffControl staff={item} history={history} allowInactive={true} />
        ) : item.userId === currentUserId ? (
          <StaffControl staff={item} history={history} allowInactive={false} />
        ) : null;
      case "equipment":
        return item.inactive ? null : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link to={`/map-machine/${item.userId}`}>
              <MachineIconWithBadge count={item?.machineFavorites?.length} />
            </Link>
          </div>
        );
      case "color":
        return <ColorIndicator colorCode={item.color} />;
      case "name":
        return <Persona {...personaProperties} />;
      case "workingRole":
        const roles =
          item.workingRole !== "-1" &&
          item.workingRole
            .split(";")
            .filter((item) => item !== "-1")
            .map((key) => workingRoleOptions[key]?.text)
            .join(", ");
        return (
          <div className={classNames.alignItemRow} style={{ width: 300 }}>
            <WrapperText inactive={item.inactive}>
              {roles || "UNKNOWN ROLE"}
            </WrapperText>
          </div>
        );
      case "aadGroups":
        if (item.aadGroups) {
          const arr = item.aadGroups.map((aadGroup) => (
            <Badge key={aadGroup} text={aadGroup} />
          ));

          return <div>{arr}</div>;
        }
        return <Badge text={item.aadGroups ? item.aadGroups : "None"} />;
      case "email":
        return <WrapperText inactive={item.inactive}>{item.email}</WrapperText>;
      case "inactive":
        return <Badge text={item.inactive ? "Inactive" : "Active"} />;
      case "disponent":
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>
              {techAreaOptions.find(
                (techArea) => techArea.key === item.techArea,
              )?.text ?? "UNKNOWN"}
            </WrapperText>
          </div>
        );
      case "techDepartment":
        return (
          <MachineTableTechDepartmentInfoCell
            techDepartments={item.techDepartment.toString()}
          />
        );
      case "generalSkills":
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <span>
              <Link to={`/map-machine/${item.userId}?skill=general`}>
                <IconWithBadge
                  iconImgUrl={"./img/bookBlue.svg"}
                  count={fieldContent?.length || 0}
                />
              </Link>
            </span>
            {renderExpiryWarning(fieldContent, "acquired")}
          </div>
        );
      case "machineSkills":
        const machineSkillMachineId = item.machineSkills.map(
          (macSkill) => macSkill.machineId,
        );

        const generalSkillIds = item.generalSkills.map(
          (skill) => skill.skillId,
        );

        const machines = machinesData.filter((machineData) => {
          return machineSkillMachineId.includes(machineData.machineId);
        });

        const allRequiredSkills = machines.reduce(
          (prev, cur) => [...prev, ...cur.requiredSkills],
          [],
        );

        const isRed = !allRequiredSkills.every((skillId) =>
          generalSkillIds.includes(skillId),
        );

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <span>
              <Link to={`/map-machine/${item.userId}`}>
                <MachineIconWithBadge
                  isRed={isRed}
                  count={fieldContent?.length || 0}
                />
              </Link>
            </span>
            {/* {renderExpiryWarning(fieldContent, "introMachine")} */}
            {renderExpiryWarning(fieldContent, "reCertification")}
          </div>
        );
      default:
        return (
          <div className={classNames.alignItemRow}>
            <WrapperText inactive={item.inactive}>{fieldContent}</WrapperText>
          </div>
        );
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "10px 0",
          width: "calc(100% - 100px)",
        }}
      >
        <TooltipForText
          text={
            showingInactiveUsers
              ? "Showing inactive people"
              : "Hiding inactive people"
          }
          noWidth
        >
          <DefaultButton
            styles={buttonStyle(showingInactiveUsers, "", 0)}
            onClick={() => {
              toggleShowActiveUsers();
            }}
          >
            <FontAwesomeIcon
              icon={faPowerOff}
              className={
                showingInactiveUsers
                  ? classNames.selectedIcon
                  : classNames.unSelectedIcon
              }
            />
          </DefaultButton>
        </TooltipForText>
      </div>
      <DetailsList
        items={listData}
        compact={false}
        columns={columns}
        selectionMode={SelectionMode.none}
        setKey="none"
        layoutMode={DetailsListLayoutMode.justified}
        isHeaderVisible={true}
        onRenderItemColumn={renderItemColumn}
        onColumnHeaderClick={onClickSorting}
      />
    </div>
  );
};

export default MissingInfoStaffTable;
