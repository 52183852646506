// Import libraries
import axios, { AxiosResponse } from "axios";
import { MessageBarType } from "@fluentui/react";

// Import actions
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from "../message/message.actions";

// Import types
import { PrebookingActionTypes } from "./prebooking.types";
import { TBooking } from "../../types/api/booking";

// Import utils
import setDefaults from "../../utils/setDefaults";
import { SOURCE_API } from "../../utils/constants";
import { AppDispatch } from "../store";

export const getPrebookings =
  (from?: string, to?: string, showInactive?: boolean) =>
  async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      if (typeof from !== "string" && typeof to !== "string") {
        from = new Date(new Date().getFullYear() - 1, 0, 1).toISOString();
        to = new Date(new Date().getFullYear() + 1, 11, 31).toISOString();
      }

      // const response = await axios.get(`${SOURCE_API}/api/Booking/getall?from=${from}&to=${to}`);
      const response = await axios.get<AxiosResponse<TBooking[]>>(
        `${SOURCE_API}/api/Booking/getall?from=${from}` +
          (showInactive ? `&includeInactive=${showInactive}` : "")
      );
      return response.data.data;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return null;
    }
  };

export const getPrebooking =
  (prebookingId: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.get<AxiosResponse<TBooking>>(
        `${SOURCE_API}/api/Booking/get?id=${prebookingId}`
      );
      return response.data.data ? response.data.data : null;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return null;
    }
  };

export const createPrebooking =
  (prebookingData: TBooking) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.post(
        `${SOURCE_API}/api/Booking`,
        prebookingData
      );

      if (response.data.succeeded === true) {
        dispatch(
          saveMessage(
            "Prebooking successfully created!",
            MessageBarType.success
          )
        );
      } else {
        dispatch(saveMessage("There was an error"));
      }
      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const updatePrebooking =
  (prebookingData: TBooking) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.put(
        `${SOURCE_API}/api/Booking`,
        prebookingData
      );

      if (response.data.succeeded === true) {
        dispatch(
          saveMessage(
            "Prebooking successfully updated!",
            MessageBarType.success
          )
        );
      } else {
        dispatch(saveMessage("There was an error"));
      }
      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const deletePrebooking =
  (prebookingId: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.delete(
        `${SOURCE_API}/api/Booking?id=${prebookingId}`
      );

      if (response.data.succeeded === true) {
        dispatch(saveMessage("Prebooking deleted", MessageBarType.success));
      } else {
        dispatch(saveMessage("There was an error"));
      }
      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const setOverlayPrebookingId =
  (prebookingId: string) => async (dispatch: AppDispatch) => {
    dispatch({
      type: PrebookingActionTypes.UPDATE_OVERLAY_PREBOOKING_ID,
      payload: prebookingId,
    });
  };

export const clearOverlayPrebookingId = () => async (dispatch: AppDispatch) => {
  dispatch({
    type: PrebookingActionTypes.CLEAR_OVERLAY_PREBOOKING_ID,
  });
};

// @ts-ignore
export const changePrebookingBubbleInfo = (data) => (dispatch: AppDispatch) => {
  dispatch({
    type: PrebookingActionTypes.CHANGE_PREBOOKING_MODAL_INFO,
    payload: data,
  });
};
