// Import libraries
import React, { Fragment } from "react";
import { Rnd } from "react-rnd";

import { useBoolean } from "@fluentui/react-hooks";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import { PrimaryButton } from "@fluentui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd,faLink } from "@fortawesome/pro-regular-svg-icons";

import { TooltipForText } from "../../../common";

import moment from "moment";
import "moment/locale/da";

// Import constants
import { CALENDAR_STEP_WIDTH } from "../../../../utils/constants";

// Import components
import FixPositionBubble from "../../../common/FixPositionBubble";
import { useState } from "react";
import { useEffect } from "react";

const classNames = mergeStyleSets({
  buttonControls: {
    position: "relative",
  },
  crmLink: {
    fontSize: 20, 
    color: "black",
    position: "absolute",
    boxSizing: "border-box",
    right: "70px",
    top: "18px",
  },
  addButton: {
    position: "absolute",
    maxWidth: "40px",
    maxHeight: "40px",
    minWidth: "40px",
    minHeight: "40px",
    borderRadius: "4px",
    boxSizing: "border-box",
    right: "15px",
    top: "7px",
  },
  addIcon: {
    fontSize: "16px",
    lineHeight: "16px",
    display: "flex",
    justifyContent: "center",
  },
  image: {
    position: "absolute",
    left: "16px",
    top: "7.5px",
    width: "40px",
    height: "40px",
    borderRadius: "40px",
  },
  firstName: {
    position: "absolute",
    left: "64px",
    top: "7px",
    lineHeight: "24px",
    fontWeight: "400",
    fontSize: "14px",
    fontStyle: "normal",
    display: "flex",
    alignItems: "center",
    margin: "0px",
    maxWidth: "209px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  lastName: {
    position: "absolute",
    left: "64px",
    top: "35px",
    color: "#868685",
    fontSize: "11px",
    lineHeight: "12px",
    letterSpacing: "1px",
    fontWeight: "400",
    fontFamily: "Verdana",
    display: "flex",
    alignItems: "center",
    margin: "0px",
    maxWidth: "209px",
  },
});

const fixPositionBubbleStyles = {
  body: {
    height: "56px",
    position: "relative",
  },
  bodyContent: {
    padding: "0px",
  },
};

// Placing it here because currently it is not going to be used in other places
const AddSupervisorConfirmation = ({
  imageUrl,
  firstName,
  lastName,
  target,
  toggleTeaching,
  onClickConfirm,
  mainPositionRight,
  beakPositionRight,
  crmLink
}) => {
  return (
    <FixPositionBubble
      target={target}
      hasSmallHeadline={true}
      onDismiss={toggleTeaching}
      closeButtonAriaLabel="Close"
      mainPositionRight={mainPositionRight}
      beakPositionRight={beakPositionRight}
      styles={fixPositionBubbleStyles}
      calloutProps={{
        calloutWidth: 328,
      }}
    >
      <Persona
        imageUrl={imageUrl}
        size={PersonaSize.size40}
        className={classNames.image}
      />
      <p className={classNames.firstName}>{firstName}</p>
      <p className={classNames.lastName}>{lastName}</p>

      {
        crmLink &&
        <a target="_blank" rel="noreferrer" href={crmLink}>
          <FontAwesomeIcon
            icon={faLink}
            className={classNames.crmLink}
          />
        </a>
      }

      <PrimaryButton onClick={onClickConfirm} className={classNames.addButton}>
        <FontAwesomeIcon icon={faAdd} className={classNames.addIcon} />
      </PrimaryButton>
    </FixPositionBubble>
  );
};

const SupervisorPlaceholder = ({
  worker: { firstName, lastName, userId, role, imageUrl },
  horizontalPositionMap,
  start,
  end,
  calendarStepWidth = CALENDAR_STEP_WIDTH,
  onConfirm,
  allowEdit,
  crmLink
}) => {
  const [x, setX] = useState(0);
  const [width, setWidth] = useState(0);

  const [teachingBubbleAddSupervisor, { toggle: toggleTeachingAddSupervisor }] =
    useBoolean(false);

  useEffect(() => {
    if (!start || !end) {
      return;
    }
    let startDate = moment(start, "YYYY-M-D").format("YYYY-M-D");

    let endDate = moment(end.slice(0,10)).add(1, "d").format("YYYY-M-D");
    const startCalendar = horizontalPositionMap.startText.split(".").reverse();
    const endCalendar = horizontalPositionMap.endText.split(".").reverse();
    let startTemp =
      new Date(startDate) <
      new Date("20" + startCalendar[0], startCalendar[1] - 1, startCalendar[2])
        ? 0
        : horizontalPositionMap[startDate];
    let endTemp =
      new Date(endDate).getTime() >=
      new Date(
        "20" + endCalendar[0],
        endCalendar[1] - 1,
        parseInt(endCalendar[2]) + 1
      ).getTime()
        ? horizontalPositionMap[endDate] || horizontalPositionMap.end + 1
        : horizontalPositionMap[endDate];
    let tempX = startTemp * calendarStepWidth;
    let tempWidth = (endTemp - startTemp) * calendarStepWidth;
    if (typeof startTemp === "undefined" || typeof endTemp === "undefined") {
      return;
    }
    if (!startTemp && endTemp) {
      tempX = 0;
      tempWidth = endTemp * calendarStepWidth;
    }
    if (startTemp && !endTemp) {
      tempWidth =
        (horizontalPositionMap["end"] - startTemp) * calendarStepWidth;
    }
    setX(tempX);
    setWidth(tempWidth);
  }, [end, start, horizontalPositionMap, calendarStepWidth]);

  const text = `${firstName} ${lastName}`;

  const targetId = `supervisorPlaceholder-${userId}`;

  return (
    <>
      <Rnd
        style={{ zIndex: 3 }}
        size={{ width, height: 20 }}
        position={{ x, y: 0 }}
        enableResizing={false}
        disableDragging={true}
      >
        <TooltipForText text={text}>
          <div
            id={targetId}
            style={{
              backgroundColor: "#000",
              display: "flex",
              marginBottom: 12,
              height: 8,
              borderRadius: 2,
            }}
            onClick={allowEdit ? toggleTeachingAddSupervisor : null}
          ></div>
        </TooltipForText>
      </Rnd>
      {teachingBubbleAddSupervisor && (
        <AddSupervisorConfirmation
          target={`#${targetId}`}
          toggleTeaching={toggleTeachingAddSupervisor}
          onClickConfirm={() => {
            toggleTeachingAddSupervisor();
            onConfirm();
          }}
          firstName={firstName}
          lastName={lastName}
          imageUrl={imageUrl}
          crmLink={crmLink}
        />
      )}
    </>
  );
};

export default SupervisorPlaceholder;