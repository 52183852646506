// Import libraries
import axios, { AxiosResponse } from "axios";
import { MessageBarType } from "@fluentui/react";

// Import actions
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from "../message/message.actions";

// Import types
import { PlanActionTypes } from "./plan.types";
import { TPlan } from "../../types";
import { AppDispatch } from "../store";

// Import utils
import setDefaults from "../../utils/setDefaults";

// Import constants
import { SOURCE_API } from "../../utils/constants";

export const getPlans =
  (
    year = new Date().getFullYear(),
    fromDate: string,
    toDate: string,
    techDepartment: string,
    showInactive?: boolean
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      let from = new Date(`${year - 1}/1/1`).toISOString();
      let to = new Date(`${year + 1}/12/31`).toISOString();
      if (fromDate && toDate) {
        from = fromDate;
        to = toDate;
      }

      // if (showInactive) {
      //   if (from && to) {
      //     uri += `&includeInactive=${showInactive}`;
      //   } else {
      //     uri += `?includeInactive=${showInactive}`;
      //   }
      // }
      const response = await axios.get<AxiosResponse<TPlan[]>>(
        `${SOURCE_API}/api/Planning/getall?from=${from}&to=${to}${
          techDepartment ? `&techDepartment=${techDepartment}` : ""
        }` + (showInactive ? `&includeInactive=${showInactive}` : "")
      );

      return response.data.data.map((item) => ({
        ...item,
        projectName: item.projectName || "empty name",
      })) as TPlan[];
    } catch (error) {
      dispatch(checkTokenValidity(error));

      return null;
    }
  };

export const getPlan = (projectId: string) => async (dispatch: AppDispatch) => {
  try {
    setDefaults(localStorage.getItem("token"));

    const response = await axios.get(
      `${SOURCE_API}/api/Planning/get?projectIds=${projectId}`
    );
    return response.data.data[0] ? (response.data.data[0] as TPlan) : null;
  } catch (error) {
    dispatch(checkTokenValidity(error));
  }
};

export const createPlan =
  (planData: TPlan, bookingId: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const url = bookingId
        ? `${SOURCE_API}/api/Planning?bookingId=${bookingId}`
        : `${SOURCE_API}/api/Planning`;

      const response = await axios.post(url, planData);

      if (response.data.succeeded === true) {
        dispatch(
          saveMessage("Plan successfully saved!", MessageBarType.success)
        );
      } else {
        dispatch(saveMessage("There was an error"));
      }
      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const updatePlan =
  (planData: TPlan, bookingId?: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const url = bookingId
        ? `${SOURCE_API}/api/Planning?bookingId=${bookingId}`
        : `${SOURCE_API}/api/Planning`;
      const response = await axios.put(url, planData);

      if (response.data.succeeded === true) {
        dispatch(
          saveMessage("Plan successfully updated!", MessageBarType.success)
        );
      } else {
        dispatch(saveMessage("There was an error"));
      }
      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const deletePlan =
  (projectId: string) => async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.delete(
        `${SOURCE_API}/api/Planning?projectId=${projectId}`
      );

      if (response.data.succeeded === true) {
        dispatch(saveMessage("Plan deleted", MessageBarType.success));
      } else {
        dispatch(saveMessage("There was an error"));
      }
      return response.data.succeeded as boolean;
    } catch (error) {
      dispatch(checkTokenValidity(error));
      return false;
    }
  };

export const setPlanOverlayProjectId =
  (projectId: string, index: number) => async (dispatch: AppDispatch) => {
    dispatch({
      type: PlanActionTypes.UPDATE_OVERLAY_PROJECT_ID,
      payload: { projectId, index },
    });
  };

export const clearPlanOverlayProjectId =
  () => async (dispatch: AppDispatch) => {
    dispatch({
      type: PlanActionTypes.CLEAR_OVERLAY_PROJECT_ID,
    });
  };

export const getActivityByResourceIdAndResourceType =
  (resourceId: string, resourceType: string) =>
  async (dispatch: AppDispatch) => {
    try {
      setDefaults(localStorage.getItem("token"));
      const response = await axios.get(
        `${SOURCE_API}/api/activitylogger?resourceId=${resourceId}&resourceType=${resourceType}`
      );
      return response.data.data;
    } catch (error) {
      dispatch(checkTokenValidity(error));
    }
  };
