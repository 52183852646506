// @ts-nocheck
// Import libraries
import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { Persona, PersonaSize } from "@fluentui/react/lib/Persona";
import {
  Image,
  ImageFit,
  SearchBox,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import {
  ScrollablePane,
  ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import moment from "moment";
import { faPencil } from "@fortawesome/pro-regular-svg-icons";

// Import utils
import {
  workingRoleOptions,
  SOURCE_API,
  stationOptions,
  COLOR_CONS_HEX,
} from "../../../../utils/constants";
import { getProjectSkillColor } from "../../../../utils/skill";
import { searchBoxStyles } from "../../../../utils/theme";
import { TFindStaffSchedule, getDatesInRange } from "../../../../utils/utils";

// Import redux
import { changeShowOtherSections } from "../../../../redux/app/app.actions";

// Import components
import { TooltipForText } from "../../../common";
import ProjectRequirementDetail from "./ProjectRequirementDetail";
import { EBOOKED, TDisplay, TDisplayList } from "./PopupSupervisors";
import { AllMachineData, AllUserData } from "../../../../types/custom";
import { TPlan, User } from "../../../../types";
import { TPlanHorizontalPositionMap } from "../../../../types/planner";
import { TMachineDisplay, TTargetBubbleMachine } from "./PopupDriver";
import { TSizeGroupOptions } from "../../../../types/sizegroupoptions";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { getCrewServiceSchedule } from "../../../../utils/service";

const classNames = mergeStyleSets({
  Persona: {
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  station: {
    margin: 5,
    width: 25,
    height: 14,
    color: "#868685",
    background: "#DFDFDF",
    textAlign: "center",
    fontSize: 11,
    lineHeight: 14,
    borderRadius: 4,
    padding: 2,
  },
  favoriteDriver: {
    position: "absolute",
    marginLeft: "212px",
  },
  verifiedProjectSkill: {
    position: "absolute",
    left: "200px",
    top: "19px",
  },
  bar: {
    position: "absolute",
    left: "59px",
    cursor: "pointer",
    top: "47px",
  },
  draw: {
    position: "absolute",
    // left: "245px",
    color: COLOR_CONS_HEX.BLUE,
    cursor: "pointer",
    top: "43px",
    left: "85px",
  },
});

const bookedStateStyle = (state: EBOOKED) => ({
  fontSize: "12px",
  borderRadius: "50%",
  color: state === BOOKED_STATE.PARTIAL ? "#F57C00" : "#d50000",
  backgroundColor: state === BOOKED_STATE.PARTIAL ? "#F57C00" : "#d50000",
});

const BOOKED_STATE: {
  [k: string]: EBOOKED;
} = {
  NOT: "NOT",
  PARTIAL: "PARTIAL",
  FULLY: "FULLY",
};

type PopupWorkersProps = {
  allPlansData: TPlan[];
  target: string;
  toggleTeaching: () => void;
  staffData: User[];
  roles: string[];
  workers: AllUserData[];
  // findNearestPossibleTime: (
  //   staffPlanning: AllUserData,
  //   horizontalPositionMap: TPlanHorizontalPositionMap,
  //   roles: string
  // ) => { start: string; end: string } | boolean;
  findStaffSchedule: TFindStaffSchedule;
  onClickDraw: (
    user: User,
    role: string,
    targetBubbleMachine: TTargetBubbleMachine
  ) => void;
  onAdd: (
    user: TDisplay,
    role: string,
    targetBubbleMachine: TTargetBubbleMachine
  ) => void;
  horizontalPositionMap: TPlanHorizontalPositionMap;
  projectId: string;
  propName: string;
  otherSectionsStaff: User[];
  projectRequiredSkills: string[];
  role: string;
  machines: AllMachineData[];
  // drivers: AllUserData[];
  startAndEndDateEarliestMachine: {
    start: string | number;
    end: string | number;
  };
};

const PopupWorkers = ({
  allPlansData,
  target,
  toggleTeaching,
  onAdd,
  role,
  staffData,
  otherSectionsStaff,
  workers,
  // findNearestPossibleTime,
  horizontalPositionMap,
  findStaffSchedule,
  projectId,
  propName,
  startAndEndDateEarliestMachine,
  projectRequiredSkills,
  onClickDraw,
  machines,
}: PopupWorkersProps) => {
  const dispatch = useDispatch();
  const [displayList, setDisplayList] = useState<TDisplayList>([]);

  // ======================================== Use Selector ============================
  const showOtherSections = useSelector(
    // @ts-ignore
    (state) => state.app.showOtherSections[propName]
  ) as boolean;

  const { sizeGroupOptions } = useSelector(
    // @ts-ignore
    (state) => state.defaultData
  ) as {
    sizeGroupOptions: TSizeGroupOptions;
  };

  // ======================================== Use State ============================
  const [targetBubbleMachine, setTargetBubbleMachine] =
    useState<TTargetBubbleMachine | null>(null);
  const [listMachines, setListMachine] = useState<TMachineDisplay[]>([]);

  const startCalendar = horizontalPositionMap.startText.split(".").reverse();
  const endCalendar = horizontalPositionMap.endText.split(".").reverse();
  const startCalendarDate = new Date(
    +("20" + startCalendar[0]),
    +startCalendar[1] - 1,
    +startCalendar[2]
  );
  const endCalendarDate = new Date(
    +("20" + endCalendar[0]),
    +endCalendar[1] - 1,
    +endCalendar[2]
  );
  const currentStaffData = useMemo(
    () =>
      showOtherSections ? [...staffData, ...otherSectionsStaff] : staffData,
    [showOtherSections, staffData, otherSectionsStaff]
  );
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const tempList = machines.map((item) => ({
      ...item.array[0],
      sizeGroupDisplay:
        sizeGroupOptions.find(
          (sizeGroup) => sizeGroup.key === item.array[0].sizeGroup
        )?.text ?? "UNKNOWN",
    }));
    setListMachine(tempList);
  }, [machines, sizeGroupOptions]);

  useEffect(() => {
    const roleWorking = workingRoleOptions.find((item) => item.role === role);
    const tempList = [...currentStaffData]
      .filter(
        (item) =>
          // @ts-ignore
          item.workingRole.includes("" + roleWorking.key) &&
          `${item.firstName} ${item.lastName}`
            .toLowerCase()
            .includes(searchValue.toLowerCase())
      )
      .map((item) => ({
        ...item,
        roleDisplay: roleWorking?.text || "",
        generalSkills: item?.generalSkills || [],
        machineSkills: item?.machineSkills || [],
      }));
    setDisplayList(tempList);
  }, [role, showOtherSections, searchValue, currentStaffData]);

  const checkStaffBookedCondition = (staffId: string) => {
    let staffPlanning = workers.find((staff) => staff.id === staffId);
    if (!staffPlanning) {
      const staffSchedule = findStaffSchedule(
        staffId,
        projectId,
        propName,
        allPlansData
      )?.filter(
        (item) =>
          new Date(item.start.slice(0, -1)) <= endCalendarDate &&
          new Date(item.end.slice(0, -1)) >= startCalendarDate
      );

      const serviceSchedules = getCrewServiceSchedule(staffId, machines).filter(
        (item) => {
          return (
            new Date(item.start.slice(0, -1)) <= endCalendarDate &&
            new Date(item.end.slice(0, -1)) >= startCalendarDate
          );
        }
      );

      const staffVacation = currentStaffData
        .find((staff) => staff.userId === staffId)
        ?.starfVacations?.filter(
          (item) =>
            new Date(item.start.slice(0, -1)) <= endCalendarDate &&
            new Date(item.end.slice(0, -1)) >= startCalendarDate
        );
      staffPlanning = {
        id: staffId,
        array: [],
        staffSchedule,
        staffVacation: staffVacation || [],
        serviceSchedules,
      };
    } else {
      staffPlanning.serviceSchedules = getCrewServiceSchedule(
        staffId,
        machines
      ).filter((item) => {
        return (
          new Date(item.start.slice(0, -1)) <= endCalendarDate &&
          new Date(item.end.slice(0, -1)) >= startCalendarDate
        );
      });
    }
    if (
      !staffPlanning.staffSchedule?.length &&
      !staffPlanning.staffVacation?.length &&
      !staffPlanning.array?.length &&
      !staffPlanning.serviceSchedules.length
    )
      return BOOKED_STATE.NOT;
    // const possibleTime = findNearestPossibleTime(staffPlanning, horizontalPositionMap, 'staff');
    // if (possibleTime) return BOOKED_STATE.PARTIAL;
    let { start: machineStart, end: machineEnd } =
      startAndEndDateEarliestMachine;

    const machineStartDate = new Date(machineStart);
    const machineEndDate = new Date(machineEnd);
    const today = new Date();

    if (today > machineStartDate && today <= machineEndDate) {
      machineStart = moment(today).format("YYYY-MM-DD");
    }

    // This means that there are no machines added currently
    //  in the detailed planner for the machine requirement being viewed
    if (machineStart === -1 && machineEnd === -1) {
      return BOOKED_STATE.FULLY;
    }

    const staffHorizontalMap: { [k: string]: number } = {};

    const keys: (keyof AllUserData)[] = [
      "staffVacation",
      "staffSchedule",
      "serviceSchedules",
    ];

    for (const k of keys) {
      const arr = staffPlanning[k as keyof AllUserData];

      if (arr && Array.isArray(arr)) {
        if (k === "serviceSchedules") {
          console.log(arr);
        }

        const { length } = arr;

        for (let i = 0; i < length; i++) {
          // Could be a staff vacation or staff schedule data
          // both have start and end date string
          const data = arr[i];
          const { start, end } = data;

          if (
            start &&
            end &&
            new Date(start) < new Date(end) &&
            !isNaN(new Date(start).getTime()) &&
            !isNaN(new Date(end).getTime())
          ) {
            const datesStr = getDatesInRange(start, end).map((date) =>
              date.toISOString().slice(0, 10)
            );

            datesStr.forEach((dateStr) => {
              staffHorizontalMap[dateStr] = 1;
            });
          }
        }
      }
    }

    const machineDateRangeStrings = getDatesInRange(
      machineStart,
      machineEnd
    ).map((date) => date.toISOString().slice(0, 10));

    let counter = 0;

    machineDateRangeStrings.forEach((str) => {
      if (staffHorizontalMap[str]) {
        counter++;
      }
    });

    if (counter === machineDateRangeStrings.length) {
      return BOOKED_STATE.FULLY;
    } else if (counter > 0) {
      return BOOKED_STATE.PARTIAL;
    }

    return BOOKED_STATE.NOT;
  };

  // const hasAllProjectSkills = ({ generalSkills }) => {
  //   const generalSkillsIds = generalSkills.map((skill) => skill.skillId);

  //   return projectRequiredSkills.every((projectRequiredSkill) =>
  //     generalSkillsIds.includes(projectRequiredSkill)
  //   );
  // };

  const machineData = useMemo<AllMachineData | null>(() => {
    if (targetBubbleMachine && Array.isArray(machines)) {
      return (
        machines.find(
          (machine) => machine.id === targetBubbleMachine.machineId
        ) || null
      );
    }

    return null;
  }, [machines, targetBubbleMachine]);

  return (
    <TeachingBubble
      target={`#${target}`}
      hasSmallHeadline={true}
      onDismiss={toggleTeaching}
      closeButtonAriaLabel="Close"
    >
      <div
        style={{
          height: "200px",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ScrollablePane
          style={{ height: "200px" }}
          scrollbarVisibility={ScrollbarVisibility.auto}
        >
          {/* vertical */}
          <Stack tokens={{ childrenGap: 10 }}>
            {listMachines &&
              listMachines.map((machine) => (
                <div
                  id={`machine-${machine.machineId}`}
                  key={`machine-${machine.machineId}`}
                  className={classNames.Persona}
                  onClick={() => {
                    setTargetBubbleMachine({
                      machineId: machine.machineId,
                      machineName: machine.machineName,
                      techArea: machine.techAreas,
                    });
                  }}
                >
                  <Persona
                    text={machine.machineName}
                    secondaryText={machine.sizeGroupDisplay}
                    size={PersonaSize.size40}
                    imageUrl={
                      machine.imageUrl ? `${SOURCE_API}${machine.imageUrl}` : ""
                    }
                  />
                  <div className={classNames.station}>
                    {machine.techDepartments !== "-1"
                      ? machine.techDepartments
                          .split(";")
                          .reduce(
                            // @ts-ignore
                            (acc, curr) => [
                              ...acc,
                              // @ts-ignore
                              ...stationOptions[curr].options,
                            ],
                            []
                          )
                          // @ts-ignore
                          .find((option) => option.key === machine.techArea)
                          ?.shortcutText
                      : ""}
                  </div>
                </div>
              ))}
          </Stack>
        </ScrollablePane>
      </div>
      {targetBubbleMachine ? (
        <TeachingBubble
          target={`#machine-${targetBubbleMachine.machineId}`}
          hasSmallHeadline={true}
          onDismiss={() => {
            setTargetBubbleMachine(null);
          }}
          closeButtonAriaLabel="Close"
        >
          <SearchBox
            styles={searchBoxStyles}
            placeholder="Søg"
            clearButtonProps={{
              style: {
                backgroundColor: "#FFFFFF",
                marginLeft: "20px",
              },
            }}
            value={searchValue}
            onChange={(e, value) => setSearchValue(value || "")}
          />
          <hr
            style={{
              width: "145%",
              marginLeft: "-50px",
              marginTop: "-2px",
              opacity: 0.5,
            }}
          />
          <div
            style={{
              height: ["managers", "workers"].includes(propName) ? 240 : 200,
              position: "relative",
              backgroundColor: "white",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <ScrollablePane
              style={{ height: 200 }}
              scrollbarVisibility={ScrollbarVisibility.auto}
            >
              {/* vertical */}
              <Stack tokens={{ childrenGap: 10 }}>
                {displayList &&
                  displayList.map((user) => (
                    <div
                      key={`worker-${user.userId}`}
                      className={classNames.Persona}
                      style={{
                        opacity: `${
                          propName !== "managers" &&
                          checkStaffBookedCondition(user.userId) ===
                            BOOKED_STATE.FULLY
                            ? 0.4
                            : 1
                        }`,
                        cursor: `${
                          propName !== "managers" &&
                          checkStaffBookedCondition(user.userId) ===
                            BOOKED_STATE.FULLY
                            ? "context-menu"
                            : "pointer"
                        }`,
                        paddingBottom: 15,
                        position: "relative",
                      }}
                      onClick={() => {
                        // propName !== "managers"
                        //   ? checkStaffBookedCondition(user.userId) !==
                        //       BOOKED_STATE.FULLY && onAdd(user, role)
                        //   : onAdd(user, role);
                      }}
                    >
                      {" "}
                      <Persona
                        text={`${user?.firstName} ${user?.lastName}`}
                        secondaryText={user.roleDisplay}
                        size={PersonaSize.size40}
                        imageUrl={
                          user.imageUrl ? `${SOURCE_API}${user.imageUrl}` : ""
                        }
                      />
                      <TooltipHost
                        content={
                          <div style={{ backgroundColor: "transparent" }}>
                            <ProjectRequirementDetail />
                          </div>
                        }
                        // This id is used on the tooltip itself, not the host
                        // (so an element with this id only exists when the tooltip is shown)
                        calloutProps={{
                          gapSpace: 0,
                          backgroundColor: "white",
                          styles: {
                            container: {
                              backgroundColor: "white",
                            },
                            root: {
                              backgroundColor: "white",
                            },
                            calloutMain: {
                              backgroundColor: "white",
                            },
                            beakCurtain: {
                              backgroundColor: "white",
                            },
                          },
                        }}
                        styles={{
                          root: {
                            display: "inline-block",
                            backgroundColor: "white",
                            position: "absolute",
                            left: "200px",
                            // top: "19px",
                            top: "39px",
                          },
                        }}
                        id={"projekter-" + user.userId}
                      >
                        {machineData ? (
                          <Image
                            src={`${window.location.origin}/img/projekter.svg`}
                            imageFit={ImageFit.cover}
                            alt="project"
                            width={15}
                            height={15}
                            // className={classNames.verifiedProjectSkill}
                            style={{
                              filter: getProjectSkillColor({
                                machine: machineData,
                                projectSkills: projectRequiredSkills,
                                staff: user,
                              }),
                            }}
                            id={"projekter-" + user.userId}
                          />
                        ) : (
                          <></>
                        )}
                      </TooltipHost>
                      <Image
                        src={`${window.location.origin}/img/bar.svg`}
                        imageFit={ImageFit.cover}
                        alt="place staff"
                        className={classNames.bar}
                        onClick={() => {
                          propName !== "managers"
                            ? checkStaffBookedCondition(user.userId) !==
                                BOOKED_STATE.FULLY &&
                              onAdd(user, role, targetBubbleMachine)
                            : onAdd(user, role, targetBubbleMachine);
                        }}
                      />
                      <FontAwesomeIcon
                        icon={faPencil as IconDefinition}
                        className={classNames.draw}
                        onClick={() => {
                          propName !== "managers"
                            ? checkStaffBookedCondition(user.userId) !==
                                BOOKED_STATE.FULLY &&
                              onClickDraw &&
                              onClickDraw(user, role, targetBubbleMachine)
                            : onClickDraw &&
                              onClickDraw(user, role, targetBubbleMachine);
                        }}
                      />
                      {propName !== "managers" &&
                        [BOOKED_STATE.PARTIAL, BOOKED_STATE.FULLY].includes(
                          checkStaffBookedCondition(user.userId)
                        ) && (
                          // <FontAwesomeIcon style={{ ...bookedStateStyle(checkStaffBookedCondition(user.userId)) }} icon={faCircle} />
                          <div
                            style={{
                              position: "absolute",
                              marginLeft: "235px",
                              textAlign: "right",
                              top: "39px",
                            }}
                          >
                            <TooltipForText
                              text="Overlap during machine requirement"
                              noWidth
                            >
                              <FontAwesomeIcon
                                style={{
                                  ...bookedStateStyle(
                                    checkStaffBookedCondition(user.userId)
                                  ),
                                }}
                                icon={faCircle as IconDefinition}
                              />
                            </TooltipForText>
                          </div>
                        )}
                      <div className={classNames.station}>
                        {stationOptions[user.techDepartment]?.options.find(
                          (option) => option.key === user.techArea
                        )?.shortcutText ?? ""}
                      </div>
                    </div>
                  ))}
              </Stack>
            </ScrollablePane>
            <div
              style={{
                margin: "220px auto 20px auto",
                color: "rgb(0, 108, 173)",
                cursor: "pointer",
                display: propName === "managers" ? "unset" : "hidden",
              }}
              onClick={() =>
                dispatch(changeShowOtherSections(!showOtherSections, propName))
              }
            >
              {showOtherSections ? "Hide" : "Show"} other sections
            </div>
          </div>
        </TeachingBubble>
      ) : (
        <></>
      )}
    </TeachingBubble>
  );
};

export default PopupWorkers;
