import {
  Image,
  ImageFit,
  Persona,
  PersonaSize,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  Stack,
  TeachingBubble,
  TooltipHost,
  mergeStyleSets,
} from "@fluentui/react";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faPencil } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from "react-redux";

// Import utils
import { searchBoxStyles } from "../../../utils/theme";
import { getMachineSkillColor } from "../../../utils/skill";
import {
  COLOR_CONS_HEX,
  SOURCE_API,
  stationOptions,
  workingRoleOptions,
} from "../../../utils/constants";

import {
  checkStaffBookedCondition,
  bookedStateStyle,
} from "../../../utils/mapping";

// Import components
import { TooltipForText } from "../../common";
import MachineSkillRequirementDetail from "../planning/detailedPlanning/MachineSkillRequirementDetail";

// Import redux
import { changeShowOtherSections } from "../../../redux/app/app.actions";
import { OverlapKeys } from "../../../redux/project/project.actions";

// Import types
import { AllMachineData, AllUserData } from "../../../types/custom";
import {
  BOOKED_STATE,
  TDisplayList,
} from "../planning/detailedPlanning/PopupSupervisors";

// Import components

const classNames = mergeStyleSets({
  Persona: {
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  station: {
    margin: 5,
    width: 25,
    height: 14,
    color: "#868685",
    background: "#DFDFDF",
    textAlign: "center",
    fontSize: 11,
    lineHeight: 14,
    borderRadius: 4,
    padding: 2,
  },
  favoriteDriver: {
    position: "absolute",
    marginLeft: "212px",
  },
  verifiedProjectSkill: {
    position: "absolute",
    left: "200px",
    top: "19px",
  },
  bar: {
    position: "absolute",
    left: "59px",
    cursor: "pointer",
    top: "47px",
  },
  draw: {
    position: "absolute",
    // left: "245px",
    color: COLOR_CONS_HEX.BLUE,
    cursor: "pointer",
    top: "43px",
    left: "85px",
  },
});

type PopupStaffProps = {
  targetId: string;
  onDismiss?: () => void;
  propName: OverlapKeys;
  users: {
    otherSectionStaffs: AllUserData[];
    users: AllUserData[];
  };
  staffsAllData: AllUserData[];
  machineData?: AllMachineData | null;
  onAdd: (user: AllUserData, role: OverlapKeys) => void;
  start: string;
  end: string;
  onClickDraw?: (id: string) => void;
  isDrawingNew?: boolean;
};

// We can use the same Popup UI For Driver,Supervisor and Drivers
// We will use HOC to determine how to check availability of the employee
const PopupStaff = ({
  targetId,
  onDismiss,
  propName,
  users: mapUsers,
  staffsAllData,
  machineData,
  onAdd,
  start,
  end,
  onClickDraw,
  isDrawingNew,
}: PopupStaffProps) => {
  const dispatch = useDispatch();

  const { users, otherSectionStaffs } = mapUsers;

  // ======================================== Use Selector ============================
  const showOtherSections = useSelector(
    // @ts-ignore
    (state) => state.app.showOtherSections[propName],
  ) as boolean;

  // =================================== Use State ========================================
  const [searchValue, setSearchValue] = useState<string>();

  const [displayList, setDisplayList] = useState<TDisplayList>([]);

  useEffect(() => {
    if (users?.length) {
      const list: TDisplayList = [];

      [...users, ...(showOtherSections ? otherSectionStaffs : [])]
        .filter(({ staffData: { firstName, lastName } }) => {
          const name = (firstName + " " + lastName).toLocaleLowerCase();

          return !searchValue || name.includes(searchValue.toLocaleLowerCase());
        })
        .forEach((user) => {
          list.push({
            ...user,
            roleDisplay:
              workingRoleOptions.find(
                (roleOption) => roleOption.name === propName,
              )?.text || "",
          });
        });

      setDisplayList(list);
    }
  }, [users, showOtherSections, otherSectionStaffs, propName, searchValue]);

  return (
    // @ts-ignore
    <TeachingBubble
      target={`#${targetId}`}
      hasSmallHeadline={true}
      onDismiss={() => {
        onDismiss && onDismiss();
      }}
      closeButtonAriaLabel="Close"
    >
      <SearchBox
        styles={searchBoxStyles}
        placeholder="Søg"
        clearButtonProps={{
          style: {
            backgroundColor: "#FFFFFF",
            marginLeft: "20px",
          },
        }}
        value={searchValue}
        onChange={(e, value) => setSearchValue(value || "")}
      />
      <hr
        style={{
          width: "145%",
          marginLeft: "-50px",
          marginTop: "-2px",
          opacity: 0.5,
        }}
      />
      <div
        style={{
          height: ["managers", "workers"].includes(propName) ? 240 : 200,
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ScrollablePane
          style={{ height: 200 }}
          scrollbarVisibility={ScrollbarVisibility.auto}
        >
          {/* vertical */}
          <Stack tokens={{ childrenGap: 10 }}>
            {displayList &&
              displayList.map((user) => {
                const bookedCondition = checkStaffBookedCondition({
                  end,
                  start,
                  user,
                });

                const bookedStyle = bookedStateStyle(bookedCondition);

                return (
                  <div
                    key={`worker-${user.staffData.userId}`}
                    className={classNames.Persona}
                    style={{
                      opacity: `${
                        // propName !== "managers" &&
                        bookedCondition === BOOKED_STATE.FULLY ? 0.4 : 1
                      }`,
                      cursor: `${
                        // propName !== "managers" &&
                        bookedCondition === BOOKED_STATE.FULLY
                          ? "context-menu"
                          : "pointer"
                      }`,
                      paddingBottom: 15,
                      position: "relative",
                    }}
                    onClick={() => {
                      // propName !== "managers"
                      //   ? checkStaffBookedCondition(user.userId) !==
                      //       BOOKED_STATE.FULLY && onAdd(user, role)
                      //   : onAdd(user, role);
                    }}
                  >
                    {" "}
                    <Persona
                      text={`${user.staffData.firstName} ${user.staffData.lastName}`}
                      secondaryText={user.roleDisplay}
                      size={PersonaSize.size40}
                      imageUrl={
                        user.staffData.imageUrl
                          ? `${SOURCE_API}${user.staffData.imageUrl}`
                          : ""
                      }
                    />
                    <TooltipHost
                      content={
                        <div style={{ backgroundColor: "transparent" }}>
                          <MachineSkillRequirementDetail />
                        </div>
                      }
                      // This id is used on the tooltip itself, not the host
                      // (so an element with this id only exists when the tooltip is shown)
                      calloutProps={{
                        gapSpace: 0,
                        backgroundColor: "white",
                        styles: {
                          container: {
                            backgroundColor: "white",
                          },
                          root: {
                            backgroundColor: "white",
                          },
                          calloutMain: {
                            backgroundColor: "white",
                          },
                          beakCurtain: {
                            backgroundColor: "white",
                          },
                        },
                      }}
                      styles={{
                        root: {
                          display: "inline-block",
                          backgroundColor: "white",
                          position: "absolute",
                          marginLeft: "212px",
                        },
                      }}
                      id={"machineInfo-" + user.staffData.userId}
                    >
                      {machineData ? (
                        <Image
                          src={`${window.location.origin}/img/machineBlue.svg`}
                          imageFit={ImageFit.cover}
                          alt="machineFavorite"
                          width={19}
                          height={18}
                          // className={classNames.favoriteDriver}
                          style={{
                            filter: getMachineSkillColor({
                              machine: machineData,
                              staff: user.staffData,
                            }),
                          }}
                          id={"machineInfo-" + user.staffData.userId}
                        />
                      ) : (
                        <></>
                      )}
                    </TooltipHost>
                    {/* {machineData ? (
                  <Image
                    src={`${window.location.origin}/img/projekter.svg`}
                    imageFit={ImageFit.cover}
                    alt="project"
                    width={15}
                    height={15}
                    // className={classNames.verifiedProjectSkill}
                    style={{
                      filter: getProjectSkillColor({
                        machine: machineData,
                        projectSkills: projectRequiredSkills,
                        staff: user,
                      }),
                    }}
                    id={"projekter-" + user.userId}
                  />
                ) : (
                  <></>
                )} */}
                    <Image
                      src={`${window.location.origin}/img/bar.svg`}
                      imageFit={ImageFit.cover}
                      alt="place staff"
                      className={classNames.bar}
                      onClick={() => {
                        // propName !== "managers"
                        //   ? checkStaffBookedCondition(user.userId) !==
                        //       BOOKED_STATE.FULLY &&
                        //     onAdd(user, role, targetBubbleMachine)
                        //   : onAdd(user, role, targetBubbleMachine);
                        const valid =
                          bookedCondition !== "FULLY" ||
                          propName === "managers";

                        if (valid) {
                          onAdd(user, propName);
                          onDismiss && onDismiss();
                        }
                      }}
                    />
                    <FontAwesomeIcon
                      icon={faPencil as IconDefinition}
                      className={classNames.draw}
                      style={{
                        visibility: isDrawingNew ? "hidden" : "unset",
                      }}
                      // onClick={() => {
                      //   propName !== "managers"
                      //     ? checkStaffBookedCondition(user.userId) !==
                      //         BOOKED_STATE.FULLY &&
                      //       onClickDraw &&
                      //       onClickDraw(user, role, targetBubbleMachine)
                      //     : onClickDraw &&
                      //       onClickDraw(user, role, targetBubbleMachine);
                      // }}
                      onClick={() => {
                        onClickDraw && onClickDraw(user.id);
                      }}
                    />
                    {propName !== "managers" &&
                      [BOOKED_STATE.PARTIAL, BOOKED_STATE.FULLY].includes(
                        bookedCondition,
                      ) && (
                        // <FontAwesomeIcon style={{ ...bookedStateStyle(checkStaffBookedCondition(user.userId)) }} icon={faCircle} />
                        <div
                          style={{
                            position: "absolute",
                            marginLeft: "235px",
                            textAlign: "right",
                          }}
                        >
                          <TooltipForText
                            text="Overlap during machine requirement"
                            noWidth
                          >
                            <FontAwesomeIcon
                              style={bookedStyle}
                              icon={faCircle as IconDefinition}
                            />
                          </TooltipForText>
                        </div>
                      )}
                    <div className={classNames.station}>
                      {stationOptions[
                        user.staffData.techDepartment
                      ]?.options.find(
                        (option) => option.key === user.staffData.techArea,
                      )?.shortcutText ?? ""}
                    </div>
                  </div>
                );
              })}
          </Stack>
        </ScrollablePane>
        <div
          style={{
            margin: "220px auto 20px auto",
            color: "rgb(0, 108, 173)",
            cursor: "pointer",
            display: propName === "managers" ? "unset" : "hidden",
          }}
          onClick={() =>
            dispatch(changeShowOtherSections(!showOtherSections, propName))
          }
        >
          {showOtherSections ? "Hide" : "Show"} other sections
        </div>
      </div>
    </TeachingBubble>
  );
};

export default PopupStaff;
