import axios from 'axios';
import setDefaults from '../../utils/setDefaults';
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from '../message/message.actions';
import { MessageBarType } from '@fluentui/react';
import { SOURCE_API } from '../../utils/constants';

export const getCommentByProjectId = (projectId, pageNum, pageSize = 2) => async dispatch =>  {
    try {
        setDefaults(localStorage.getItem('token'));
        const response = await axios.get(`${SOURCE_API}/api/Comment/getbyprojectid?projectId=${projectId}&pageNum=${pageNum}&pageSize=${pageSize}`);
        return response.data.data
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
};

export const postNewComment = (commentData) => async(dispatch) => {
    try {
        setDefaults(localStorage.getItem('token'));
        const response = await axios.post(`${SOURCE_API}/api/Comment`, commentData);

        if(response.data.succeeded === true) {
            // dispatch(saveMessage('Comment added!', MessageBarType.success));
        } else {
            dispatch(saveMessage('There was an error'));
        }
        return response.data.succeeded;
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
};

export const deleteComment = (commentId) => async(dispatch) => {
    try {
        setDefaults(localStorage.getItem('token'));
        const response = await axios.delete(`${SOURCE_API}/api/Comment?commentId=${commentId}`);

        if(response.data.succeeded === true) {
            dispatch(saveMessage('Comment deleted', MessageBarType.success));
        } else {
            dispatch(saveMessage('There was an error'));
        }
        return response.data.succeeded;
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
}