// Import libraries
import React, {ReactElement} from 'react';
import { useCacheBuster } from '../../hooks';

// Import types
import { children } from "../../types";

type CacheBusterProps = {
    children: children,
};
  
const CacheBuster = ({children}: CacheBusterProps): ReactElement=>{
    useCacheBuster();
    
    return <>
        {children}
    </>
}

export default CacheBuster