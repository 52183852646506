import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import Select from "react-select";
import { IconButton, PrimaryButton, Stack } from "@fluentui/react";
import {
  primaryButtonStyles,
  inputStyles,
  customAutoCompleteStyles,
} from "../../../utils/theme";
import { Label } from "@fluentui/react/lib/Label";
import { TextField } from "@fluentui/react/lib/TextField";
import { CustomInputErrorComponent } from "../../common";
import CalendarCustom from "../../common/CalendarCustom";
import CalendarSchedule from "../../common/calendar/CalendarSchedule";
import { handleBeforeSubmitPeriod } from "../../../utils/utils";

const classNames = mergeStyleSets({
  container: {
    marginBottom: 24,
  },
  label: {
    fontSize: 29,
    lineHeight: 29,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 92px 118px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: 320,
    margin: "0 auto",
  },
  input: {
    marginTop: 24,
  },
  back: {
    width: 82,
  },
  selector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(159, 159, 159)",
    borderRadius: 4,
    height: 46,
    width: 318,
    marginTop: 24,
    selectors: {
      ":hover": {
        border: "1px solid rgb(42, 42, 42)",
      },
    },
  },
});

export const MachineServiceReasonOptions = [
  {
    key: "Employee",
    label: "Employee",
  },
  {
    key: "Service",
    label: "Service",
  },
];

const MachineServiceForm = ({
  values: { machineIndex, comment, period = [new Date(), new Date()], reason },
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  handleChange,
  isValid,
  data,
  bookedSchedules,
  targetInfo,
  showMachineSelector = true,
  text = "Save",
}) => {
  const dispatch = useDispatch();
  const machineIndexOptions = useMemo(
    () =>
      data.map((machine, index) => ({
        key: index,
        label: machine.machineName,
      })),
    [data]
  );

  const setPeriod = (newPeriod) => {
    setFieldValue("period", newPeriod);
  };

  const onSubmit = () => {
    const overlap = handleBeforeSubmitPeriod(
      bookedSchedules,
      targetInfo,
      period,
      dispatch
    );
    if (!overlap) handleSubmit();
  };

  return (
    <form className={classNames.container}>
      <div className={classNames.content}>
        <div
          style={{
            fontFamily: "Verdana",
            fontSize: 11,
            fontWeight: "bold",
            transform: "translate(9px, 41px)",
            zIndex: 2,
          }}
        >
          Reason
        </div>
        <Select
          placeholder="VÆLG"
          name="reason"
          closeMenuOnSelect={true}
          defaultValue={MachineServiceReasonOptions.find(
            (item) => item.key === reason
          )}
          options={MachineServiceReasonOptions}
          isClearable={true}
          isSearchable={true}
          onChange={(item) => setFieldValue("reason", item?.key)}
          className={classNames.input}
          styles={customAutoCompleteStyles(errors.reason, true)}
          getOptionValue={(option) => option.label}
        />
        <TextField
          label="COMMENT"
          styles={{
            ...inputStyles,
            fieldGroup: { ...inputStyles.fieldGroup, background: "none" },
          }}
          value={comment}
          name="comment"
          placeholder="Comment"
          className={classNames.input}
          onChange={handleChange}
          errorMessage={touched.comment || errors.comment ? errors.comment : ""}
        />
        {showMachineSelector ? (
          <>
            <div
              style={{
                fontFamily: "Verdana",
                fontSize: 11,
                fontWeight: "bold",
                transform: "translate(9px, 41px)",
                zIndex: 2,
              }}
            >
              NAVN
            </div>
            <Select
              placeholder="VÆLG"
              name="name"
              closeMenuOnSelect={true}
              defaultValue={machineIndexOptions.find(
                (item) => item.key === machineIndex
              )}
              options={machineIndexOptions}
              isClearable={true}
              isSearchable={true}
              onChange={(item) => setFieldValue("machineIndex", item?.key)}
              className={classNames.input}
              styles={customAutoCompleteStyles(errors.machineIndex, true)}
              getOptionValue={(option) => option.label}
            />
            <CustomInputErrorComponent
              errorMessage={
                touched.machineIndex || errors.machineIndex
                  ? errors.machineIndex
                  : ""
              }
            />
            {typeof machineIndex !== "undefined" && (
              <CalendarCustom
                period={period}
                setPeriod={setPeriod}
                bookedSchedules={bookedSchedules}
                targetInfo={targetInfo}
              />
            )}
            <CustomInputErrorComponent
              errorMessage={
                machineIndex && (touched.period || errors.period)
                  ? errors.period
                  : ""
              }
            />
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "20px 0 -15px 0",
        }}
      >
        <PrimaryButton
          disabled={!isValid}
          text={text}
          onClick={onSubmit}
          styles={primaryButtonStyles}
        />
      </div>
    </form>
  );
};

export default MachineServiceForm;
