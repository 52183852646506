// Import libraries
import { useState, useEffect } from "react";
import Select from "react-select";
import {
  IconButton,
  PrimaryButton,
  Stack,
  TeachingBubble,
} from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { TextField } from "@fluentui/react/lib/TextField";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPalette } from "@fortawesome/pro-regular-svg-icons";

// Import components
import {
  ColorPickerComponent,
  CustomInputErrorComponent,
  FileUpload,
} from "../../common";

// Import utils
import {
  techDepartmentOptions,
  stationOptions,
  noneValue,
  SKILL_TYPE_KEY,
  ignoreStationOptionsList,
} from "../../../utils/constants";
import {
  customAutoCompleteStyles,
  inputStyles,
  primaryButtonStyles,
} from "../../../utils/theme";
import RequiredSkillsTable from "./RequiredSkillsTable";
import { getMachineDocumentLink } from "../../../redux/machines/machines.utils";
import { hasEditRight } from "../../../utils/utils";

const classNames = mergeStyleSets({
  selector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(159, 159, 159)",
    borderRadius: 4,
    height: 46,
    // width: 318,
    marginBottom: 24,
    selectors: {
      ":hover": {
        border: "1px solid rgb(42, 42, 42)",
      },
    },
  },
  container: {
    minHeight: "100vh",
    background: "#F1F1F1",
  },
  label: {
    fontSize: 29,
    lineHeight: 29,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 116px 118px",
  },
  back: {
    width: 76,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: "33%",
    minWidth: 320,
    maxWidth: 500,
    // margin: "0 auto",
  },
  input: {
    marginBottom: 24,
  },
  fileErrorMessage: {
    fontSize: "12px",
    fontWeight: 400,
    color: " rgb(164, 38, 44)",
    marginBottom: 24,
  },
  icon: {
    fontSize: 20,
    cursor: "pointer",
    position: "relative",
    top: 4,
    right: 16,
  },
  checkbox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
    padding: 10,
    alignItems: "center",
    border: "1px solid rgb(159, 159, 159)",
    fontSize: 14,
    fontWeight: 400,
    height: 48,
    boxSizing: "border-box",
  },
});

const checkboxStyles = {
  root: {
    borderColor: "black",
    backgroundColor: "white",
    color: "black",
  },
  checkbox: {
    background: "white",
    marginLeft: 0,
    borderColor: "black",
    borderWidth: 2.5,
  },
  checkmark: {
    color: "black",
    backgroundColor: "white",
    borderColor: "black",
    fontWeight: 900,
  },
};

const MachineForm = ({
  values: {
    color,
    machineNo,
    machineName,
    machineType,
    techDepartments,
    techAreas,
    sizeGroup,
    imageUrl,
    machineDocumentUrl,
    requiredSkills,
  },
  skills,
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  handleChange,
  isValid,
  history,
  values,
}) => {
  const [avatar, setAvatar] = useState(imageUrl || null);
  const [documentLink, setDocumentLink] = useState(
    getMachineDocumentLink(machineNo || "")
  );
  const [file, setFile] = useState(machineDocumentUrl || null);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const { machineTypeOptions, sizeGroupOptions } = useSelector(
    (state) => state.defaultData
  );
  const sizeGroupOptionsWithLabel = sizeGroupOptions.map((option) => ({
    key: option.key,
    label: option.text,
  }));
  const techDepartmentOptionsWithLabel = techDepartmentOptions.map(
    (option) => ({ key: option.key, label: option.text })
  );
  const techDepartmentArray = techDepartments
    ?.split(";")
    .map((item) => (item ? parseInt(item) : null));
  const techAreaArray = techAreas
    ?.split(";")
    .map((item) => (item ? parseInt(item) : null));

  const currentStationOptions = techDepartments
    ? techDepartments
        .split(";")
        .filter((el) => !ignoreStationOptionsList.includes(parseInt(el)))
        .reduce((acc, curr) => [...acc, ...stationOptions[curr].options], [])
    : [];
  const currentMachineTypeOptions = techDepartments
    ? techDepartments.split(";").reduce(
        (acc, curr) => [
          ...acc,
          ...(machineTypeOptions
            .find((item) => item.id === parseInt(curr))
            ?.options?.map((item) => ({
              key: item.key,
              label: item.text,
            })) || [noneValue]),
        ],
        []
      )
    : [];

  useEffect(() => {
    if (avatar) {
      setFieldValue("imageUrl", avatar);
    }
    // eslint-disable-next-line
  }, [avatar]);

  useEffect(() => {
    setFieldValue("machineDocumentUrl", file);
    // eslint-disable-next-line
  }, [file]);

  const handleSubmitAndSelectSubSystems = () => {
    handleSubmit();
  };

  return (
    <form className={classNames.container}>
      <div className={classNames.header}>
        <div className={classNames.back}>
          <IconButton
            iconProps={{ iconName: "ChromeBack" }}
            onClick={() => history.push("/machines")}
          />
        </div>
        <Label className={classNames.label}>Opret ny maskine</Label>
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <PrimaryButton
            text="GEM"
            disabled={!isValid || !allowEdit}
            onClick={handleSubmitAndSelectSubSystems}
            style={primaryButtonStyles}
          />
        </Stack>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div className="machineFormContainer" style={{ padding: 40 }}>
          <div
            className={classNames.content}
            style={{ pointerEvents: !allowEdit && "none" }}
          >
            <FileUpload
              imageUrl={avatar}
              setFile={setAvatar}
              placeholder="UPLOAD BILLEDE"
            />
            {touched.imageUrl || errors.imageUrl ? (
              <span className={classNames.fileErrorMessage}>
                {errors.imageUrl}
              </span>
            ) : (
              ""
            )}
            <div
              onClick={() => setColorPickerVisible(!colorPickerVisible)}
              className={classNames.selector}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 8,
                }}
              >
                <div
                  style={{
                    fontSize: 11,
                    marginRight: 8,
                    fontWeight: 600,
                    color: "#2A2A2A",
                    marginTop: 5,
                  }}
                >
                  FARVE
                </div>
                <div
                  style={{
                    background: `rgba(${color},1)`,
                    height: 16,
                    width: 172,
                    borderRadius: 4,
                    marginTop: 3,
                    marginRight: 8,
                  }}
                />
              </div>
              <div className={classNames.icon}>
                <FontAwesomeIcon
                  icon={faPalette}
                  id="colorInput"
                  style={{ color: "black", marginTop: 8 }}
                />
              </div>
            </div>
            <TextField
              label="MASKINNUMMER"
              styles={inputStyles}
              value={machineNo}
              name="machineNo"
              placeholder="MASKINNUMMER"
              className={classNames.input}
              onChange={(e) => {
                handleChange(e);
                setDocumentLink(getMachineDocumentLink(e.target.value));
              }}
              errorMessage={
                touched.machineNo || errors.machineNo ? errors.machineNo : ""
              }
            />
            <TextField
              label="NAVN"
              styles={inputStyles}
              value={machineName}
              name="machineName"
              placeholder="NAVN"
              className={classNames.input}
              onChange={handleChange}
              errorMessage={
                touched.machineName || errors.machineName
                  ? errors.machineName
                  : ""
              }
            />

            {/* <FileUploadPdf
                    fileUrl={file}
                    setFile={setFile}
                    placeholder='VÆLG FIL'
                /> */}
          </div>
          <div
            className={classNames.content}
            style={{ pointerEvents: !allowEdit && "none" }}
          >
            <TextField
              label="DOCUMENT"
              styles={inputStyles}
              value={machineName ? documentLink : ""}
              placeholder="LINK"
              className={classNames.input}
            />

            {/* MASKINTYPE */}
            <div
              style={{
                fontFamily: "Verdana",
                fontSize: 11,
                fontWeight: "bold",
                transform: "translate(9px, 18px)",
                marginTop: -13,
                zIndex: 2,
              }}
            >
              MASKINTYPE
            </div>

            <div>
              <Select
                placeholder="MASKINTYPE"
                name="MASKINTYPE"
                closeMenuOnSelect={true}
                defaultValue={
                  techDepartments
                    ? (
                        currentMachineTypeOptions.options ||
                        currentMachineTypeOptions
                      )?.find((item) => item.key === machineType) || noneValue
                    : ""
                }
                options={
                  techDepartments
                    ? currentMachineTypeOptions ?? [noneValue]
                    : []
                }
                isClearable={true}
                isSearchable={true}
                onChange={(item) => setFieldValue("machineType", item?.key)}
                className={classNames.input}
                styles={customAutoCompleteStyles(errors.machineType)}
                getOptionValue={(option) => option.label}
              />
            </div>
            <CustomInputErrorComponent
              styles={{ margin: "-20px 0 23px 0" }}
              errorMessage={
                touched.machineType || errors.machineType
                  ? errors.machineType
                  : ""
              }
            />

            {/* SATSGRUPPE */}
            <div
              style={{
                fontFamily: "Verdana",
                fontSize: 11,
                fontWeight: "bold",
                transform: "translate(9px, 18px)",
                marginTop: -13,
                zIndex: 2,
              }}
            >
              SATSGRUPPE
            </div>
            <div>
              <Select
                placeholder="SATSGRUPPE"
                name="sizeGroup"
                closeMenuOnSelect={true}
                defaultValue={sizeGroupOptionsWithLabel.find(
                  (item) => item.key === sizeGroup
                )}
                options={sizeGroupOptionsWithLabel}
                isClearable={true}
                isSearchable={true}
                onChange={(item) => setFieldValue("sizeGroup", item?.key)}
                className={classNames.input}
                styles={customAutoCompleteStyles(errors.sizeGroup)}
                getOptionValue={(option) => option.label}
              />
            </div>
            <CustomInputErrorComponent
              styles={{ margin: "-20px 0 23px 0" }}
              errorMessage={
                touched.sizeGroup || errors.sizeGroup ? errors.sizeGroup : ""
              }
            />

            {/* FAGSEKTION */}
            <div
              style={{
                fontFamily: "Verdana",
                fontSize: 11,
                fontWeight: "bold",
                transform: "translate(9px, 18px)",
                marginTop: -13,
                zIndex: 2,
              }}
            >
              FAGSEKTION
            </div>
            <div>
              <Select
                placeholder="FAGSEKTION"
                name="techDepartments"
                closeMenuOnSelect={true}
                defaultValue={techDepartmentOptionsWithLabel.filter((item) =>
                  techDepartments?.includes(item.key)
                )}
                options={techDepartmentOptionsWithLabel}
                isSearchable={true}
                onChange={(event, item) => {
                  let newTechDepartments;
                  if (item.action === "remove-value") {
                    newTechDepartments = techDepartmentArray.filter(
                      (key) => key !== item.removedValue.key
                    );
                  } else if (item.action === "select-option") {
                    newTechDepartments =
                      techDepartmentArray && techDepartmentArray[0]
                        ? [...techDepartmentArray, item.option.key]
                        : [item.option.key];
                  } else {
                    newTechDepartments = [];
                  }
                  setFieldValue(
                    "techDepartments",
                    newTechDepartments.join(";")
                  );
                }}
                className={classNames.input}
                styles={customAutoCompleteStyles(errors.machineId)}
                getOptionValue={(option) => option.label}
                isMulti
              />
            </div>
            <CustomInputErrorComponent
              styles={{ margin: "-20px 0 23px 0" }}
              errorMessage={
                touched.techDepartments || errors.techDepartments
                  ? errors.techDepartments
                  : ""
              }
            />

            {currentStationOptions.length ? (
              <>
                {/* DISPONENT */}
                <div
                  style={{
                    fontFamily: "Verdana",
                    fontSize: 11,
                    fontWeight: "bold",
                    transform: "translate(9px, 18px)",
                    marginTop: -13,
                    zIndex: 2,
                  }}
                >
                  DISPONENT
                </div>
                <div>
                  <Select
                    placeholder="DISPONENT"
                    name="techAreas"
                    closeMenuOnSelect={true}
                    defaultValue={currentStationOptions.filter((item) =>
                      techAreas?.includes(item.key)
                    )}
                    options={currentStationOptions}
                    isSearchable={true}
                    onChange={(event, item) => {
                      let newTechAreas;
                      if (item.action === "remove-value") {
                        newTechAreas = techAreaArray.filter(
                          (key) => key !== item.removedValue.key
                        );
                      } else if (item.action === "select-option") {
                        newTechAreas =
                          techAreaArray && techAreaArray[0]
                            ? [...techAreaArray, item.option.key]
                            : [item.option.key];
                      } else {
                        newTechAreas = [];
                      }
                      setFieldValue("techAreas", newTechAreas.join(";"));
                    }}
                    className={classNames.input}
                    styles={customAutoCompleteStyles(errors.machineId)}
                    getOptionValue={(option) => option.label}
                    isMulti
                  />
                </div>
              </>
            ) : (
              <></>
            )}
            <CustomInputErrorComponent
              styles={{ margin: "-20px 0 23px 0" }}
              errorMessage={
                touched.techAreas || errors.techAreas ? errors.techAreas : ""
              }
            />

            {colorPickerVisible && (
              <TeachingBubble
                target="#colorInput"
                onDismiss={() => setColorPickerVisible(!colorPickerVisible)}
                styles={{
                  content: {
                    background: "white",
                  },
                }}
              >
                <ColorPickerComponent
                  selectColor={(value) => setFieldValue("color", value)}
                  color={color}
                  togglePickerVisible={() =>
                    setColorPickerVisible(!colorPickerVisible)
                  }
                  isMachine
                />
              </TeachingBubble>
            )}
          </div>
          <div
            className={classNames.content}
            style={{ pointerEvents: !allowEdit && "none" }}
          >
            <RequiredSkillsTable
              onUpdatedRequiredSkills={(newRequiredSkills) => {
                setFieldValue("requiredSkills", newRequiredSkills);

                if (isValid && allowEdit) {
                  handleSubmitAndSelectSubSystems();
                }
              }}
              skills={skills}
              requiredSkills={requiredSkills}
              techDepartments={techDepartments}
              hasEditRights={allowEdit}
              skillType={SKILL_TYPE_KEY.MACHINE}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default MachineForm;
