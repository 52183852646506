import axios from 'axios';
import { checkTokenValidity } from "../user/user.actions";
import { saveMessage } from '../message/message.actions';
import { MessageBarType } from '@fluentui/react';
import setDefaults from '../../utils/setDefaults';
import { SOURCE_API } from '../../utils/constants';

export const uploadFile = (file) => async dispatch =>  {
    try {
        setDefaults(localStorage.getItem('token'));
        
        let formData = new FormData();
        formData.append("files", file);

        const response = await axios.post(`${SOURCE_API}/files`, formData);
        if(response.data.succeeded === true) {
            dispatch(saveMessage('File upload successful!', MessageBarType.success));
            return response.data.data[0];
        } else {
            dispatch(saveMessage('There was an error'));
        }
    } catch (error) {
        dispatch(checkTokenValidity(error));
    }
};