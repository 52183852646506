// Import libraries
import {
  Image,
  ImageFit,
  Persona,
  PersonaSize,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  Stack,
  TeachingBubble,
  mergeStyleSets,
} from "@fluentui/react";
import { useMemo, useState } from "react";

// Import types
import { TMachine, TTechDepartmentEnum } from "../../../types";
import { SOURCE_API, stationOptions } from "../../../utils/constants";
import { searchBoxStyles } from "../../../utils/theme";

const classNames = mergeStyleSets({
  Persona: {
    padding: "5px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  station: {
    margin: 5,
    width: 25,
    height: 14,
    color: "#868685",
    background: "#DFDFDF",
    textAlign: "center",
    fontSize: 11,
    lineHeight: 14,
    borderRadius: 4,
    padding: 2,
  },
  favoriteDriver: {
    position: "absolute",
    marginLeft: "200px",
  },
});

interface IPopupMachineSkillsProps {
  toggleTeaching: () => void;
  target: string;
  machines: TMachine[];
  staffTechDepartment: TTechDepartmentEnum;
  onSelectMachine: (selectedMachine: TMachine) => void;
}

const PopupMachineSkills = ({
  toggleTeaching,
  target,
  machines,
  staffTechDepartment,
  onSelectMachine,
}: IPopupMachineSkillsProps) => {
  const [showOtherSections, setShowOtherSections] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState("");

  const techDepartment = "" + staffTechDepartment;

  const [sameDeptMachines, otherDeptMachines] = useMemo<
    [TMachine[], TMachine[]]
  >(() => {
    const sameDeptMachines: TMachine[] = [];
    const otherDeptMachines: TMachine[] = [];

    machines.forEach((mac) => {
      if (mac.techDepartments.includes(techDepartment)) {
        sameDeptMachines.push(mac);
      } else {
        otherDeptMachines.push(mac);
      }
    });

    return [sameDeptMachines, otherDeptMachines];
  }, [machines, techDepartment]);

  const machineLists = useMemo(() => {
    const list = [
      ...sameDeptMachines,
      ...(showOtherSections ? otherDeptMachines : []),
    ];
    return searchValue
      ? list.filter((el) =>
          el.machineName.toLowerCase().includes(searchValue.toLowerCase()),
        )
      : list;
  }, [sameDeptMachines, otherDeptMachines, showOtherSections, searchValue]);

  return (
    // @ts-ignore
    <TeachingBubble
      target={`#${target}`}
      hasSmallHeadline={true}
      onDismiss={toggleTeaching}
      closeButtonAriaLabel="Close"
    >
      <SearchBox
        styles={searchBoxStyles}
        placeholder="Søg"
        clearButtonProps={{
          style: {
            backgroundColor: "#FFFFFF",
            marginLeft: "20px",
          },
        }}
        value={searchValue}
        onChange={(e, value) =>
          typeof value === "string" && setSearchValue(value)
        }
      />
      <hr
        style={{
          width: "145%",
          marginLeft: "-50px",
          marginTop: "-2px",
          opacity: 0.5,
        }}
      />
      <div
        style={{
          height: "240px",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ScrollablePane
          style={{ height: "200px" }}
          scrollbarVisibility={ScrollbarVisibility.auto}
        >
          <Stack
            // @ts-ignore
            vertical
            tokens={{ childrenGap: 10 }}
          >
            {machineLists.map(
              (
                {
                  machineId,
                  machineName,
                  sizeGroup,
                  imageUrl,
                  techDepartments,
                  techAreas,
                },
                idx,
              ) => (
                <div key={machineId}>
                  <div
                    onClick={() => {
                      onSelectMachine(machineLists[idx]);
                    }}
                    className={classNames.Persona}
                  >
                    <Persona
                      text={machineName}
                      secondaryText={""}
                      size={PersonaSize.size40}
                      imageUrl={imageUrl ? `${SOURCE_API}${imageUrl}` : ""}
                    />
                    <div className={classNames.station}>
                      {techDepartments !== "-1"
                        ? techDepartments
                            .split(";")
                            // @ts-ignore
                            .reduce((acc, curr) => {
                              // @ts-ignore
                              return [...acc, ...stationOptions[curr].options];
                            }, [])
                            // @ts-ignore
                            .find((option) => option.key + "" === techAreas)
                            ?.shortcutText
                        : ""}
                    </div>
                  </div>
                </div>
              ),
            )}
          </Stack>
        </ScrollablePane>
        <div
          style={{
            margin: "220px auto 20px auto",
            color: "rgb(0, 108, 173)",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowOtherSections((section) => !section);
          }}
        >
          {showOtherSections ? "Hide" : "Show"} other sections
        </div>
      </div>
    </TeachingBubble>
  );
};

export default PopupMachineSkills;
