import {CSSProperties, ReactElement, useEffect, useState} from "react";
import { Rnd } from "react-rnd";
import {
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  COLOR_CONS_HEX,
  RND_BAR_HEIGHT,
  crmSpecialStatus,
} from "../../../utils/constants";
import moment from "moment";
import { TPlanHorizontalPositionMap } from "../../../types/planner";
import { useSelector } from "react-redux";
import { TooltipForText } from "../../common";

// yPos={yPos}
// start={start}
// end={end}
// crmProjectStatusCode={crmProjectStatusCode}
// projectId={projectId}
// color={color}
// name={name}

type MachineBookingBarProps = {
  yPos: number;
  start: string;
  end: string;
  crmProjectStatusCode: string;
  color: string;
  name: string;
  horizontalPositionMap: TPlanHorizontalPositionMap;
};

const textStyles: CSSProperties = {
  padding: "0 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const MachineBookingBar = ({
  color,
  crmProjectStatusCode,
  end,
  name,
  start,
  yPos,
  horizontalPositionMap,
}: MachineBookingBarProps) => {
  // Use States
  const [width, setWidth] = useState<number>(0);
  const [x, setX] = useState<number>(0);
  const [startText, setStartText] = useState<string | null>(null);
  const [endText, setEndText] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(false);

  // ======================= Use Selectors ========================================================
  const displayWeekView = useSelector(
    // @ts-ignore
    (state) => state.machine.displayWeekView
  ) as boolean;

  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;

  let textDisplay = name;
  useEffect(() => {
    if (!start || !end) {
      return;
    }
    let startDate = moment(start, "YYYY-M-D").format("YYYY-M-D");
    let endDate = moment(end).add(1, "d").utcOffset(0).format("YYYY-M-D");
    const startCalendar = horizontalPositionMap.startText.split(".").reverse();
    const endCalendar = horizontalPositionMap.endText.split(".").reverse();
    const startDateBeforeStartCalendar =
      new Date(startDate).getTime() <
      new Date(
        +("20" + startCalendar[0]),
        +startCalendar[1] - 1,
        +startCalendar[2]
      ).getTime();
    const endDateAfterEndCalendar =
      new Date(endDate).getTime() >=
      new Date(
        +("20" + endCalendar[0]),
        +endCalendar[1] - 1,
        parseInt(endCalendar[2]) + 1
      ).getTime();
    let startTemp = startDateBeforeStartCalendar
      ? 0
      : horizontalPositionMap[startDate];
    let endTemp = endDateAfterEndCalendar
      ? horizontalPositionMap[endDate] || horizontalPositionMap.end + 1
      : horizontalPositionMap[endDate];

    // @ts-ignore
    let tempX = startTemp * calendarStepWidth;
    // @ts-ignore
    let tempWidth = (endTemp - startTemp) * calendarStepWidth;
    if (typeof startTemp === "undefined" && typeof endTemp !== "undefined") {
      tempX = 0;
      // @ts-ignore
      tempWidth = endTemp * calendarStepWidth;
    }
    if (typeof startTemp === "undefined" || typeof endTemp === "undefined") {
      setWidth(0);
      return;
    }
    if (typeof startTemp === "undefined" && typeof endTemp === "undefined") {
      tempWidth =
        (horizontalPositionMap["end"] - startTemp) * calendarStepWidth;
    }
    const startTextTemp = moment(start, "YYYY-M-D").format("D.M.YY");
    const endTextTemp = moment(end, "YYYY-M-D").format("D.M.YY");
    setStartText(startTextTemp);
    setEndText(endTextTemp);
    setX(tempX);
    setWidth(tempWidth);

    setShow(tempWidth !== 0);
  }, [start, end, horizontalPositionMap, calendarStepWidth]);

  let renderDetail: ReactElement = (
    <TooltipForText text={textDisplay}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#fff",
        }}
      >
        <div style={textStyles}>{startText}</div>
        <div style={textStyles}>
          {crmSpecialStatus[
            crmProjectStatusCode as unknown as keyof typeof crmSpecialStatus
          ] && (
            <span
              style={{
                color: COLOR_CONS_HEX.ALERT,
                fontWeight: "bold",
                marginRight: 8,
              }}
            >
              {
                crmSpecialStatus[
                  crmProjectStatusCode as unknown as keyof typeof crmSpecialStatus
                ].text
              }
            </span>
          )}
          {textDisplay}
        </div>
        <div style={textStyles}>{endText}</div>
      </div>
    </TooltipForText>
  );
  if (width <= 275) {
    renderDetail = (
      <TooltipForText text={textDisplay}>
        <div
          style={{ display: "flex", justifyContent: "center", color: "#fff" }}
        >
          <div style={textStyles}>{textDisplay}</div>
        </div>
      </TooltipForText>
    );
  }

  return (
    <>
      {show ? (
        <Rnd
          style={{ zIndex: 3, top: 0, left: 0 }}
          size={{ width: width, height: 20 }}
          position={{ x: x, y: yPos }}
          enableResizing={false}
          disableDragging={true}
        >
          <div
            id="detailBar"
            style={{
              background: `repeating-linear-gradient(90deg, rgba(${color},1), rgba(${color},1) 24px, rgba(${color},0.4) 24px, rgba(${color},0.4) 48px)`,
              display: "flex",
              marginBottom: 12,
              height: RND_BAR_HEIGHT,
              borderRadius: 8,
            }}
          >
            <div
              style={{
                color: "#fff",
                fontWeight: "bold",
                height: "100%",
                width: "100%",
                fontSize: 13,
                lineHeight: "32px",
                margin: "2px 4px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: "#000",
                }}
              >
                {renderDetail}
              </div>
            </div>
          </div>
        </Rnd>
      ) : (
        <></>
      )}
    </>
  );
};

export default MachineBookingBar;
