/* eslint-disable no-loop-func */
// Import libraries
import {MessageBarType, Stack} from "@fluentui/react";
import {useBoolean} from "@fluentui/react-hooks";
import {Image, ImageFit} from "@fluentui/react/lib/Image";
import {Persona, PersonaSize} from "@fluentui/react/lib/Persona";
import {mergeStyleSets} from "@fluentui/react/lib/Styling";
import {
    faCalendarAlt,
    faDigging,
    faPencil,
    faPlusCircle,
    faUserHardHat,
    faUsers,
    faUserTie,
} from "@fortawesome/pro-regular-svg-icons";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// @ts-expect-error
import {datesGenerator} from "dates-generator";
import moment from "moment";
import {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import {Prompt, useHistory, useParams} from "react-router-dom";

// Import redux
import {getHolidayList} from "../../redux/app/app.actions";

import {
    getMachines,
    getTeamByTeamId,
    getTeams,
} from "../../redux/machines/machines.actions";

import {
    saveMessage,
    showConflictMessage,
} from "../../redux/message/message.actions";

import {getPlan, getPlans, updatePlan} from "../../redux/plan/plan.actions";
import {getPrebookings} from "../../redux/prebooking/prebooking.actions";

import {getProject, OverlapKeys} from "../../redux/project/project.actions";
import {getStaffs, updateStaff} from "../../redux/user/user.actions";

// Import utils
import {
    BACKGROUND_HEIGHT,
    CALENDAR_STEP_WIDTH,
    CALENDAR_WEEKVIEW_STEP_WIDTH,
    COLOR_GREY_OUT,
    COLOR_GREY_OUT_HEX,
    crmSpecialStatus,
    months,
    reasonVacationStaffOptions,
    ROLES,
    SOURCE_API,
} from "../../utils/constants";

import {
    addDaysToDate,
    dynamicSort,
    findStaffIndexAndCounter,
    findStaffSchedule,
    findYearOfDates,
    getAvailableTimesOnConflict,
    getCalendarHoliday,
    getLastDate,
    getMonday,
    getReqCrmLink,
    hasEditRight,
    sleep,
    startCalendarFromSelectedWeek,
} from "../../utils/utils";

// Import components
import {DeleteConfirmation, TooltipForText} from "../common";
import {
    CalendarControlsDetailedPlanning,
    Employment,
    Machine,
    MachineRequirementBar,
    MachineServiceStaffVacationBar,
    MachineStaffBookedBar,
    PopupDriver,
    PopupMachines,
    PopupTeams,
    PopupWorkers,
    SupervisorPlaceholder,
    PopupSupervisors,
} from "./planning/detailedPlanning";
import DetailPlanBarDrawer from "./planning/detailedPlanning/DetailPlanBarDrawer";
import DetailPlanVacationDrawer from "./planning/detailedPlanning/DetailPlanVacationDrawer";
import {planActivityLogComposer} from "./PlanningUtils";
import FixPositionBubble from "../common/FixPositionBubble";
import {BLANK_MACHINE_FILTER_OPTION} from "./planning/detailedPlanning/MachineFilters";
import MachineServiceCalendar from "./machineservice/MachineServiceCalendar";
import useGetColorList from "../../hooks/useGetColorList";
import {TPlanHorizontalPositionMap} from "../../types/planner";
import {
    TDates,
    THoliday,
    TMachine,
    TMachineTeamModel,
    TPlan,
    TProject,
    User,
    UserWorkerVM,
} from "../../types";
import {
    AllMachineData,
    AllUserData,
    Direction,
    DraggableEvent,
    MachineArray,
    MachineDrawData,
    StaffDrawData,
    UserArray,
    VacationDrawData,
} from "../../types/custom";
import {DraggableData, Position, ResizableDelta} from "react-rnd";
import {TTargetBubbleMachine} from "./planning/detailedPlanning/PopupDriver";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {generateDatesRange, getServiceText} from "../../utils/mapping";
import {getCrewServiceSchedule} from "../../utils/service";

const ID_TARGET = {
    MACHINE: "MACHINE",
    WORKER: "WORKER",
    DRIVER: "DRIVER",
    SUPERVISOR: "SUPERVISOR",
    TEAMS: "TEAMS",
    ONE_WEEK_TEAMS: "ONE_WEEK",
};

const roleProp: { [k: string]: OverlapKeys } = {
    WORKER: "workers",
    DRIVER: "drivers",
    SUPERVISOR: "managers",
};

const coinProps = {
    styles: {
        image: {
            margin: 0,
            width: 28,
        },
    },
};

const getWidthStartEndNumberByHorizontalPositionMap = (
    horizontalPositionMap: TPlanHorizontalPositionMap,
    start: string,
    end: string,
    calendarStepWidth: number
) => {
    let tempStart: string | number = moment(start, "YYYY-M-D").format("YYYY-M-D");
    tempStart = horizontalPositionMap[tempStart] as number;
    let tempEnd: string | number = moment(end, "YYYY-M-D").format("YYYY-M-D");
    tempEnd = horizontalPositionMap[tempEnd] as number;
    const width =
        ((tempEnd as number) - (tempStart as number)) * calendarStepWidth;
    const startText =
        horizontalPositionMap["positionsToDates"][
        (tempStart as number) * calendarStepWidth
            ];
    const endText =
        horizontalPositionMap["positionsToDates"][
        (tempEnd as number) * calendarStepWidth
            ];
    return {width, start: tempStart, end: tempEnd, startText, endText};
};

type TParams = { projectId: string; planningIndex: string };

const DetailedPlanning = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userRoles = useSelector(
        // @ts-ignore
        (state) => state?.user?.user?.workingRole
    ) as string;
    // @ts-ignore
    const {addCalendarLength} = useSelector((state) => state.app) as number;
    const addCalendarLengthRef = useRef(null);

    const [leftHeight, setLeftHeight] = useState<number>(0);

    const currentUserId = useSelector(
        // @ts-ignore
        (state) => state?.user?.user?.userId
    ) as string;

    // @ts-ignore
    const {displayWeekView} = useSelector((state) => state.machine) as {
        displayWeekView: boolean;
    };

    const [scrollTodayRef, setScrollTodayRef] = useState<NodeJS.Timeout>();

    useEffect(() => {
        if (scrollTodayRef) {
            return () => {
                clearTimeout(scrollTodayRef);
            };
        }
    }, [scrollTodayRef]);

    useEffect(() => {
        const waitForRender = async () => {
            let observedElement = document.getElementById(
                "leftContainer"
            ) as HTMLElement;

            while (!observedElement) {
                observedElement = document.getElementById(
                    "leftContainer"
                ) as HTMLElement;

                if (!observedElement) await sleep(100);
            }

            if (!observedElement) return;

            const resizeObserver = new ResizeObserver((entries) => {
                for (let entry of entries) {
                    if (entry.target === observedElement) {
                        setLeftHeight(entry.contentRect.height + 10);
                    }
                }
            });

            resizeObserver.observe(observedElement);

            return () => {
                resizeObserver.unobserve(observedElement);
            };
        };

        waitForRender();
    }, []);

    // @ts-ignore
    const {techDepartment} = useSelector((state) => state?.machine?.filter) as {
        techDepartment: string;
    };
    const [hoverData, setHoverData] = useState<{
        text: string;
        boldText: string;
        hoverId: string;
    } | null>(null);
    const [calendarStepWidth, setCalendarStepWidth] = useState(
        displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
    );
    const [dates, setDates] = useState<TDates>([]);
    const widthOfCalendar =
        dates.reduce((acc, cur) => acc + cur.length, 0) * calendarStepWidth;
    const [horizontalPositionMap, setHorizontalPositionMap] =
        useState<TPlanHorizontalPositionMap | null>(null);
    const queryClient = useQueryClient();
    const {
        projectId,
        planningIndex,
        // teamId
    } = useParams<TParams>();
    const teamId = null;
    const [anyUpdate, setAnyUpdate] = useState(false);
    // const [done, setDone] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [teachingBubbleMachines, {toggle: toggleTeachingMachines}] =
        useBoolean(false);
    const [teachingBubbleWorkers, {toggle: toggleTeachingWorkers}] =
        useBoolean(false);
    const [teachingBubbleDrivers, {toggle: toggleTeachingDrivers}] =
        useBoolean(false);
    const [teachingBubbleSupervisor, {toggle: toggleTeachingSupervisors}] =
        useBoolean(false);
    const [teachingBubbleTeams, {toggle: toggleTeachingTeams}] =
        useBoolean(false);
    const [teachingBubbleOneWeekTeams, {toggle: toggleTeachingOneWeekTeams}] =
        useBoolean(false);
    const [refreshList, {toggle: toggleRefreshList}] = useBoolean(true);
    const [machines, setMachines] = useState<AllMachineData[]>([]);

    const [selectedMachines, setSelectedMachines] = useState<string[]>([]);
    const [workers, setWorkers] = useState<AllUserData[]>([]);
    const [drivers, setDrivers] = useState<AllUserData[]>([]);
    const [supervisors, setSupervisors] = useState<AllUserData[]>([]);

    // use for add teams
    const machinesRef = useRef(machines);
    const workersRef = useRef(workers);
    const driversRef = useRef(drivers);
    const supervisorsRef = useRef(supervisors);
    const [targetBubbleDelete, setTargetBubbleDelete] = useState<{
        target: string;
        role: string;
        indexList: number;
    } | null>(null);
    const [machinesTeamList, setMachinesTeamList] = useState([]);
    const [machineTeamList, setMachineTeamList] = useState([]);
    const [addTeamOnRouting, setAddTeamOnRouting] = useState(false);
    const [drawData, setDrawData] = useState<
        StaffDrawData | MachineDrawData | VacationDrawData | null
    >(null);

    const isGettingDraw = useRef(false);
    const [tempDrawMachineId, setTempDrawMachineId] = useState("");
    const [tempDrawStaffData, setTempDrawStaffData] = useState<{
        id: string;
        role: string;
    } | null>(null);

    const [saveRef, setSaveRef] = useState<NodeJS.Timeout>();
    const [refreshTimerRef, setRefreshTimerRef] = useState<NodeJS.Timeout>();

    const [tempVacations, setTempVacations] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);

    const queryKey = useMemo(() => {
        return ["plans", dates.length];
    }, [dates]);

    useEffect(() => {
        if (saveRef) {
            return () => {
                clearTimeout(saveRef);
            };
        }
    }, [saveRef]);

    useEffect(() => {
        if (refreshTimerRef) {
            return () => {
                clearTimeout(refreshTimerRef);
            };
        }
    }, [refreshTimerRef]);

    const {data: allPlansData} = useQuery(
        queryKey,
        () => {
            if (dates.length) {
                const startDate = dates[0][0];
                const endDate = getLastDate(dates);
                const startCalendar = new Date(
                    startDate.year,
                    startDate.month,
                    startDate.date
                ).toISOString();
                const endCalendar = new Date(
                    endDate.year,
                    endDate.month,
                    endDate.date
                ).toISOString();

                return getPlans(
                    startDate.year,
                    startCalendar,
                    endCalendar,
                    techDepartment
                )(dispatch);
            }
            return [];
        },
        {
            staleTime: 6000 * 5,
            refetchOnWindowFocus: false,
        }
    );

    const {data: machineData, isLoading: isLoadingMachines} = useQuery(
        "machines",
        // @ts-ignore
        () => getMachines()(dispatch),
        {
            placeholderData: [] as TMachine[],
        }
    );

    const {data: staffData} = useQuery("staffs", () => getStaffs()(dispatch));
    const {data: prebookingsData} = useQuery("prebookings", () =>
        getPrebookings()(dispatch)
    );
    const {data: projectData} = useQuery(
        ["project", projectId],
        () => getProject(projectId)(dispatch),
        {
            // staleTime: 36000,
            initialData: () =>
                (queryClient.getQueryData("projects") as TProject[])?.find(
                    (project) => project.projectId === projectId
                ) || null,
            initialDataUpdatedAt() {
                return queryClient.getQueryState("projects")?.dataUpdatedAt;
            },
            refetchOnWindowFocus: false,
        }
    );

    const {data: planData} = useQuery(
        ["plan", projectId],
        () => getPlan(projectId)(dispatch),
        {
            initialData: () =>
                (queryClient.getQueryData("plans") as TPlan[])?.find(
                    (plan) => plan.projectId === projectId
                ),
            initialDataUpdatedAt: () =>
                queryClient.getQueryState("plans")?.dataUpdatedAt,
            refetchOnWindowFocus: false,
        }
    );

    const allowEdit = hasEditRight(userRoles) && !planData?.inactive;
    const {data: teamData} = useQuery(
        ["team", teamId],
        () => (teamId ? getTeamByTeamId(teamId)(dispatch) : null),
        {
            enabled: !!teamId,
        }
    );
    const {data: teamsData} = useQuery("teams", () => dispatch(getTeams()));

    useEffect(() => {
        return () => {
            queryClient.cancelQueries("teams");
            queryClient.cancelQueries("staffs");
            queryClient.cancelQueries("prebookings");
            queryClient.cancelQueries("machines");
        };
    }, [queryClient]);

    const {mutate: mutateUpdatePlan} = useMutation(
        (plan: TPlan) => updatePlan(plan)(dispatch),
        {
            onSuccess: (data) => {
                if (data) {
                    queryClient.invalidateQueries(["project", projectId]);
                    queryClient.invalidateQueries(["plan", projectId]);
                    queryClient.invalidateQueries("plans");
                    setAnyUpdate(false);
                } else {
                    // queryClient.invalidateQueries(["project", projectId]);
                    // queryClient.invalidateQueries(["plan", projectId]);
                    // queryClient.invalidateQueries("plans");
                }
            },
        }
    );

    const {mutate: updateStaffMutation} = useMutation(
        (staff: User) => updateStaff(staff, false)(dispatch),
        {
            onSuccess: (data) => {
                if (data) {
                    queryClient.invalidateQueries("plans");
                    queryClient.invalidateQueries("staffs");
                }
            },
            onError: () => {
                // queryClient.invalidateQueries("plans");
                // queryClient.invalidateQueries("staffs");
            },
        }
    );
    const holidayRef = useRef<{
        years: number[];
        holidays: THoliday[];
    } | null>(null);

    // const heightOfBackground = 1500;

    const classNames = mergeStyleSets({
        root: {
            display: "flex",
            flexDirection: "row",
        },
        pageContentContainer: {
            display: "flex",
            background: "#F1F1F1",
            width: "100%",
        },
        pageInnerContainer: {
            margin: "40px 0 40px 118px",
        },
        pageContent: {
            display: "flex",
            width: widthOfCalendar,
        },
        leftContainer: {
            position: "sticky",
            top: 0,
            left: 0,
            background: "#f1f1f1",
            marginLeft: -318,
            marginTop: 114,
            // height: heightOfBackground,
            zIndex: 999,
        },
        sideBarContainer: {
            display: "flex",
            justifyContent: "space-between",
            marginTop: 30,
            minHeight: 110,
        },
        sideBarHeader: {
            display: "flex",
            marginLeft: 65,
            marginTop: 12,
        },
        sideBarRightContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: 100,
            marginRight: 10,
            marginLeft: -20,
        },
        sidebarIconContainer: {
            width: 100,
            display: "flex",
            alignItems: "center",
            position: "relative",
            top: 5,
            justifyContent: "end",
        },
        calendarContainer: {
            width: widthOfCalendar,
        },
        days: {
            display: "flex",
            justifyContent: "flex-start",
        },
        dayOfWeeks: {
            marginTop: -15,
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 8,
        },
        weeks: {
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: 4,
        },
        week: {
            minWidth: 175,
            height: 20,
            background: "#E7E7E7",
            fontSize: 11,
            lineHeight: 20,
            borderRadius: 10,
        },
        image: {
            marginRight: "15px",
            fontSize: 16,
        },
        iconPlusContainer: {
            position: "relative",
            left: -61,
            top: 10,
        },
        iconPlus: {
            fontSize: 28,
            height: 29,
            color: "#006CAD",
            selectors: {
                ":hover": {
                    color: "#DBDBDB",
                },
            },
            cursor: "pointer",
        },
        iconPlusCircle: {
            marginTop: 10,
            marginLeft: 20,
        },
        functionalIcon: {
            fontSize: 18,
            height: 16,
            color: "#006CAD",
            selectors: {
                ":hover": {
                    color: "#DBDBDB",
                },
            },
            cursor: "pointer",
            marginRight: "10px",
        },
        sidebarSeparatorContainer: {
            marginTop: 25,
            height: "20px",
            position: "relative",
            left: 50,
        },
        separatorContainerStyle: {
            marginTop: 60,
            height: "20px",
            position: "relative",
            left: -230,
            width: "calc(100% + 230px)",
        },
        separatorStyle: {
            border: "1px solid #DFDFDF",
        },
        titleElementStyle: {
            display: "flex",
            alignItems: "center",
            height: "100px",
            position: "absolute",
            left: -245,
        },
        titleElementStyleCustom: {
            display: "flex",
            alignItems: "center",
            height: "100px",
            position: "absolute",
            left: -210,
            top: 30,
        },
        titleNameElementStyle: {
            fontWeight: "bold",
            marginRight: "16px",
        },
        titleNameElementStyleCustom: {
            fontWeight: "bold",
            marginRight: "16px",
            color: "#006CAD",
            cursor: "pointer",
        },
        contentElementStyle: {
            position: "relative",
            top: 25,
        },
        avatarContainerStyle: {
            position: "relative",
            left: -110,
            top: 5,
            width: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
        },
        personaStyle: {
            width: 28,
            marginRight: "10px",
        },
        expandCalendarIcon: {
            marginLeft: 30,
            fontSize: 35,
            color: "#006CAD",
            cursor: "pointer",
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        setCalendarStepWidth(
            displayWeekView ? CALENDAR_WEEKVIEW_STEP_WIDTH : CALENDAR_STEP_WIDTH
        );
    }, [displayWeekView]);

    const scrollToTodayAnchor = () => {
        // const observer = elementObserver(`#today-anchor`);
        // observer.then((element) => {
        //   if (element instanceof HTMLElement) {
        //     element.scrollIntoView({
        // behavior: "smooth",
        // block: "end",
        // inline: "start",
        //     });
        //   }
        // });

        if (scrollTodayRef) {
            clearTimeout(scrollTodayRef);
        }

        const ref = setTimeout(() => {
            const el = document.querySelector("#today-anchor");

            if (el instanceof HTMLElement) {
                el.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "start",
                });
            } else {
                scrollToTodayAnchor();
            }
        }, 2000);

        setScrollTodayRef(ref);
    };

    useEffect(() => {
        if (projectData && planData) {
            let startDate = new Date(projectData?.start);
            let endDate = new Date(projectData?.end);

            if (
                startDate >
                new Date(planData["machineRequirements"][+planningIndex]?.start)
            ) {
                startDate = new Date(
                    planData["machineRequirements"][+planningIndex].start
                );
            }
            if (
                endDate < new Date(planData["machineRequirements"][+planningIndex]?.end)
            ) {
                endDate = new Date(planData["machineRequirements"][+planningIndex].end);
            }

            // Loop through all keyToCheck to find the right startDate & endDate
            if (
                Array.isArray(planData["machineRequirements"][+planningIndex]?.machines)
            ) {
                const keyToCheck = ["machines", "drivers", "workers", "managers"];
                keyToCheck.forEach((key) => {
                    // @ts-ignore
                    planData["machineRequirements"][+planningIndex][key].forEach(
                        // @ts-ignore
                        (machine) => {
                            if (startDate > new Date(machine?.start)) {
                                startDate = new Date(machine?.start);
                            }
                            if (endDate < new Date(machine?.end)) {
                                endDate = new Date(machine?.end);
                            }
                        }
                    );
                });
            }

            if (startDate < new Date() && endDate > new Date()) {
                scrollToTodayAnchor();
            }
            // Handle for case endDate before today
            if (endDate < new Date()) {
                endDate = addDaysToDate(new Date(), 60);
                scrollToTodayAnchor();
            }

            // Add more day to end date
            endDate = addDaysToDate(endDate, (addCalendarLength || 0) * 30);

            // machineRequirements

            let datesData = datesGenerator({
                month: startDate.getMonth(),
                year: startDate.getFullYear(),
                startingDay: 1,
            });
            let dates = startCalendarFromSelectedWeek(
                getMonday(startDate),
                datesData["dates"]
            ); //Get monday to start from the beginning of week

            while (datesData["nextYear"] <= endDate.getFullYear()) {
                datesData = datesGenerator({
                    month: datesData.nextMonth,
                    year: datesData.nextYear,
                    startingDay: 1,
                });
                let temp = datesData["dates"];
                // removing duplicated weeks
                if (dates[dates.length - 1][0]["date"] === temp[0][0]["date"]) {
                    temp.shift();
                }
                dates = [...dates, ...datesData["dates"]];
            }

            startDate.setHours(0);
            endDate.setHours(0);
            const startText = moment(startDate, "YYYY-M-D").format("D.M.YY");
            const endText = moment(endDate, "YYYY-M-D").format("D.M.YY");

            const horizontalPositionMap: TPlanHorizontalPositionMap = {
                startText,
                endText,
                displayEnd: 0,
                end: 0,
                positionsToDates: {},
            };
            const datePositionCorrespondence: {
                [k: number]: {
                    value: string;
                    display: string;
                };
            } = {};
            let datesToDisplay: TDates = [];
            let effectiveCounter = 0;
            let counter = 0;
            for (let i = 0; i < dates.length; i++) {
                let weekToSave = [];
                for (let j = 0; j < dates[i].length; j++) {
                    //date: 1, month: 2, year: 2021
                    let currentDate = new Date(
                        dates[i][j]["year"],
                        dates[i][j]["month"],
                        dates[i][j]["date"]
                    );
                    if (currentDate > endDate && counter >= 66) {
                        break;
                    }
                    if (startDate <= currentDate) {
                        weekToSave.push(dates[i][j]);
                        let key = `${dates[i][j]["year"]}-${dates[i][j]["month"] + 1}-${
                            dates[i][j]["date"]
                        }`;
                        horizontalPositionMap[key] = effectiveCounter;
                        datePositionCorrespondence[effectiveCounter * calendarStepWidth] = {
                            value: moment(key, "YYYY-M-D").format(
                                "YYYY-MM-DD[T]HH:mm:ss.SSS"
                            ),
                            display: moment(key, "YYYY-M-D").format("D.M.YY"),
                        };
                        effectiveCounter++;
                    }
                    counter++;
                }
                if (weekToSave.length) {
                    datesToDisplay.push(weekToSave);
                }
            }

            // Find the holiday of calendar
            async function getDatesWithHolidays() {
                const yearOfDates = findYearOfDates(datesToDisplay);
                let holidays: THoliday[] = [];

                if (
                    !holidayRef.current ||
                    JSON.stringify(yearOfDates) !==
                    JSON.stringify(holidayRef.current?.years)
                ) {
                    for (const year of yearOfDates) {
                        const currentHolidays = (await getHolidayList(year)(dispatch)) as {
                            holidays: THoliday[];
                        };
                        holidays = [...holidays, ...(currentHolidays?.holidays || [])];
                    }
                    holidayRef.current = {
                        years: yearOfDates,
                        holidays: holidays,
                    };
                } else {
                    holidays = holidayRef.current.holidays;
                }

                datesToDisplay = getCalendarHoliday(datesToDisplay, holidays);
                setDates(datesToDisplay);
            }

            getDatesWithHolidays();

            setDates(datesToDisplay);
            const newStartDate = new Date(
                datesToDisplay[0][0].year,
                datesToDisplay[0][0].month,
                datesToDisplay[0][0].date
            );
            horizontalPositionMap.startText = moment(newStartDate, "YYYY-M-D").format(
                "D.M.YY"
            );
            horizontalPositionMap["end"] = --effectiveCounter;
            horizontalPositionMap["displayEnd"] = --counter;
            horizontalPositionMap["positionsToDates"] = datePositionCorrespondence;
            setHorizontalPositionMap(horizontalPositionMap);
        }
    }, [
        projectData,
        planData,
        prebookingsData,
        addCalendarLength,
        calendarStepWidth,
        dispatch,
        planningIndex,
    ]);

    useEffect(() => {
        if (
            projectData &&
            planData &&
            prebookingsData &&
            allPlansData &&
            horizontalPositionMap &&
            machineData
        ) {
            // Project done status
            // const isDone =
            //     ![
            //         planData?.machineRequirements[planningIndex]?.drivers ?? [],
            //         planData?.machineRequirements[planningIndex]?.workers ?? [],
            //         planData?.machineRequirements[planningIndex]?.managers ?? [],
            //     ].some((array) => array.length === 0) || planData?.machineRequirements[planningIndex]?.done;
            // setDone(isDone);

            setHidden(!!planData?.machineRequirements[+planningIndex]?.isHidden);

            if (!planData?.machineRequirements[+planningIndex]) {
                return;
            }
            const tempMachines: AllMachineData[] = planData.machineRequirements[
                +planningIndex
                ].machines.reduce((acc, crr) => {
                const {width, start, end, startText, endText} =
                    getWidthStartEndNumberByHorizontalPositionMap(
                        horizontalPositionMap,
                        crr.start,
                        crr.end,
                        calendarStepWidth
                    );
                const machine = {
                    ...crr,
                    start,
                    end,
                    width,
                    startText,
                    endText,
                };
                const index = acc.findIndex((item1) => item1.id === crr.machineId);
                if (index !== -1) {
                    // @ts-ignore
                    acc[index].array.push(machine);
                } else {
                    // array.push({id: item.machineId, machineName: item.machineName, array: [machine], machineService: item.machineService})
                    const machineBookedProjects = findMachineBookedProjects(
                        crr.machineId,
                        planData.projectId
                    );
                    const machineBookedPrebookings = prebookingsData
                        .filter((item) => item.machineId === crr.machineId)
                        .map((item) => ({
                            color: "242, 135, 5",
                            end: item.end,
                            prebookingName: item.projectName,
                            start: item.start,
                        }));
                    acc.push({
                        id: crr.machineId,
                        machineName: crr.machineName,
                        // @ts-ignore
                        array: [machine],
                        machineService: crr.machineService,
                        machineBookedProjects,
                        machineBookedPrebookings,
                    });
                }
                return acc;
            }, [] as AllMachineData[]);

            const staffRoles: OverlapKeys[] = ["drivers", "workers", "managers"];

            // Prevent machines/staffs reseted when change calendar length
            if (
                addCalendarLengthRef.current === null ||
                addCalendarLengthRef.current === addCalendarLength
            ) {
                const [tempDrivers, tempWorkers, tempSupervisors] = (
                    [
                        planData.machineRequirements[+planningIndex].drivers,
                        planData.machineRequirements[+planningIndex].workers,
                        planData.machineRequirements[+planningIndex].managers,
                    ] as UserWorkerVM[][]
                ).map((target, currentIndex) =>
                    target.reduce((array, item) => {
                        const {width, start, end, startText, endText} =
                            getWidthStartEndNumberByHorizontalPositionMap(
                                horizontalPositionMap,
                                item.start,
                                item.end,
                                calendarStepWidth
                            );

                        const user = {
                            ...item,
                            userName: `${item.firstName} ${item.lastName}`,
                            userId: item.id,
                            imageUrl: item.imageUrl,
                            start,
                            end,
                            width,
                            startText,
                            endText,
                        };

                        const index = array.findIndex((item1) => item1.id === item.id);
                        if (index !== -1) {
                            // @ts-ignore
                            array[index].array.push(user);
                        } else {
                            const staffSchedule = findStaffSchedule(
                                item.id,
                                planData.projectId,
                                staffRoles[currentIndex],
                                allPlansData
                            );

                            const serviceSchedules = getCrewServiceSchedule(
                                user.userId,
                                machineData
                            );
                            array.push({
                                id: item.id,
                                // @ts-ignore
                                array: [user],
                                staffVacation: item.starfVacations,
                                staffSchedule,
                                serviceSchedules,
                            });
                        }
                        return array;
                    }, [] as AllUserData[])
                );

                if (!drivers.length) {
                    setDrivers(tempDrivers);
                    driversRef.current = tempDrivers;
                }
                if (!machines.length) {
                    setMachines(tempMachines);
                    machinesRef.current = tempMachines;
                }
                if (!workers.length) {
                    setWorkers(tempWorkers);
                    workersRef.current = tempWorkers;
                }
                if (!supervisors.length) {
                    tempSupervisors.forEach((supervisor) => {
                        supervisor = findSupervisorsVariant(supervisor);
                    });
                    setSupervisors(tempSupervisors);
                    supervisorsRef.current = tempSupervisors;
                }
            }
            if (addCalendarLengthRef.current !== addCalendarLength) {
                addCalendarLengthRef.current = addCalendarLength;
            }

            setIsInitialized(true);
        }
    }, [
        projectData,
        planData,
        allPlansData,
        prebookingsData,
        planningIndex,
        calendarStepWidth,
        addCalendarLength,
        machineData,
    ]);

    const findSupervisorsVariant = (supervisor: AllUserData) => {
        const supervisorBars = [...supervisor.staffSchedule];
        const supervisorPlans = [...supervisor.array];
        let isOverlap = false;
        // If array is overlap with any other plans, calculate for the position counter
        for (const plan of supervisorPlans) {
            const planStartDate = plan.startText?.value.slice(0, 10);
            const planEndDate = plan.endText?.value.slice(0, 10);
            for (let i = 0; i < supervisorBars.length; i++) {
                const itemStartDate = supervisorBars[i].start;
                const itemEndDate = supervisorBars[i].end;
                isOverlap = checkTimeOverlap(
                    planStartDate,
                    planEndDate,
                    itemStartDate,
                    itemEndDate
                );
                if (isOverlap) break;
            }
            if (isOverlap) break;
        }
        if (isOverlap) {
            const {positionAndCounter} = findStaffIndexAndCounter(
                // @ts-ignore
                supervisorBars,
                1
            );
            // @ts-ignore
            supervisor.staffSchedule = supervisor.staffSchedule.map(
                // @ts-ignore
                (item, index) => ({...item, counter: positionAndCounter[index]})
            );
            // @ts-ignore
            supervisor.maxCounter = Math.max(...Object.values(positionAndCounter));
        } else supervisor.maxCounter = 1;
        return supervisor;
    };

    const getMonthIndicator = (dates: TDates) => {
        let components = [];
        // const currentMonth = calendar.month;
        let temp = dates[0][0]["month"];
        let counter = 0;
        for (let i = 0; i < dates.length; i++) {
            for (let j = 0; j < dates[i].length; j++) {
                if (dates[i][j]["month"] === temp) {
                    counter++;
                } else {
                    components.push({length: counter, label: months[temp]});
                    counter = 1;
                    temp = dates[i][j]["month"];
                }
            }
        }
        components.push({length: counter, label: months[temp]});
        return components;
    };

    const chooseRole = useCallback(
        (role: any) => {
            let target = null;
            let setTarget:
                | ((value: AllMachineData[]) => void)
                | ((value: AllUserData[]) => void)
                | null = null;
            let toggleTeachingBubble = null;
            // eslint-disable-next-line default-case
            switch (role) {
                case ROLES.MACHINE:
                    target = machinesRef.current;
                    setTarget = (value: AllMachineData[]) => {
                        setMachines(value);
                        machinesRef.current = value;
                    };
                    toggleTeachingBubble = toggleTeachingMachines;
                    break;
                case ROLES.WORKER:
                    target = workersRef.current;
                    setTarget = (value: AllUserData[]) => {
                        setWorkers(value);
                        workersRef.current = value;
                    };
                    toggleTeachingBubble = toggleTeachingWorkers;
                    break;
                case ROLES.DRIVER:
                    target = driversRef.current;
                    setTarget = (value: AllUserData[]) => {
                        setDrivers(value);
                        driversRef.current = value;
                    };
                    toggleTeachingBubble = toggleTeachingDrivers;
                    break;
                case ROLES.FUNKTIONÆR:
                case ROLES.SUPERVISOR:
                    target = supervisorsRef.current;
                    setTarget = (value: AllUserData[]) => {
                        setSupervisors(value);
                        supervisorsRef.current = value;
                    };
                    toggleTeachingBubble = toggleTeachingSupervisors;
                    break;
            }
            return {target, setTarget, toggleTeachingBubble};
        },
        [
            toggleTeachingMachines,
            toggleTeachingWorkers,
            toggleTeachingDrivers,
            toggleTeachingSupervisors,
        ]
    );

    const machineColorMap = useGetColorList(machines.map((el) => el.machineName));

    const drawDataBackground = useMemo(() => {
        if (drawData && machineColorMap[(drawData as MachineDrawData).machineId]) {
            return machineColorMap[(drawData as MachineDrawData).machineId];
        }

        return "#fff";
    }, [drawData]);

    const drawDataColor = useMemo(() => {
        if (drawData && machineColorMap[(drawData as MachineDrawData).machineId]) {
            return "#fff";
        }

        return "#000";
    }, [drawData, machineColorMap]);

    useEffect(() => {
        if (!drawData && tempDrawMachineId && !isGettingDraw.current) {
            setTempDrawMachineId("");

            const found = machines.find((el) => el.id === tempDrawMachineId);
            //aarsleff-fe.kodebaze.com/4420b18a-2ef7-41b4-a351-6be8affd1b8d
            api: if (found && !found.array.every((el) => el.startText || el.start)) {
                const tempList = machines.filter((el) => el.id !== tempDrawMachineId);
                setMachines(tempList);
                machinesRef.current = tempList;
            }
        }
    }, [drawData, machines, tempDrawMachineId]);

    useEffect(() => {
        if (!drawData && tempDrawStaffData && !isGettingDraw.current) {
            setTempDrawStaffData(null);

            const {id, role} = tempDrawStaffData;
            const {target, setTarget} = chooseRole(role);

            if (target && setTarget) {
                const foundIndex = target.findIndex((el) => el.id === id);

                if (foundIndex !== -1) {
                    // && !found.array.every((el) => el.startText || el.start)

                    // @ts-ignore
                    const arr = target[foundIndex].array.filter(
                        // @ts-ignore
                        (el) => el.startText || el.start
                    );

                    if (arr.length === 0) {
                        // @ts-ignore
                        const tempList = target.filter((el) => el.id !== id);

                        // @ts-ignore
                        setTarget(tempList);
                    } else {
                        const copyTarget = JSON.parse(JSON.stringify(target));

                        copyTarget[foundIndex].array = arr;

                        setTarget(copyTarget);
                    }
                }
            }
        }
    }, [drawData, machines, tempDrawStaffData, chooseRole]);

    const onDrawAddOverlap = () => {
        if (tempDrawMachineId) {
            setTempDrawMachineId("");

            const tempList = [...machines].filter(
                (el) => el.id !== tempDrawMachineId
            );
            setMachines(tempList);
            machinesRef.current = tempList;
        }

        if (tempDrawStaffData) {
            const {id, role} = tempDrawStaffData;

            const {target, setTarget} = chooseRole(role);

            if (target) {
                // @ts-ignore
                const tempList = target.filter((el) => el.id !== id);

                // @ts-ignore
                setTarget(tempList);
            }
        }
    };

    const isDrawingNew = useMemo(
        () => !!tempDrawMachineId || drawData || !!tempDrawStaffData,
        [tempDrawMachineId, tempDrawStaffData, drawData]
    );

    // This is for drawing newly added machines
    const addDrawMachine = (machine: TMachine) => {
        if (!planData || !prebookingsData) {
            return;
        }
        const tempList = [...machines];

        const indexList = tempList.findIndex(
            // @ts-ignore
            (item) => item.id === (machine.machineId || machine.id)
        );

        if (indexList === -1) {
            const machineBookedProjects = findMachineBookedProjects(
                machine.machineId,
                planData.projectId
            );
            const machineBookedPrebookings = prebookingsData
                .filter((item) => item.machineId === machine.machineId)
                .map((item) => ({
                    color: "242, 135, 5",
                    end: item.end,
                    prebookingName: item.projectName,
                    start: item.start,
                }));
            let currentMachineData: AllMachineData = {
                id: machine.machineId,
                machineName: machine.machineName,
                array: [],
                machineService: machine.machineService,
                machineBookedProjects,
                machineBookedPrebookings,
            };
            // start = horizontalPositionMap[machineReqStartDate];
            // end = horizontalPositionMap[machineReqEndDate];
            // const timeIsOverlap = checkTimeOverlapOthers(
            //   currentMachineData,
            //   horizontalPositionMap,
            //   "machine",
            //   start,
            //   end
            // );
            // if (timeIsOverlap) {
            //   const mostSuitableTime = findMostSuitableTime(
            //     currentMachineData,
            //     horizontalPositionMap,
            //     "machine",
            //     start,
            //     end
            //   );
            //   if (mostSuitableTime) {
            //     start = mostSuitableTime.start;
            //     end = mostSuitableTime.end;
            //   } else {
            //     const nearestPossibleTime = findNearestPossibleTime(
            //       currentMachineData,
            //       horizontalPositionMap,
            //       "machine"
            //     );
            //     if (!nearestPossibleTime) return;
            //     start = nearestPossibleTime.start;
            //     end = nearestPossibleTime.end;
            //   }
            // }
            const array = [
                {
                    ...machine,
                    // start,
                    // end,
                    // width: 25,
                    // startText:
                    //   horizontalPositionMap.positionsToDates[start * calendarStepWidth],
                    // endText:
                    //   horizontalPositionMap.positionsToDates[end * calendarStepWidth],
                },
            ];

            // @ts-ignore
            currentMachineData.array = array;
            tempList.push(currentMachineData);
            setMachines(tempList);
            machinesRef.current = tempList;

            onDraw(
                // @ts-ignore
                machine,
                currentMachineData.array,
                `machineContainer${currentMachineData.id}`,
                ROLES.MACHINE,
                currentMachineData.machineName
            );

            setTempDrawMachineId(currentMachineData.id);
        } else {
            dispatch(saveMessage("This machine is already added to this plan"));
        }
    };

    const addMachine = (
        machine: TMachine,
        isAutoAdd = false,
        isDrawn?: boolean
    ) => {
        if (!horizontalPositionMap || !planData || !prebookingsData) {
            return;
        }
        const tempList = [...machines];
        const indexList = tempList.findIndex(
            // @ts-ignore
            (item) => item.id === (machine.machineId || (machine.id as string))
        );
        if (isDrawn) {
            if (machine.machineId !== tempDrawMachineId) {
                // @ts-ignore
                tempList[indexList].array = [...tempList[indexList].array, machine];
            } else {
                // @ts-ignore
                tempList[indexList].array = [machine];
            }
            setMachines(tempList);
            machinesRef.current = tempList;
            !anyUpdate && setAnyUpdate(true);
            return;
        }
        let machineReqStartDate = moment(
            planData?.machineRequirements[+planningIndex]?.start
        ).format("YYYY-M-D");
        let machineReqEndDate = moment(
            planData?.machineRequirements[+planningIndex]?.end
        ).format("YYYY-M-D");
        let start, end;
        if (indexList !== -1) {
            const list = [...tempList[indexList].array];
            const mostSuitableTime = findMostSuitableTime(
                tempList[indexList],
                horizontalPositionMap,
                "machine",
                horizontalPositionMap[machineReqStartDate] as number,
                horizontalPositionMap[machineReqEndDate] as number
            );

            console.log(mostSuitableTime);

            if (mostSuitableTime) {
                start = mostSuitableTime.start;
                end = mostSuitableTime.end;
            } else {
                const nearestPossibleTime = findNearestPossibleTime(
                    tempList[indexList],
                    horizontalPositionMap,
                    "machine"
                );
                if (!nearestPossibleTime) return;
                start = nearestPossibleTime.start;
                end = nearestPossibleTime.end;
            }
            const newTarget = {
                ...machine,
                end,
                start,
                width: 25,
                startText:
                    horizontalPositionMap.positionsToDates[start * calendarStepWidth],
                endText:
                    horizontalPositionMap.positionsToDates[end * calendarStepWidth],
            };
            // @ts-ignore
            tempList[indexList].array = [...list, newTarget];
            setMachines(tempList);
            machinesRef.current = tempList;
        } else {
            const machineBookedProjects = findMachineBookedProjects(
                machine.machineId,
                planData.projectId
            );
            const machineBookedPrebookings = prebookingsData
                .filter((item) => item.machineId === machine.machineId)
                .map((item) => ({
                    color: "242, 135, 5",
                    end: item.end,
                    prebookingName: item.projectName,
                    start: item.start,
                }));
            let currentMachineData = {
                id: machine.machineId,
                machineName: machine.machineName,
                array: [],
                machineService: machine.machineService,
                machineBookedProjects,
                machineBookedPrebookings,
            };
            start = horizontalPositionMap[machineReqStartDate];
            end = horizontalPositionMap[machineReqEndDate];
            const timeIsOverlap = checkTimeOverlapOthers(
                currentMachineData,
                horizontalPositionMap,
                "machine",
                start as number,
                end as number
            );
            if (timeIsOverlap) {
                const mostSuitableTime = findMostSuitableTime(
                    currentMachineData,
                    horizontalPositionMap,
                    "machine",
                    start as number,
                    end as number
                );
                if (mostSuitableTime) {
                    start = mostSuitableTime.start;
                    end = mostSuitableTime.end;
                } else {
                    const nearestPossibleTime = findNearestPossibleTime(
                        currentMachineData,
                        horizontalPositionMap,
                        "machine"
                    );
                    if (!nearestPossibleTime) return;
                    start = nearestPossibleTime.start;
                    end = nearestPossibleTime.end;
                }
            }
            const array = [
                {
                    ...machine,
                    start,
                    end,
                    width: 25,
                    startText:
                        horizontalPositionMap.positionsToDates[
                        (start as number) * calendarStepWidth
                            ],
                    endText:
                        horizontalPositionMap.positionsToDates[
                        (end as number) * calendarStepWidth
                            ],
                },
            ];

            // @ts-ignore
            currentMachineData.array = array;
            tempList.push(currentMachineData);
            setMachines(tempList);
            machinesRef.current = tempList;
        }
        !isAutoAdd && toggleTeachingMachines();
        !anyUpdate && setAnyUpdate(true);
    };

    const getCurrentMachineBar = () => {
        const today = new Date();

        const firstMachine = machines.find((machine) => {
            const array = machine.array;

            let isFound = false;

            array.forEach((el) => {
                if (!isFound) {
                    const {value: endValue} = el.endText;

                    isFound = today <= new Date(endValue);

                    if (isFound) {
                        return;
                    }
                }
            });

            return isFound;
        });

        if (!firstMachine) {
            return null;
        }

        let firstMachineInfo = firstMachine.array
            .sort((a, b) => {
                // @ts-ignore
                return new Date(b.endText.value) - new Date(a.endText.value);
            })
            .find((machineBar) => {
                const {value: endValue} = machineBar.endText;

                return today <= new Date(endValue);
            });

        if (
            firstMachineInfo &&
            new Date(firstMachineInfo?.endText?.value) <= today
        ) {
            return null;
        } else {
            return firstMachineInfo;
        }
    };

    const addDrawStaff = async (
        user: User,
        role: string,
        machine?: TMachine | TTargetBubbleMachine
    ) => {
        if (!planData || !prebookingsData || !allPlansData || !machineData) {
            return;
        }

        const {target, setTarget} = chooseRole(role);

        if (!target || !setTarget) {
            return;
        }

        // @ts-ignore
        if (target && !target.find((el) => el.id === user.userId)) {
            let chosenMachine = machine || getCurrentMachineBar();

            const tempList = [...target];

            const staffSchedule = await findStaffSchedule(
                // @ts-ignore
                user.userId ? user.userId : user.id,
                planData.projectId,
                roleProp[role as keyof typeof roleProp],
                allPlansData
            );

            const servicesSchedules = getCrewServiceSchedule(
                user.userId,
                machineData
            );

            let currentStaffData = {
                // @ts-ignore
                id: user.userId ? user.userId : user.id,
                array: [],
                staffVacation: user.starfVacations,
                staffSchedule,
                servicesSchedules,
            };

            const array = [
                {
                    machineName: chosenMachine?.machineName ?? null,
                    machineId: chosenMachine?.machineId ?? null,
                    userName: `${user.firstName} ${user.lastName}`,
                    // @ts-ignore
                    userId: user.userId ? user.userId : user.id,
                    imageUrl: user.imageUrl,
                    // start,
                    // end,
                    // width: 25,
                    // startText:
                    //   horizontalPositionMap.positionsToDates[start * calendarStepWidth],
                    // endText:
                    //   horizontalPositionMap.positionsToDates[end * calendarStepWidth],
                },
            ];

            // @ts-ignore
            currentStaffData.array = array;

            // if (role === ROLES.SUPERVISOR) {
            //   currentStaffData = findSupervisorsVariant(currentStaffData);
            // }

            // @ts-ignore
            tempList.push(currentStaffData);

            // @ts-ignore
            setTarget(tempList);

            onDraw(
                // @ts-ignore
                user,
                currentStaffData.array,
                `${role.toLocaleLowerCase()}Container${user.userId}`,
                role,
                chosenMachine?.machineName
            );

            setTempDrawStaffData({
                id: user.userId,
                role,
            });
        } else {
            dispatch(saveMessage("This staff is already added to this plan"));
        }
    };

    const getMachineBackgroundColor = (machineList: any) => {
        if (!isStaffMachineSelected(machineList)) {
            return COLOR_GREY_OUT_HEX;
        }

        return machineColorMap[machineList.machineName] || "#fff";
    };

    const filterEmployeeNotSelected = ({
                                           data,
                                           isSupervisor = false,
                                       }: {
        data: AllUserData[];
        isSupervisor?: boolean;
    }) => {
        return data.filter((el) =>
            doesStaffWorkOnAnySelectedMachine(el, isSupervisor)
        );
    };

    const filterMachineNotSelected = ({data}: { data: AllMachineData[] }) => {
        // @ts-ignore
        return data.filter((el) => isStaffMachineSelected(el));
    };

    const getMachineColor = (machineList: any) => {
        if (!isStaffMachineSelected(machineList)) {
            return "#fff";
        }

        return machineColorMap[machineList.machineName] ? "#fff" : undefined;
    };

    const getEmploymentBackgroundColor = ({
                                              list,
                                              machineName,
                                              isSupervisor = false,
                                          }: {
        list: any;
        machineName: string;
        isSupervisor?: boolean;
    }) => {
        if (!doesStaffWorkOnAnySelectedMachine(list, isSupervisor)) {
            return COLOR_GREY_OUT_HEX;
        }

        return machineColorMap[machineName] || "#fff";
    };

    const getEmploymentColor = ({
                                    list,
                                    machineName,
                                    isSupervisor = false,
                                }: {
        list: any;
        machineName: string;
        isSupervisor?: boolean;
    }) => {
        if (!doesStaffWorkOnAnySelectedMachine(list, isSupervisor)) {
            return "#fff";
        }

        return machineColorMap[machineName] ? "#fff" : "#000";
    };

    const addEmployment = async (
        user: UserWorkerVM,
        role: string,
        machine:
            | TMachine
            | { machineName: string; machineId: string }
            | null = null,
        isAutoAdd = false,
        isTeam = false,
        isDrawn?: boolean,
        oneWeekDefault: boolean = true
    ) => {
        // role could be WORKER, DRIVER, SUPERVISOR, FUNCTIONKER
        const {target, setTarget, toggleTeachingBubble} = chooseRole(role);
        if (
            horizontalPositionMap &&
            allPlansData &&
            target &&
            setTarget &&
            planData &&
            horizontalPositionMap &&
            toggleTeachingBubble &&
            machineData
        ) {
            const tempList = [...target];

            // @ts-ignore
            const userId = user.userId ? user.userId : user.id;
            const indexList = tempList.findIndex((item) => item.id === userId);

            if (isDrawn) {
                const newTarget = {
                    // @ts-ignore
                    machineName: machine?.machineName ?? user?.machineName ?? null,
                    // @ts-ignore
                    machineId: machine?.machineName ?? user?.machineId ?? null,
                    userName: `${user.firstName} ${user.lastName}`,
                    // @ts-ignore
                    userId: user.userId ? user.userId : user.id,
                    // @ts-ignore
                    imageUrl: user.imageUrl,
                    ...user,
                };

                const startText: string = moment(
                    // @ts-ignore
                    newTarget.startText.value.slice(0, 10),
                    "YYYY-M-D"
                ).format("YYYY-M-D");

                const endText: string = moment(
                    // @ts-ignore
                    newTarget.endText.value.slice(0, 10),
                    "YYYY-M-D"
                ).format("YYYY-M-D");

                const isSupervisor = role === ROLES.SUPERVISOR;

                // const array = isSupervisor ? [] : tempList[indexList].array || [];
                const array = tempList[indexList].array || [];

                const arr: { start: string; end: string }[] = [
                    // @ts-ignore
                    ...(array || [])
                        // @ts-ignore
                        .filter((el) => el?.startText?.value)
                        // @ts-ignore
                        .map((el) => ({
                            start: el.startText.value,
                            end: el.endText.value,
                        })),
                    // @ts-ignore
                    ...(!isSupervisor ? tempList[indexList].staffSchedule || [] : []).map(
                        // @ts-ignore
                        (el) => ({
                            start: el.start,
                            end: el.end,
                        })
                    ),
                    // @ts-ignore
                    ...(tempList[indexList].staffVacation || []).map(
                        // @ts-ignore
                        (el) => ({
                            start: el.start,
                            end: el.end,
                        })
                    ),
                ];

                if (role !== ROLES.MACHINE) {
                    const serviceSchedules = getCrewServiceSchedule(
                        newTarget.userId,
                        machineData
                    );

                    arr.push(
                        ...serviceSchedules.map((el) => {
                            return {
                                start: el.start,
                                end: el.end,
                            };
                        })
                    );
                }

                for (const item of arr) {
                    const startDate = moment(item.start.slice(0, 10), "YYYY-M-D").format(
                        "YYYY-M-D"
                    );
                    const endDate = moment(item.end.slice(0, 10))
                        .add(1, "d")
                        .format("YYYY-M-D");

                    const dates = generateDatesRange({
                        startDate,
                        endDate,
                    }).map((el) =>
                        moment(el.toISOString().slice(0, 10), "YYYY-M-D").format("YYYY-M-D")
                    );

                    dates.pop();

                    const map: {
                        [k: string]: number;
                    } = {};

                    dates.forEach((el) => {
                        map[el] = 1;
                    });

                    if (map[startText] || map[endText]) {
                        dispatch(saveMessage("Cannot Overlap"));
                        return;
                    }
                }

                // @ts-ignore
                tempList[indexList].array = [...tempList[indexList].array, newTarget];
                // @ts-ignore
                setTarget(tempList);
                !anyUpdate && setAnyUpdate(true);
                return;
            }
            let machineReqStartDate = moment(
                planData?.machineRequirements[+planningIndex]?.start
            ).format("YYYY-M-D");

            let machineReqEndDate = moment(
                planData?.machineRequirements[+planningIndex]?.end
            ).format("YYYY-M-D");

            let start, end;

            if (indexList !== -1) {
                const list = [...tempList[indexList].array];
                if (role === ROLES.SUPERVISOR || role === ROLES.FUNKTIONÆR) {
                    if (isTeam) {
                        const teamMachine = machines.find(
                            (item) => item.id === machine?.machineId
                        );
                        const firstMachineInfo = teamMachine?.array[0];

                        if (firstMachineInfo) {
                            start = firstMachineInfo.start;
                            end =
                                horizontalPositionMap[
                                    moment(
                                        firstMachineInfo.endText.value.slice(0, 10),
                                        "YYYY-M-D"
                                    ).format("YYYY-M-D")
                                    ]; //avoid end different
                        }
                    } else {
                        const lastTarget = list[list.length - 1];

                        // @ts-ignore
                        if (
                            lastTarget.end + 1 >=
                            (horizontalPositionMap["end"] as number)
                        ) {
                            return;
                        }
                        start = lastTarget["end"] + 1;
                        end = lastTarget["end"] + 1;
                    }
                } else {
                    let checkTimeStart = horizontalPositionMap[machineReqStartDate];
                    let checkTimeEnd = horizontalPositionMap[machineReqEndDate];
                    if (role === ROLES.DRIVER || role === ROLES.MACHINE || isTeam) {
                        const driverMachine = machines.find(
                            (item) => item.id === machine?.machineId
                        );

                        if (driverMachine) {
                            const firstMachineInfo = driverMachine.array[0];
                            checkTimeStart = firstMachineInfo.start;
                            checkTimeEnd =
                                horizontalPositionMap[
                                    moment(
                                        firstMachineInfo.endText.value.slice(0, 10),
                                        "YYYY-M-D"
                                    ).format("YYYY-M-D")
                                    ]; //avoid end different
                        }
                    } else {
                        const firstMachine = machines[0];

                        if (firstMachine) {
                            const firstMachineInfo = firstMachine.array[0];
                            checkTimeStart = firstMachineInfo.start;
                            checkTimeEnd =
                                horizontalPositionMap[
                                    moment(
                                        firstMachineInfo.endText.value.slice(0, 10),
                                        "YYYY-M-D"
                                    ).format("YYYY-M-D")
                                    ]; //avoid end different
                        }
                    }
                    const mostSuitableTime = findMostSuitableTime(
                        tempList[indexList],
                        horizontalPositionMap,
                        "staff",
                        checkTimeStart as number,
                        checkTimeEnd as number
                    );

                    if (mostSuitableTime) {
                        start = mostSuitableTime.start;

                        end = mostSuitableTime.end;
                    } else {
                        const nearestPossibleTime = findNearestPossibleTime(
                            tempList[indexList],
                            horizontalPositionMap,
                            "machine"
                        );
                        if (!nearestPossibleTime) return;
                        start = nearestPossibleTime.start;
                        end = nearestPossibleTime.end;

                        if (
                            start < (horizontalPositionMap[machineReqStartDate] as number) ||
                            end < (horizontalPositionMap[machineReqStartDate] as number)
                        ) {
                            dispatch(saveMessage("This staff is already added."));
                            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
                        }

                        if (start === 0 && end === 0) {
                            dispatch(saveMessage("This staff is already added."));
                            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
                        }
                    }

                    // const startEnd = findNearestPossibleTime(tempList[indexList], horizontalPositionMap, 'staff');
                    // if (!startEnd) return;
                    // start = startEnd?.start;
                    // end = startEnd?.end;
                }
                const startText =
                    horizontalPositionMap.positionsToDates[
                    (start as number) * calendarStepWidth
                        ];
                const endText =
                    horizontalPositionMap.positionsToDates[
                    (end as number) * calendarStepWidth
                        ];

                if (startText && endText) {
                    const startValue = startText.value;
                    const endValue = endText.value;

                    const today = new Date();

                    if (new Date(startValue) < today && today < new Date(endValue)) {
                        start =
                            horizontalPositionMap[
                                moment(today.toISOString()).format("YYYY-M-D")
                                ];
                    }
                }

                if (isTeam && oneWeekDefault) {
                    if ((start as number) + 7 <= (end as number)) {
                        end = (start as number) + 7;
                    }
                }

                if (isTeam) {
                    const startDate = new Date(
                        horizontalPositionMap.positionsToDates[
                        (start as number) * calendarStepWidth
                            ].value.slice(0, 10)
                    );

                    const endDate = new Date(
                        horizontalPositionMap.positionsToDates[
                        (end as number) * calendarStepWidth
                            ].value.slice(0, 10)
                    );

                    const machineStartDate = new Date(machineReqStartDate);
                    const machineEndDate = new Date(machineReqEndDate);

                    if (startDate < machineStartDate && endDate < machineEndDate) {
                        return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
                    }
                }

                // let machineReqStartDate = moment(
                //   planData?.machineRequirements[+planningIndex]?.start
                // ).format("YYYY-M-D");
                // let machineReqEndDate = moment(
                //   planData?.machineRequirements[+planningIndex]?.end
                // ).format("YYYY-M-D");

                const newTarget = {
                    // @ts-ignore
                    machineName: machine?.machineName ?? user?.machineName ?? null,
                    // @ts-ignore
                    machineId: machine?.machineName ?? user?.machineId ?? null,
                    userName: isAutoAdd
                        ? // @ts-ignore
                        user.userName
                            ? // @ts-ignore
                            user.userName
                            : `${user.firstName} ${user.lastName}`
                        : `${user.firstName} ${user.lastName}`,
                    // @ts-ignore
                    userId: user.userId ? user.userId : user.id,
                    imageUrl: user.imageUrl,
                    start,
                    end,
                    width: 25,
                    startText:
                        horizontalPositionMap.positionsToDates[
                        (start as number) * calendarStepWidth
                            ],
                    endText:
                        horizontalPositionMap.positionsToDates[
                        (end as number) * calendarStepWidth
                            ],
                };
                // @ts-ignore
                tempList[indexList].array = [...list, newTarget];
                // @ts-ignore
                setTarget(tempList);
            } else {
                const staffSchedule = await findStaffSchedule(
                    // @ts-ignore
                    user.userId ? user.userId : user.id,
                    planData.projectId,
                    roleProp[role],
                    allPlansData
                );

                const serviceSchedules = getCrewServiceSchedule(user.id, machineData);

                let currentStaffData = {
                    // @ts-ignore
                    id: user.userId ? user.userId : user.id,
                    array: [],
                    staffVacation: user.starfVacations,
                    staffSchedule,
                    serviceSchedules,
                };
                start = horizontalPositionMap[machineReqStartDate];
                end = horizontalPositionMap[machineReqEndDate];
                if (role === ROLES.DRIVER || role === ROLES.WORKER || isTeam) {
                    const driverMachine = machines.find(
                        (item) => item.id === machine?.machineId
                    );
                    if (driverMachine) {
                        getCurrentMachineBar();
                        const firstMachineInfo = driverMachine.array[0];
                        start = firstMachineInfo.start;
                        end =
                            horizontalPositionMap[
                                moment(
                                    firstMachineInfo.endText.value.slice(0, 10),
                                    "YYYY-M-D"
                                ).format("YYYY-M-D")
                                ]; //avoid end different
                    }
                } else {
                    let firstMachineInfo = getCurrentMachineBar();

                    if (firstMachineInfo) {
                        start = firstMachineInfo.start;
                        end =
                            horizontalPositionMap[
                                moment(
                                    firstMachineInfo.endText.value.slice(0, 10),
                                    "YYYY-M-D"
                                ).format("YYYY-M-D")
                                ]; //avoid end different
                    } else {
                        dispatch(
                            saveMessage("No available machine bar to assign the worker to.")
                        );
                        return;
                    }
                }

                const timeIsOverlap = checkTimeOverlapOthers(
                    // @ts-ignore
                    // To Be Done
                    currentStaffData,
                    horizontalPositionMap,
                    "staff",
                    start,
                    end
                );
                // Supervisors are allowed to be overlapped
                if (timeIsOverlap && role !== ROLES.SUPERVISOR) {
                    const mostSuitableTime = findMostSuitableTime(
                        // @ts-ignore
                        currentStaffData,
                        horizontalPositionMap,
                        "staff",
                        start as number,
                        end as number
                    );
                    if (mostSuitableTime) {
                        start = mostSuitableTime.start;
                        end = mostSuitableTime.end;
                    } else {
                        const nearestPossibleTime = findNearestPossibleTime(
                            // @ts-ignore
                            currentStaffData,
                            horizontalPositionMap,
                            "staff"
                        );

                        if (!nearestPossibleTime) {
                            dispatch(
                                saveMessage(
                                    `Schedule of ${roleProp[role].slice(0, -1)} ${
                                        user.firstName
                                    } ${user.firstName} was fully booked!`
                                )
                            );
                            return;
                        }
                        start = nearestPossibleTime.start;
                        end = nearestPossibleTime.end;

                        if (
                            start < (horizontalPositionMap[machineReqStartDate] as number) ||
                            end < (horizontalPositionMap[machineReqStartDate] as number)
                        ) {
                            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
                        }

                        if (start === 0 && end === 0) {
                            return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
                        }
                    }
                }

                // We want to ensure that the start and end of the employee is not before today
                // Meaning if today was 17-11-2022 and the machine requirement start date is 15-11-2022
                // We want to set the start date of the employee to 17-11-2022
                const startText =
                    horizontalPositionMap.positionsToDates[
                    (start as number) * calendarStepWidth
                        ];
                const endText =
                    horizontalPositionMap.positionsToDates[
                    (end as number) * calendarStepWidth
                        ];

                if (startText && endText) {
                    const startValue = startText.value;
                    const endValue = endText.value;

                    const today = new Date();

                    if (today > new Date(startValue) && today <= new Date(endValue)) {
                        start =
                            horizontalPositionMap[
                                moment(today.toISOString()).format("YYYY-M-D")
                                ];
                    }
                }

                if (isTeam && oneWeekDefault) {
                    if ((start as number) + 7 <= (end as number)) {
                        end = (start as number) + 7;
                    }
                }

                if (isTeam) {
                    const startDate = new Date(
                        horizontalPositionMap.positionsToDates[
                        (start as number) * calendarStepWidth
                            ].value.slice(0, 10)
                    );

                    const endDate = new Date(
                        horizontalPositionMap.positionsToDates[
                        (end as number) * calendarStepWidth
                            ].value.slice(0, 10)
                    );

                    const machineStartDate = new Date(machineReqStartDate);
                    const machineEndDate = new Date(machineReqEndDate);

                    if (
                        // @ts-ignore
                        start < (horizontalPositionMap[machineReqStartDate] as number) ||
                        // @ts-ignore
                        end < (horizontalPositionMap[machineReqStartDate] as number)
                    ) {
                        return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
                    }

                    if (startDate < machineStartDate && endDate < machineEndDate) {
                        return `Team Member ${user.firstName} ${user.lastName} is busy during that period.`;
                    }
                }

                const array = [
                    {
                        machineName: machine?.machineName ?? null,
                        machineId: machine?.machineId ?? null,
                        userName: `${user.firstName} ${user.lastName}`,
                        // @ts-ignore
                        userId: user.userId ? user.userId : user.id,
                        imageUrl: user.imageUrl,
                        start,
                        end,
                        width: 25,
                        startText:
                            horizontalPositionMap.positionsToDates[
                            (start as number) * calendarStepWidth
                                ],
                        endText:
                            horizontalPositionMap.positionsToDates[
                            (end as number) * calendarStepWidth
                                ],
                    },
                ];
                // @ts-ignore
                currentStaffData.array = array;
                if (role === ROLES.SUPERVISOR)
                    // @ts-ignore
                    currentStaffData = findSupervisorsVariant(currentStaffData);
                // @ts-ignore
                tempList.push(currentStaffData);
                // @ts-ignore
                setTarget(tempList);
            }
            !isAutoAdd && toggleTeachingBubble();
            !anyUpdate && setAnyUpdate(true);
        }
    };

    const onDelete = (index: number, indexList: number, role: OverlapKeys) => {
        const {target, setTarget} = chooseRole(role);
        if (target && setTarget) {
            const arrayEmployment = JSON.parse(JSON.stringify(target));
            if (arrayEmployment[indexList].array.length === 1) {
                arrayEmployment.splice(indexList, 1);
            } else {
                arrayEmployment[indexList].array.splice(index, 1);
            }
            setTarget(arrayEmployment);
            toggleRefreshList();
            timeOutToggleRefresh();
            !anyUpdate && setAnyUpdate(true);
        }
    };

    const onClickSetTargetDelete = (role: string, indexList: number) => {
        setTargetBubbleDelete({
            target: `#delete-${role}-${indexList}`,
            role,
            indexList,
        });
    };

    const onClickDeleteListElement = () => {
        if (targetBubbleDelete) {
            const {target, setTarget} = chooseRole(targetBubbleDelete.role);

            if (!target || !setTarget) {
                return;
            }
            const targetTemp = JSON.parse(JSON.stringify(target));
            targetTemp.splice(targetBubbleDelete.indexList, 1);
            setTarget(targetTemp);
            setTargetBubbleDelete(null);
            toggleRefreshList();
            timeOutToggleRefresh();
            !anyUpdate && setAnyUpdate(true);
        }
    };

    const timeOutToggleRefresh = () => {
        if (refreshTimerRef) {
            clearTimeout(refreshTimerRef);
        }

        const ref = setTimeout(() => {
            toggleRefreshList();
        }, 0);

        setRefreshTimerRef(ref);
    };

    const onDrag = (
        e: DraggableEvent,
        d: DraggableData,
        width: number,
        currentIndex: number,
        indexList: number,
        role: string
    ) => {
        const {target, setTarget} = chooseRole(role);

        if (!target || !setTarget || !horizontalPositionMap || !machineData) {
            return;
        }

        const currentStart = Math.round(d.lastX / calendarStepWidth);
        const currentEnd = Math.round((d.lastX + width) / calendarStepWidth);
        let index = -1;
        const array = JSON.parse(JSON.stringify(target));
        if (array[indexList].array[currentIndex].start === currentStart) {
            return;
        }

        const startValue =
            horizontalPositionMap.positionsToDates[currentStart * calendarStepWidth];

        const endValue =
            horizontalPositionMap.positionsToDates[currentEnd * calendarStepWidth];

        if (!endValue || !startValue) {
            toggleRefreshList();
            toggleRefreshList();
            dispatch(saveMessage(`Invalid ${!startValue ? "start" : "end"} value`));
            return;
        }

        const currentStartDate: Date = new Date(startValue.value);
        const currentEndDate: Date = new Date(endValue.value);

        const temp = {
            ...array[indexList].array[currentIndex],
            start: currentStart,
            end: currentEnd,
            width: (currentEnd - currentStart) * calendarStepWidth,
            startText:
                horizontalPositionMap.positionsToDates[
                currentStart * calendarStepWidth
                    ],
            endText:
                horizontalPositionMap.positionsToDates[
                (currentEnd - 1) * calendarStepWidth
                    ],
        };

        // Remove current item
        array[indexList].array.splice(currentIndex, 1);

        // if (role !== ROLES.SUPERVISOR) {
        for (const item of array[indexList].array) {
            const startBeforeCurrentPlanEnd = currentStart <= item.end;
            const endAfterCurrentPlanStart = currentEnd > item.start;

            if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
                toggleRefreshList();
                toggleRefreshList();
                dispatch(saveMessage("Cannot Overlap"));
                return;
            }
        }
        // }

        // const starfVacations =
        // @ts-ignore
        // role === ROLES.SUPERVISOR ? [] : target[indexList].staffVacation || [];

        const starfVacations = target[indexList].staffVacation || [];

        const arr = [
            // @ts-ignore
            ...starfVacations.map((el) => ({
                start: el?.item?.start || el.start,
                end: el?.item?.end || el.end,
            })),
            // @ts-ignore
            ...(role !== ROLES.SUPERVISOR ? array[indexList].staffSchedule || [] : [])
                // @ts-ignore
                .map((el) => ({
                    start: el.start,
                    end: el.end,
                })),
        ];

        if (role !== ROLES.MACHINE) {
            const serviceSchedules = getCrewServiceSchedule(
                target[indexList].id,
                machineData
            );

            arr.push(
                ...serviceSchedules.map((el) => {
                    return {
                        start: el.start,
                        end: el.end,
                    };
                })
            );
        }

        if (role === ROLES.MACHINE) {
            arr.push(
                ...[
                    // @ts-ignore
                    ...array[indexList].machineService.map((el) => ({
                        start: el.start,
                        end: el.end,
                    })),
                    // @ts-ignore
                    ...array[indexList].machineBookedProjects.map((el) => ({
                        start: el.start,
                        end: el.end,
                    })),
                    // @ts-ignore
                    ...array[indexList].machineBookedPrebookings.map((el) => ({
                        start: el.start,
                        end: el.end,
                    })),
                ]
            );
        }

        for (const item of arr) {
            // const startDate = moment(item.start.slice(0, 10), "YYYY-M-D").format(
            //   "YYYY-M-D"
            // );
            // const endDate = moment(item.end.slice(0, 10))
            //   .add(1, "d")
            //   .format("YYYY-M-D");

            // const vacationStartNum = horizontalPositionMap[startDate];
            // const vacationEndNum = horizontalPositionMap[endDate];

            // if (vacationStartNum && vacationEndNum) {
            // @ts-ignore
            const startBeforeCurrentPlanEnd = currentStartDate < new Date(item.end);
            // @ts-ignore
            const endAfterCurrentPlanStart = currentEndDate > new Date(item.start);

            if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
                toggleRefreshList();
                toggleRefreshList();
                dispatch(saveMessage("Cannot Overlap"));
                return;
            }
            // }
        }

        if (target[indexList].array.length === 1) {
            index = 0;
        } else if (target[indexList].array.length > 1) {
            for (let i = 0; i < array[indexList].array.length; i++) {
                if (
                    (i === 0 && currentEnd <= array[indexList].array[i].start) ||
                    (array[indexList].array[i - 1]?.end <= currentStart &&
                        currentEnd <= array[indexList].array[i].start)
                ) {
                    index = i;
                    break;
                }
                if (
                    i === array[indexList].array.length - 1 &&
                    currentStart >= array[indexList].array[i].end
                ) {
                    index = array[indexList].array.length;
                }
            }
        }
        if (index === -1) {
            return;
        }
        array[indexList].array.splice(index, 0, temp);
        setTarget(array);
        // Have to call this 2 times to force refresh list
        toggleRefreshList();
        toggleRefreshList();
        !anyUpdate && setAnyUpdate(true);
    };

    const onResize = (
        e: MouseEvent | TouchEvent,
        direction: Direction,
        ref: HTMLElement,
        delta: ResizableDelta,
        position: Position,
        index: number,
        indexList: number,
        role: string
    ) => {
        const {target, setTarget} = chooseRole(role);

        if (
            !target ||
            !setTarget ||
            !horizontalPositionMap ||
            !planData ||
            !machineData
        ) {
            return;
        }

        let currentStart = target[indexList].array[index].start;
        let currentEnd = target[indexList].array[index].end;
        if (direction === "left") {
            currentStart = Math.round(position.x / calendarStepWidth);
            // if (target[indexList].array[index - 1] && target[indexList].array[index - 1].end > currentStart) {
            //     currentStart = target[indexList].array[index - 1].end;
            // }
        } else {
            currentEnd = Math.round(
                currentStart + ref.offsetWidth / calendarStepWidth
            );
        }
        const startValue =
            horizontalPositionMap.positionsToDates[currentStart * calendarStepWidth];

        const endValue =
            horizontalPositionMap.positionsToDates[currentEnd * calendarStepWidth];

        if (!endValue || !startValue) {
            toggleRefreshList();
            toggleRefreshList();
            dispatch(saveMessage(`Invalid ${!startValue ? "start" : "end"} value`));
            return;
        }

        const currentStartDate: Date = new Date(startValue.value);
        const currentEndDate: Date = new Date(endValue.value);

        if (currentStartDate.getTime() === currentEndDate.getTime()) {
            toggleRefreshList();
            toggleRefreshList();
            dispatch(saveMessage(`Invalid date`));
            return;
        }

        // Check for overlap
        // @ts-ignore
        const arrayRemoveCurrentIndex = target[indexList].array.filter(
            // @ts-ignore
            (item) =>
                item.start !== target[indexList].array[index].start &&
                item.end !== target[indexList].array[index].end
        );

        // if (role !== ROLES.SUPERVISOR) {
        for (const item of arrayRemoveCurrentIndex) {
            const startBeforeCurrentPlanEnd = currentStart < item.end;
            const endAfterCurrentPlanStart = currentEnd > item.start;

            if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
                toggleRefreshList();
                toggleRefreshList();
                dispatch(saveMessage("Cannot Overlap"));
                return;
            }
        }
        // }

        // @ts-ignore
        // const starfVacations =
        // @ts-ignore
        // role === ROLES.SUPERVISOR ? [] : target[indexList].staffVacation || [];

        const starfVacations = target[indexList].staffVacation || [];

        const arr = [
            // @ts-ignore
            ...starfVacations.map((el) => ({
                start: el?.item?.start || el.start,
                end: el?.item?.end || el.end,
            })),
            ...(role !== ROLES.SUPERVISOR
                    ? // @ts-ignore
                    target[indexList].staffSchedule || []
                    : []
            )
                // @ts-ignore
                .map((el) => ({
                    start: el.start,
                    end: el.end,
                })),
        ];

        if (role !== ROLES.MACHINE) {
            const serviceSchedules = getCrewServiceSchedule(
                target[indexList].id,
                machineData
            );

            arr.push(
                ...serviceSchedules.map((el) => {
                    return {
                        start: el.start,
                        end: el.end,
                    };
                })
            );
        }

        if (role === ROLES.MACHINE) {
            arr.push(
                ...[
                    // @ts-ignore
                    ...target[indexList].machineService.map((el) => ({
                        start: el.start,
                        end: el.end,
                    })),
                    // @ts-ignore
                    ...target[indexList].machineBookedProjects.map((el) => ({
                        start: el.start,
                        end: el.end,
                    })),
                    // @ts-ignore
                    ...target[indexList].machineBookedPrebookings.map((el) => ({
                        start: el.start,
                        end: el.end,
                    })),
                ]
            );
        }

        for (const item of arr) {
            // const startDate = moment(item.start.slice(0, 10), "YYYY-M-D").format(
            //   "YYYY-M-D"
            // );
            // const endDate = moment(item.end.slice(0, 10))
            //   .add(1, "d")
            //   .format("YYYY-M-D");

            // const vacationStartNum = horizontalPositionMap[startDate];
            // const vacationEndNum = horizontalPositionMap[endDate];

            // if (vacationStartNum && vacationEndNum) {
            // @ts-ignore
            const startBeforeCurrentPlanEnd = currentStartDate < new Date(item.end);
            // @ts-ignore
            const endAfterCurrentPlanStart = currentEndDate > new Date(item.start);

            if (startBeforeCurrentPlanEnd && endAfterCurrentPlanStart) {
                toggleRefreshList();
                toggleRefreshList();
                dispatch(saveMessage("Cannot Overlap"));
                return;
            }
            // }
        }

        if (
            currentStart > horizontalPositionMap.end ||
            currentEnd > horizontalPositionMap.end ||
            currentStart === currentEnd
        ) {
            return;
        }
        const array = JSON.parse(JSON.stringify(target));
        const temp1 = {
            ...array[indexList].array[index],
            width: (currentEnd - currentStart) * calendarStepWidth,
            start: currentStart,
            end: currentEnd,
            startText:
                horizontalPositionMap.positionsToDates[
                currentStart * calendarStepWidth
                    ],
            endText:
                horizontalPositionMap.positionsToDates[
                (currentEnd - 1) * calendarStepWidth
                    ],
        };
        array[indexList].array[index] = {...temp1};

        // array[index] = {...temp};
        setTarget(array);
        // Have to call this 2 times to force refresh list
        toggleRefreshList();
        toggleRefreshList();
        !anyUpdate && setAnyUpdate(true);
    };

    const onSubmitUpdatePlan = () => {
        if (!planData) {
            return;
        }

        // @ts-ignore
        const {machinesNew, conflictMachineData} = checkForOverlappedMachines(
            machines,
            planData.projectId
        );
        // @ts-ignore
        const {driversNew, driversConflictData} = checkForOverlappedStaffs(
            drivers,
            planData.projectId,
            "drivers"
        );
        // @ts-ignore
        const {workersNew, workersConflictData} = checkForOverlappedStaffs(
            workers,
            planData.projectId,
            "workers"
        );
        // supervisors are allowed to be overlapped
        // const { managersNew, managersConflictData } = checkForOverlappedStaffs(supervisors, planData.projectId, 'managers');
        // @ts-ignore
        const overlappedMachines = machinesNew.some((machine) =>
            // @ts-ignore
            machine.array.some((item) => item.overlapped?.length)
        );
        // @ts-ignore
        const overlappedDrivers = driversNew.some((staff) =>
            // @ts-ignore
            staff.array.some((item) => item.overlapped?.length)
        );
        // @ts-ignore
        const overlappedWorkers = workersNew.some((staff) =>
            // @ts-ignore
            staff.array.some((item) => item.overlapped?.length)
        );
        // const overlappedManagers = managersNew.some((staff) => staff.array.some((item) => item.overlapped?.length));
        if (overlappedMachines || overlappedDrivers || overlappedWorkers) {
            dispatch(
                showConflictMessage(
                    {
                        machine: conflictMachineData,
                        driver: driversConflictData,
                        worker: workersConflictData,
                        // manager: managersConflictData,
                    },
                    MessageBarType.error
                )
            );
            return false;
        }
        tempVacations.length && saveTempVacations();
        if (planData?.machineRequirements[+planningIndex]) {
            let [replaceDrivers, replaceWorkers, replaceSupervisors] = (
                [drivers, workers, supervisors] as AllUserData[][]
            ).map((target) =>
                target
                    .reduce((array, item) => [...array, ...item.array], [] as UserArray[])
                    .map((item) => ({
                        id: item.userId,
                        firstName: item.firstName
                            ? item.firstName
                            : item.userName.split(" ")[0],
                        lastName: item.lastName
                            ? item.lastName
                            : item.userName.split(" ")[1],
                        start: item.startText.value,
                        end: item.endText.value,
                        machineId: item?.machineId ?? null,
                    }))
            );
            const replaceMachines = machines
                .reduce(
                    (array, item) => [...array, ...item.array],
                    [] as MachineArray[]
                )
                .map((machine) => ({
                    machineId: machine.machineId,
                    machineName: machine.machineName,
                    start: machine.startText.value,
                    end: machine.endText.value,
                }));
            const newPlan = JSON.parse(JSON.stringify(planData));
            newPlan.machineRequirements[planningIndex].machines = replaceMachines;
            newPlan.machineRequirements[planningIndex].drivers = replaceDrivers;
            newPlan.machineRequirements[planningIndex].workers = replaceWorkers;
            newPlan.machineRequirements[planningIndex].managers = replaceSupervisors;
            // newPlan.machineRequirements[planningIndex].done = done;
            newPlan.machineRequirements[planningIndex].isHidden = hidden;
            newPlan.activityLog = planActivityLogComposer(
                planData?.machineRequirements[+planningIndex],
                newPlan.machineRequirements[planningIndex],
                currentUserId
            );

            mutateUpdatePlan(newPlan);
        }
        return true;
    };

    const findMachineBookedProjects = (machineId: string, projectId: string) => {
        const machineBookedProjects: {
            projectId: string;
            name: string;
            color: string;
            start: string;
            end: string;
            crmProjectStatusCode: string;
        }[] = [];
        allPlansData
            ?.filter((plan) => !plan.inactive)
            .forEach((plan) => {
                plan.machineRequirements.forEach((requirement) => {
                    requirement.machines.forEach((machine) => {
                        if (
                            plan.projectId !== projectId &&
                            machine.machineId === machineId
                        ) {
                            const machineInfo = {
                                projectId: plan.projectId,
                                name: plan.projectName,
                                color: plan.color,
                                start: machine.start,
                                end: machine.end,
                                crmProjectStatusCode: plan.crmProjectStatusCode,
                            };
                            machineBookedProjects.push(machineInfo);
                        }
                    });
                });
            });
        return machineBookedProjects;
    };

    const checkTimeOverlap = (
        start: string,
        end: string,
        currentStart: string,
        currentEnd: string
    ) => {
        const startDate = new Date(
            start
                .split("-")
                .map((item) => (item.length < 2 ? `0${item}` : item))
                .join("-")
        );
        const endDate = new Date(
            end
                .split("-")
                .map((item) => (item.length < 2 ? `0${item}` : item))
                .join("-")
        );
        const currentStartDate = new Date(currentStart);
        const currentEndDate = new Date(currentEnd);
        const startBeforeCurrentEnd = startDate <= currentEndDate;
        const endAfterCurrentStart = endDate >= currentStartDate;
        const isOverLap = startBeforeCurrentEnd && endAfterCurrentStart;
        return isOverLap;
    };

    const checkForOverlappedMachines = (
        currentMachines: AllMachineData[],
        projectId: string
    ) => {
        if (!allPlansData) {
            return;
        }

        const conflictMachineData = {};
        currentMachines.forEach((machineReq) => {
            machineReq.array.forEach((currentMachine) => {
                const machineId = currentMachine.machineId;
                const overlappedMachines: (AllMachineData & {
                    projectId: string;
                    name: string;
                    color: string;
                    backgroundCaro: boolean;
                })[] = [];
                // Find all overlapped machines
                allPlansData
                    .filter(
                        (plan) =>
                            // @ts-ignore
                            !plan.inactive && !crmSpecialStatus[plan.crmProjectStatusCode]
                    )
                    .forEach((plan) => {
                        plan.machineRequirements.forEach((requirement) => {
                            requirement.machines.forEach((machine) => {
                                const isOverLap = checkTimeOverlap(
                                    currentMachine.startText.value,
                                    currentMachine.endText?.value,
                                    machine.start,
                                    machine.end
                                );
                                if (
                                    plan.projectId !== projectId &&
                                    machine.machineId === machineId &&
                                    isOverLap
                                ) {
                                    const machineInfo = {
                                        ...machine,
                                        projectId: plan.projectId,
                                        name: plan.projectName,
                                        color: plan.color,
                                        backgroundCaro: false,
                                    };

                                    // @ts-ignore
                                    overlappedMachines.push(machineInfo);
                                    // @ts-ignore
                                    if (!conflictMachineData[currentMachine.machineName])
                                        // @ts-ignore
                                        conflictMachineData[currentMachine.machineName] = [];
                                    // check if machine is presented in the array
                                    if (
                                        !JSON.stringify(
                                            // @ts-ignore
                                            conflictMachineData[currentMachine.machineName]
                                        ).includes(JSON.stringify(machineInfo))
                                    )
                                        // @ts-ignore
                                        conflictMachineData[currentMachine.machineName].push(
                                            machineInfo
                                        );
                                }
                            });
                        });
                    });
                machineReq.machineService?.forEach((service) => {
                    const isOverLap = checkTimeOverlap(
                        currentMachine.startText.value,
                        currentMachine.endText?.value,
                        service.start.slice(0, -1), // slice(0,-1) to remove timezone
                        service.end.slice(0, -1)
                    );
                    if (isOverLap) {
                        // @ts-ignore
                        service.name = service.comment;
                        // @ts-ignore
                        service.backgroundCaro = true;
                        // @ts-ignore
                        overlappedMachines.push(service);
                        // @ts-ignore
                        if (!conflictMachineData[currentMachine.machineName])
                            // @ts-ignore
                            conflictMachineData[currentMachine.machineName] = [];
                        // check if service is presented in the array
                        if (
                            // @ts-ignore
                            !JSON.stringify(
                                // @ts-ignore
                                conflictMachineData[currentMachine.machineName]
                            ).includes(JSON.stringify(service))
                        )
                            // @ts-ignore
                            conflictMachineData[currentMachine.machineName].push(service);
                    }
                });
                machineReq.machineBookedPrebookings?.forEach((prebooking) => {
                    const isOverLap = checkTimeOverlap(
                        currentMachine.startText.value,
                        currentMachine.endText?.value,
                        prebooking.start.slice(0, -1), // slice(0,-1) to remove timezone
                        prebooking.end.slice(0, -1)
                    );
                    if (isOverLap) {
                        // @ts-ignore
                        prebooking.name = prebooking.prebookingName;
                        // @ts-ignore
                        prebooking.backgroundCaro = false;
                        // @ts-ignore
                        overlappedMachines.push(prebooking);
                        // @ts-ignore
                        if (!conflictMachineData[currentMachine.machineName])
                            // @ts-ignore
                            conflictMachineData[currentMachine.machineName] = [];
                        // check if prebooking is presented in the array
                        if (
                            !JSON.stringify(
                                // @ts-ignore
                                conflictMachineData[currentMachine.machineName]
                            ).includes(JSON.stringify(prebooking))
                        )
                            // @ts-ignore
                            conflictMachineData[currentMachine.machineName].push(prebooking);
                    }
                });
                // @ts-ignore
                currentMachine.overlapped = overlappedMachines;
            });
        });
        return {machinesNew: currentMachines, conflictMachineData};
    };

    const checkForOverlappedStaffs = (
        currentStaffs: AllUserData[],
        projectId: string,
        role: string
    ) => {
        if (!allPlansData) {
            return;
        }
        const conflictStaffData = {};
        currentStaffs.forEach((item) => {
            item.array.forEach((staff) => {
                const staffId = staff.id || staff.userId;
                const staffFullName = staff.userName;
                // @ts-ignore
                const overlappedSchedule = [];
                if (role !== "managers") {
                    // Manager'fs plans are allowed to be overlapped
                    // Find all overlapped plans
                    allPlansData
                        .filter(
                            (plan) =>
                                // @ts-ignore
                                !plan.inactive && !crmSpecialStatus[plan.crmProjectStatusCode]
                        )
                        .forEach((plan) => {
                            plan.machineRequirements.forEach((requirement) => {
                                // @ts-ignore
                                requirement[role].forEach((currentStaff) => {
                                    const isOverLap = checkTimeOverlap(
                                        staff.startText.value,
                                        staff.endText?.value,
                                        currentStaff.start.slice(0, -1),
                                        currentStaff.end.slice(0, -1)
                                    );
                                    if (
                                        plan.projectId !== projectId &&
                                        currentStaff.id === staffId &&
                                        isOverLap
                                    ) {
                                        const staffInfo = {
                                            ...currentStaff,
                                            projectId: plan.projectId,
                                            name: plan.projectName,
                                            color: plan.color,
                                            backgroundCaro: false,
                                        };
                                        overlappedSchedule.push(staffInfo);
                                        // @ts-ignore
                                        if (!conflictStaffData[staffFullName])
                                            // @ts-ignore
                                            conflictStaffData[staffFullName] = [];
                                        // check if staff is presented in the array
                                        if (
                                            !JSON.stringify(
                                                // @ts-ignore
                                                conflictStaffData[staffFullName]
                                            ).includes(JSON.stringify(staffInfo))
                                        )
                                            // @ts-ignore
                                            conflictStaffData[staffFullName].push(staffInfo);
                                    }
                                });
                            });
                        });
                }
                item.staffVacation?.forEach((vacation) => {
                    const isOverLap = checkTimeOverlap(
                        staff.startText.value,
                        staff.endText?.value,
                        vacation.start.slice(0, -1),
                        vacation.end.slice(0, -1)
                    ); // slice(0,-1) to remove timezone
                    if (isOverLap) {
                        // @ts-ignore
                        vacation.name = reasonVacationStaffOptions[vacation.reason].text;
                        // @ts-ignore
                        vacation.backgroundCaro = true;
                        overlappedSchedule.push(vacation);
                        // @ts-ignore
                        if (!conflictStaffData[staffFullName])
                            // @ts-ignore
                            conflictStaffData[staffFullName] = [];
                        // check if service is presented in the array
                        if (
                            // @ts-ignore
                            !JSON.stringify(conflictStaffData[staffFullName]).includes(
                                JSON.stringify(vacation)
                            )
                        )
                            // @ts-ignore
                            conflictStaffData[staffFullName].push(vacation);
                    }
                });
                // @ts-ignore
                staff.overlapped = overlappedSchedule;
            });
        });
        return {
            [`${role}New`]: currentStaffs,
            [`${role}ConflictData`]: conflictStaffData,
        };
    };

    const findMostSuitableTime = (
        currentData: AllMachineData | AllUserData,
        horizontalPositionMap: TPlanHorizontalPositionMap,
        target: "machine" | "staff",
        machineReqStart: number,
        machineReqEnd: number
    ) => {
        const possibleTime = [];
        let newStart = machineReqStart; // Start check from the beginning of calendar, allow one day booking
        let newEnd = machineReqStart; // Start check from the beginning of calendar, allow one day booking

        while (newEnd <= machineReqEnd) {
            const isOverLap = checkTimeOverlapOthers(
                currentData,
                horizontalPositionMap,
                target,
                newStart,
                newEnd
            );
            if (!isOverLap) {
                possibleTime.push({start: newStart, end: newEnd});
                newEnd++;
            } else {
                newStart++;
                newEnd = newStart;
            }
        }
        const mostSuitableTime = possibleTime.sort(
            (a, b) => b.end - b.start - (a.end - a.start)
        )[0];
        return mostSuitableTime;
    };

    // staffPlanning: AllUserData,
    // horizontalPositionMap: TPlanHorizontalPositionMap,
    // roles: string

    const findNearestPossibleTime = (
        currentData: AllUserData | AllMachineData,
        horizontalPositionMap: TPlanHorizontalPositionMap,
        target: "machine" | "staff"
    ) => {
        let newStartEnd = 0; // Start check from the beginning of calendar, allow one day booking

        while (newStartEnd <= horizontalPositionMap.end) {
            const isOverLap = checkTimeOverlapOthers(
                currentData,
                horizontalPositionMap,
                target,
                newStartEnd,
                newStartEnd
            );
            if (!isOverLap) return {start: newStartEnd, end: newStartEnd};
            newStartEnd++;
        }
        return false;
    };

    const checkTimeOverlapOthers = (
        currentData: AllUserData | AllMachineData,
        horizontalPositionMap: TPlanHorizontalPositionMap,
        target: "staff" | "machine",
        newStart: number,
        newEnd: number
    ) => {
        const filterArraysKey =
            target === "machine"
                ? [
                    "array",
                    "machineService",
                    "machineBookedProjects",
                    "machineBookedPrebookings",
                ]
                : ["array", "staffSchedule", "staffVacation"];
        let isOverLap = false;
        let newStartDate = Object.keys(horizontalPositionMap).find(
            (key) => horizontalPositionMap[key] === newStart
        );
        let newEndDate = Object.keys(horizontalPositionMap).find(
            (key) => horizontalPositionMap[key] === newEnd
        );

        if (newStartDate && newEndDate) {
            for (const key of filterArraysKey) {
                for (const item of currentData[key as keyof typeof currentData] || []) {
                    if (typeof item !== "string") {
                        const currentStart =
                            key === "array"
                                ? item.startText.value.slice(0, 10)
                                : // @ts-ignore
                                item.start.slice(0, 10);
                        const currentEnd =
                            key === "array"
                                ? item.endText.value.slice(0, 10)
                                : // @ts-ignore
                                item.end.slice(0, 10);
                        isOverLap = checkTimeOverlap(
                            newStartDate,
                            newEndDate,
                            currentStart,
                            currentEnd
                        );
                        if (isOverLap) break;
                    }
                }
                if (isOverLap) break;
            }
        }
        return isOverLap;
    };

    const machineForAdd = useMemo(() => {
        return (
            machineData
                ?.filter(
                    (machine) =>
                        !machine.inactive &&
                        machine.techDepartments.includes(
                            planData?.machineRequirements[+planningIndex]
                                ?.techDepartment as unknown as string
                        )
                )
                .sort((a, b) => dynamicSort(["machineType", "machineName"])(a, b)) ?? []
        );
    }, [machineData, planData?.machineRequirements, planningIndex]);

    const otherSectionsMachine = useMemo(() => {
        return (
            machineData
                ?.filter(
                    (machine) =>
                        !machine.inactive &&
                        !machine.techDepartments.includes(
                            planData?.machineRequirements[+planningIndex]
                                ?.techDepartment as unknown as string
                        )
                )
                .sort((a, b) => dynamicSort(["machineType", "machineName"])(a, b)) ?? []
        );
    }, [machineData, planData?.machineRequirements, planningIndex]);

    const staffForAdd = useMemo(() => {
        return (
            staffData?.filter(
                (staff) =>
                    !staff.inactive &&
                    staff.techDepartment ===
                    planData?.machineRequirements[+planningIndex]?.techDepartment
            ) ?? []
        );
    }, [planData?.machineRequirements, planningIndex, staffData]);

    const otherSectionsStaff = useMemo(() => {
        return (
            staffData?.filter(
                (staff) =>
                    !staff.inactive &&
                    staff.techDepartment !==
                    planData?.machineRequirements[+planningIndex]?.techDepartment
            ) ?? []
        );
    }, [planData?.machineRequirements, planningIndex, staffData]);

    const handleSelectedTeam = async (
        team: TMachineTeamModel,
        oneWeekDefault = true
    ) => {
        const teamData = await getTeamByTeamId(team.id)(dispatch);

        if (teamData) {
            const machine = {
                machineId: teamData.machineId,
                machineName: teamData.machineName,
            };

            const errorMessages: string[] = [];

            for await (const driver of teamData?.drivers || []) {
                const msg = await addEmployment(
                    driver,
                    ROLES.DRIVER,
                    machine /* machine */,
                    true /* isAutoAdd */,
                    true /*isTeam*/,
                    false,
                    oneWeekDefault
                );

                if (typeof msg === "string") {
                    errorMessages.push(msg);
                }
            }

            for await (const worker of teamData?.workers || []) {
                const msg = await addEmployment(
                    worker,
                    ROLES.WORKER,
                    machine /* machine */,
                    true /* isAutoAdd */,
                    true /*isTeam*/,
                    false,
                    oneWeekDefault
                );

                if (typeof msg === "string") {
                    errorMessages.push(msg);
                }
            }

            for await (const manager of teamData?.managers || []) {
                const msg = await addEmployment(
                    manager,
                    ROLES.SUPERVISOR,
                    machine /* machine */,
                    true /* isAutoAdd */,
                    true /*isTeam*/,
                    false,
                    oneWeekDefault
                );

                if (typeof msg === "string") {
                    errorMessages.push(msg);
                }
            }

            if (errorMessages.length !== 0) {
                // const message = errorMessages.join("\r\n");

                dispatch(
                    saveMessage(
                        <div style={{margin: 0}}>
                            {errorMessages.map((val, index) => (
                                <p style={{margin: 0}} key={"message-" + index}>
                                    {val}
                                </p>
                            ))}
                        </div>
                    )
                );
            }
        }
    };

    // Add team when click from planning page
    useEffect(() => {
        if (
            projectData &&
            planData &&
            teamData &&
            horizontalPositionMap &&
            allPlansData &&
            !addTeamOnRouting
        ) {
            // Wait 200ms for machine/staff initial
            setAddTeamOnRouting(true);
            const ref = setTimeout(async () => {
                await handleSelectedTeam(teamData);
            }, 200);

            return () => {
                clearTimeout(ref);
            };
        }
    }, [projectData, planData, teamData, horizontalPositionMap, allPlansData]);

    useEffect(() => {
        let arr = [];
        // @ts-ignore
        let res = [];
        teamsData &&
        // @ts-ignore
        teamsData.forEach((t) => {
            if (
                !t.inactive &&
                t.techDepartments.includes(
                    planData?.machineRequirements[+planningIndex]?.techDepartment
                )
            ) {
                // @ts-ignore
                if (!res[t.machineId]) {
                    res[t.machineId] = {
                        machineId: t.machineId,
                        machineName: t.machineName,
                        techAreas: t.techAreas,
                        techDepartments: t.techDepartments,
                        teams: [],
                    };
                }
                // @ts-ignore
                res[t.machineId].teams.push(t);
            }
        });

        // @ts-ignore
        let temp = Object.values(res);

        for (let i = 0; i < machines.length; i++) {
            for (let j = 0; j < temp.length; j++) {
                if (machines[i].id === temp[j].machineId) {
                    arr.push(temp[j]);
                }
            }
        }

        // @ts-ignore
        setMachinesTeamList(arr);
    }, [machines, teamsData]);

    const getMachineTeams = (machineId: string, isOneWeek = true) => {
        const res =
            machinesTeamList &&
            // @ts-ignore
            machinesTeamList.filter((m) => m.machineId === machineId);
        setMachineTeamList(res);

        if (isOneWeek) {
            toggleTeachingOneWeekTeams();
        } else {
            toggleTeachingTeams();
        }
    };

    const submitAndClose = () => {
        const saveResult = onSubmitUpdatePlan();
        if (saveResult) {
            if (saveRef) {
                clearTimeout(saveRef);
            }

            const ref = setTimeout(() => {
                // @ts-ignore
                history.goBack() || history.push("/");
            }, 1000);

            setSaveRef(ref);
        }
    };

    const onDraw = async (
        target: MachineArray | UserArray,
        targetArray: MachineArray[] | UserArray[],
        boundArea: string,
        role: string,
        machineId?: string
    ) => {
        if (!planData || !prebookingsData || !staffData || !allPlansData) {
            return;
        }

        try {
            isGettingDraw.current = true;
            let name: string | null = null;
            let listToCheck = [];
            let id: string | null = null;
            if (role === ROLES.MACHINE) {
                id = target.machineId;
                name = target.machineName;
                const machineBookedProjects = await findMachineBookedProjects(
                    target.machineId,
                    planData.projectId
                );
                const machineBookedPrebookings = await prebookingsData
                    .filter((item) => item.machineId === target.machineId)
                    .map((item) => ({
                        color: "242, 135, 5",
                        end: item.end,
                        prebookingName: item.projectName,
                        start: item.start,
                    }));
                listToCheck = [
                    ...targetArray,
                    ...machineBookedProjects,
                    ...machineBookedPrebookings,

                    // @ts-ignore
                    ...(target.machineService || []),
                ];
            } else {
                // @ts-ignore
                id = target.userId || target.id;

                name =
                    // @ts-ignore
                    target.userName || `${target.firstName} - ${target.lastName}`;
                const staffInfo = staffData.find((item) => item.userId === id);
                const staffSchedule = await findStaffSchedule(
                    // @ts-ignore
                    id as string,
                    planData.projectId,
                    roleProp[role],
                    allPlansData
                );
                listToCheck = [...staffSchedule, ...(staffInfo?.starfVacations || [])];
            }

            // @ts-ignore
            const drawData: StaffDrawData | MachineDrawData = {
                id: id as string,
                name: name as string,
                // @ts-ignore
                onSave: role === ROLES.MACHINE ? addMachine : addEmployment,
                boundArea,
                listToCheck,
                role,
                machineId: machineId as string,
            };
            setDrawData(drawData);
        } finally {
            isGettingDraw.current = false;
        }
    };

    const onDrawVacation = (type: string) => {
        const drawData = {
            type,
        };

        // @ts-ignore
        setDrawData(drawData);
    };

    const cancelDrawOnModifyBar = () => {
        setDrawData(null);
        const planContainer = document.getElementsByClassName(
            "detailPlanContainer"
        )[0] as HTMLElement;
        planContainer.style.cursor = "default";
    };

    // @ts-ignore
    const onConfirmVacation = (vacationData) => {
        if (!horizontalPositionMap) {
            return;
        }

        // @ts-ignore
        setTempVacations((tempVacations) => [...tempVacations, vacationData]);
        let staffType = {
            driver: {
                list: [...drivers],
                setData: setDrivers,
            },
            worker: {
                list: [...workers],
                setData: setWorkers,
            },
            supervisor: {
                list: [...supervisors],
                setData: setSupervisors,
            },
        };

        // @ts-ignore
        let list = staffType[vacationData.type].list;
        const startText = vacationData.startText.value;
        const endText = vacationData.endText.value;
        // @ts-ignore
        const matchedStaff = list.find((item) => item.id === vacationData.id);
        const matchedStaffIndex = list.findIndex(
            // @ts-ignore
            (item) => item.id === vacationData.id
        );

        // @ts-ignore
        const overlapedPlans = [];
        // @ts-ignore
        let noOverlappedPlans = [];
        // @ts-ignore
        matchedStaff.array.forEach((plan, index) => {
            let itemStartText = plan.startText?.value;
            let itemEndText = plan.endText?.value;
            const isOverlap = checkTimeOverlap(
                startText,
                endText,
                itemStartText,
                itemEndText
            );
            if (isOverlap) {
                overlapedPlans.push(plan);
            } else noOverlappedPlans.push(plan);
        });
        let breakFunction = false;
        if (overlapedPlans.length) {
            // @ts-ignore
            overlapedPlans.forEach((plan) => {
                let itemStartText = plan.startText?.value;
                let itemEndText = plan.endText?.value;
                if (
                    moment(itemStartText).isSame(moment(startText)) &&
                    moment(itemEndText).isSame(moment(endText))
                ) {
                    breakFunction = true;
                    dispatch(
                        saveMessage(`Don't support perfect overlap!`, MessageBarType.error)
                    );
                }
                const availableTimes = getAvailableTimesOnConflict(
                    itemStartText,
                    itemEndText,
                    startText,
                    endText
                );
                let transform = availableTimes.map((item) => {
                    const startTextDisplay = moment(item.startText).format("YYYY-M-D");
                    const endTextDisplay = moment(item.endText).format("YYYY-M-D");
                    return {
                        ...plan,
                        startText: {value: item.startText, display: startTextDisplay},
                        endText: {value: item.endText, display: endTextDisplay},
                        start: horizontalPositionMap[startTextDisplay],
                        end: horizontalPositionMap[endTextDisplay],
                    };
                });
                // @ts-ignore
                noOverlappedPlans = [...noOverlappedPlans, ...transform];
            });
            if (breakFunction) return false;
            // @ts-ignore
            matchedStaff.array = noOverlappedPlans;
        }
        matchedStaff.staffVacation = [
            ...(matchedStaff.staffVacation || []),
            {
                color: vacationData.color,
                start:
                    moment(vacationData.startText.value).format("YYYY-MM-DD") +
                    "T00:00:00.000Z",
                end:
                    moment(vacationData.endText.value).format("YYYY-MM-DD") +
                    "T00:00:00.000Z",
                notes: vacationData.notes,
                reason: vacationData.reason,
            },
        ];
        list[matchedStaffIndex] = matchedStaff;
        // @ts-ignore
        staffType[vacationData.type].setData(list);
        setAnyUpdate(true);
    };

    const saveTempVacations = async () => {
        const tempVacationObject = {};
        tempVacations.forEach((vacation) => {
            const vacationTransform = {
                // @ts-ignore
                color: vacation.color,
                // @ts-ignore
                reason: vacation.reason,
                // @ts-ignore
                notes: vacation.notes,
                // @ts-ignore
                start: vacation.startText.value,
                // @ts-ignore
                end: vacation.endText.value,
            };

            // @ts-ignore
            if (!tempVacationObject[vacation.id])
                // @ts-ignore
                tempVacationObject[vacation.id] = [vacationTransform];
            // @ts-ignore
            else tempVacationObject[vacation.id].push(vacationTransform);
        });
        for (const id in tempVacationObject) {
            // @ts-ignore
            const staff = staffData.find((item) => item.userId === id);
            const starfVacations =
                // @ts-ignore
                staff.starfVacations && staff.starfVacations.length > 0
                    ? // @ts-ignore
                    [...staff.starfVacations, ...tempVacationObject[id]]
                    : // @ts-ignore
                    [...tempVacationObject[id]];
            const newStaffInfo = {...staff, starfVacations};
            // @ts-ignore
            await updateStaffMutation(newStaffInfo);
        }
    };

    const isStaffMachineSelected = (staff: UserWorkerVM) => {
        const {machineName} = staff;

        if (!machineName) {
            return true;
        }

        return selectedMachines.includes(staff.machineName);
    };

    const doesStaffWorkOnAnySelectedMachine = (
        staff: AllUserData,
        isSupervisor = false
    ) => {
        const staffMachineMap = staff.array
            .map((el) => el.machineName)
            .filter((el) => !!el);

        if (
            staff.array.length !== staffMachineMap.length &&
            // selectedMachines.length === machines.length &&
            selectedMachines.includes(BLANK_MACHINE_FILTER_OPTION)
        ) {
            return true;
        }

        if (
            selectedMachines.includes(BLANK_MACHINE_FILTER_OPTION) &&
            staffMachineMap.every((el) => !selectedMachines.includes(el)) &&
            isSupervisor
        ) {
            return true;
        }

        return selectedMachines.some((el) => staffMachineMap.includes(el));
    };

    const startAndEndDateEarliestMachine = useMemo(() => {
        const defaultVal = {
            start: -1,
            end: -1,
        };
        if (machines.length) {
            const allMachinesBar = machines.reduce(
                (acc, curr) => [...acc, ...curr.array],
                [] as MachineArray[]
            );

            const today = new Date();

            // Get the machine where by  the start and end date
            // encompass todays date
            const selectedMachineBar = allMachinesBar
                .filter(
                    (el) =>
                        el.machineId !== tempDrawMachineId && el.startText && el.endText
                )
                .sort((a, b) => {
                    const endDateA = new Date(a.endText.value);
                    const endDateB = new Date(b.endText.value);
                    return endDateA < endDateB ? -1 : endDateA === endDateB ? 0 : 1;
                })
                .find((machineBar) => {
                    const {value: endValue} = machineBar.endText;

                    return today <= new Date(endValue);
                });

            if (selectedMachineBar) {
                const {
                    startText: {value: machineStartDate},
                    endText: {value: machineEndDate},
                } = selectedMachineBar;

                return {
                    start: machineStartDate.slice(0, 10),
                    end: machineEndDate.slice(0, 10),
                };
            }
        }

        return defaultVal;
    }, [machines, tempDrawMachineId]);

    const renderSupervisor = () => {
        if (!machines?.length || !planData) {
            return null;
        }

        const machineRequirements = planData.machineRequirements[+planningIndex];
        const {crmAnsvarligEmail} = machineRequirements;

        if (!crmAnsvarligEmail) {
            return null;
        }

        const supervisorStaffData = staffData?.find(
            (item) =>
                item.email.toLowerCase() === crmAnsvarligEmail.toLowerCase() &&
                ["0", "4"].some((roleKey) => item.workingRole.includes(roleKey))
        );

        if (!supervisorStaffData) {
            return null;
        }

        const allMachinesBar = machines.reduce(
            (acc, curr) => [...acc, ...curr.array],
            [] as MachineArray[]
        );
        const earliestMachine = allMachinesBar.sort((a, b) => a.start - b.start)[0];

        const {
            startText: {value: machineStartDate},
            endText: {value: machineEndDate},
        } = earliestMachine;

        // We need to check if it the user has already added the crm supervisor into the list
        // of supervisors to be saved
        // If yes, don't show the black bar
        // If no, show the black bar.
        // Commented out because currently we want to show the black bar and the white bar together
        // const flattenedSupervisorList = supervisors.reduce((prevValue,currentValue)=>{
        //     return [...prevValue,...currentValue.array];
        // },[]);

        // const supervisor = flattenedSupervisorList.find((item)=>{
        //     const name = item.firstName ? `${item.firstName} ${item.lastName}`.toLowerCase() : item.userName.toLowerCase();

        //     const isEqual = name===crmAnsvarlig.toLowerCase();

        //     return isEqual;
        // });

        // if(supervisor){
        //     const {
        //         startText,
        //         endText
        //     } = supervisor;

        //     // It means it's already stored in the local supervisors list
        //     if(startText.value === machineStartDate && endText.value === machineEndDate){
        //         return null;
        //     }
        // }

        const {crmActivitiesId} = planData.machineRequirements[+planningIndex];

        let isCrmReq = !!crmActivitiesId;

        const reqCrmLink = isCrmReq ? getReqCrmLink(crmActivitiesId) : null;

        return (
            <SupervisorPlaceholder
                worker={{
                    firstName: supervisorStaffData.firstName,
                    lastName: supervisorStaffData.lastName,
                    userId: supervisorStaffData.userId,
                    role: supervisorStaffData.authorizeRole,
                    imageUrl: supervisorStaffData.imageUrl,
                }}
                calendarStepWidth={calendarStepWidth}
                // color={projectData?.color}
                horizontalPositionMap={horizontalPositionMap}
                start={machineStartDate}
                end={machineEndDate}
                onConfirm={() => {
                    // @ts-ignore
                    addEmployment(supervisorStaffData, ROLES.SUPERVISOR, null, true);
                }}
                allowEdit={allowEdit && !drawData?.type}
                crmLink={reqCrmLink}
            />
        );
    };

    return (
        <div className={classNames.root}>
            <Prompt
                when={anyUpdate}
                message={JSON.stringify({
                    header:
                        "Are you sure you want to leave the page, you have unsaved changes?",
                    content: "",
                })}
            />
            <div className={classNames.pageContentContainer}>
                <div className={classNames.pageInnerContainer}>
                    <CalendarControlsDetailedPlanning
                        // planColor={projectData?.color}
                        projectName={projectData?.projectName}
                        // projectId={projectData?.projectId || ""}
                        // projectDescription={projectData?.projectDescription}
                        submitUpdatePlan={onSubmitUpdatePlan}
                        // done={done}
                        // setDone={setDone}
                        hidden={hidden}
                        setHidden={setHidden}
                        machines={machines}
                        onSelectMachines={(newSelectedMachines: string[]) => {
                            setSelectedMachines(newSelectedMachines);
                        }}
                        // drivers={drivers}
                        // workers={workers}
                        // supervisors={supervisors}
                        setAnyUpdate={setAnyUpdate}
                        submitAndClose={submitAndClose}
                        allowEdit={allowEdit}
                        inactive={planData?.inactive}
                        onDrawVacation={onDrawVacation}
                        isDisabled={
                            !isInitialized
                            // || isDrawingNew
                        }
                        machineType={
                            planData?.machineRequirements[+planningIndex].machineType
                        }
                        machineGroup={
                            planData?.machineRequirements[+planningIndex].sizeGroup
                        }
                        activities={
                            planData?.machineRequirements[+planningIndex].activities
                        }
                        planDescription={
                            planData?.machineRequirements[+planningIndex].crmDescription
                        }
                        crmActivitiesId={
                            planData?.machineRequirements[+planningIndex].crmActivitiesId
                        }
                    />
                    <div style={{height: 88}}/>

                    {horizontalPositionMap && (
                        <div className={classNames.pageContent}>
                            <div id="leftContainer" className={classNames.leftContainer}>
                                {/* Machines Sidebar*/}
                                <div
                                    className={classNames.sideBarContainer}
                                    style={{marginTop: 16}}
                                >
                                    <div className={classNames.sideBarHeader}>
                                        <Image
                                            src={`${window.location.origin}/img/machineBlack.svg`}
                                            imageFit={ImageFit.cover}
                                            alt="machine"
                                            width={20}
                                            height={18}
                                            className={classNames.image}
                                        />
                                        <div className={classNames.titleNameElementStyle}>
                                            MASKINE
                                        </div>
                                    </div>
                                    <div className={classNames.sideBarRightContainer}>
                                        {refreshList &&
                                            machines &&
                                            filterMachineNotSelected({data: machines}).map(
                                                (machineList, indexList) => (
                                                    <div key={machineList.id} style={{marginBottom: 5}}>
                                                        <div style={{height: 36}}>
                                                            {machineList.array.map((machine, index) => (
                                                                <Fragment key={`${index}${machine.machineId}`}>
                                                                    {index === 0 && (
                                                                        <div
                                                                            className={
                                                                                classNames.sidebarIconContainer
                                                                            }
                                                                            style={
                                                                                {
                                                                                    // visibility: isStaffMachineSelected(
                                                                                    //   // @ts-ignore
                                                                                    //   machineList
                                                                                    // )
                                                                                    //   ? "visible"
                                                                                    //   : "hidden",
                                                                                }
                                                                            }
                                                                        >
                                                                            {allowEdit &&
                                                                                machinesTeamList.find(
                                                                                    (v) =>
                                                                                        // @ts-ignore
                                                                                        v.machineId === machine.machineId
                                                                                ) && (
                                                                                    <TooltipForText
                                                                                        text={"Add team for 1 week"}
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            id={ID_TARGET.ONE_WEEK_TEAMS}
                                                                                            onClick={() =>
                                                                                                getMachineTeams(
                                                                                                    machine.machineId
                                                                                                )
                                                                                            }
                                                                                            icon={faCalendarAlt as IconProp}
                                                                                            className={
                                                                                                classNames.functionalIcon
                                                                                            }
                                                                                        />
                                                                                    </TooltipForText>
                                                                                )}
                                                                            {allowEdit &&
                                                                                machinesTeamList.find(
                                                                                    (v) =>
                                                                                        // @ts-ignore
                                                                                        v.machineId === machine.machineId
                                                                                ) && (
                                                                                    // @ts-ignore
                                                                                    <TooltipForText
                                                                                        text={
                                                                                            "Add team for whole machine booking"
                                                                                        }
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            id={ID_TARGET.TEAMS}
                                                                                            onClick={() =>
                                                                                                getMachineTeams(
                                                                                                    machine.machineId,
                                                                                                    false
                                                                                                )
                                                                                            }
                                                                                            icon={faUsers as IconProp}
                                                                                            className={
                                                                                                classNames.functionalIcon
                                                                                            }
                                                                                        />
                                                                                    </TooltipForText>
                                                                                )}
                                                                            {allowEdit && !isDrawingNew && (
                                                                                <FontAwesomeIcon
                                                                                    onClick={() => {
                                                                                        onClickSetTargetDelete(
                                                                                            ID_TARGET.MACHINE,
                                                                                            indexList
                                                                                        );
                                                                                    }}
                                                                                    id={`delete-${ID_TARGET.MACHINE}-${indexList}`}
                                                                                    icon={faTimes as IconProp}
                                                                                    className={classNames.functionalIcon}
                                                                                />
                                                                            )}
                                                                            <Persona
                                                                                imageUrl={
                                                                                    machine.imageUrl
                                                                                        ? `${SOURCE_API}${machine.imageUrl}`
                                                                                        : ""
                                                                                }
                                                                                size={PersonaSize.size28}
                                                                                coinProps={coinProps}
                                                                                className={classNames.personaStyle}
                                                                                onMouseOut={() => {
                                                                                    setHoverData(null);
                                                                                }}
                                                                                onMouseOver={() => {
                                                                                    setHoverData({
                                                                                        text: machine.machineName,
                                                                                        boldText: "",
                                                                                        hoverId: `hover-${ID_TARGET.MACHINE}-${indexList}`,
                                                                                    });
                                                                                }}
                                                                                id={`hover-${ID_TARGET.MACHINE}-${indexList}`}
                                                                            />
                                                                            {allowEdit && !isDrawingNew && (
                                                                                <FontAwesomeIcon
                                                                                    style={{marginRight: "0px"}}
                                                                                    icon={faPencil as IconProp}
                                                                                    className={classNames.functionalIcon}
                                                                                    onClick={() =>
                                                                                        onDraw(
                                                                                            machine,
                                                                                            machineList.array,
                                                                                            `machineContainer${machineList.id}`,
                                                                                            ROLES.MACHINE,
                                                                                            machineList.machineName
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        {allowEdit && (
                                            <div className={classNames.iconPlusCircle}>
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle as IconProp}
                                                    id={ID_TARGET.MACHINE}
                                                    className={classNames.iconPlus}
                                                    onClick={toggleTeachingMachines}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={classNames.sidebarSeparatorContainer}>
                                    <hr className={classNames.separatorStyle}/>
                                </div>

                                {/* Drivers Sidebar*/}
                                <div className={classNames.sideBarContainer}>
                                    <div className={classNames.sideBarHeader}>
                                        <FontAwesomeIcon
                                            icon={faUserHardHat as IconProp}
                                            className={classNames.image}
                                        />
                                        <div className={classNames.titleNameElementStyle}>
                                            MASKINFØRER
                                        </div>
                                    </div>
                                    <div className={classNames.sideBarRightContainer}>
                                        {refreshList &&
                                            drivers &&
                                            filterEmployeeNotSelected({data: drivers}).map(
                                                (driverList, indexList) => (
                                                    <div
                                                        key={driverList.id}
                                                        style={{
                                                            marginBottom: 5,
                                                            // visibility: doesStaffWorkOnAnySelectedMachine(
                                                            //   driverList
                                                            // )
                                                            //   ? "visible"
                                                            //   : "hidden",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 36,
                                                            }}
                                                        >
                                                            {driverList.array.map((driver, index) => (
                                                                <Fragment key={`${index}${driver.userId}`}>
                                                                    {index === 0 && (
                                                                        <div
                                                                            className={
                                                                                classNames.sidebarIconContainer
                                                                            }
                                                                        >
                                                                            {allowEdit && !isDrawingNew && (
                                                                                <FontAwesomeIcon
                                                                                    onClick={() => {
                                                                                        onClickSetTargetDelete(
                                                                                            ID_TARGET.DRIVER,
                                                                                            indexList
                                                                                        );
                                                                                    }}
                                                                                    id={`delete-${ID_TARGET.DRIVER}-${indexList}`}
                                                                                    icon={faTimes as IconProp}
                                                                                    className={classNames.functionalIcon}
                                                                                />
                                                                            )}
                                                                            <Persona
                                                                                imageUrl={
                                                                                    driver.imageUrl
                                                                                        ? `${SOURCE_API}${driver.imageUrl}`
                                                                                        : ""
                                                                                }
                                                                                size={PersonaSize.size28}
                                                                                coinProps={coinProps}
                                                                                className={classNames.personaStyle}
                                                                                onMouseOut={() => {
                                                                                    setHoverData(null);
                                                                                }}
                                                                                onMouseOver={() => {
                                                                                    setHoverData({
                                                                                        text:
                                                                                            driver.userName ||
                                                                                            driver.firstName +
                                                                                            " " +
                                                                                            (driver.lastName || ""),
                                                                                        boldText: driver.machineName,
                                                                                        hoverId: `hover-${ID_TARGET.DRIVER}-${indexList}`,
                                                                                    });
                                                                                }}
                                                                                id={`hover-${ID_TARGET.DRIVER}-${indexList}`}
                                                                            />
                                                                            {allowEdit && !isDrawingNew && (
                                                                                <FontAwesomeIcon
                                                                                    style={{marginRight: "0px"}}
                                                                                    icon={faPencil as IconProp}
                                                                                    className={classNames.functionalIcon}
                                                                                    onClick={() =>
                                                                                        onDraw(
                                                                                            driver,
                                                                                            driverList.array,
                                                                                            `driverContainer${driverList.id}`,
                                                                                            ROLES.DRIVER
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        {allowEdit && (
                                            <div className={classNames.iconPlusCircle}>
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle as IconProp}
                                                    id={ID_TARGET.DRIVER}
                                                    className={classNames.iconPlus}
                                                    onClick={toggleTeachingDrivers}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={classNames.sidebarSeparatorContainer}>
                                    <hr className={classNames.separatorStyle}/>
                                </div>

                                {/* Workers Sidebar*/}
                                <div className={classNames.sideBarContainer}>
                                    <div className={classNames.sideBarHeader}>
                                        <FontAwesomeIcon
                                            icon={faDigging as IconProp}
                                            className={classNames.image}
                                        />
                                        <div className={classNames.titleNameElementStyle}>
                                            HÅNDMAND
                                        </div>
                                    </div>
                                    <div className={classNames.sideBarRightContainer}>
                                        {refreshList &&
                                            workers &&
                                            filterEmployeeNotSelected({data: workers}).map(
                                                (workerList, indexList) => (
                                                    <div
                                                        key={workerList.id}
                                                        style={{
                                                            marginBottom: 5,
                                                            // visibility: doesStaffWorkOnAnySelectedMachine(
                                                            //   workerList
                                                            // )
                                                            //   ? "visible"
                                                            //   : "hidden",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                height: 36,
                                                                // Unnecessary
                                                                // visibility: doesStaffWorkOnAnySelectedMachine(
                                                                //   workerList
                                                                // )
                                                                //   ? "visible"
                                                                //   : "hidden",
                                                            }}
                                                        >
                                                            {workerList.array.map((worker, index) => (
                                                                <Fragment key={`${index}${worker.userId}`}>
                                                                    {index === 0 && (
                                                                        <div
                                                                            className={
                                                                                classNames.sidebarIconContainer
                                                                            }
                                                                        >
                                                                            {allowEdit && !isDrawingNew && (
                                                                                <FontAwesomeIcon
                                                                                    onClick={() => {
                                                                                        onClickSetTargetDelete(
                                                                                            ID_TARGET.WORKER,
                                                                                            indexList
                                                                                        );
                                                                                    }}
                                                                                    id={`delete-${ID_TARGET.WORKER}-${indexList}`}
                                                                                    icon={faTimes as IconProp}
                                                                                    className={classNames.functionalIcon}
                                                                                />
                                                                            )}
                                                                            <Persona
                                                                                imageUrl={
                                                                                    worker.imageUrl
                                                                                        ? `${SOURCE_API}${worker.imageUrl}`
                                                                                        : ""
                                                                                }
                                                                                size={PersonaSize.size28}
                                                                                coinProps={coinProps}
                                                                                className={classNames.personaStyle}
                                                                                onMouseOut={() => {
                                                                                    setHoverData(null);
                                                                                }}
                                                                                onMouseOver={() => {
                                                                                    setHoverData({
                                                                                        text:
                                                                                            worker.userName ||
                                                                                            worker.firstName +
                                                                                            " " +
                                                                                            (worker.lastName || ""),
                                                                                        boldText: "",
                                                                                        hoverId: `hover-${ID_TARGET.WORKER}-${indexList}`,
                                                                                    });
                                                                                }}
                                                                                id={`hover-${ID_TARGET.WORKER}-${indexList}`}
                                                                            />
                                                                            {allowEdit && !isDrawingNew && (
                                                                                <FontAwesomeIcon
                                                                                    style={{marginRight: "0px"}}
                                                                                    icon={faPencil as IconProp}
                                                                                    className={classNames.functionalIcon}
                                                                                    onClick={() =>
                                                                                        onDraw(
                                                                                            worker,
                                                                                            workerList.array,
                                                                                            `workerContainer${workerList.id}`,
                                                                                            ROLES.WORKER
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                </Fragment>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        {allowEdit && (
                                            <div className={classNames.iconPlusCircle}>
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle as IconProp}
                                                    id={ID_TARGET.WORKER}
                                                    className={classNames.iconPlus}
                                                    onClick={toggleTeachingWorkers}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={classNames.sidebarSeparatorContainer}>
                                    <hr className={classNames.separatorStyle}/>
                                </div>

                                {/* Supervisor Sidebar*/}
                                <div className={classNames.sideBarContainer}>
                                    <div className={classNames.sideBarHeader}>
                                        <FontAwesomeIcon
                                            icon={faUserTie as IconProp}
                                            className={classNames.image}
                                        />
                                        <div className={classNames.titleNameElementStyle}>
                                            FORMAND
                                        </div>
                                    </div>
                                    <div className={classNames.sideBarRightContainer}>
                                        {refreshList &&
                                            supervisors &&
                                            filterEmployeeNotSelected({
                                                data: supervisors,
                                                isSupervisor: true,
                                            }).map((supervisorList, indexList) => (
                                                <div
                                                    key={supervisorList.id}
                                                    style={{marginBottom: 5}}
                                                >
                                                    <div
                                                        style={{
                                                            height:
                                                                36 + (supervisorList.maxCounter || 0) * 40,
                                                        }}
                                                    >
                                                        {supervisorList.array.map((supervisor, index) => (
                                                            <Fragment key={`${index}${supervisor.userId}`}>
                                                                {index === 0 && (
                                                                    <div
                                                                        className={classNames.sidebarIconContainer}
                                                                        // style={{
                                                                        //   visibility:
                                                                        //     doesStaffWorkOnAnySelectedMachine(
                                                                        //       supervisorList,
                                                                        //       true
                                                                        //     )
                                                                        //       ? "visible"
                                                                        //       : "hidden",
                                                                        // }}
                                                                    >
                                                                        {allowEdit && !isDrawingNew && (
                                                                            <FontAwesomeIcon
                                                                                id={`delete-${ID_TARGET.SUPERVISOR}-${indexList}`}
                                                                                onClick={() => {
                                                                                    onClickSetTargetDelete(
                                                                                        ID_TARGET.SUPERVISOR,
                                                                                        indexList
                                                                                    );
                                                                                }}
                                                                                icon={faTimes as IconProp}
                                                                                className={classNames.functionalIcon}
                                                                            />
                                                                        )}
                                                                        <Persona
                                                                            imageUrl={
                                                                                supervisor.imageUrl
                                                                                    ? `${SOURCE_API}${supervisor.imageUrl}`
                                                                                    : ""
                                                                            }
                                                                            size={PersonaSize.size28}
                                                                            coinProps={coinProps}
                                                                            className={classNames.personaStyle}
                                                                            onMouseOut={() => {
                                                                                setHoverData(null);
                                                                            }}
                                                                            onMouseOver={() => {
                                                                                setHoverData({
                                                                                    text:
                                                                                        supervisor.userName ||
                                                                                        supervisor.firstName +
                                                                                        " " +
                                                                                        (supervisor.lastName || ""),
                                                                                    boldText: "",
                                                                                    hoverId: `hover-${ID_TARGET.SUPERVISOR}-${indexList}`,
                                                                                });
                                                                            }}
                                                                            id={`hover-${ID_TARGET.SUPERVISOR}-${indexList}`}
                                                                        />
                                                                        {allowEdit && !isDrawingNew && (
                                                                            <FontAwesomeIcon
                                                                                style={{marginRight: "0px"}}
                                                                                icon={faPencil as IconProp}
                                                                                className={classNames.functionalIcon}
                                                                                onClick={() =>
                                                                                    onDraw(
                                                                                        supervisor,
                                                                                        supervisorList.array,
                                                                                        `supervisorContainer${supervisorList.id}`,
                                                                                        ROLES.SUPERVISOR
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            ))}
                                        {allowEdit && (
                                            <div className={classNames.iconPlusCircle}>
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle as IconProp}
                                                    id={ID_TARGET.SUPERVISOR}
                                                    className={classNames.iconPlus}
                                                    onClick={toggleTeachingSupervisors}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div style={{marginLeft: 200}}>
                                <MachineServiceCalendar
                                    dates={dates}
                                    widthOfCalendar={widthOfCalendar}
                                    backgroundHeight={leftHeight || BACKGROUND_HEIGHT}
                                />

                                <div
                                    className="detailPlanContainer"
                                    style={{
                                        height: leftHeight,
                                        width:
                                            calendarStepWidth * horizontalPositionMap["end"] +
                                            calendarStepWidth,
                                        position: "absolute",
                                        backgroundImage: displayWeekView
                                            ? `url("${window.location.origin}/img/calendarBackground5.png")`
                                            : `url("${window.location.origin}/img/calendarBackground4.png")`,
                                        backgroundRepeat: "repeat",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "absolute",
                                            display: "flex",
                                            flexDirection: "column",
                                            top: 0,
                                            left: 0,
                                            zIndex: 556,
                                            width: calendarStepWidth * horizontalPositionMap["end"],
                                            height: "100%",
                                            userSelect: "none",
                                        }}
                                    >
                                        {/* Project Bar */}
                                        <MachineRequirementBar
                                            calendarStepWidth={calendarStepWidth}
                                            color={projectData?.color}
                                            horizontalPositionMap={horizontalPositionMap}
                                            start={projectData?.start}
                                            end={projectData?.end}
                                        />
                                        {/* Machine requirement Bar */}
                                        <div style={{position: "relative", bottom: -10}}>
                                            <MachineRequirementBar
                                                calendarStepWidth={calendarStepWidth}
                                                color={"0, 0, 0"}
                                                horizontalPositionMap={horizontalPositionMap}
                                                start={
                                                    planData?.machineRequirements[+planningIndex]?.start
                                                }
                                                end={planData?.machineRequirements[+planningIndex]?.end}
                                            />
                                        </div>
                                        {/* Machine */}
                                        <div>
                                            <div className={classNames.contentElementStyle}>
                                                {refreshList &&
                                                    machines &&
                                                    filterMachineNotSelected({data: machines}).map(
                                                        (machineList, indexList) => (
                                                            <div
                                                                key={`${indexList}${machineList}`}
                                                                style={{
                                                                    margin: "5px 0",
                                                                    // @ts-ignore
                                                                    // visibility: isStaffMachineSelected(machineList)
                                                                    //   ? "visible"
                                                                    //   : "hidden",
                                                                }}
                                                            >
                                                                {machineList?.machineService ? (
                                                                    <Stack horizontal>
                                                                        {machineList?.machineService.map(
                                                                            (
                                                                                itemMachineService,
                                                                                indexMachineService
                                                                            ) => (
                                                                                <div
                                                                                    key={indexMachineService}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    <MachineServiceStaffVacationBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={itemMachineService?.start}
                                                                                        end={itemMachineService?.end}
                                                                                        text={itemMachineService?.comment}
                                                                                        // color={itemMachineService?.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                        isMachineService
                                                                                        notes={itemMachineService.reason}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                {machineList.machineBookedProjects.length ? (
                                                                    <Stack horizontal>
                                                                        {machineList.machineBookedProjects.map(
                                                                            (item, index) => (
                                                                                <div
                                                                                    key={`${item.projectId}${index}`}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    <MachineStaffBookedBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={item.start}
                                                                                        end={item.end}
                                                                                        // color={item.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                        text={item.name}
                                                                                        crmProjectStatusCode={
                                                                                            item.crmProjectStatusCode
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                {machineList.machineBookedPrebookings.length ? (
                                                                    <Stack horizontal>
                                                                        {machineList.machineBookedPrebookings.map(
                                                                            (item, index) => (
                                                                                <div
                                                                                    // @ts-ignore
                                                                                    key={`${item.projectId}${index}`}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    <MachineStaffBookedBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={item.start}
                                                                                        end={item.end}
                                                                                        // color={item.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                        text={item.prebookingName}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                <div
                                                                    className={`barContainer machineContainer${machineList.id}`}
                                                                    style={{
                                                                        zIndex: 556,
                                                                        width: "100%",
                                                                        height: 36,
                                                                        background:
                                                                            drawData?.id === machineList.id
                                                                                ? "rgba(0, 108, 173, 0.2)"
                                                                                : "unset",
                                                                    }}
                                                                >
                                                                    {machineList.array.map((machine, index) => (
                                                                        <Fragment
                                                                            key={`${index}${machine.machineId}`}
                                                                        >
                                                                            {machine?.startText ? (
                                                                                <Machine
                                                                                    calendarStepWidth={calendarStepWidth}
                                                                                    horizontalPositions={
                                                                                        horizontalPositionMap
                                                                                    }
                                                                                    boundArea={`machineContainer${machineList.id}`}
                                                                                    indexList={indexList}
                                                                                    role={ROLES.MACHINE}
                                                                                    index={index}
                                                                                    key={`${index}${machine.machineId}`}
                                                                                    onResize={onResize}
                                                                                    onDrag={onDrag}
                                                                                    machine={machine}
                                                                                    onDelete={onDelete}
                                                                                    cancelDrawOnModifyBar={
                                                                                        cancelDrawOnModifyBar
                                                                                    }
                                                                                    allowEdit={
                                                                                        allowEdit && !drawData?.type
                                                                                    }
                                                                                    // @ts-ignore
                                                                                    isDrawingVacationService={
                                                                                        !!drawData?.type
                                                                                    }
                                                                                    backgroundColor={getMachineBackgroundColor(
                                                                                        machineList
                                                                                    )}
                                                                                    color={getMachineColor(machineList)}
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </Fragment>
                                                                    ))}
                                                                </div>
                                                                {drawData &&
                                                                    !drawData.type &&
                                                                    drawData.id === machineList.id && (
                                                                        // @ts-ignore
                                                                        <DetailPlanBarDrawer
                                                                            calendarStepWidth={calendarStepWidth}
                                                                            horizontalPositionMap={
                                                                                horizontalPositionMap
                                                                            }
                                                                            name={drawData.name}
                                                                            listToCheck={drawData.listToCheck}
                                                                            onSave={
                                                                                (
                                                                                    drawData as
                                                                                        | MachineDrawData
                                                                                        | StaffDrawData
                                                                                ).role === ROLES.MACHINE
                                                                                    ? addMachine
                                                                                    : addEmployment
                                                                            }
                                                                            boundArea={
                                                                                (
                                                                                    drawData as
                                                                                        | MachineDrawData
                                                                                        | StaffDrawData
                                                                                ).boundArea
                                                                            }
                                                                            targetData={machineList.array[0]}
                                                                            setDrawData={setDrawData}
                                                                            role={ROLES.MACHINE}
                                                                            onDrawAddOverlap={onDrawAddOverlap}
                                                                            backgroundColor={drawDataBackground}
                                                                            color={drawDataColor}
                                                                        />
                                                                    )}
                                                                {drawData?.type && (
                                                                    // @ts-ignore
                                                                    <DetailPlanVacationDrawer
                                                                        calendarStepWidth={calendarStepWidth}
                                                                        horizontalPositionMap={
                                                                            horizontalPositionMap
                                                                        }
                                                                        drawData={drawData}
                                                                        setDrawData={setDrawData}
                                                                        staffData={staffData}
                                                                        machineData={machineData}
                                                                        containerId={`machineContainer${machineList.id}`}
                                                                        staffsInDetail={[
                                                                            ...drivers,
                                                                            ...workers,
                                                                            ...supervisors,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            <div
                                                style={{
                                                    height: !filterMachineNotSelected({data: machines})
                                                        .length
                                                        ? 101
                                                        : filterMachineNotSelected({data: machines})
                                                            .length === 1
                                                            ? 55
                                                            : 25,
                                                }}
                                            />
                                            <div
                                                className={classNames.separatorContainerStyle}
                                                style={{
                                                    marginTop: allowEdit
                                                        ? 64
                                                        : !filterMachineNotSelected({data: machines})
                                                            .length ||
                                                        filterMachineNotSelected({data: machines})
                                                            .length === 1
                                                            ? 64
                                                            : 25,
                                                }}
                                            >
                                                <hr className={classNames.separatorStyle}/>
                                            </div>
                                        </div>
                                        {/* Driver */}
                                        <div>
                                            <div className={classNames.contentElementStyle}>
                                                {refreshList &&
                                                    drivers &&
                                                    filterEmployeeNotSelected({data: drivers}).map(
                                                        (driverList, indexList) => (
                                                            <div
                                                                key={driverList.id}
                                                                style={{
                                                                    margin: "5px 0",
                                                                    // visibility: doesStaffWorkOnAnySelectedMachine(
                                                                    //   driverList
                                                                    // )
                                                                    //   ? "visible"
                                                                    //   : "hidden",
                                                                }}
                                                            >
                                                                {driverList?.staffVacation ? (
                                                                    <Stack horizontal>
                                                                        {driverList?.staffVacation.map(
                                                                            (itemVacation, indexVacation) => (
                                                                                <div
                                                                                    key={indexVacation}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    {/* <MachineRequirementBar color={itemVacation?.color} horizontalPositionMap={horizontalPositionMap} start={itemVacation?.start} end={itemVacation?.end} /> */}
                                                                                    <MachineServiceStaffVacationBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={itemVacation?.start}
                                                                                        end={itemVacation?.end}
                                                                                        text={itemVacation?.reason}
                                                                                        notes={itemVacation?.notes}
                                                                                        // color={itemVacation?.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                {driverList?.staffSchedule?.length ? (
                                                                    <Stack horizontal>
                                                                        {driverList.staffSchedule.map(
                                                                            (staff, index) => (
                                                                                <div
                                                                                    key={`driver-${staff.projectId}${index}`}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    <MachineStaffBookedBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={staff.start}
                                                                                        end={staff.end}
                                                                                        // color={staff.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                        text={staff.name}
                                                                                        crmProjectStatusCode={
                                                                                            staff.crmProjectStatusCode
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                {driverList?.serviceSchedules?.length ? (
                                                                    <Stack horizontal>
                                                                        {driverList.serviceSchedules.map(
                                                                            (
                                                                                {
                                                                                    comment,
                                                                                    reason,
                                                                                    start,
                                                                                    end,
                                                                                    machineId,
                                                                                },
                                                                                index
                                                                            ) => (
                                                                                <div
                                                                                    key={`driver-service-${driverList.id}-${machineId}-${index}`}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    <MachineServiceStaffVacationBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={start}
                                                                                        end={end}
                                                                                        text={comment}
                                                                                        // color={itemMachineService?.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                        isMachineService
                                                                                        notes={reason}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                <div
                                                                    className={`barContainer driverContainer${driverList.id}`}
                                                                    style={{
                                                                        zIndex: 556,
                                                                        width: "100%",
                                                                        height: 36,
                                                                        background:
                                                                            drawData?.id === driverList.id
                                                                                ? "rgba(0, 108, 173, 0.2)"
                                                                                : "unset",
                                                                        // visibility: doesStaffWorkOnAnySelectedMachine(
                                                                        //   driverList
                                                                        // )
                                                                        //   ? "visible"
                                                                        //   : "hidden",
                                                                    }}
                                                                >
                                                                    {driverList.array.map((driver, index) => (
                                                                        <Fragment key={`${index}${driver.userId}`}>
                                                                            {driver?.start || driver?.startText ? (
                                                                                <Employment
                                                                                    calendarStepWidth={calendarStepWidth}
                                                                                    horizontalPositions={
                                                                                        horizontalPositionMap
                                                                                    }
                                                                                    boundArea={`driverContainer${driverList.id}`}
                                                                                    role={ROLES.DRIVER}
                                                                                    indexList={indexList}
                                                                                    index={index}
                                                                                    onResize={onResize}
                                                                                    onDrag={onDrag}
                                                                                    worker={driver}
                                                                                    onDelete={onDelete}
                                                                                    cancelDrawOnModifyBar={
                                                                                        cancelDrawOnModifyBar
                                                                                    }
                                                                                    allowEdit={
                                                                                        allowEdit && !drawData?.type
                                                                                    }
                                                                                    isDrawingVacationService={
                                                                                        !!drawData?.type
                                                                                    }
                                                                                    drawData={drawData}
                                                                                    backgroundColor={getEmploymentBackgroundColor(
                                                                                        {
                                                                                            list: driverList,
                                                                                            machineName: driver.machineName,
                                                                                        }
                                                                                    )}
                                                                                    color={getEmploymentColor({
                                                                                        list: driverList,
                                                                                        machineName: driver.machineName,
                                                                                    })}
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </Fragment>
                                                                    ))}
                                                                </div>
                                                                {drawData &&
                                                                    !drawData.type &&
                                                                    drawData.id === driverList.id && (
                                                                        // @ts-ignore
                                                                        <DetailPlanBarDrawer
                                                                            calendarStepWidth={calendarStepWidth}
                                                                            horizontalPositionMap={
                                                                                horizontalPositionMap
                                                                            }
                                                                            // containerPosition={drawData.containerPosition}
                                                                            name={drawData.name}
                                                                            listToCheck={drawData.listToCheck}
                                                                            onSave={
                                                                                (
                                                                                    drawData as
                                                                                        | StaffDrawData
                                                                                        | MachineDrawData
                                                                                ).onSave
                                                                            }
                                                                            boundArea={
                                                                                (
                                                                                    drawData as
                                                                                        | StaffDrawData
                                                                                        | MachineDrawData
                                                                                ).boundArea
                                                                            }
                                                                            targetData={driverList.array[0]}
                                                                            setDrawData={setDrawData}
                                                                            role={ROLES.DRIVER}
                                                                            backgroundColor={drawDataBackground}
                                                                            color={drawDataColor}
                                                                        />
                                                                    )}
                                                                {drawData?.type && (
                                                                    <DetailPlanVacationDrawer
                                                                        calendarStepWidth={calendarStepWidth}
                                                                        horizontalPositionMap={
                                                                            horizontalPositionMap
                                                                        }
                                                                        drawData={drawData}
                                                                        setDrawData={setDrawData}
                                                                        staffData={staffData}
                                                                        machineData={machineData}
                                                                        containerId={`driverContainer${driverList.id}`}
                                                                        onConfirmVacation={onConfirmVacation}
                                                                        staffsInDetail={[
                                                                            ...drivers,
                                                                            ...workers,
                                                                            ...supervisors,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            <div
                                                style={{
                                                    height: !filterEmployeeNotSelected({data: drivers})
                                                        .length
                                                        ? 105
                                                        : filterEmployeeNotSelected({data: drivers})
                                                            .length === 1
                                                            ? 59
                                                            : 29,
                                                }}
                                            />
                                            <div
                                                className={classNames.separatorContainerStyle}
                                                style={{
                                                    marginTop: allowEdit
                                                        ? 60
                                                        : !filterEmployeeNotSelected({data: drivers})
                                                            .length ||
                                                        filterEmployeeNotSelected({data: drivers})
                                                            .length === 1
                                                            ? 60
                                                            : 21,
                                                }}
                                            >
                                                <hr className={classNames.separatorStyle}/>
                                            </div>
                                        </div>
                                        {/* Worker */}
                                        <div>
                                            <div className={classNames.contentElementStyle}>
                                                {refreshList &&
                                                    workers &&
                                                    filterEmployeeNotSelected({data: workers}).map(
                                                        (workerList, indexList) => (
                                                            <div
                                                                key={workerList.id}
                                                                style={{
                                                                    margin: "5px 0",
                                                                    // visibility: doesStaffWorkOnAnySelectedMachine(
                                                                    //   workerList
                                                                    // )
                                                                    //   ? "visible"
                                                                    //   : "hidden",
                                                                }}
                                                            >
                                                                {workerList?.staffVacation ? (
                                                                    <Stack horizontal>
                                                                        {workerList?.staffVacation.map(
                                                                            (itemVacation, indexVacation) => (
                                                                                <div
                                                                                    key={indexVacation}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    {/* <MachineRequirementBar color={itemVacation?.color} horizontalPositionMap={horizontalPositionMap} start={itemVacation?.start} end={itemVacation?.end} /> */}
                                                                                    <MachineServiceStaffVacationBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={itemVacation?.start}
                                                                                        end={itemVacation?.end}
                                                                                        text={itemVacation?.reason}
                                                                                        notes={itemVacation?.notes}
                                                                                        // color={itemVacation?.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                {workerList?.staffSchedule?.length ? (
                                                                    <Stack horizontal>
                                                                        {workerList.staffSchedule.map(
                                                                            (staff, index) => (
                                                                                <div
                                                                                    key={`worker-${staff.projectId}${index}`}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    <MachineStaffBookedBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={staff.start}
                                                                                        end={staff.end}
                                                                                        // color={staff.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                        text={staff.name}
                                                                                        crmProjectStatusCode={
                                                                                            staff.crmProjectStatusCode
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                {workerList?.serviceSchedules?.length ? (
                                                                    <Stack horizontal>
                                                                        {workerList.serviceSchedules.map(
                                                                            (
                                                                                {
                                                                                    comment,
                                                                                    reason,
                                                                                    start,
                                                                                    end,
                                                                                    machineId,
                                                                                },
                                                                                index
                                                                            ) => (
                                                                                <div
                                                                                    key={`driver-service-${workerList.id}-${machineId}-${index}`}
                                                                                    style={{position: "relative"}}
                                                                                >
                                                                                    <MachineServiceStaffVacationBar
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositionMap={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        start={start}
                                                                                        end={end}
                                                                                        text={comment}
                                                                                        // color={itemMachineService?.color}
                                                                                        color={COLOR_GREY_OUT}
                                                                                        isMachineService
                                                                                        notes={reason}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        )}
                                                                    </Stack>
                                                                ) : null}
                                                                <div
                                                                    className={`barContainer workerContainer${workerList.id}`}
                                                                    style={{
                                                                        zIndex: 556,
                                                                        width: "100%",
                                                                        height: 36,
                                                                        background:
                                                                            drawData?.id === workerList.id
                                                                                ? "rgba(0, 108, 173, 0.2)"
                                                                                : "unset",
                                                                    }}
                                                                >
                                                                    {workerList.array.map((worker, index) => (
                                                                        <Fragment key={`${index}${worker.userId}`}>
                                                                            {worker?.start || worker?.startText ? (
                                                                                <Employment
                                                                                    calendarStepWidth={calendarStepWidth}
                                                                                    horizontalPositions={
                                                                                        horizontalPositionMap
                                                                                    }
                                                                                    boundArea={`workerContainer${workerList.id}`}
                                                                                    role={ROLES.WORKER}
                                                                                    indexList={indexList}
                                                                                    index={index}
                                                                                    onResize={onResize}
                                                                                    onDrag={onDrag}
                                                                                    worker={worker}
                                                                                    onDelete={onDelete}
                                                                                    // @ts-ignore
                                                                                    setDrawData={setDrawData}
                                                                                    allowEdit={
                                                                                        allowEdit && !drawData?.type
                                                                                    }
                                                                                    isDrawingVacationService={
                                                                                        !!drawData?.type
                                                                                    }
                                                                                    drawData={drawData}
                                                                                    // backgroundColor={
                                                                                    //   machineColorMap[worker.machineName] ||
                                                                                    //   "#fff"
                                                                                    // }
                                                                                    // color={
                                                                                    //   machineColorMap[worker.machineName]
                                                                                    //     ? "#fff"
                                                                                    //     : "#000"
                                                                                    // }
                                                                                    backgroundColor={getEmploymentBackgroundColor(
                                                                                        {
                                                                                            list: workerList,
                                                                                            machineName: worker.machineName,
                                                                                        }
                                                                                    )}
                                                                                    color={getEmploymentColor({
                                                                                        list: workerList,
                                                                                        machineName: worker.machineName,
                                                                                    })}
                                                                                />
                                                                            ) : (
                                                                                <></>
                                                                            )}
                                                                        </Fragment>
                                                                    ))}
                                                                </div>
                                                                {drawData &&
                                                                    !(drawData as VacationDrawData).type &&
                                                                    drawData.id === workerList.id && (
                                                                        // @ts-ignore
                                                                        <DetailPlanBarDrawer
                                                                            calendarStepWidth={calendarStepWidth}
                                                                            horizontalPositionMap={
                                                                                horizontalPositionMap
                                                                            }
                                                                            // containerPosition={drawData.containerPosition}
                                                                            name={drawData.name}
                                                                            listToCheck={drawData.listToCheck}
                                                                            onSave={
                                                                                (
                                                                                    drawData as
                                                                                        | StaffDrawData
                                                                                        | MachineDrawData
                                                                                ).onSave
                                                                            }
                                                                            boundArea={
                                                                                (
                                                                                    drawData as
                                                                                        | StaffDrawData
                                                                                        | MachineDrawData
                                                                                ).boundArea
                                                                            }
                                                                            targetData={workerList.array[0]}
                                                                            setDrawData={setDrawData}
                                                                            role={ROLES.WORKER}
                                                                            backgroundColor={drawDataBackground}
                                                                            color={drawDataColor}
                                                                        />
                                                                    )}
                                                                {(drawData as VacationDrawData)?.type && (
                                                                    <DetailPlanVacationDrawer
                                                                        calendarStepWidth={calendarStepWidth}
                                                                        horizontalPositionMap={
                                                                            horizontalPositionMap
                                                                        }
                                                                        drawData={drawData}
                                                                        setDrawData={setDrawData}
                                                                        staffData={staffData}
                                                                        machineData={machineData}
                                                                        containerId={`workerContainer${workerList.id}`}
                                                                        onConfirmVacation={onConfirmVacation}
                                                                        staffsInDetail={[
                                                                            ...drivers,
                                                                            ...workers,
                                                                            ...supervisors,
                                                                        ]}
                                                                    />
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                            <div
                                                style={{
                                                    height: !filterEmployeeNotSelected({data: workers})
                                                        .length
                                                        ? 105
                                                        : filterEmployeeNotSelected({data: workers})
                                                            .length === 1
                                                            ? 59
                                                            : 29,
                                                }}
                                            />
                                            <div
                                                className={classNames.separatorContainerStyle}
                                                style={{
                                                    marginTop: allowEdit
                                                        ? 60
                                                        : !filterEmployeeNotSelected({data: workers})
                                                            .length ||
                                                        filterEmployeeNotSelected({data: workers})
                                                            .length === 1
                                                            ? 60
                                                            : 21,
                                                }}
                                            >
                                                <hr className={classNames.separatorStyle}/>
                                            </div>
                                        </div>
                                        {/* Supervisor */}
                                        <div style={{position: "relative"}}>
                                            {renderSupervisor()}
                                            <div className={classNames.contentElementStyle}>
                                                {refreshList &&
                                                    supervisors &&
                                                    filterEmployeeNotSelected({
                                                        data: supervisors,
                                                        isSupervisor: true,
                                                    }).map((supervisorList, indexList) => (
                                                        <div
                                                            key={supervisorList.id}
                                                            style={{
                                                                margin: "5px 0",
                                                                // visibility: doesStaffWorkOnAnySelectedMachine(
                                                                //   supervisorList,
                                                                //   true
                                                                // )
                                                                //   ? "visible"
                                                                //   : "hidden",
                                                            }}
                                                        >
                                                            {supervisorList?.staffVacation ? (
                                                                <Stack horizontal>
                                                                    {supervisorList?.staffVacation.map(
                                                                        (itemVacation, indexVacation) => (
                                                                            <div
                                                                                key={indexVacation}
                                                                                style={{position: "relative"}}
                                                                            >
                                                                                <MachineServiceStaffVacationBar
                                                                                    calendarStepWidth={calendarStepWidth}
                                                                                    horizontalPositionMap={
                                                                                        horizontalPositionMap
                                                                                    }
                                                                                    start={itemVacation?.start}
                                                                                    end={itemVacation?.end}
                                                                                    text={itemVacation?.reason}
                                                                                    notes={itemVacation?.notes}
                                                                                    // color={itemVacation?.color}
                                                                                    color={COLOR_GREY_OUT}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </Stack>
                                                            ) : null}
                                                            {supervisorList?.serviceSchedules?.length ? (
                                                                <Stack horizontal>
                                                                    {supervisorList.serviceSchedules.map(
                                                                        (
                                                                            {
                                                                                comment,
                                                                                reason,
                                                                                start,
                                                                                end,
                                                                                machineId,
                                                                            },
                                                                            index
                                                                        ) => (
                                                                            <div
                                                                                key={`driver-service-${supervisorList.id}-${machineId}-${index}`}
                                                                                style={{position: "relative"}}
                                                                            >
                                                                                <MachineServiceStaffVacationBar
                                                                                    calendarStepWidth={calendarStepWidth}
                                                                                    horizontalPositionMap={
                                                                                        horizontalPositionMap
                                                                                    }
                                                                                    start={start}
                                                                                    end={end}
                                                                                    text={comment}
                                                                                    // color={itemMachineService?.color}
                                                                                    color={COLOR_GREY_OUT}
                                                                                    isMachineService
                                                                                    notes={reason}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </Stack>
                                                            ) : null}
                                                            {supervisorList?.staffSchedule?.length ? (
                                                                <Stack horizontal>
                                                                    {supervisorList.staffSchedule.map(
                                                                        (staff, index) => (
                                                                            <div
                                                                                key={`supervisor-${staff.projectId}${index}`}
                                                                                style={{position: "relative"}}
                                                                            >
                                                                                <MachineStaffBookedBar
                                                                                    calendarStepWidth={calendarStepWidth}
                                                                                    horizontalPositionMap={
                                                                                        horizontalPositionMap
                                                                                    }
                                                                                    start={staff.start}
                                                                                    end={staff.end}
                                                                                    // color={staff.color}
                                                                                    color={COLOR_GREY_OUT}
                                                                                    text={staff.name}
                                                                                    crmProjectStatusCode={
                                                                                        staff.crmProjectStatusCode
                                                                                    }
                                                                                    // @ts-ignore
                                                                                    staffY={(staff.counter || 0) * 40}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </Stack>
                                                            ) : null}
                                                            <div
                                                                className={`barContainer supervisorContainer${supervisorList.id}`}
                                                                style={{
                                                                    zIndex: 556,
                                                                    width: "100%",
                                                                    height:
                                                                        36 + (supervisorList.maxCounter || 0) * 40,
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        height: 36,
                                                                        background:
                                                                            drawData?.id === supervisorList.id
                                                                                ? "rgba(0, 108, 173, 0.2)"
                                                                                : "unset",
                                                                    }}
                                                                >
                                                                    {supervisorList.array.map(
                                                                        (supervisor, index) => (
                                                                            <div key={`${index}${supervisor.userId}`}>
                                                                                {supervisor?.start ||
                                                                                supervisor?.startText ? (
                                                                                    <Employment
                                                                                        calendarStepWidth={
                                                                                            calendarStepWidth
                                                                                        }
                                                                                        horizontalPositions={
                                                                                            horizontalPositionMap
                                                                                        }
                                                                                        boundArea={`supervisorContainer${supervisorList.id}`}
                                                                                        role={ROLES.SUPERVISOR}
                                                                                        indexList={indexList}
                                                                                        index={index}
                                                                                        onResize={onResize}
                                                                                        onDrag={onDrag}
                                                                                        worker={supervisor}
                                                                                        onDelete={onDelete}
                                                                                        // @ts-ignore
                                                                                        setDrawData={setDrawData}
                                                                                        allowEdit={
                                                                                            // @ts-ignore
                                                                                            allowEdit && !drawData?.type
                                                                                        }
                                                                                        isDrawingVacationService={
                                                                                            // @ts-ignore
                                                                                            !!drawData?.type
                                                                                        }
                                                                                        drawData={drawData}
                                                                                        // backgroundColor={
                                                                                        //   machineColorMap[
                                                                                        //     supervisor.machineName
                                                                                        //   ] || "#fff"
                                                                                        // }
                                                                                        // color={
                                                                                        //   machineColorMap[
                                                                                        //     supervisor.machineName
                                                                                        //   ]
                                                                                        //     ? "#fff"
                                                                                        //     : "#000"
                                                                                        // }
                                                                                        backgroundColor={getEmploymentBackgroundColor(
                                                                                            {
                                                                                                list: supervisorList,
                                                                                                machineName:
                                                                                                supervisor.machineName,
                                                                                                isSupervisor: true,
                                                                                            }
                                                                                        )}
                                                                                        color={getEmploymentColor({
                                                                                            list: supervisorList,
                                                                                            machineName:
                                                                                            supervisor.machineName,
                                                                                            isSupervisor: true,
                                                                                        })}
                                                                                    />
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {drawData &&
                                                                !(drawData as VacationDrawData).type &&
                                                                drawData.id === supervisorList.id && (
                                                                    // @ts-ignore
                                                                    <DetailPlanBarDrawer
                                                                        calendarStepWidth={calendarStepWidth}
                                                                        horizontalPositionMap={
                                                                            horizontalPositionMap
                                                                        }
                                                                        // containerPosition={drawData.containerPosition}
                                                                        name={drawData.name}
                                                                        listToCheck={drawData.listToCheck}
                                                                        onSave={
                                                                            (
                                                                                drawData as
                                                                                    | StaffDrawData
                                                                                    | MachineDrawData
                                                                            ).onSave
                                                                        }
                                                                        boundArea={`supervisorContainer${drawData.id}`}
                                                                        targetData={supervisorList.array[0]}
                                                                        setDrawData={setDrawData}
                                                                        role={ROLES.SUPERVISOR}
                                                                        backgroundColor={drawDataBackground}
                                                                        color={drawDataColor}
                                                                    />
                                                                )}
                                                            {(drawData as VacationDrawData | null)?.type && (
                                                                <DetailPlanVacationDrawer
                                                                    calendarStepWidth={calendarStepWidth}
                                                                    horizontalPositionMap={horizontalPositionMap}
                                                                    drawData={drawData}
                                                                    setDrawData={setDrawData}
                                                                    staffData={staffData}
                                                                    machineData={machineData}
                                                                    containerId={`supervisorContainer${supervisorList.id}`}
                                                                    onConfirmVacation={onConfirmVacation}
                                                                    staffsInDetail={[
                                                                        ...drivers,
                                                                        ...workers,
                                                                        ...supervisors,
                                                                    ]}
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {teachingBubbleMachines && !isLoadingMachines && prebookingsData && (
                <PopupMachines
                    target={ID_TARGET.MACHINE}
                    otherSectionsMachine={otherSectionsMachine}
                    machineData={machineForAdd}
                    machines={machines}
                    findNearestPossibleTime={findNearestPossibleTime}
                    horizontalPositionMap={horizontalPositionMap}
                    findMachineBookedProjects={findMachineBookedProjects}
                    projectId={projectId}
                    onAdd={addMachine}
                    toggleTeaching={toggleTeachingMachines}
                    prebookingsData={prebookingsData}
                    onClickDraw={(machine: TMachine) => {
                        addDrawMachine(machine);
                        toggleTeachingMachines();
                    }}
                    canDraw={!isDrawingNew}
                />
            )}
            {teachingBubbleDrivers && (
                <PopupDriver
                    role={ROLES.DRIVER}
                    target={ID_TARGET.DRIVER}
                    machines={machines}
                    staffData={staffForAdd}
                    otherSectionsStaff={otherSectionsStaff}
                    drivers={drivers}
                    findNearestPossibleTime={findNearestPossibleTime}
                    horizontalPositionMap={
                        horizontalPositionMap as TPlanHorizontalPositionMap
                    }
                    findStaffSchedule={findStaffSchedule}
                    projectId={projectId}
                    // @ts-ignore
                    onAdd={addEmployment}
                    toggleTeaching={toggleTeachingDrivers}
                    propName="drivers"
                    allPlansData={allPlansData || []}
                    startAndEndDateEarliestMachine={startAndEndDateEarliestMachine}
                    machineData={machineData}
                    projectRequiredSkills={projectData?.requiredSkills || []}
                    onClickDraw={(user, role, targetBubbleMachine) => {
                        addDrawStaff(user, role, targetBubbleMachine);
                        toggleTeachingDrivers();
                    }}
                />
            )}
            {teachingBubbleWorkers && (
                <PopupWorkers
                    allPlansData={allPlansData || []}
                    role={ROLES.WORKER}
                    target={ID_TARGET.WORKER}
                    staffData={staffForAdd}
                    otherSectionsStaff={otherSectionsStaff}
                    workers={workers}
                    findNearestPossibleTime={findNearestPossibleTime}
                    horizontalPositionMap={
                        horizontalPositionMap as TPlanHorizontalPositionMap
                    }
                    findStaffSchedule={findStaffSchedule}
                    projectId={projectId}
                    // @ts-ignore
                    onAdd={addEmployment}
                    toggleTeaching={toggleTeachingWorkers}
                    propName="workers"
                    startAndEndDateEarliestMachine={startAndEndDateEarliestMachine}
                    projectRequiredSkills={projectData?.requiredSkills || []}
                    onClickDraw={(user, role, targetBubbleMachine) => {
                        addDrawStaff(user, role, targetBubbleMachine);
                        toggleTeachingWorkers();
                    }}
                    machines={machines}
                />
            )}
            {teachingBubbleSupervisor && (
                <PopupSupervisors
                    allPlansData={allPlansData || []}
                    roles={[ROLES.SUPERVISOR, ROLES.FUNKTIONÆR]}
                    target={ID_TARGET.SUPERVISOR}
                    staffData={staffForAdd}
                    otherSectionsStaff={otherSectionsStaff}
                    workers={supervisors}
                    // @ts-ignore
                    findNearestPossibleTime={findNearestPossibleTime}
                    horizontalPositionMap={
                        horizontalPositionMap as TPlanHorizontalPositionMap
                    }
                    findStaffSchedule={findStaffSchedule}
                    projectId={projectId}
                    // @ts-ignore
                    onAdd={addEmployment}
                    toggleTeaching={toggleTeachingSupervisors}
                    propName="managers"
                    onClickDraw={(user, role) => {
                        // @ts-ignore
                        addDrawStaff(user as AllUserData, role);
                        toggleTeachingSupervisors();
                    }}
                />
            )}
            {targetBubbleDelete && (
                <DeleteConfirmation
                    target={targetBubbleDelete.target}
                    toggleTeaching={() => {
                        setTargetBubbleDelete(null);
                    }}
                    onClickConfirm={onClickDeleteListElement}
                />
            )}
            {teachingBubbleTeams && !isLoadingMachines && (
                <PopupTeams
                    target={ID_TARGET.TEAMS}
                    onAdd={(team: TMachineTeamModel) => handleSelectedTeam(team, false)}
                    machineData={machineTeamList}
                    toggleTeaching={toggleTeachingTeams}
                />
            )}
            {teachingBubbleOneWeekTeams && !isLoadingMachines && (
                <PopupTeams
                    target={ID_TARGET.ONE_WEEK_TEAMS}
                    onAdd={handleSelectedTeam}
                    machineData={machineTeamList}
                    toggleTeaching={toggleTeachingOneWeekTeams}
                />
            )}
            {hoverData ? (
                // @ts-ignore
                <FixPositionBubble
                    target={"#" + hoverData.hoverId}
                    onDismiss={() => {
                        setHoverData(null);
                    }}
                    // closeButtonAriaLabel="Close"
                >
          <span>
            {hoverData.text + " "}
              <strong>
              {hoverData.boldText ? "(" + hoverData.boldText + ")" : ""}
            </strong>
          </span>
                </FixPositionBubble>
            ) : (
                <></>
            )}
        </div>
    );
};

export default DetailedPlanning;
