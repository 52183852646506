// Import libraries
import React, {Children, cloneElement, ReactElement, useEffect, useState} from 'react';

// Import types
import { children } from '../../types';

type ScreenSizeAndPositionProps = {
    children: children
}

// Wrapper component used for auto re-render child when size and scroll position of page change
const ScreenSizeAndPosition = ({children}: ScreenSizeAndPositionProps) : ReactElement => {
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const [pageHeight, setPageHeight] = useState(window.innerHeight);
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);

    const handleScroll = () => {
        const scrollPositionX = window.pageXOffset;
        const scrollPositionY = window.pageYOffset;
        setScrollX(scrollPositionX);
        setScrollY(scrollPositionY);
    };

    const handleResize = () => {
        setPageWidth(window.innerWidth);
        setPageHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('resize', handleResize, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        }
    });

    return (
        <>
            {Children.map(children, (child) =>
                cloneElement(child as ReactElement, { pageWidth, pageHeight, scrollX, scrollY })
            )}
        </>
    )
}

export default ScreenSizeAndPosition;