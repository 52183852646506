import React, { useState } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import Select from "react-select";
import { Label } from "@fluentui/react/lib/Label";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { TextField } from "@fluentui/react/lib/TextField";
import { IconButton, PrimaryButton, Stack, Checkbox } from "@fluentui/react";
import {
  ColorPickerComponent,
  MapPickerComponent,
  CustomInputErrorComponent,
} from "../../common/index";
import {
  primaryButtonStyles,
  inputStyles,
  customAutoCompleteStyles,
} from "../../../utils/theme";
import CalendarCustom from "../../common/CalendarCustom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarked, faPalette } from "@fortawesome/pro-regular-svg-icons";
import { useSelector } from "react-redux";
import {
  SKILL_TYPE_KEY,
  techDepartmentOptions,
} from "../../../utils/constants";
import { hasEditRight } from "../../../utils/utils";
import RequiredSkillsTable from "../machines/RequiredSkillsTable";

const classNames = mergeStyleSets({
  selector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(159, 159, 159)",
    borderRadius: 4,
    height: 46,
    width: 318,
    marginTop: 24,
    selectors: {
      ":hover": {
        border: "1px solid rgb(42, 42, 42)",
      },
    },
  },
  container: {
    minHeight: "100vh",
    background: "#F1F1F1",
    marginBottom: 24,
  },
  label: {
    fontSize: 29,
    lineHeight: 29,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 92px 118px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: 320,
    margin: "0 auto",
  },
  input: {
    marginTop: 24,
  },
  back: {
    width: 70,
  },
  calendarClassName: {
    zIndex: 2,
  },
  icon: {
    fontSize: 20,
    cursor: "pointer",
    position: "relative",
    top: 4,
    right: 16,
  },
});

const customStyles = {
  input: (provided) => ({
    ...provided,
    color: "#F1F1F1",
    height: 38,
    cursor: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: 9,
  }),
  control: (provided) => ({
    ...provided,
    background: "#F1F1F1",
    border: "1px solid rgb(159, 159, 159)",
    "&:hover": {
      borderColor: "rgb(42, 42, 42)",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#000000",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
};

const ProjectForm = ({
  values: {
    isQuotation = false,
    reason,
    hovedsagsNummer,
    projectNo,
    projectName,
    projectDescription,
    projectType,
    techDepartments = [],
    personResponsbible = null,
    color = "",
    period = [new Date(), new Date()],
    section,
    geoCoordinate = { latitude: 0, longitude: 0 },
    requiredSkills,
  },
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  handleChange,
  isValid,
  history,
  skills,
}) => {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const techDepartmentOptionsTransform = techDepartmentOptions.map((item) => ({
    value: item.key,
    label: item.text,
  }));
  const [mapPickerVisible, setMapPickerVisible] = useState(false);
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const { sectionOptions } = useSelector((state) => state.defaultData);
  const sectionOptionsWithLabel = sectionOptions.map((option) => ({
    key: option.key,
    label: option.text,
  }));

  const togglePickerVisible = () => {
    setColorPickerVisible((prevState) => !prevState);
  };

  const toggleMapPickerVisible = () => {
    setMapPickerVisible((prevState) => !prevState);
  };

  const handleSubmitAndSetRedirectToMachineRequirements = ({
    setRedirect = false,
  }) => {
    if (setRedirect) {
      setFieldValue("redirectToMachineReq", setRedirect);
    }
    handleSubmit();
  };

  const onTechDepartmentChange = (values) => {
    const filtered = values.map((value) => value.value);
    setFieldValue("techDepartments", filtered);
    setFieldValue("personResponsbible", null);
  };

  const setPeriod = (newPeriod) => {
    setFieldValue("period", newPeriod);
  };

  return (
    <form className={classNames.container}>
      <div className={classNames.header}>
        <div className={classNames.back}>
          <IconButton
            iconProps={{ iconName: "ChromeBack" }}
            onClick={() => history.push("/projects")}
          />
        </div>
        <Label className={classNames.label}>Opret nyt projekt</Label>
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <PrimaryButton
            disabled={!isValid || !allowEdit}
            text="GEM"
            onClick={handleSubmitAndSetRedirectToMachineRequirements}
            styles={primaryButtonStyles}
          />
        </Stack>
      </div>
      <div className="machineFormContainer">
        <div
          className={classNames.content}
          style={{ pointerEvents: !allowEdit && "none" }}
        >
          <div onClick={togglePickerVisible} className={classNames.selector}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <div
                style={{
                  fontSize: 11,
                  marginRight: 8,
                  fontWeight: 600,
                  color: "#2A2A2A",
                  marginTop: 5,
                }}
              >
                FARVE
              </div>
              <div
                style={{
                  background: `rgba(${color},1)`,
                  height: 16,
                  width: 172,
                  borderRadius: 4,
                  marginTop: 3,
                  marginRight: 8,
                }}
              />
            </div>
            <div className={classNames.icon}>
              <FontAwesomeIcon
                icon={faPalette}
                id="colorInput"
                style={{ color: "black", marginTop: 8 }}
              />
            </div>
          </div>
          <CustomInputErrorComponent
            errorMessage={touched.color || errors.color ? errors.color : ""}
          />
          <TextField
            label="HOVEDSAG"
            styles={inputStyles}
            value={hovedsagsNummer}
            name="hovedsagsNummer"
            placeholder="HOVEDSAG"
            className={classNames.input}
            onChange={handleChange}
            errorMessage={
              touched.hovedsagsNummer || errors.hovedsagsNummer
                ? errors.hovedsagsNummer
                : ""
            }
          />
          <TextField
            label="ARKIVERINGSSAG"
            styles={inputStyles}
            value={projectNo}
            name="projectNo"
            placeholder="ARKIVERINGSSAG"
            className={classNames.input}
            onChange={handleChange}
            errorMessage={
              touched.projectNo || errors.projectNo ? errors.projectNo : ""
            }
          />
          <TextField
            label="HOVEDSAGNAVN"
            styles={inputStyles}
            value={projectName}
            name="projectName"
            placeholder="NAVN"
            className={classNames.input}
            onChange={handleChange}
            errorMessage={
              touched.projectName || errors.projectName
                ? errors.projectName
                : ""
            }
          />
          <TextField
            label="PROJEKTBESKRIVELSE"
            styles={inputStyles}
            value={projectDescription}
            name="projectDescription"
            placeholder="Description "
            className={classNames.input}
            onChange={handleChange}
            errorMessage={
              touched.projectDescription || errors.projectDescription
                ? errors.projectDescription
                : ""
            }
          />
          <div
            style={{
              fontFamily: "Verdana",
              fontSize: 11,
              fontWeight: "bold",
              transform: "translate(9px, 41px)",
              zIndex: 2,
              marginTop: -13,
            }}
          >
            SEKTIONER
          </div>
          <Select
            placeholder="Region"
            name="section"
            closeMenuOnSelect={true}
            defaultValue={sectionOptionsWithLabel.find(
              (item) => item.key === section,
            )}
            options={sectionOptionsWithLabel}
            isClearable={true}
            isSearchable={true}
            onChange={(item) => setFieldValue("section", item?.key)}
            className={classNames.input}
            styles={customAutoCompleteStyles(errors.section)}
            getOptionValue={(option) => option.label}
          />
          <CustomInputErrorComponent
            errorMessage={
              touched.section || errors.section ? errors.section : ""
            }
          />
          <CalendarCustom period={period} setPeriod={setPeriod} />
        </div>
        <div
          className={classNames.content}
          style={{ pointerEvents: !allowEdit && "none" }}
        >
          <CustomInputErrorComponent
            errorMessage={touched.period || errors.period ? errors.period : ""}
          />
          <TextField
            label="PROJEKTTYPE"
            styles={inputStyles}
            value={projectType}
            name="projectType"
            placeholder="PROJEKTTYPE"
            className={classNames.input}
            onChange={handleChange}
            errorMessage={
              touched.projectType || errors.projectType
                ? errors.projectType
                : ""
            }
          />
          <Select
            placeholder="FAGSEKTION"
            closeMenuOnSelect={false}
            isMulti
            defaultValue={techDepartmentOptionsTransform.filter((option) =>
              techDepartments.includes(option.value),
            )}
            options={techDepartmentOptionsTransform}
            onChange={onTechDepartmentChange}
            className={classNames.input}
            styles={customStyles}
          />
          {!techDepartments.length && (
            <CustomInputErrorComponent
              errorMessage={
                touched.techDepartments || errors.techDepartments
                  ? errors.techDepartments
                  : ""
              }
            />
          )}
          <TextField
            label="RESPONSIBLE PERSON"
            styles={inputStyles}
            value={personResponsbible}
            name="personResponsbible"
            placeholder="PERSON RESPONSIBLE"
            className={classNames.input}
            onChange={handleChange}
          />
          <div onClick={toggleMapPickerVisible} className={classNames.selector}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 8,
              }}
            >
              <div
                style={{
                  fontSize: 11,
                  marginRight: 8,
                  fontWeight: 600,
                  color: "#2A2A2A",
                  marginTop: 5,
                }}
              >
                LOKATION
              </div>
              <div
                style={{
                  fontSize: 14,
                  lineHeight: "30px",
                  marginRight: 8,
                  fontWeight: 400,
                  color: "#2A2A2A",
                }}
              >
                {geoCoordinate?.latitude && geoCoordinate?.longitude
                  ? `${geoCoordinate.latitude.toFixed(4)}, ${geoCoordinate.longitude.toFixed(4)}`
                  : ""}
              </div>
            </div>
            <div className={classNames.icon}>
              <FontAwesomeIcon
                icon={faMapMarked}
                id="mapInput"
                style={{ color: "black", marginTop: 8 }}
              />
            </div>
          </div>
          <CustomInputErrorComponent
            errorMessage={
              touched.geoCoordinate || errors.geoCoordinate
                ? errors.geoCoordinate
                : ""
            }
          />
          <div className={classNames.selector}>
            <div
              style={{ display: "flex", alignItems: "center", marginLeft: 8 }}
            >
              <div style={{ marginRight: 8, fontWeight: 400, color: "#000" }}>
                Quotation
              </div>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", marginRight: 12 }}
            >
              <Checkbox
                onChange={(e) => {
                  setFieldValue("isQuotation", e.target.checked);
                }}
                checked={isQuotation}
              />
            </div>
          </div>
          <TextField
            label="ATTENTION"
            styles={inputStyles}
            value={reason}
            name="reason"
            placeholder="Reason"
            className={classNames.input}
            onChange={handleChange}
          />
        </div>
        <div
          className={classNames.content}
          style={{ pointerEvents: !allowEdit && "none" }}
        >
          <RequiredSkillsTable
            onUpdatedRequiredSkills={(newRequiredSkills) => {
              setFieldValue("requiredSkills", newRequiredSkills);

              // if (isValid && allowEdit) {
              //     handleSubmitAndSetRedirectToMachineRequirements({ setRedirect: false });
              // }
            }}
            skills={skills}
            requiredSkills={requiredSkills}
            techDepartments={techDepartments.join(";")}
            hasEditRights={allowEdit}
            skillType={SKILL_TYPE_KEY.GENERAL}
          />
        </div>
      </div>
      {colorPickerVisible && (
        <TeachingBubble
          target="#colorInput"
          onDismiss={togglePickerVisible}
          styles={{
            content: {
              background: "white",
            },
          }}
        >
          <ColorPickerComponent
            selectColor={(value) => setFieldValue("color", value)}
            color={color}
            togglePickerVisible={togglePickerVisible}
          />
        </TeachingBubble>
      )}
      {mapPickerVisible && (
        <TeachingBubble
          target="#mapInput"
          onDismiss={toggleMapPickerVisible}
          calloutProps={{
            calloutMinWidth: 548,
          }}
          styles={{
            content: {
              background: "white",
              width: 548,
              maxWidth: "unset",
            },
          }}
        >
          <MapPickerComponent
            geoCoordinate={
              geoCoordinate ? geoCoordinate : { latitude: 0, longitude: 0 }
            }
            setGeoCoordinate={(value) => setFieldValue("geoCoordinate", value)}
            toggleMapPickerVisible={toggleMapPickerVisible}
          />
        </TeachingBubble>
      )}
    </form>
  );
};

export default ProjectForm;
