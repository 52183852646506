import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Stack, DefaultButton } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {
  setFilter,
  toggleDisplayStaffView,
} from "../../../redux/machines/machines.actions";
import {
  techDepartmentOptions,
  stationOptions,
} from "../../../utils/constants";
import { outlinedDropDownStyles } from "../../../utils/theme";
import { TooltipForText } from "../";
import { Image } from "@fluentui/react/lib/Image";
import { CALENDAR_PAGE } from "../../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHardHat } from "@fortawesome/pro-regular-svg-icons";
import ToggleButton from "../ToggleButton";
import PlanningPageFilter from "../../pages/planning/detailedPlanning/PlanningPageFilter";
import ScreenSizeAndPosition from "../ScreenSizeAndPosition";
import StaffPageFilter from "../../pages/staff/StaffPageFilter";

const CalendarControlsLeft = ({
  displayStaffView,
  toggleDisplayStaffView,
  calendarPage,
}) => {
  const classNames = mergeStyleSets({
    buttonContainer: {
      display: "flex",
      width: 270,
      flexWrap: "wrap",
      margin: `${
        calendarPage === CALENDAR_PAGE.DISPOSITION ||
        calendarPage === CALENDAR_PAGE.MACHINE_SERVICE
          ? "20px 0 20px 40px"
          : "20px 0 70px 20px"
      }`,
    },
    unselectedIcon: {
      fontSize: 18,
      color: "#006CAD",
    },
    selectedIcon: {
      fontSize: 18,
      color: "#fff",
    },
    image: {
      fontSize: 36,
    },
  });
  const dispatch = useDispatch();
  const filter = useSelector((state) => state?.machine?.filter);
  const { techDepartment } = useSelector((state) => state?.machine?.filter);
  return (
    <div className={classNames.buttonContainer}>
      <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
        <Dropdown
          selectedKey={techDepartment}
          name="projectType"
          onChange={(event, item) =>
            dispatch(
              setFilter({
                techDepartment: item.key,
                [item.key]: {
                  techArea: filter[item.key].techArea
                    ? filter[item.key].techArea
                    : stationOptions[item.key]["options"].map(
                        (item) => item.key
                      ),
                },
              })
            )
          }
          placeholder="Department"
          options={techDepartmentOptions}
          styles={outlinedDropDownStyles}
        />
        {calendarPage === CALENDAR_PAGE.STAFF && (
          <ScreenSizeAndPosition>
            <StaffPageFilter />
          </ScreenSizeAndPosition>
        )}
      </Stack>
      {calendarPage === CALENDAR_PAGE.DISPOSITION && (
        <Stack horizontal style={{ marginTop: 15 }}>
          <TooltipForText
            text={displayStaffView ? "Staff View" : "Machine View"}
          >
            <ToggleButton
              styles={{ marginRight: 15 }}
              toggle={displayStaffView}
              onClick={() => {
                toggleDisplayStaffView(!displayStaffView);
              }}
              LeftIcon={() => (
                <Image
                  src={
                    !displayStaffView
                      ? "./img/machineWhite.svg"
                      : "./img/machineBlue.svg"
                  }
                  alt="Machine"
                  width={20}
                  height={24}
                  className={classNames.image}
                />
              )}
              RightIcon={() => (
                <FontAwesomeIcon
                  icon={faHardHat}
                  className={
                    displayStaffView
                      ? classNames.selectedIcon
                      : classNames.unselectedIcon
                  }
                />
              )}
            />
          </TooltipForText>
          <ScreenSizeAndPosition>
            <PlanningPageFilter />
          </ScreenSizeAndPosition>
        </Stack>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  displayStaffView: state.machine.displayStaffOnPlanning,
});

export default connect(mapStateToProps, { toggleDisplayStaffView })(
  CalendarControlsLeft
);
