import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { DefaultButton, SearchBox, TeachingBubble } from '@fluentui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTrumpet } from '@fortawesome/pro-regular-svg-icons';
import { buttonStyle } from '../../common/calendar/ButtonStyles';
import { setFilter } from '../../../redux/machines/machines.actions';
import ToggleableSelector from '../planning/filterSelector/ToggleableSelector';
import { stationOptions } from '../../../utils/constants';
import StaffRoleSelector from '../planning/filterSelector/StaffRoleSelector';

const classNames = mergeStyleSets({
    unSelectedIcon: {
        marginRight: 2,
        fontSize: 16,
        color: '#006CAD',
    },
    iconNumber: {
        position: 'absolute',
        paddingLeft: '3px',
        paddingRight: '3px',
        borderRadius: '50%',
        marginTop: '-15px',
        marginLeft: '15px',
        color: '#FFFFFF',
        fontSize: 10,
        fontWeight: 'bold',
        backgroundColor: '#006CAD',
    },
    heading: {
        fontSize: 13,
        fontWeight: 'bold',
        color: '#000000',
    },
    sectionContainer: {
        marginTop: 35,
    },
    lastSection: {
        marginTop: 35,
        marginBottom: 50,
    },
});

const StaffPageFilter = ({ scrollY }) => {
    const dispatch = useDispatch();
    const filter = useSelector((state) => state?.machine?.filter);
    const techDepartment = useSelector((state) => state?.machine?.filter.techDepartment);
    const [showBubble, setShowBubble] = useState(false);

    const componentStyles = (displayStaffView) => ({
        teachingBubble: {
            root: {
                marginTop: displayStaffView ? `${140 - scrollY}px` : `${170 - scrollY}px`,
                width: 600,
            },
            subComponentStyles: {
                callout: {
                    beak: {
                        marginTop: displayStaffView ? `${-140 + scrollY}px` : `${-170 + scrollY}px`,
                    },
                },
            },
        },
        searchBoxOld: {
            root: {
                border: 'none',
                backgroundColor: '#FFFFFF',
                margin: '-10px -10px 0 -5px',
                height: '56px',
                ':after': {
                    border: 'none',
                },
                width: '180px',
            },
            iconContainer: {
                display: 'none',
            },
            clearButton: {
                marginLeft: '-50px',
            },
        },
        searchBox: {
            root: {
                border: "none",
                backgroundColor: "#FFFFFF",
                marginTop: "-10px",
                marginRight: "-10px",
                height: "56px",
                ":after": {
                    border: "none",
                },
            },
            iconContainer: {
                display: "none",
            }
        }
    });

    const countSelectorNumbers = () => {
        let count = 0;
        const countKey = ['techArea', 'staffRole'];
        const currentFilter = filter[techDepartment];
        Object.keys(currentFilter).forEach((key) => {
            if (countKey.includes(key)) {
                if (key === 'staffRole') {
                    count += currentFilter[key]?.filter((item) => item.isOn)?.length;
                } else if (key === 'selectedRegion') {
                    count += currentFilter['availableRegion']?.filter((item) => currentFilter[key].includes(item))?.length || 0;
                } else if (key === 'techArea') {
                    count += currentFilter[key]?.length;
                } else if (key === 'selectedMachineSizeGroup') {
                    count += currentFilter['availableMachineSizeGroup']?.filter((item) => currentFilter[key].includes(item))?.length || 0;
                }
            }
        });
        return count;
    };

    return (
        <div>
            <div id='filter-button'>
                <DefaultButton onClick={() => setShowBubble(!showBubble)} styles={{ ...buttonStyle(false) }}>
                    <FontAwesomeIcon icon={faFilter} className={classNames.unSelectedIcon} />
                    {countSelectorNumbers() > 0 && <span className={classNames.iconNumber}>{countSelectorNumbers()}</span>}
                </DefaultButton>
            </div>
            {showBubble && (
                <TeachingBubble
                    styles={componentStyles(faTrumpet).teachingBubble}
                    closeButtonAriaLabel='Close'
                    target='#filter-button'
                    onDismiss={() => setShowBubble(!showBubble)}
                >
                    <div style={{ margin: '11px' }}>
                        <div>
                            <span className={classNames.heading}>Staff names</span>
                            <div style={{ marginTop: '20px' }}>
                                <SearchBox
                                    styles={componentStyles(true).searchBox}
                                    placeholder='Search'
                                    clearButtonProps={{
                                        style: {
                                            backgroundColor: '#FFFFFF',
                                            marginLeft: '20px',
                                        },
                                    }}
                                    value={filter[techDepartment]['staffNames'] || ''}
                                    onChange={(e, value) => dispatch(setFilter({ [techDepartment]: { staffNames: value || '' } }))}
                                />
                                <hr style={{ width: '145%', marginLeft: '-50px', marginTop: '-2px', opacity: 0.5 }} />
                            </div>
                        </div>
                        <div className={classNames.sectionContainer}>
                            <span className={classNames.heading}>Disponent</span>
                            <ToggleableSelector
                                filterKey='techArea'
                                techDepartment={filter.techDepartment}
                                allOptions={stationOptions[techDepartment]['options']}
                                availableOptions={stationOptions[techDepartment]['options'].map((item) => item.key)}
                                selectedOptions={filter[techDepartment].techArea}
                            />
                        </div>
                        <div className={classNames.lastSection}>
                            <span className={classNames.heading}>Personalets rolle</span>
                            <StaffRoleSelector />
                        </div>
                    </div>
                </TeachingBubble>
            )}
        </div>
    );
};

export default StaffPageFilter;
