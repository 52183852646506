// Import libraries
import { Fragment } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { useBoolean } from "@fluentui/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faMapMarkerAlt,
  faFileInvoiceDollar,
} from "@fortawesome/pro-regular-svg-icons";
import { TooltipHost } from "@fluentui/react";

// Import components
import MapAddressWithTeachingBubbleComponent from "../../common/MapAddressWithTeachingBubbleComponent";
import { MachineIconWithBadge } from "../../common/index";

const classNames = mergeStyleSets({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: 150,
  },
  icon: {
    fontSize: 24,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
  },
  documentText: {
    color: "#000",
  },
  section: {
    width: 20,
    height: "100%",
  },
});

const hostStyles = { root: { display: "inline-block", padding: 0 } };

const callOutProps = {
  gapSpace: 0,
  styles: {
    beakCurtain: {
      backgroundColor: "#fff",
    },
  },
};

const ProjectTableInfoCell = ({
  machineCount,
  history,
  projectId,
  geoCoordinate,
  projectDescription,
  isQuotation,
  missingRequiredInfo,
}) => {
  const [teachingBubbleShowMap, { toggle: toggleTeachingShowMap }] =
    useBoolean(false);

  const ableToShowMap =
    geoCoordinate &&
    geoCoordinate?.latitude !== 0 &&
    geoCoordinate?.longitude !== 0;
  return (
    <Fragment>
      <div className={classNames.container}>
        <div className={classNames.section}>
          {isQuotation && (
            <FontAwesomeIcon
              icon={faFileInvoiceDollar}
              className={classNames.icon}
              style={{ cursor: "default" }}
            />
          )}
        </div>
        <div className={classNames.section}>
          <MachineIconWithBadge
            count={machineCount}
            isRed={missingRequiredInfo}
            onClick={() =>
              history.push(`/add-machine-requirements/${projectId}`)
            }
          />
        </div>
        <div className={classNames.section}>
          <TooltipHost
            content={projectDescription}
            id={`showDescription-${projectId}`}
            styles={hostStyles}
            calloutProps={callOutProps}
          >
            <FontAwesomeIcon
              aria-describedby={`showDescription-${projectId}`}
              id={`showDescription-${projectId}`}
              icon={faFile}
              className={classNames.icon}
            />
          </TooltipHost>
        </div>
        <div className={classNames.section}>
          <FontAwesomeIcon
            id={`showMap-${projectId}`}
            icon={faMapMarkerAlt}
            className={classNames.icon}
            onClick={toggleTeachingShowMap}
          />
        </div>
      </div>
      {teachingBubbleShowMap && ableToShowMap && (
        <MapAddressWithTeachingBubbleComponent
          target={`#showMap-${projectId}`}
          toggleTeaching={toggleTeachingShowMap}
          marker={{
            lat: geoCoordinate?.latitude,
            lng: geoCoordinate?.longitude,
          }}
        />
      )}
    </Fragment>
  );
};

export default ProjectTableInfoCell;
