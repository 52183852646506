import React, { Fragment, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getPrebooking,
  clearOverlayPrebookingId,
  deletePrebooking,
  changePrebookingBubbleInfo,
} from "../../../../redux/prebooking/prebooking.actions";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Panel } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComment,
  faHistory,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import CommentContainer from "./Comment/CommentContainer";
import {
  ScrollablePane,
  ScrollbarVisibility,
} from "@fluentui/react/lib/ScrollablePane";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { defaultButtonStyles } from "../../../../utils/theme";
import { useBoolean } from "@fluentui/react-hooks";
import { DeleteConfirmation } from "../../../common";
import { Stack } from "@fluentui/react";
import { useHistory } from "react-router-dom";
import ToggleButton from "../../../common/ToggleButton";
import ActivityContainer from "./Comment/ActivityContainer";
import { hasEditRight } from "../../../../utils/utils";

const classNames = mergeStyleSets({
  buttonBottomContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButtonOutside: {
    zIndex: 1,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 20,
    left: -40,
    height: 40,
    width: 40,
    background: "#DFDFDF",
    cursor: "pointer",
    boxShadow:
      "rgb(0 0 0 / 22%) -5px 0px 5px 0px, rgb(0 0 0 / 18%) -14px 0px 20px 0px",
    borderTopLeftRadius: "10%",
    borderBottomLeftRadius: "10%",
  },
  closeButtonInside: {
    width: "80%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    selectors: {
      ":hover": {
        background: "rgba(0, 0, 0, 0.05)",
      },
    },
  },
  projectNameSection: {
    height: 144,
    width: "100%",
    borderRadius: "10px",
    marginBottom: 16,
    // cursor: 'pointer'
  },
  responsibleAndDescriptionSection: {
    height: 144,
    width: "100%",
    borderRadius: "10px",
    backgroundColor: "#fff",
    marginBottom: 16,
  },
  input: {
    position: "relative",
    margin: "16px 0",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  unSelectedIcon: {
    marginRight: 2,
    fontSize: 16,
    color: "#006CAD",
  },
  selectedIcon: {
    marginRight: 2,
    fontSize: 16,
    color: "#fff",
  },
});

export const convertButtonStyles = {
  root: {
    borderRadius: 4,
    height: 40,
    backgroundColor: "rgb(242, 135, 5)",
    border: "1px solid rgb(242, 135, 5)",
  },
  label: { fontWeight: 400 },
  rootHovered: {
    backgroundColor: "242, 135, 5",
    border: "1px solid 242, 135, 5",
  },
};

const overlayStyle = {
  main: {
    overflow: "visible",
    minWidth: "25%",
    background: "#DFDFDF",
  },
};

const PrebookingOverlay = ({
  clearOverlayPrebookingId,
  overlayPrebookingId,
  getPrebooking,
  deletePrebooking,
  pageWidth,
}) => {
  const color = "242, 135, 5";
  const { openOverlay } = useSelector((state) => state.prebooking);
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [displayComment, setDisplayComment] = useState(false);
  const [deleteConfirmation, { toggle: toggleDeleteConfirmation }] =
    useBoolean(false);
  const history = useHistory();

  const { data: prebookingData, isSuccess } = useQuery(
    ["prebooking", overlayPrebookingId],
    () => getPrebooking(overlayPrebookingId),
    {
      enabled: !!overlayPrebookingId,
      initialData: () =>
        queryClient
          .getQueryData("prebookings")
          ?.find((prebooking) => prebooking.id === overlayPrebookingId),
    }
  );

  const { mutate: mutateDeletePrebooking } = useMutation(
    (prebookingId) => deletePrebooking(prebookingId),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("prebookings");
          clearOverlayPrebookingId();
        }
      },
    }
  );

  const onClickToEditPage = () => {
    history.push(`/edit-pre-booking/${prebookingData.id}`);
  };

  const onClickConfirmDelete = () => {
    toggleDeleteConfirmation();
    mutateDeletePrebooking(overlayPrebookingId);
  };

  const onClickRedirectToReplacePage = () => {
    history.push(`/replacePrebooking/${prebookingData.id}`);
  };

  const openPrebookingModal = () => {
    dispatch(
      changePrebookingBubbleInfo({
        showPrebookingModal: true,
        isEditModal: true,
        overlayPrebookingId: overlayPrebookingId,
        bubbleTarget: `editPrebookingOnOverlay-${overlayPrebookingId}`,
      })
    );
  };

  return (
    <Panel
      // To entirely disable the default dismiss behavior:
      // 1. Don't provide an onDismiss prop
      isOpen={!!overlayPrebookingId && openOverlay} // 2. Control the visibility
      hasCloseButton={false}
      customWidth="500px"
      styles={overlayStyle}
    >
      <div
        onClick={clearOverlayPrebookingId}
        className={classNames.closeButtonOutside}
      >
        <div className={classNames.closeButtonInside}>
          <FontAwesomeIcon
            icon={faTimes}
            style={{
              color: isSuccess && prebookingData && "#F28750",
              fontSize: 30,
            }}
          />
        </div>
      </div>
      <div className={classNames.innerContainer}>
        {isSuccess && prebookingData && (
          <Fragment>
            <div
              className={classNames.projectNameSection}
              style={{ backgroundColor: `rgb(${color})` }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: 16,
                  color: "#fff",
                  height: 114,
                }}
              >
                <div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontSize: 14, fontWeight: 400 }}>
                      {prebookingData.id}
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: 32,
                      fontWeight: 700,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      webkitBoxOrient: "vertical",
                      WebkitLineClamp: "2",
                      lineHeight: "1em",
                      maxHeight: "2em",
                    }}
                  >
                    {prebookingData.projectName}
                  </div>
                </div>
                <div style={{ fontSize: 11, fontWeight: 700 }}>
                  {prebookingData?.machineName
                    ? prebookingData?.machineName
                    : "Unknown Machine"}
                </div>
              </div>
            </div>
            <div className={classNames.responsibleAndDescriptionSection}>
              <div style={{ padding: 16, color: "#000", height: 112 }}>
                <div style={{ position: "relative", height: "100%" }}>
                  <ScrollablePane
                    scrollbarVisibility={ScrollbarVisibility.auto}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ marginBottom: 10 }}>
                        <div
                          style={{
                            fontSize: 14,
                            fontWeight: 700,
                            marginBottom: 4,
                          }}
                        >
                          Responsible
                        </div>
                        <div style={{ fontSize: 14, fontWeight: 400 }}>
                          {prebookingData?.personResponsbible?.name
                            ? prebookingData?.personResponsbible?.name
                            : "Unknown"}
                        </div>
                      </div>
                    </div>
                  </ScrollablePane>
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: 10,
                height: 450,
                width: "100%",
                position: "relative",
              }}
            >
              <div
                style={
                  pageWidth > 1470
                    ? {
                        backgroundColor: "#fff",
                        position: "absolute",
                        top: 16,
                        right: 18,
                        zIndex: 2,
                        width: 108,
                      }
                    : {
                        display: "flex",
                        backgroundColor: displayComment ? "#fff" : "#f1f1f1",
                        justifyContent: "center",
                      }
                }
              >
                <ToggleButton
                  toggle={displayComment}
                  onClick={() => {
                    setDisplayComment(!displayComment);
                  }}
                  LeftIcon={() => (
                    <FontAwesomeIcon
                      icon={faHistory}
                      className={
                        !displayComment
                          ? classNames.selectedIcon
                          : classNames.unSelectedIcon
                      }
                    />
                  )}
                  RightIcon={() => (
                    <FontAwesomeIcon
                      icon={faComment}
                      className={
                        displayComment
                          ? classNames.selectedIcon
                          : classNames.unSelectedIcon
                      }
                    />
                  )}
                />
              </div>
              {displayComment ? (
                <CommentContainer
                  projectId={overlayPrebookingId}
                  projectColor={color}
                />
              ) : (
                <ActivityContainer
                  projectId={overlayPrebookingId}
                  isPrebooking={true}
                />
              )}
            </div>
            {allowEdit && (
              <>
                <div className={classNames.input}>
                  <PrimaryButton
                    id="replacePrebooking"
                    text="Convert"
                    onClick={onClickRedirectToReplacePage}
                    styles={convertButtonStyles}
                  />
                </div>
                <div className={classNames.buttonBottomContainer}>
                  <Stack horizontal="true" tokens={{ childrenGap: 16 }}>
                    <DefaultButton
                      text="Edit"
                      id={`editPrebookingOnOverlay-${overlayPrebookingId}`}
                      // onClick={onClickToEditPage}
                      onClick={openPrebookingModal}
                      styles={defaultButtonStyles}
                      style={{ color: "#006CAD" }}
                    />
                    <DefaultButton
                      id="deletePrebooking"
                      text="Delete"
                      onClick={toggleDeleteConfirmation}
                      styles={defaultButtonStyles}
                      style={{ color: "#006CAD" }}
                    />
                  </Stack>
                </div>
              </>
            )}
            {deleteConfirmation && (
              <DeleteConfirmation
                target={`#deletePrebooking`}
                toggleTeaching={toggleDeleteConfirmation}
                onClickConfirm={onClickConfirmDelete}
              />
            )}
          </Fragment>
        )}
      </div>
    </Panel>
  );
};

const mapStateToProps = (state) => ({
  overlayPrebookingId: state.prebooking.overlayPrebookingId,
});

export default connect(mapStateToProps, {
  getPrebooking,
  clearOverlayPrebookingId,
  deletePrebooking,
})(PrebookingOverlay);
