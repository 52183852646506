// Import libraries
import { useBoolean } from "@fluentui/react-hooks";
import {
  DefaultButton,
  DirectionalHint,
  Dropdown,
  Image,
  Panel,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { faPencil, faSync, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

// Import redux
import {
  changeCRMOverlay,
  changeSearchHistory,
  clearSearchHistory,
  selectCRMTab,
  setBarDrawer,
  setCRMcounter,
  setCurrentUnplannedItem,
  setDarkOverlay,
  setDrawingMachineReqIndex,
  setDrawingProjectId,
  setDrawingProjectName,
  setPlanPlaceholderData,
} from "../../../redux/app/app.actions";

import { syncCRM, getLastCRMCall } from "../../../redux/user/user.actions";

import {
  getOverlapProject,
  getProjects,
} from "../../../redux/project/project.actions";

// Import utils
import { techDepartmentOptions } from "../../../utils/constants";
import {
  autoCompleteSearchStyles,
  defaultButtonStyles,
  dropDownStyles,
  primaryButtonStyles,
} from "../../../utils/theme";
import { openInNewTab } from "../../../utils/utils";

// Import styles
import { buttonStyle, crmButtonStyle } from "../calendar/ButtonStyles";

// Import components
import { TooltipForText } from "..";
import FixPositionBubble from "../FixPositionBubble";
import CRMOverlap from "./CRMOverlap";
import moment from "moment";

const classNames = mergeStyleSets({
  buttonBottomContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  closeButtonOutside: {
    zIndex: 1,
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 20,
    left: -40,
    height: 40,
    width: 40,
    background: "#fff",
    cursor: "pointer",
    boxShadow:
      "rgb(0 0 0 / 22%) -5px 0px 5px 0px, rgb(0 0 0 / 18%) -14px 0px 20px 0px",
    borderTopLeftRadius: "10%",
    borderBottomLeftRadius: "10%",
  },
  closeButtonInside: {
    width: "80%",
    height: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    selectors: {
      ":hover": {
        background: "rgba(0, 0, 0, 0.05)",
      },
    },
  },
  mainContainer: {
    marginLeft: 10,
    minHeight: "100vh",
  },
  tabContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
  },
  tab: {
    fontSize: 13,
    fontWeight: 700,
    width: 120,
    height: 35,
    borderBottom: "2px solid #DFDFDF",
    textAlign: "center",
    margin: "0 10px",
    cursor: "pointer",
    paddingTop: 10,
    selectors: {
      ":hover": {
        backgroundColor: "#99b3ff",
      },
    },
  },
  disabledTab: {
    fontSize: 13,
    fontWeight: 700,
    width: 120,
    height: 35,
    borderBottom: "2px solid #DFDFDF",
    textAlign: "center",
    margin: "0 10px",
    paddingTop: 10,
    opacity: 0.5,
  },
  active: {
    borderBottom: "4px solid #006CAD",
  },
  resultContainer: {
    marginTop: 30,
  },
  result: {
    display: "flex",
    flexDirection: "column",
    width: "97%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    marginTop: 30,
    zIndex: 3,
  },
  contentData: {
    display: "flex",
    flexDirection: "column",
  },
  contentTextContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
    minHeight: 40,
  },
  headerIcon: {
    fontSize: 19,
  },
  showHideIcon: {
    fontSize: 14,
    color: "#000",
    transition: "all 0.3s ease",
  },
  badge: {
    background: "#006CAD",
    color: "#ffffff",
    fontSize: 11,
    textAlign: "center",
    lineHeight: 20,
    height: 20,
    width: 20,
    position: "absolute",
    top: -12,
    right: -19,
    borderRadius: "50%",
  },
  headerText: {
    fontSize: 12,
    fontFamily: "Verdana",
    fontWeight: 700,
    marginLeft: 10,
    userSelect: "none",
  },
  contentText: {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: 400,
    marginLeft: 5,
    cursor: "pointer",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "75%",
  },
  contentIcon: {
    fontSize: 14,
    marginRight: 15,
    color: "#006CAD",
    cursor: "pointer",
    overflow: "visible",
  },
  contentTextNormal: {
    fontSize: 14,
    fontFamily: "Verdana",
    fontWeight: 400,
    marginLeft: 26,
  },
  contentNote: {
    display: "inline-block",
    alignItems: "center",
    fontSize: 11,
    color: "#868685",
    maxWidth: "90%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  contentSubText: {
    display: "flex",
    alignItems: "center",
    fontSize: 11,
    color: "#868685",
  },
  spin: {
    animation: "spin 2s linear infinite",
  },
});

const overlayStyle = {
  main: {
    overflow: "visible",
    minWidth: "35%",
    background: "#fff",
  },
};

const CRMOverlay = ({
  dates,
  calendarStepWidth,
  calendarStepHeight,
  allPlansData,
  refetchPlansData,
}) => {
  const queryClient = useQueryClient();

  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [unplannedData, setUnplannedData] = useState({});
  const [plannedData, setPlannedData] = useState([]);
  const { displayStaffOnPlanning } = useSelector((state) => state.machine);
  // const defaultFilter = useSelector(
  //   (state) => state?.user?.user?.defaultFilter
  // );
  const userZoomRatio = 1;
  const {
    openCRMOverlay,
    selectedCRMtab,
    searchHistory,
    syncProcess,
    syncLastCall,
  } = useSelector((state) => state.app);

  const { showInactiveProject } = useSelector((state) => state.app);

  const { machineTypeOptions, sizeGroupOptions } = useSelector(
    (state) => state.defaultData,
  );
  const { techDepartment } = useSelector((state) => state?.machine?.filter);
  const { projectNames, selectedRegion } = useSelector(
    (state) => state?.machine?.filter?.[techDepartment],
  );
  let transformedSelectedRegion = selectedRegion;
  if (selectedRegion?.includes(999))
    transformedSelectedRegion = [
      ...selectedRegion.filter((region) => region !== 999),
      201,
      202,
      203,
    ];
  const [showChooseDrawType, { toggle: toggleShowChooseDrawType }] =
    useBoolean(false);
  const [penTarget, setPenTarget] = useState(false);
  const [chooseDrawData, setChooseDrawData] = useState({
    drawType: 0,
    id: null,
    name: null,
    machineRequirementIndex: null,
  });

  let searchHistoryWithLabel =
    searchHistory
      ?.map((value, index) => ({ key: index, label: value }))
      .reverse() || [];
  if (searchHistoryWithLabel.length)
    searchHistoryWithLabel = [
      { key: 11, label: "Clear previous search" },
      ...searchHistoryWithLabel,
    ];

  const { data: allProjects, refetch: refetchProjects } = useQuery(
    ["projects"],
    () => {
      return dispatch(getProjects(null, null, showInactiveProject));
    },
  );

  // const { data: allOverlaps } = useQuery(["overlaps", techDepartment], () => {
  //   if (techDepartment) {
  //     return dispatch(getOverlapProject(techDepartment));
  //   }

  //   return [];
  // });

  const { data: allOverlaps, isLoading } = useQuery(
    ["overlaps", techDepartment],
    async () => {
      if (techDepartment) {
        const res = await getOverlapProject(techDepartment)(dispatch);
        return res;
      }

      return undefined;
    },
  );

  const baseUrl = window.location.origin;

  useEffect(() => {
    // null means either the sync process has ended or the page just loaded
    if (syncProcess === null) {
      dispatch(getLastCRMCall());

      queryClient.invalidateQueries(["projects"]);
      refetchPlansData();
      refetchProjects();
    }
  }, [dispatch, syncProcess, queryClient, refetchProjects, refetchPlansData]);

  const filterUnplannedRequirement = useCallback(
    (allPlansData, allProjects) => {
      if (allPlansData && allProjects && machineTypeOptions) {
        let result = [];
        allPlansData
          .filter((item) => !item.inactive)
          .forEach((project, index) => {
            project?.machineRequirements?.forEach((machineReq, reqIndex) => {
              if (
                !machineReq["machines"].length &&
                machineReq.techDepartment === techDepartment &&
                transformedSelectedRegion?.includes(project["section"]) &&
                !machineReq.inactive &&
                project.projectName
                  .toLowerCase()
                  .includes(projectNames?.toLowerCase() || "")
              ) {
                const projectData = allProjects.find(
                  (item) => item.projectId === project.projectId,
                );
                const machineType = machineTypeOptions
                  .find((item) => item.id === machineReq.techDepartment)
                  ?.options.find((item) => item.key === machineReq.machineType);
                const machineSizeGroup = sizeGroupOptions.find(
                  (item) => item.key === machineReq.sizeGroup,
                );

                let isStartDateValid = moment(machineReq.start).isValid();
                let isEndDateValid = moment(machineReq.end).isValid();
                let stateCode = machineReq.stateCode;
                let resourceRequestCount = machineReq.resourceRequestCount;
                const start = moment(machineReq.start).format("DD-MM-YYYY");
                const end = moment(machineReq.end).format("DD-MM-YYYY");

                !stateCode &&
                  resourceRequestCount > 0 &&
                  projectData &&
                  result.push({
                    id: project.projectId,
                    machineType: machineType?.key,
                    machineSizeGroup: machineSizeGroup?.key,
                    index: reqIndex,
                    name: `${project.projectNo} ${project.projectName}`,
                    subText: `${machineReq.crmActivities} ${
                      isStartDateValid && isEndDateValid
                        ? `• ${start} - ${end}`
                        : ""
                    }`,
                    link: `${baseUrl}/add-machine-requirements/${
                      projectData.crmProjectId || projectData.projectId
                    }`,
                    start: machineReq.start,
                    end: machineReq.end,
                    note: machineReq.crmDescription,
                    resourceRequestName: machineReq.resourceRequestName,
                  });
              }
            });
          });
        return result;
      } else return [];
    },
    [
      baseUrl,
      machineTypeOptions,
      projectNames,
      sizeGroupOptions,
      techDepartment,
      transformedSelectedRegion,
    ],
  );

  const filterPlannedProjects = useCallback(
    (allPlansData) => {
      const filteredPlansData = allPlansData
        .filter(
          (plan) =>
            plan.projectNo &&
            plan.projectName &&
            plan.section &&
            !plan["inactive"] &&
            plan["machineRequirements"].some(
              (requirement) =>
                requirement.machines.length &&
                requirement.techDepartment === techDepartment,
            ) &&
            plan.projectName
              .toLowerCase()
              .includes(inputValue?.toLowerCase()) &&
            inputValue,
        )
        .slice(0, 10)
        .map((item) => ({
          id: item.projectId,
          name: `${item.projectNo} ${item.projectName}`,
          link: `${baseUrl}/add-machine-requirements/${item.projectId}`,
        }));
      return filteredPlansData;
    },
    [baseUrl, inputValue, techDepartment],
  );

  useEffect(() => {
    if (displayStaffOnPlanning) {
      dispatch(selectCRMTab("planned"));
    }
    if (allProjects && allPlansData) {
      if (selectedCRMtab === "unplanned") {
        const hasUnplannedRequirement = filterUnplannedRequirement(
          allPlansData,
          allProjects,
        );

        dispatch(setCRMcounter(hasUnplannedRequirement.length));
        setUnplannedData({
          hasRequirement: hasUnplannedRequirement,
        });
      } else {
        const plannedProjects = filterPlannedProjects(allPlansData);
        setPlannedData(plannedProjects);
      }
    }
  }, [
    allProjects,
    allPlansData,
    openCRMOverlay,
    techDepartment,
    inputValue,
    selectedCRMtab,
    filterUnplannedRequirement,
    dispatch,
    displayStaffOnPlanning,
    filterPlannedProjects,
  ]);

  const onInputChange = (input, event) => {
    if (event.action === "input-change") {
      setInputValue(input);
    } else if (inputValue?.length) {
      dispatch(changeSearchHistory(inputValue));
    }
  };

  const onSelectItem = (item) => {
    if (item?.label === "Clear previous search") {
      dispatch(clearSearchHistory());
      setInputValue("");
    } else {
      setInputValue(item?.label);
    }
  };

  const drawPlan = async (item) => {
    dispatch(changeCRMOverlay(false));
    dispatch(setDrawingProjectId(item.id));
    dispatch(setBarDrawer("plan"));
  };

  // Staff vacation Udlån
  const drawStaffVacation = (projectName) => {
    dispatch(changeCRMOverlay(false));
    dispatch(setDrawingProjectName(projectName));
    dispatch(setBarDrawer("staffVacation"));
  };

  const dragPlan = async (item) => {
    dispatch(changeCRMOverlay(false));

    const id = `${item.id}-${item.index}`;

    const elementNode = document.getElementById(id);
    const darkOverlay = document.getElementById("darkOverlay");
    const transform = elementNode.style.transform.match(/\d+/g);

    dispatch(
      setPlanPlaceholderData({
        active: true,
        target: item,
        machineType: item.machineType,
        machineSizeGroup: item.machineSizeGroup,
        calendarStepWidth,
        calendarStepHeight,
        start: item.start,
        end: item.end,
      }),
    );
    dispatch(
      setCurrentUnplannedItem({
        x: parseInt(transform[0]),
        y: parseInt(transform[1]),
        width: elementNode.offsetWidth,
        barId: id,
      }),
    );
    let xPosition;
    let yPosition;
    let x;
    let y;
    // Handle move bar along with mouse
    ["mousemove"].forEach((event) => {
      window.addEventListener(event, function handleMouseMove(e) {
        xPosition = !isNaN(
          e.pageX / userZoomRatio - 321 - elementNode.offsetWidth / 2,
        )
          ? e.pageX / userZoomRatio - 321 - elementNode.offsetWidth / 2
          : xPosition; // 301 is starting point of y axis, minus additional 10 to get closer to the bar
        yPosition = !isNaN(e.pageY / userZoomRatio - 312)
          ? e.pageY / userZoomRatio - 312
          : yPosition; // 354 is starting point of x axis
        x = Math.floor(xPosition / calendarStepWidth) * calendarStepWidth;
        y = Math.floor(yPosition / calendarStepHeight) * calendarStepHeight;
        if (darkOverlay.firstElementChild) {
          elementNode.style.transform = `translate(${x}px, ${y}px)`;
          elementNode.style.zIndex = 100003;
          elementNode.style.opacity = 1;
          elementNode.style.pointerEvents = "none";
        } else {
          elementNode.style.pointerEvents = "unset";
          this.removeEventListener(event, handleMouseMove, false);
          darkOverlay.style.cursor = "default";
        }
      });
      setTimeout(() => {
        darkOverlay.style.cursor = `url(${window.location.origin}/img/arrows-alt.svg), auto`;
      }, 100);
    });

    // Handle save prompt when left click
    window.addEventListener("click", function handleMouseClick(e) {
      if (darkOverlay.firstElementChild) {
        dispatch(setDarkOverlay(false));
        dispatch(setPlanPlaceholderData({}));
        window.postMessage({
          text: "Update unplanned plan data",
          projectId: item.id,
          requirementIndex: item.index,
          newX: x,
          newY: y,
        });
      } else {
        this.removeEventListener("click", handleMouseClick, false);
      }
    });
  };

  const onDrawStaffPlan = () => {
    toggleShowChooseDrawType();
    if (chooseDrawData.drawType === 0) {
      drawStaffVacation(chooseDrawData.name);
    } else {
      drawPlan(chooseDrawData);
      dispatch(
        setDrawingMachineReqIndex(chooseDrawData.machineRequirementIndex),
      );
      dispatch(setDrawingProjectName(chooseDrawData.name));
      dispatch(setDrawingProjectId(chooseDrawData.id));
    }
  };

  const formatOptionLabel = ({ text, subText }) => (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div>{text}</div>
      <div style={{ color: "#808080", fontSize: 10 }}>{subText}</div>
    </div>
  );

  const getOverlapsCount = () => {
    if (!allOverlaps) {
      return null;
    }

    const keys = Object.keys(allOverlaps);

    let count = 0;

    keys.forEach((key) => {
      count += allOverlaps[key].data.length;
    });

    return count === 0 ? null : count;
  };

  const getUnplannedData = () => {
    if (selectedCRMtab !== "unplanned") {
      return <></>;
    }

    if (allPlansData) {
      return (
        <div className={classNames.resultContainer}>
          {Object.keys(unplannedData).map((key, index) => (
            <div key={index} className={classNames.result}>
              <div>
                <div
                  id={`contentData-${key}`}
                  className={classNames.contentData}
                >
                  {unplannedData[key].map((item, index) => (
                    <div
                      key={item.id + "-" + index}
                      className={classNames.contentTextContainer}
                      style={{
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        marginBottom: item.subText || item.note ? 10 : 0,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPencil}
                          onClick={() => {
                            drawPlan(item);
                            dispatch(setDrawingMachineReqIndex(item.index));
                          }}
                          className={classNames.contentIcon}
                        />
                        <Image
                          src={`./img/bar.svg`}
                          className={classNames.contentIcon}
                          onClick={() => dragPlan(item)}
                        />
                        <span
                          className={classNames.contentText}
                          onClick={() => openInNewTab(item.link)}
                          style={{ flexGrow: 1 }}
                        >
                          {item.name}
                          <span className={classNames.contentSubText}>
                            {item.subText}
                          </span>
                          <span className={classNames.contentNote}>
                            {item.resourceRequestName}
                          </span>
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      <Spinner
        style={{ marginTop: "30%" }}
        size={SpinnerSize.large}
        label="Loading..."
        labelPosition="right"
      />;
    }
  };

  return (
    <Panel isOpen={openCRMOverlay} hasCloseButton={false} styles={overlayStyle}>
      <div className={classNames.closeButtonOutside}>
        <div
          className={classNames.closeButtonInside}
          onClick={() => dispatch(changeCRMOverlay(false))}
        >
          <FontAwesomeIcon
            icon={faTimes}
            style={{ color: "#006CAD", fontSize: 30 }}
          />
        </div>
      </div>
      <div className={classNames.mainContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: "4px",
            }}
          >
            <TooltipForText
              text={"CRM Sync"}
              noWidth
              direction={DirectionalHint.leftCenter}
            >
              <DefaultButton
                id="crmSync"
                styles={{
                  ...crmButtonStyle(false, "#fff"),
                  root: {
                    ...crmButtonStyle(false, "#fff").root,
                  },
                }}
                onClick={() => {
                  dispatch(syncCRM());
                }}
              >
                <FontAwesomeIcon
                  icon={faSync}
                  className={`${classNames.unSelectedIcon} ${
                    syncProcess && classNames.spin
                  }`}
                  style={{ marginRight: 8 }}
                />
                Sync CRM
              </DefaultButton>
            </TooltipForText>
            <div>
              {syncLastCall && (
                <>
                  <p style={{ margin: 5 }}>Last sync:</p>
                  <p
                    style={{
                      color: "#006CAD",
                      fontWeight: 400,
                      fontSize: 12,
                      margin: 5,
                    }}
                  >
                    {syncLastCall}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={classNames.tabContainer}>
          <Pivot
            selectedKey={selectedCRMtab}
            onLinkClick={(item) => {
              item?.props?.itemKey &&
                !displayStaffOnPlanning &&
                dispatch(selectCRMTab(item?.props?.itemKey));
            }}
          >
            <PivotItem headerText="MANGLER" itemKey={"unplanned"} />
            <PivotItem headerText="PLANLAGTE" itemKey={"planned"} />
            <PivotItem
              itemCount={getOverlapsCount()}
              headerText="OVERLAP"
              itemKey={"overlap"}
            />
          </Pivot>
        </div>
        {getUnplannedData()}
        {selectedCRMtab === "planned" ? (
          <>
            <div className={classNames.searchInput}>
              <div style={{ marginTop: 20 }}>
                <Select
                  placeholder="Search"
                  closeMenuOnSelect={true}
                  options={searchHistoryWithLabel}
                  isClearable={true}
                  isSearchable={true}
                  onInputChange={onInputChange}
                  onChange={onSelectItem}
                  className={classNames.input}
                  styles={autoCompleteSearchStyles}
                  value={inputValue && { label: inputValue }}
                  getOptionValue={(option) => option.label}
                  noOptionsMessage={() => null}
                />
              </div>
            </div>
            <div style={{ marginTop: 20 }} className={classNames.contentData}>
              {plannedData.map((item, index) => (
                <div
                  key={item.id + "-" + index}
                  className={classNames.contentTextContainer}
                  style={{
                    display: "flex",
                  }}
                >
                  <FontAwesomeIcon
                    id={`pen-${item.id + "-" + index}`}
                    icon={faPencil}
                    onClick={() => {
                      if (displayStaffOnPlanning) {
                        toggleShowChooseDrawType();
                        setChooseDrawData((value) => ({
                          ...value,
                          id: item.id,
                          name: item.name,
                        }));
                        setPenTarget(`pen-${item.id + "-" + index}`);
                      } else drawPlan(item);
                    }}
                    className={classNames.contentIcon}
                  />
                  <span
                    className={classNames.contentText}
                    onClick={() => openInNewTab(item.link)}
                  >
                    {item.name}
                  </span>
                </div>
              ))}
            </div>
          </>
        ) : (
          <></>
        )}
        {selectedCRMtab === "overlap" && allOverlaps ? (
          <div className={classNames.resultContainer}>
            <CRMOverlap data={allOverlaps} />
          </div>
        ) : (
          <></>
        )}
      </div>
      {showChooseDrawType && penTarget && (
        <FixPositionBubble
          calloutProps={{
            directionalHint: DirectionalHint.leftCenter,
            calloutWidth: 300,
          }}
          onDismiss={toggleShowChooseDrawType}
          target={`#${penTarget}`}
          mainPositionRight={userZoomRatio === 0.5 ? "1328px" : null}
        >
          <div>
            <Dropdown
              label="TYPE"
              selectedKey={chooseDrawData.drawType}
              onChange={(event, item) =>
                setChooseDrawData((value) => ({ ...value, drawType: item.key }))
              }
              placeholder="TYPE"
              options={[
                { key: 0, text: "Staff vacation Udlån" },
                { key: 1, text: "Assign staff to plan" },
              ]}
              styles={{
                ...dropDownStyles,
                dropdown: {
                  ...dropDownStyles.dropdown,
                  width: 250,
                  marginBottom: 10,
                },
                title: { ...dropDownStyles.title, backgroundColor: "white" },
              }}
            />
            {chooseDrawData.drawType === 1 && (
              <Dropdown
                label="MACHINE REQUIREMENTS"
                selectedKey={chooseDrawData.machineRequirementIndex}
                onChange={(event, item) =>
                  setChooseDrawData((value) => ({
                    ...value,
                    machineRequirementIndex: item.key,
                  }))
                }
                placeholder="Choose machine requirement"
                options={allPlansData
                  .find((item) => item.projectId === chooseDrawData.id)
                  .machineRequirements.filter(
                    (machineReq) =>
                      machineReq.machines.length &&
                      machineReq.techDepartment === techDepartment,
                  )
                  .map((machineReq, index) => {
                    const techDepartmentName =
                      techDepartmentOptions.find(
                        (item) => item.key === machineReq.techDepartment,
                      )?.text || "Unknown";
                    const machineType =
                      machineTypeOptions
                        .find((item) => item.id === machineReq.techDepartment)
                        ?.options.find(
                          (item) => item.key === machineReq.machineType,
                        )?.text || "Unknown";
                    const machineSizeGroup = sizeGroupOptions.find(
                      (item) => item.key === machineReq.sizeGroup,
                    );
                    return {
                      key: index,
                      text:
                        machineReq.machines[0]?.machineName ||
                        "No machine added",
                      subText: `${techDepartmentName} • ${machineType} • ${
                        machineSizeGroup?.text || "Unknown"
                      }`,
                    };
                  })}
                styles={{
                  ...dropDownStyles,
                  dropdown: {
                    ...dropDownStyles.dropdown,
                    width: 250,
                    marginBottom: 10,
                  },
                  title: { ...dropDownStyles.title, backgroundColor: "white" },
                }}
                onRenderOption={formatOptionLabel}
              />
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <PrimaryButton
                disabled={
                  chooseDrawData.drawType === 1 &&
                  chooseDrawData.machineRequirementIndex === null
                }
                text="Draw"
                onClick={onDrawStaffPlan}
                styles={primaryButtonStyles}
              />
            </div>
          </div>
        </FixPositionBubble>
      )}
    </Panel>
  );
};

export default CRMOverlay;
