import React, { useMemo } from 'react';
import Select from 'react-select';
import { PrimaryButton } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Label } from '@fluentui/react/lib/Label';
import { primaryButtonStyles, inputStyles, customAutoCompleteStyles } from '../../../utils/theme';
import { TextField } from '@fluentui/react/lib/TextField';
import { CustomInputErrorComponent } from '../../common';
import { Spinner, SpinnerSize } from '@fluentui/react';
import CalendarCustom from '../../common/CalendarCustom';
import { useSelector, useDispatch } from 'react-redux';
import CalendarSchedule from '../../common/calendar/CalendarSchedule';
import { handleBeforeSubmitPeriod } from '../../../utils/utils';

const classNames = mergeStyleSets({
    selector: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '1px solid rgb(159, 159, 159)',
        borderRadius: 4,
        height: 46,
        width: 318,
        marginTop: 24,
        selectors: {
            ':hover': {
                border: '1px solid rgb(42, 42, 42)',
            },
        },
    },
    container: {
        background: '#fff',
        marginBottom: 24,
    },
    label: {
        fontSize: 29,
        lineHeight: 29,
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        padding: '100px 0px 50px 0px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: 320,
        margin: '0 auto',
    },
    input: {
        marginTop: 24,
        background: '#fff',
    },
    back: {
        width: 92,
    },
    calendarClassName: {
        zIndex: 2,
    },
});

const PrebookingForm = ({
    values: { id, prebookingName, personResponsbible = null, machineId, period = [new Date(), new Date()] },
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleChange,
    isValid,
    isLoadingMachines,
    machineData,
    bookedSchedules,
    targetInfo,
}) => {
    const dispatch = useDispatch();
    const techDepartment = useSelector((state) => state?.machine?.filter.techDepartment);
    const machineOptions = useMemo(
        () =>
            machineData
                ? machineData
                      .filter((machine) => machine.techDepartments.includes(techDepartment))
                      .map((machine) => ({ key: machine.machineId, label: machine.machineName }))
                : [],
        [machineData]
    );
    const setPeriod = (newPeriod) => {
        setFieldValue('period', newPeriod);
    };
    const onSubmit = () => {
        const overlap = handleBeforeSubmitPeriod(bookedSchedules, targetInfo, period, dispatch);
        if (!overlap) handleSubmit();
    };
    
    return (
        <form className={classNames.container}>
            <div className={classNames.content}>
                {isLoadingMachines ? (
                    <Spinner size={SpinnerSize.large} label='Loading...' />
                ) : (
                    <>
                        <TextField
                            label='HOVEDSAGNAVN'
                            styles={{ ...inputStyles, fieldGroup: { ...inputStyles.fieldGroup, background: 'none' } }}
                            value={prebookingName || ''}
                            name='prebookingName'
                            placeholder='PREBOOKING NAME'
                            className={classNames.input}
                            onChange={handleChange}
                            errorMessage={touched.prebookingName || errors.prebookingName ? errors.prebookingName : ''}
                        />
                        <TextField
                            label='RESPONSIBLE PERSON'
                            styles={{ ...inputStyles, fieldGroup: { ...inputStyles.fieldGroup, background: 'none' } }}
                            value={personResponsbible || ''}
                            name='personResponsbible'
                            placeholder='PERSON RESPONSIBLE'
                            className={classNames.input}
                            onChange={handleChange}
                        />
                        <div
                            style={{
                                fontFamily: 'Verdana',
                                color: '#000',
                                fontSize: 11,
                                fontWeight: 'bold',
                                transform: 'translate(9px, 41px)',
                                marginTop: -13,
                                zIndex: 2,
                            }}
                        >
                            MASKINNAVN
                        </div>
                        <Select
                            placeholder='VÆLG'
                            name='machineId'
                            closeMenuOnSelect={true}
                            defaultValue={machineOptions.find((item) => item.key === machineId)}
                            options={machineOptions}
                            isClearable={true}
                            isSearchable={true}
                            onChange={(item) => setFieldValue('machineId', item?.key)}
                            className={classNames.input}
                            styles={customAutoCompleteStyles(errors.machineId, true)}
                            getOptionValue={(option) => option.label}
                        />
                        <CustomInputErrorComponent errorMessage={touched.machineId || errors.machineId ? errors.machineId : ''} />
                        {typeof machineId !== 'undefined' && (
                            <CalendarCustom period={period} setPeriod={setPeriod} bookedSchedules={bookedSchedules} targetInfo={targetInfo}/>
                        )}
                        <CustomInputErrorComponent errorMessage={machineId && (touched.period || errors.period) ? errors.period : ''} />
                    </>
                )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '20px 0 -15px 0' }}>
                <PrimaryButton disabled={!isValid} text='Save' onClick={onSubmit} styles={primaryButtonStyles} />
            </div>
        </form>
    );
};

export default PrebookingForm;
