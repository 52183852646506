import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Label } from "@fluentui/react/lib/Label";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { IconButton, PrimaryButton, Stack } from "@fluentui/react";
import { formatDate, getWeeksOfYear } from "../../utils/utils";
import { stationOptions } from "../../utils/constants";
import { setFilter } from "../../redux/machines/machines.actions";
import { toggleSelectorItem } from "../../redux/machines/machines.utils";
import { primaryButtonStyles } from "../../utils/theme";

const commonStationSelectorButton = {
  height: 20,
  borderRadius: 24,
  color: "#ffffff",
  background: "#006CAD",
  padding: "4px 16px",
  fontSize: 14,
  lineHeight: 20,
  margin: "8px 0px",
  cursor: "pointer",
  selectors: {
    ":hover": {
      color: "#DBDBDB",
    },
  },
};

export const outlinedDropDownStyles = {
  dropdownItem: {
    height: 40,
  },
  dropdownItemSelected: {
    height: 40,
  },
  caretDown: {
    marginTop: 5,
    marginRight: 10,
    color: "#006CAD",
  },
  title: {
    height: 40,
    lineHeight: 40,
    borderRadius: 4,
    borderColor: "#006CAD",
    color: "#006CAD",
  },
  dropdown: {
    width: 180,
  },
};

const classNames = mergeStyleSets({
  container: {
    minHeight: "100vh",
    background: "#F1F1F1",
  },
  goBack: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    backgroundColor: "transparent",
    borderRadius: "50%",
    selectors: {
      ":hover": {
        backgroundColor: "rgb(232, 232, 232)",
      },
    },
  },
  icon: {
    color: "#006CAD",
    fontSize: "30px",
  },
  label: {
    fontSize: 29,
    lineHeight: 29,
    marginLeft: 134,
  },
  yearLabel: {
    fontSize: 19,
    lineHeight: 19,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 116px 118px",
  },
  addNew: {
    width: 1203,
    margin: "0px auto 78px auto",
    // alignItems: 'center'
  },
  input: {
    marginBottom: 24,
  },
  pageInnerContainer: {
    width: 920,
    margin: "0 auto",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 1200,
    margin: "0px auto",
  },
  selectWeekText: {
    fontSize: 11,
    fontWeight: 700,
    marginBottom: 38,
  },
  weekContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  weekItem: {
    color: "#000",
    cursor: "pointer",
    zIndex: 2,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 68,
    height: 68,
    borderRight: "1px solid #DBDBDB",
    selectors: {
      ":hover": {
        color: "#fff",
      },
    },
  },
  circleCurrentWeek: {
    position: "absolute",
    border: "2px solid #006CAD",
    left: 0,
    right: 0,
    marginLeft: "auto",
    marginRight: "auto",
    height: 26,
    width: 26,
    borderRadius: "50%",
  },
  button: [
    commonStationSelectorButton,
    {
      color: "#868685",
      background: "#DFDFDF",
      selectors: {
        ":hover": {
          color: "#DFDFDF",
          background: "#868685",
        },
      },
    },
  ],
  buttonSelected: commonStationSelectorButton,
  radioWrapper: {
    boxShadow: "none",
    margin: "0px",
    padding: "0px 15px 0px 8px",
    boxSizing: "border-box",
    backgroundColor: "rgb(241, 241, 241)",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgb(0, 108, 173)",
    borderRadius: "4px",
    cursor: "pointer",
    display: "block",
    width: "180px",
    height: "40px",
    position: "relative",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "rgb(0, 108, 173)",
  },
  radio: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Verdana",
    fontSize: "14px",
  },
  radioP: {
    margin: 0,
  },
  radioSvg: {
    fontSize: "20px",
    fontWeight: "lighter",
    color: "#006CAD",
  },
  radioContent: {
    display: "flex",
    flexFlow: "column nowrap",
    fontSize: "20px",
    padding: "10px",
    marginTop: "5px",
    background: "#ebebeb",
    borderRadius: "7px",
  },
  radioContentA: {
    outline: "transparent",
    position: "relative",
    fontFamily: "Verdana",
    fontSize: "14px",
    boxSizing: "border-box",
    border: "1px solid transparent",
    display: "flex",
    textDecoration: "none",
    textAlign: "left",
    cursor: "pointer",
    padding: "0px 8px",
    borderRadius: "0px",
    height: "40px",
    color: "rgb(42, 42, 42)",
    backgroundColor: "transparent",
    alignItems: "center",
    width: "100%",
    minHeight: "36px",
    lineHeight: "20px",
    overflowWrap: "break-word",
    selectors: {
      ":hover": {
        color: "rgb(0, 108, 173)",
      },
    },
  },
  btnDisabled: {
    boxShadow: "none",
    margin: "0px",
    padding: "0px 15px 0px 8px",
    boxSizing: "border-box",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "4px",
    cursor: "pointer",
    display: "block",
    width: "180px",
    height: "40px",
    position: "relative",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "rgb(42, 42, 42)",
    borderColor: "rgb(232, 232, 232)",
    backgroundColor: "rgb(232, 232, 232)",
  },
  radioSvgDisabled: {
    fontSize: "20px",
    fontWeight: "lighter",
    color: "rgb(42, 42, 42)",
  },
});

const SelectWeek = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [originalWeeks, setOriginalWeeks] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedWeeks, setSelectedWeeks] = useState({
    start: null,
    end: null,
  });
  const { techDepartment } = useSelector((state) => state?.machine?.filter);
  const { techArea } = useSelector(
    (state) => state?.machine?.filter?.[techDepartment]
  );

  useEffect(() => {
    const currentYearWeeks = getWeeksOfYear();
    const nextYearWeeks = getWeeksOfYear(currentYear + 1);
    const weeks = [...currentYearWeeks, ...nextYearWeeks];
    setWeeks(weeks);
    setOriginalWeeks(weeks);
  }, [currentYear]);

  useEffect(() => {
    const tempWeeks = JSON.parse(JSON.stringify(originalWeeks));
    if (selectedWeeks.start !== null && selectedWeeks.end !== null) {
      for (let i = selectedWeeks.start; i <= selectedWeeks.end; i++) {
        if (i === selectedWeeks.start) {
          tempWeeks[i].start = true;
        } else if (i === selectedWeeks.end) {
          tempWeeks[i].end = true;
        }
        tempWeeks[i].isSelected = true;
      }
    } else if (selectedWeeks.start !== null) {
      tempWeeks[selectedWeeks.start].isSelected = true;
    }
    setWeeks(tempWeeks);
  }, [originalWeeks, selectedWeeks]);

  const onClickSelectWeek = (index) => {
    let end = null;
    if (
      (selectedWeeks.start !== null && selectedWeeks.end !== null) ||
      selectedWeeks.start === null
    ) {
      setSelectedWeeks({ start: index, end });
    } else {
      if (selectedWeeks.start > index) {
        setSelectedWeeks({ start: index, end: selectedWeeks.start });
      } else if (selectedWeeks.start < index) {
        setSelectedWeeks({ start: selectedWeeks.start, end: index });
      }
    }
  };

  const onSelectPrinting = () => {
    let startTemp = originalWeeks[selectedWeeks.start].dates[0];
    let endTemp = originalWeeks[selectedWeeks.start].dates[6];

    if (selectedWeeks.end) {
      endTemp = originalWeeks[selectedWeeks.end].dates[6];
    }

    const [start, end] = formatDate([
      new Date(startTemp.year, startTemp.month, startTemp.date),
      new Date(endTemp.year, endTemp.month, endTemp.date),
    ]);

    const startWeek =
      selectedWeeks.start + 1 > 52
        ? selectedWeeks.start - 51
        : selectedWeeks.start + 1;
    const endWeek = selectedWeeks.end
      ? selectedWeeks.end + 1 > 52
        ? selectedWeeks.end - 51
        : selectedWeeks.end + 1
      : startWeek;

    let appendText = `?from=${start}&to=${end}&fromWeek=${startWeek}&toWeek=${endWeek}&techDepartment=${techDepartment}`;
    const techAreaParams = techArea.map((techArea) => `${techArea}`).join(",");
    if (techAreaParams) {
      appendText += `&techArea=${techAreaParams}`;
    }

    const win = window.open(`/print${appendText}`, "_blank");
    win.focus();

    // switch(item.key) {
    //     case PRINTING_PAGE.SUPERVISOR:
    //         return <a key={index} href={`/print-supervisors${appendText}`} target="_blank" className={classNames.radioContentA} alt="Machines">{item.text}</a>
    //     case PRINTING_PAGE.PROJECTS:
    //         return <a key={index} href={`/print-projects${appendText}`} target="_blank" className={classNames.radioContentA} alt="Machines">{item.text}</a>
    //     case PRINTING_PAGE.MACHINES:
    //         return <a key={index} href={`/print-machines${appendText}`} target="_blank" className={classNames.radioContentA} alt="Machines">{item.text}</a>
    //     default:
    //         break;
    // }
  };

  return (
    <div className={classNames.container} id="abc">
      <div className={classNames.header}>
        <IconButton
          style={{
            position: "absolute",
            left: document.documentElement.offsetWidth < 1100 ? 40 : 100,
          }}
          iconProps={{ iconName: "ChromeBack" }}
          onClick={() => history.goBack()}
        />
        <Label className={classNames.label}>Udskriv oversigt</Label>
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <PrimaryButton
            styles={primaryButtonStyles}
            text="Generer oversigt"
            onClick={onSelectPrinting}
            disabled={selectedWeeks.start === null}
          />
        </Stack>
      </div>
      <div className={classNames.content}>
        <div className={classNames.selectWeekText}>
          <Stack horizontal="true" tokens={{ childrenGap: 8 }}>
            {techDepartment &&
              stationOptions[techDepartment]["options"].map((option) => (
                <div
                  key={option["key"]}
                  className={
                    techArea.includes(option["key"])
                      ? classNames.buttonSelected
                      : classNames.button
                  }
                  onClick={() =>
                    dispatch(
                      setFilter({
                        [techDepartment]: {
                          techArea: toggleSelectorItem(techArea, option["key"]),
                        },
                      })
                    )
                  }
                >
                  {option["value"]}
                </div>
              ))}
          </Stack>
        </div>
        <div className={classNames.selectWeekText}>VÆLG UGER</div>
        <div
          style={{
            display: "flex",
            width: 1200,
            justifyContent: "space-around",
            marginBottom: 20,
            marginRight: 40,
          }}
        >
          <div className={classNames.yearLabel}>{currentYear}</div>
          <div className={classNames.yearLabel}>{currentYear + 1}</div>
        </div>
        <div style={{ display: "flex" }}>
          <div className={classNames.weekContainer}>
            {weeks
              .filter((week) => week.year === currentYear)
              .map((week, index) => {
                if (week.start) {
                  return (
                    <div
                      key={index}
                      className={classNames.weekItem}
                      onClick={() => {
                        onClickSelectWeek(index);
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#006CAD",
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          borderTopLeftRadius: "50%",
                          borderBottomLeftRadius: "50%",
                        }}
                      >
                        {index + 1}
                      </div>
                    </div>
                  );
                } else if (week.end) {
                  return (
                    <div
                      key={index}
                      className={classNames.weekItem}
                      onClick={() => {
                        onClickSelectWeek(index);
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#006CAD",
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                        }}
                      >
                        {index + 1}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className={classNames.weekItem}
                      style={{
                        backgroundColor: week.isSelected
                          ? "#006CAD"
                          : "transparent",
                        color: week.isSelected ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        onClickSelectWeek(index);
                      }}
                    >
                      {index + 1}
                      {week.currentWeek && (
                        <div className={classNames.circleCurrentWeek} />
                      )}
                    </div>
                  );
                }
              })}
          </div>
          <div className={classNames.weekContainer}>
            {weeks
              .filter((week) => week.year === currentYear + 1)
              .map((week, index) => {
                const key = index + 52;
                if (week.start) {
                  return (
                    <div
                      key={index}
                      className={classNames.weekItem}
                      onClick={() => {
                        onClickSelectWeek(key);
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#006CAD",
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          borderTopLeftRadius: "50%",
                          borderBottomLeftRadius: "50%",
                        }}
                      >
                        {index + 1}
                      </div>
                    </div>
                  );
                } else if (week.end) {
                  return (
                    <div
                      key={index}
                      className={classNames.weekItem}
                      onClick={() => {
                        onClickSelectWeek(key);
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#006CAD",
                          height: "100%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          borderTopRightRadius: "50%",
                          borderBottomRightRadius: "50%",
                        }}
                      >
                        {index + 1}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      className={classNames.weekItem}
                      style={{
                        backgroundColor: week.isSelected
                          ? "#006CAD"
                          : "transparent",
                        color: week.isSelected ? "#fff" : "#000",
                      }}
                      onClick={() => {
                        onClickSelectWeek(key);
                      }}
                    >
                      {index + 1}
                      {week.currentWeek && (
                        <div className={classNames.circleCurrentWeek} />
                      )}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectWeek;
