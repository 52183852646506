import { PrimaryButton, TextField } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { reasonVacationStaffOptions } from "../../../utils/constants";
import {
  customAutoCompleteStyles,
  dropDownStyles,
  inputStyles,
  primaryButtonStyles,
} from "../../../utils/theme";
import { handleBeforeSubmitPeriod, hasEditRight } from "../../../utils/utils";
import { CustomInputErrorComponent } from "../../common";
import CalendarCustom from "../../common/CalendarCustom";

const classNames = mergeStyleSets({
  container: {
    marginBottom: 24,
  },
  label: {
    fontSize: 29,
    lineHeight: 29,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 92px 118px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: 320,
    margin: "0 auto",
  },
  input: {
    marginTop: 24,
  },
  back: {
    width: 88,
  },
  selector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(159, 159, 159)",
    borderRadius: 4,
    height: 46,
    width: 318,
    marginTop: 24,
    selectors: {
      ":hover": {
        border: "1px solid rgb(42, 42, 42)",
      },
    },
  },
});

const onRenderOption = (option) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ flexBasis: "80%", width: "80%%" }}>{option.text}</div>
      {/* <div style={{ flexBasis: '20%', width: '25px', height: '50%', backgroundColor: `rgb(${option.color})` }}></div> */}
    </div>
  );
};

const VacationStaffForm = ({
  values: { staffIndex, reason, notes, period = [new Date(), new Date()] },
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  isValid,
  userData,
  bookedSchedules,
  targetInfo,
}) => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const userId = useSelector((state) => state?.user?.user?.userId);
  const staffIndexOptions = useMemo(
    () =>
      userData.map((staff, index) => ({
        key: index,
        label: `${staff.firstName} ${staff.lastName ?? ""}`,
        id: staff.userId,
      })),
    [userData]
  );
  if (userId && !allowEdit && !staffIndex) {
    staffIndex = staffIndexOptions.find((staff) => staff.id === userId).key;
    setFieldValue("staffIndex", staffIndex);
  }

  const setPeriod = (newPeriod) => {
    setFieldValue("period", newPeriod);
  };

  const onSubmit = () => {
    const overlap = handleBeforeSubmitPeriod(
      bookedSchedules,
      targetInfo,
      period,
      dispatch
    );
    if (!overlap) handleSubmit();
  };

  return (
    <form className={classNames.container}>
      <div className={classNames.content}>
        <div
          style={{
            fontFamily: "Verdana",
            fontSize: 11,
            fontWeight: "bold",
            transform: "translate(9px, 41px)",
            zIndex: 2,
          }}
        >
          BEMANDNING
        </div>
        <Select
          placeholder="VÆLG"
          name="staff"
          closeMenuOnSelect={true}
          defaultValue={staffIndexOptions.find(
            (item) => item.key === staffIndex
          )}
          options={staffIndexOptions}
          isClearable={true}
          isSearchable={true}
          onChange={(item) => setFieldValue("staffIndex", item?.key)}
          className={classNames.input}
          styles={customAutoCompleteStyles(errors.staffIndex, true)}
          getOptionValue={(option) => option.label}
          isDisabled={!allowEdit}
        />
        <CustomInputErrorComponent
          errorMessage={
            allowEdit &&
            (touched.staffIndex || errors.staffIndex ? errors.staffIndex : "")
          }
        />
        <Dropdown
          label="ÅRSAG"
          selectedKey={reason}
          name="Reason"
          onChange={(event, item) => setFieldValue("reason", item.key)}
          placeholder="ÅRSAG"
          options={reasonVacationStaffOptions}
          styles={{
            ...dropDownStyles,
            title: { ...dropDownStyles.title, backgroundColor: "white" },
          }}
          className={classNames.input}
          onRenderOption={onRenderOption}
          errorMessage={touched.reason || errors.reason ? errors.reason : ""}
        />
        {reason === 2 && (
          <TextField
            label="HOVEDSAGNAVN"
            styles={{
              ...inputStyles,
              fieldGroup: { ...inputStyles.fieldGroup, background: "none" },
            }}
            value={notes}
            name="Notes"
            placeholder="HOVEDSAGNAVN"
            className={classNames.input}
            onChange={(event, value) => setFieldValue("notes", value)}
            errorMessage={touched.notes || errors.notes ? errors.notes : ""}
          />
        )}
        {typeof staffIndex !== "undefined" && (
          <CalendarCustom
            period={period}
            setPeriod={setPeriod}
            bookedSchedules={bookedSchedules}
            targetInfo={targetInfo}
          />
        )}
        <CustomInputErrorComponent
          errorMessage={errors.period && staffIndex ? errors.period : ""}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "20px 0 -15px 0",
        }}
      >
        <PrimaryButton
          disabled={!isValid}
          text="Save"
          onClick={onSubmit}
          styles={primaryButtonStyles}
        />
      </div>
    </form>
  );
};

export default VacationStaffForm;
