// Import libraries
import {useState, useEffect, useCallback, useMemo, ReactElement} from "react";
import { Dropdown, mergeStyleSets } from "@fluentui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition, IconProp } from "@fortawesome/fontawesome-svg-core";
import { addMonths, differenceInDays, format } from "date-fns";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch } from "react-redux";

// Import types
import { GeneralSkill, MachineSkill, TMachine, User } from "../../../types";
import {
  faCircleExclamation,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";

// Import redux
import { saveMessage } from "../../../redux/message/message.actions";

// Import types
import { Skill } from "../../../types/settings";

// Import constants
import { SKILL_TYPE_KEY } from "../../../utils/constants";

// Import component
import DatePicker from "../../common/DatePicker";
import SkillTableControl from "../mapmachines/SkillTableControl";
// import { TooltipForText } from "../../common";
import TextWithTeachingBubble from "../../common/TextWithTeachingBubble";
import PopupMachineSkills from "../mapmachines/PopupMachineSkills";
import { isValidDateString } from "../../../utils/utils";

const dropDownStyles = {
  dropdownItem: {
    height: 48,
  },
  dropdownItemSelected: {
    height: 48,
  },
  title: {
    border: "none",
  },
  root: {
    width: "100%",
  },
  dropdown: {
    width: "100%",
    caretDownWrapper: {
      marginTop: 8,
    },
  },
  caretDown: {
    color: "#006CAD",
    fontWeight: "1000",
  },
};

const classNames = mergeStyleSets({
  input: {
    border: "none",
    // width: 100,
    backgroundColor: "#f1f1f1",
    fontFamily: "Verdana",
    fontSize: 14,
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  tr: {
    textAlign: "left",
    height: 55,
    borderBottom: "1px solid #DBDBDB",
  },
  th: {
    boxSizing: "border-box",
    padding: "0px 0px 0px 16px",
    fontSize: 11,
    fontWeight: 700,
    color: "#000",
  },
  td: {
    whiteSpace: "noWrap",
    padding: "0px 0px 0px 16px",
  },
  pageInnerContainer: {
    maxWidth: 1300,
    width: "100%",
    margin: "0 auto",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
    marginRight: 12,
  },
  iconColumn: {
    width: 40,
    textAlign: "center",
    padding: "0 20px",
  },
  textAreaInput: {
    marginRight: 10,
    borderRadius: 5,
    width: "95%",
    // textOverflow: 'ellipsis',
    // overflow: 'hidden',
    // minWidth: 312,
    // maxWidth: 312,
    // resize: 'none',
    // padding: 5,
    // wordWrap: 'break-word'
  },
  ekstraContainer: {
    paddingTop: 9,
    paddingBottom: 9,
  },
  ekstra: {
    fontWeight: "400",
    fontSize: 11,
    fontFamily: "Verdana",
    letterSpacing: 1,
    color: "#575756",
    lineHeight: 12,
    height: 16,
    marginTop: 2,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: "#DFDFDF",
    marginBottom: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  machineName: {
    display: "block",
    fontSize: 14,
    color: "black",
    lineHeight: 24,
  },
  machineNo: {
    display: "block",
    fontSize: 11,
    fontWeight: 400,
    color: "#868685",
    lineHeight: 12,
  },
  maskineTh: {
    width: 156,
  },
  skillTh: {
    width: 200,
  },
  initCert: {
    width: 176,
  },
  recert: {
    width: 164,
  },
  note: {
    width: 246,
  },
  action: {
    width: 144,
  },
});

type MachineSkillTableProps = {
  staffMachineSkills?: MachineSkill[];
  availableMachineSkills: Skill[];
  staffGeneralSkills?: GeneralSkill[];
  allSkills: Skill[];
  machines: TMachine[];
  setIsEditing: (isEditing: boolean) => void;
  onUpdateStaffMachineSkills: (skills: MachineSkill[]) => void;
  canEdit: boolean;
  staffData: User;
};

const MachineSkillTable = ({
  staffMachineSkills = [],
  machines = [],
  setIsEditing,
  onUpdateStaffMachineSkills,
  canEdit,
  staffGeneralSkills,
  allSkills,
  staffData,
}: MachineSkillTableProps): ReactElement => {
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editMachineSkill, setEditMachineSkill] = useState<MachineSkill | null>(
    null
  );
  const [popupData, setPopupData] = useState<{
    id: string;
    value: string;
  }>();

  // This is used for the machine popup bubble
  const [machinePopupId, setMachinePopupId] = useState<string | null>(null);

  const [isNewSkill, setIsNewSkill] = useState<boolean>(false);
  const [machineSkillsCopy, setMachineSkillsCopy] =
    useState<MachineSkill[]>(staffMachineSkills);

  const dispatch = useDispatch();

  const isEditing = useMemo(() => editIndex !== null, [editIndex]);

  // We are setting the edit index so that we can reset the view
  // if the user switches to general skill
  useEffect(() => {
    return () => {
      setEditIndex(null);
    };
  }, []);

  useEffect(() => {
    if (isEditing) {
      setPopupData(undefined);
    }
  }, [isEditing]);

  const getUnAssignedMachineSkill = () => {
    const staffMachineIds = machineSkillsCopy.map((skill) => skill.machineId);

    return machines.find(
      (machine) => !staffMachineIds.includes(machine.machineId)
    );
  };

  // Event Functions
  const onAddClick = (e: React.MouseEvent<SVGSVGElement>): void => {
    if (!isEditing) {
      const copy = [...machineSkillsCopy];

      const unassignedMachine: any = getUnAssignedMachineSkill();

      if (unassignedMachine === null) {
        dispatch(saveMessage("All machines have already been assigned."));
        return;
      }

      const newSkill: MachineSkill = {
        machineId: unassignedMachine.machineId,
        initCertification: new Date().toISOString(),
        note: "",
        reCertification: addMonths(new Date(), 12).toISOString(),
        inactive: false,
      };

      copy.push(newSkill);

      setMachineSkillsCopy(copy);
      setIsNewSkill(true);
      setEditMachineSkill(newSkill);
      setEditIndex(copy.length - 1);
      setIsEditing(true);
    } else {
      dispatch(
        saveMessage(
          "You must complete the editing process before create new one."
        )
      );
    }
  };

  // Remove and Edit function
  const onClickEditGeneralSkill = useCallback(
    (index: number) => {
      if (isEditing) {
        dispatch(saveMessage("You can only edit one item at a time!"));
      } else {
        if (machineSkillsCopy[index]) {
          setEditMachineSkill({
            ...machineSkillsCopy[index],
          });
          setIsEditing(true);
          setEditIndex(index);
        }
      }
    },
    [setEditIndex, isEditing, dispatch, machineSkillsCopy, setIsEditing]
  );

  const resetEditState = useCallback(() => {
    setEditIndex(null);
    setEditMachineSkill(null);
    setIsNewSkill(false);
    setIsEditing(false);
    setMachinePopupId(null);
  }, [setEditIndex, setEditMachineSkill, setIsNewSkill, setIsEditing]);

  const onApplyChange = useCallback(() => {
    if (editMachineSkill && editIndex !== null) {
      const { initCertification, machineId, reCertification } =
        editMachineSkill;

      const mac = machines.find((machine) => machine.machineId === machineId);

      const missingInfo =
        !mac ||
        new Date(reCertification).getTime() < 0 ||
        new Date(initCertification).getTime() < 0;

      if (missingInfo) {
        dispatch(saveMessage("Please provide missing information!"));
      } else {
        const copy = [...machineSkillsCopy];
        copy[editIndex] = editMachineSkill;

        setMachineSkillsCopy(copy);
        onUpdateStaffMachineSkills(copy);
        resetEditState();
      }
    }
  }, [
    machineSkillsCopy,
    editIndex,
    editMachineSkill,
    resetEditState,
    onUpdateStaffMachineSkills,
    dispatch,
    machines,
  ]);

  const onClickInactiveSkill = useCallback(
    (index: number) => {
      if (typeof machineSkillsCopy[index] !== "undefined") {
        const copy = [...machineSkillsCopy];

        const skillCopy = copy[index];

        skillCopy.inactive = !skillCopy.inactive;

        copy[index] = skillCopy;

        resetEditState();
        setMachineSkillsCopy(copy);
        onUpdateStaffMachineSkills(copy);

        // onApplyChange();
        resetEditState();
      }
    },
    [machineSkillsCopy, resetEditState, onApplyChange]
  );

  const onClickRemoveGeneralSkill = useCallback(
    (index: number) => {
      if (typeof machineSkillsCopy[index] !== "undefined") {
        if (editIndex === null && !editMachineSkill) {
          const copy = [...machineSkillsCopy];

          copy.splice(index, 1);

          resetEditState();
          setMachineSkillsCopy(copy);
          onUpdateStaffMachineSkills(copy);
        } else if (isNewSkill) {
          const copy = [...machineSkillsCopy];
          copy.splice(index, 1);
          setMachineSkillsCopy(copy);

          resetEditState();
        } else {
          resetEditState();
        }
      }
    },
    [
      machineSkillsCopy,
      setMachineSkillsCopy,
      isNewSkill,
      editIndex,
      editMachineSkill,
      resetEditState,
      onUpdateStaffMachineSkills,
    ]
  );

  // Render Functions
  const renderMachineSkills = useCallback((): ReactElement[] => {
    const renderNote = ({
      index,
      note,
      inactive,
    }: {
      index: number;
      note: string;
      inactive: boolean;
    }) => {
      const id = `machine-skill-${index}`;

      if (index === editIndex) {
        return (
          <td className={classNames.td}>
            <textarea
              className={classNames.textAreaInput}
              value={note}
              onChange={(e) => {
                setEditMachineSkill((values) => ({
                  ...(values as MachineSkill),
                  note: e.target.value,
                }));
              }}
              id={id}
            />
          </td>
        );
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          <textarea
            className={classNames.textAreaInput}
            value={note}
            readOnly
            style={{ background: "none", border: "none" }}
          />
        </td>
      );
    };

    const renderMachineSkills = ({ machineId }: { machineId: string }) => {
      const arr: ReactElement[] = [];

      const machine = machines.find(
        (machine) => machine.machineId === machineId
      );

      if (machine) {
        const skills = allSkills.filter((skill) =>
          machine.requiredSkills.includes(skill.id)
        );

        const staffGeneralSkillsIds =
          staffGeneralSkills?.map((el) => el.skillId) || [];

        skills.forEach((skill) => {
          let backgroundColor = "#DFDFDF";
          let color = "#000";

          if (
            [SKILL_TYPE_KEY.GENERAL, SKILL_TYPE_KEY.MACHINE].includes(
              skill.type
            )
          ) {
            const hasSkill = staffGeneralSkillsIds.includes(skill.id);

            backgroundColor = hasSkill ? "#1A936F" : "#F57C00";
            color = "#FFF";
          }

          arr.push(
            <p
              style={{
                backgroundColor,
                color,
              }}
              key={skill.id}
              className={classNames.ekstra}
            >
              {skill.name}
            </p>
          );
        });
      }

      return (
        <td className={`${classNames.td} ${classNames.ekstraContainer}`}>
          {arr}
        </td>
      );
    };

    const renderAction = ({
      index,
      inactive,
    }: {
      index: number;
      inactive: boolean;
    }) => {
      if (!canEdit) {
        return <td className={classNames.td}></td>;
      }

      if (index === editIndex) {
        return (
          <td className={classNames.td}>
            <div className={classNames.iconContainer}>
              <FontAwesomeIcon
                icon={faCheck as IconDefinition}
                className={classNames.icon}
                onClick={onApplyChange}
              />
              <FontAwesomeIcon
                icon={faTimes as IconDefinition}
                className={classNames.icon}
                onClick={() => {
                  // resetEditState();
                  onClickRemoveGeneralSkill(index);
                }}
              />
            </div>
          </td>
        );
      }

      return (
        <td className={classNames.td}>
          <SkillTableControl
            id={index.toString()}
            onClickDelete={() => {
              onClickRemoveGeneralSkill(index);
            }}
            onClickInactive={() => {
              onClickInactiveSkill(index);
            }}
            onClickEdit={() => {
              onClickEditGeneralSkill(index);
            }}
            inactive={inactive}
          />
        </td>
      );
    };

    const renderMachineName = ({
      inactive,
      index,
      machineId,
      machineName,
      machineNo,
    }: {
      index: number;
      machineName: string;
      machineNo: string;
      machineId: string;
      inactive: boolean;
    }) => {
      // const options = machines.map((machine) => ({
      //   key: machine.machineId,
      //   text: machine.machineName,
      // }));

      if (index === editIndex) {
        return (
          <td
            className={classNames.td}
            style={{
              height: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                setMachinePopupId(machineId);
              }}
            >
              <div>{machineName}</div>
              <FontAwesomeIcon
                id={`edit-${machineId}`}
                icon={faChevronDown as IconProp}
              />
            </div>
            {/* <Dropdown
              selectedKey={machineId}
              options={options}
              onChange={(_, item) => {
                if (item) {
                  const machine = machines.find(
                    (machine) => machine.machineId === item.key
                  );

                  if (machine) {
                    const staffMachineIds = machineSkillsCopy.map(
                      (skill) => skill.machineId
                    );

                    if (
                      staffMachineIds.includes(machine.machineId) &&
                      staffMachineIds.findIndex(
                        (val) => val === machine.machineId
                      ) !== editIndex
                    ) {
                      dispatch(
                        saveMessage(
                          "This machine skill has already assigned to this person"
                        )
                      );
                      return;
                    }

                    setEditMachineSkill((values) => ({
                      ...(values as MachineSkill),
                      machineName: machine.machineName,
                      machineId: machine.machineId,
                    }));
                  }
                }
              }}
              styles={dropDownStyles}
              errorMessage={undefined}
              className={classNames.input}
            /> */}
          </td>
        );
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          <span className={classNames.machineName}>{machineName}</span>
          <span className={classNames.machineNo}>{machineNo}</span>
        </td>
      );
    };

    const renderRecertification = ({
      index,
      reviewDateStr,
      inactive,
    }: {
      index: number;
      reviewDateStr: string;
      inactive: boolean;
    }) => {
      const reCertification = new Date(reviewDateStr);

      const daysDifference = differenceInDays(reCertification, new Date());

      const text: string = isValidDateString(reviewDateStr)
        ? format(reCertification, "dd.MM.yyyy")
        : "";
      if (index === editIndex) {
        return (
          <td className={classNames.td}>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{text}</span>
              <div className={classNames.icon}>
                <DatePicker
                  date={reCertification}
                  onSelectDate={(date) => {
                    if (date) {
                      setEditMachineSkill((values) => ({
                        ...(values as MachineSkill),
                        reCertification: date.toISOString(),
                      }));
                    }
                  }}
                  id={`machine-review-date${index}`}
                  isMonthPickerVisible
                />
              </div>
            </span>
          </td>
        );
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{text}</span>
            {daysDifference >= 0 && daysDifference < 30 ? (
              <span>
                <FontAwesomeIcon
                  icon={faCircleExclamation as IconDefinition}
                  color={"#E3B505"}
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </span>
            ) : (
              <></>
            )}
            {daysDifference < 0 ? (
              <span>
                <FontAwesomeIcon
                  icon={faTriangleExclamation as IconDefinition}
                  color={"#F57C00"}
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              </span>
            ) : (
              <></>
            )}
          </span>
        </td>
      );
    };

    const renderInitCertification = ({
      index,
      initCertification,
      inactive,
    }: {
      index: number;
      initCertification: string;
      inactive: boolean;
    }) => {
      const introMachineDate = new Date(initCertification);

      const text: string = isValidDateString(initCertification)
        ? format(introMachineDate, "dd.MM.yyyy")
        : "";

      if (index === editIndex) {
        return (
          <td className={classNames.td}>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{text}</span>
              <DatePicker
                date={introMachineDate}
                onSelectDate={(date) => {
                  if (date) {
                    setEditMachineSkill((values) => ({
                      ...(values as MachineSkill),
                      initCertification: date.toISOString(),
                      reCertification: addMonths(date, 12).toISOString(),
                    }));
                  }
                }}
                id={`machine-intro-date${index}`}
                isMonthPickerVisible
              />
            </span>
          </td>
        );
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          <span
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>{text}</span>
          </span>
        </td>
      );
    };

    const arr: ReactElement[] = [];
    
    // sort the machineSkillsCopy by machineName
    machineSkillsCopy.sort((a, b) => {
      const machineA = machines.find(
        (machine) => machine.machineId === a.machineId
      );
      const machineB = machines.find(
        (machine) => machine.machineId === b.machineId
      );

      return machineB?.machineName.localeCompare(machineA!.machineName) || 0;
    });

    machineSkillsCopy?.forEach((machineSkill, index) => {
      const { initCertification, machineId, note, reCertification, inactive } =
        machineSkill;

      // Get the machine by id
      const machine = machines.find(
        (machine) =>
          machine.machineId ===
          (index === editIndex
            ? (editMachineSkill?.machineId as string)
            : machineId)
      );

      if (machine) {
        const machineName = machine ? machine?.machineName : "";

        arr.push(
          <tr key={machineId} className={classNames.tr}>
            {renderMachineName({
              index: index,
              machineId:
                index === editIndex
                  ? (editMachineSkill?.machineId as string)
                  : machine?.machineId,
              machineName,
              machineNo: machine?.machineNo,
              inactive,
            })}
            {renderMachineSkills({
              machineId:
                index === editIndex
                  ? (editMachineSkill?.machineId as string)
                  : machineId,
            })}
            {renderInitCertification({
              inactive,
              index,
              initCertification:
                index === editIndex
                  ? editMachineSkill?.initCertification ||
                    new Date().toISOString()
                  : initCertification,
            })}
            {renderRecertification({
              index,
              reviewDateStr:
                index === editIndex
                  ? editMachineSkill?.reCertification ||
                    new Date().toISOString()
                  : reCertification,
              inactive,
            })}
            {renderNote({
              inactive,
              index,
              note:
                index === editIndex ? (editMachineSkill?.note as string) : note,
            })}
            {renderAction({ index, inactive })}
          </tr>
        );
      }
    });

    return arr.reverse();
  }, [
    machines,
    onClickEditGeneralSkill,
    onClickRemoveGeneralSkill,
    editIndex,
    editMachineSkill,
    onApplyChange,
    machineSkillsCopy,
    dispatch,
    canEdit,
    allSkills,
    staffGeneralSkills,
    onClickInactiveSkill,
  ]);

  return (
    <>
      <div className={classNames.pageInnerContainer}>
        <div className={classNames.tableContainer}>
          <table className={classNames.table}>
            <tbody>
              <tr className={classNames.tr}>
                <th style={{ width: 156 }} className={classNames.th}>
                  MASKINE
                </th>
                <th style={{ width: 200 }} className={classNames.th}>
                  SKILLS
                </th>
                <th style={{ width: 176 }} className={classNames.th}>
                  INITIAL CERTIFICATION
                </th>
                <th style={{ width: 164 }} className={classNames.th}>
                  RECERTIFICATION
                </th>
                <th style={{ width: 246 }} className={classNames.th}>
                  NOTE
                </th>
                <th style={{ width: 144 }} className={classNames.th}>
                  ACTION
                </th>
              </tr>
              <tr className={classNames.tr}>
                <td colSpan={9}></td>
                <td>
                  {canEdit ? (
                    <FontAwesomeIcon
                      icon={faPlus as IconDefinition}
                      className={classNames.icon}
                      onClick={onAddClick}
                    />
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
              {renderMachineSkills()}
            </tbody>
          </table>
        </div>
      </div>
      {popupData && (
        <TextWithTeachingBubble
          target={`#${popupData?.id}`}
          onDismiss={() => {
            setPopupData(undefined);
          }}
          value={popupData?.value}
          onChangeValue={(newVal) => {
            if (popupData && popupData?.id) {
              const { id } = popupData;

              if (id.split("-")[2]) {
                const idNumber = parseInt(id.split("-")[2]);

                if (typeof idNumber === "number") {
                  const values = machineSkillsCopy[idNumber];

                  if (values) {
                    setEditMachineSkill((values) => ({
                      ...(values as MachineSkill),
                      note: newVal,
                    }));
                  }
                }
              }
            }
          }}
        />
      )}
      {machinePopupId ? (
        <PopupMachineSkills
          toggleTeaching={() => {
            setMachinePopupId(null);
          }}
          target={`edit-${machinePopupId}`}
          // We want to only show the machines list with have yet to be added
          machines={machines.filter((el) => {
            return !machineSkillsCopy.find(
              (macSkill) => macSkill.machineId === el.machineId
            );
          })}
          staffTechDepartment={staffData.techDepartment}
          onSelectMachine={(selectedMachine) => {
            if (editMachineSkill && isEditing) {
              setEditMachineSkill((values) => ({
                ...(values as MachineSkill),
                machineId: selectedMachine.machineId,
              }));
            }
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default MachineSkillTable;
