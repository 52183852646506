import React from 'react';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Spinner, SpinnerSize } from '@fluentui/react';

const classNames = mergeStyleSets({
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 4,
        marginBottom: 12,
        height: 58
    },
    titleText: {
        marginLeft: 16,
        fontSize: 32,
        lineHeight: 58,
        fontWeight: 'bold',
        color: '#ffffff',
    },
});

const PlanOverlayHeader = ({label, isLoading, color, onClose}) => {
    return (
        <div className={classNames.title} style={{background: `rgb(${color ? color : '241,241,241'})` }}>
            <div className={classNames.titleText}>
                { isLoading ?
                    <Spinner className={classNames.spinner} size={SpinnerSize.large} label="loading" labelPosition="right" />
                    :
                    label
                }
            </div>
        </div>
    )
}

export default PlanOverlayHeader
