// Import libraries
import {
  Rnd,
  RndDragCallback,
  RndResizeCallback,
  RndResizeStartCallback,
} from "react-rnd";
import {ReactElement, useCallback, useEffect, useState} from "react";
import { useSelector } from "react-redux";

// Import components
import { DeleteConfirmation, TooltipForText } from "../../common";

// Import types
import { TPlanHorizontalPositionMap } from "../../../types/planner";

// Import utils
import { getMapTransform } from "../../../utils/mapping";
import {
  CALENDAR_STEP_WIDTH,
  CALENDAR_WEEKVIEW_STEP_WIDTH,
  RND_BAR_HEIGHT,
} from "../../../utils/constants";
// import { OverlapKeys } from "../../../redux/project/project.actions";

const textStyles: React.CSSProperties = {
  padding: "0 10px",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

type MachineServiceBarProps = {
  color?: string;
  textDisplay: string;
  start: string;
  end: string;
  selected?: boolean;
  horizontalPositionMap: TPlanHorizontalPositionMap;
  yPos?: number;
  allowEdit?: boolean;
  onChangeStartEnd?: (val: {
    start: string;
    end: string;
    recalculate?: () => void;
  }) => void;
  tooltipText?: string;
  onInitialize?: (val: { recalculate: () => void }) => void;
  onDelete?: () => void;
  id?: string;
};

const MachineServiceBar = ({
  color,
  end,
  start,
  textDisplay,
  selected = false,
  horizontalPositionMap,
  yPos = 0,
  allowEdit = false,
  onChangeStartEnd,
  tooltipText,
  onInitialize,
  onDelete,
  id,
}: MachineServiceBarProps) => {
  // ======================= Use Selectors ========================================================
  const displayWeekView = useSelector(
    // @ts-ignore
    (state) => state.machine.displayWeekView
  ) as boolean;

  const [isDelete, setIsDelete] = useState<boolean>(false);

  // ======================= Use States ===========================================================

  const [width, setWidth] = useState<number>(0);
  const [x, setX] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>();

  const calendarStepWidth = displayWeekView
    ? CALENDAR_WEEKVIEW_STEP_WIDTH
    : CALENDAR_STEP_WIDTH;

  // =========================================== Use Effects ======================================

  const recalculate = useCallback(() => {
    if (isEditing) {
      return;
    }

    const { width, xPos } = getMapTransform({
      calendarStepWidth,
      end,
      start,
      horizontalPositionMap,
    });

    setWidth(width);
    setX(xPos);

    // If width equals zero it means the start date or end date
    // are not on the position map
    setShow(width !== 0);
  }, [horizontalPositionMap, start, end, isEditing, calendarStepWidth]);

  useEffect(() => {
    recalculate();

    onInitialize &&
      onInitialize({
        recalculate: () => {
          recalculate();
        },
      });
  }, [recalculate, onInitialize]);

  //  ========================================= Functions ===========================================

  const onResizeStart: RndResizeStartCallback = (e, dir, ref) => {};
  const onResizeStop: RndResizeCallback = (e, dir, ref, delta, position) => {
    const newXPos = parseInt(position.x.toFixed(0));
    let newWidth = ref.offsetWidth;

    if (newWidth % calendarStepWidth !== 0) {
      newWidth =
        parseInt((newWidth / calendarStepWidth).toFixed(0)) * calendarStepWidth;
    }

    if ((newXPos === x && newWidth === width) || width === 0) {
      return;
    }
    const newStart = horizontalPositionMap.positionsToDates[newXPos]
      ? horizontalPositionMap.positionsToDates[newXPos].value
      : null;

    const newEnd = horizontalPositionMap.positionsToDates[
      newXPos + newWidth - calendarStepWidth
    ]
      ? horizontalPositionMap.positionsToDates[
          newXPos + newWidth - calendarStepWidth
        ].value
      : null;

    setX(newXPos);
    setWidth(newWidth);

    if (newStart && newEnd) {
      onChangeStartEnd &&
        onChangeStartEnd({
          start: newStart,
          end: newEnd,
          recalculate,
        });
    }
  };
  const onDragStart: RndDragCallback = (e, data) => {};
  const onDragStop: RndDragCallback = (e, d) => {
    const newXPos = parseInt(d.lastX.toFixed(0));
    if (newXPos === x) {
      return;
    }

    const newStart = horizontalPositionMap.positionsToDates[newXPos]
      ? horizontalPositionMap.positionsToDates[newXPos].value
      : null;
    const newEnd = horizontalPositionMap.positionsToDates[
      newXPos + width - calendarStepWidth
    ]
      ? horizontalPositionMap.positionsToDates[
          newXPos + width - calendarStepWidth
        ].value
      : null;

    setX(newXPos);

    if (newStart && newEnd) {
      onChangeStartEnd &&
        onChangeStartEnd({
          start: newStart,
          end: newEnd,
          recalculate,
        });
    }
  };

  let detail: ReactElement = (
    <TooltipForText text={textDisplay}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          color: "#000",
        }}
      >
        {/* <div style={textStyles}>{formatDateForBar(start)}</div> */}
        <div style={textStyles}>{textDisplay}</div>
        {/* <div style={textStyles}>{formatDateForBar(end)}</div> */}
      </div>
    </TooltipForText>
  );
  if (width <= 275) {
    detail = (
      <TooltipForText
        // text={`(${formatDateForBar(start)}) ${textDisplay} (${formatDateForBar(
        //   end
        // )})`}
        text={tooltipText || textDisplay}
      >
        <div
          style={{ display: "flex", justifyContent: "center", color: "#000" }}
        >
          <div style={textStyles}>{textDisplay}</div>
        </div>
      </TooltipForText>
    );
  }

  return show ? (
    <>
      <Rnd
        style={{ zIndex: 3, top: 0, left: 0 }}
        size={{ width, height: RND_BAR_HEIGHT }}
        position={{ x, y: yPos }}
        enableResizing={{
          left: allowEdit,
          right: allowEdit,
        }}
        disableDragging={!allowEdit}
        dragAxis="x"
        resizeGrid={[calendarStepWidth, 1]}
        dragGrid={[calendarStepWidth, 1]}
        onResizeStart={onResizeStart}
        onResizeStop={onResizeStop}
        onDragStart={onDragStart}
        onDragStop={onDragStop}
        bounds=".detailPlanContainer"
        onMouseUp={() => {
          onDelete && id && setIsDelete(true);
        }}
        id={id}
      >
        <div
          id="detailBar"
          style={{
            // backgroundImage: `linear-gradient(45deg, rgba(${color},1) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgba(${color},1) 75%), linear-gradient(45deg, transparent 75%, rgba(${color},1) 75%), linear-gradient(45deg, rgba(${color},1) 25%, rgba(${color},0.5) 25%)`,
            backgroundSize: "38px 38px",
            backgroundPosition: "0 0, 0 0, -19px -19px, 19px 19px",
            display: "flex",
            marginBottom: 12,
            height: 32,
            borderRadius: 8,
            backgroundColor: color ? `rgba(${color}` : "#fff",
            border: selected ? "1px solid black" : "",
          }}
        >
          <div
            style={{
              color: "#fff",
              fontWeight: "bold",
              height: "100%",
              width: "100%",
              fontSize: 13,
              lineHeight: "32px",
              margin: "2px 4px",
              cursor: `url(${window.location.origin}/img/arrows-alt.svg), auto`,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "#000",
              }}
            >
              {detail}
            </div>
          </div>
        </div>
      </Rnd>
      {isDelete ? (
        <DeleteConfirmation
          target={"#" + id}
          toggleTeaching={() => {
            setIsDelete(false);
          }}
          onClickConfirm={() => {
            onDelete && onDelete();
            setIsDelete(false);
          }}
        />
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

export default MachineServiceBar;
