import { useMemo } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { IconButton, PrimaryButton, Stack } from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { techDepartmentOptions } from "../../../utils/constants";
import {
  customAutoCompleteStyles,
  inputStyles,
  primaryButtonStyles,
} from "../../../utils/theme";
import { workingRoleOptions } from "../../../utils/constants";
import { TagPicker } from "@fluentui/react/lib/Pickers";
import { useId } from "@fluentui/react-hooks";
import { TextField } from "@fluentui/react/lib/TextField";
import { CustomInputErrorComponent } from "../../common";
import { hasEditRight } from "../../../utils/utils";

const classNames = mergeStyleSets({
  selector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(159, 159, 159)",
    borderRadius: 4,
    height: "fit-content",
    width: 318,
    marginBottom: 24,
    paddingBottom: 5,
    selectors: {
      ":hover": {
        border: "1px solid rgb(42, 42, 42)",
      },
    },
  },
  container: {
    minHeight: "100vh",
    background: "#F1F1F1",
  },
  label: {
    fontSize: 29,
    lineHeight: 29,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 116px 118px",

    label: {
      marginLeft: "100px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: 320,
    margin: "0 auto",
  },
  input: {
    marginBottom: 24,
    fontWeight: "400 !important",
  },
  fileErrorMessage: {
    fontSize: "12px",
    fontWeight: 400,
    color: " rgb(164, 38, 44)",
    marginBottom: 24,
    marginTop: -18,
  },
  icon: {
    fontSize: 20,
    cursor: "pointer",
    position: "relative",
    top: 4,
    right: 16,
  },
});

const MachineTeamForm = ({
  values: { name, machineId, techDepartments, drivers, workers, managers },
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  handleChange,
  isValid,
  history,
  machineData,
  staffData,
}) => {
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const pickerId = useId("inline-picker");
  const pickerSuggestionsProps = { noResultsFoundText: "No users found!" };
  const textDepartmentOptionsWithLabel = techDepartmentOptions.map(
    (option) => ({ key: option.key, label: option.text })
  );
  const machineIndexOptions = useMemo(
    () =>
      machineData &&
      techDepartments &&
      machineData
        .filter((machine) =>
          techDepartments
            .split(";")
            ?.find((dep) => machine.techDepartments.includes(dep))
        )
        .map((machine, index) => ({
          key: machine.machineId,
          label: machine.machineName,
        })),
    [machineData, techDepartments]
  );

  const staffIndexOptions = useMemo(
    () =>
      staffData &&
      staffData.map((staff, index) => ({
        key: staff.userId,
        name: staff.firstName + " " + staff.lastName,
        workingRole: staff.workingRole,
      })),
    [staffData]
  );

  const filterStaff = (role) => {
    const options =
      staffIndexOptions &&
      staffIndexOptions.filter((item) => item.workingRole.includes(role));
    return options;
  };

  const listContainsTagList = (tag, tagList) => {
    if (!tagList || !tagList.length || tagList.length === 0) {
      return false;
    }
    return tagList.some((compareTag) => compareTag.id === tag.key);
  };

  const filterDriverTags = (filterText, tagList) => {
    const arr = filterStaff(workingRoleOptions[1].key) ?? [];
    return filterText
      ? arr.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
            !listContainsTagList(tag, tagList)
        )
      : [];
  };

  const filterWorkerTags = (filterText, tagList) => {
    const arr = filterStaff(workingRoleOptions[2].key) ?? [];
    return filterText
      ? arr.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
            !listContainsTagList(tag, tagList)
        )
      : [];
  };

  const filterSupervisorTags = (filterText, tagList) => {
    const arr = filterStaff(workingRoleOptions[0].key) ?? [];
    return filterText
      ? arr.filter(
          (tag) =>
            tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 &&
            !listContainsTagList(tag, tagList)
        )
      : [];
  };

  const getTextFromItem = (item) => item.name;

  const handleSubmitAndSelectSubSystems = () => handleSubmit();

  const newList = (list) => {
    if (!list) return [];

    const arr = list.map((v) => ({
      id: v.id,
      name: v.name !== undefined ? v.name : v.firstName + " " + v.lastName,
    }));
    return arr;
  };

  return (
    <form className={classNames.container}>
      <div className={classNames.header}>
        <IconButton
          iconProps={{ iconName: "ChromeBack" }}
          onClick={() =>
            history.push({
              pathname: "/machines",
              state: "MACHINE_TABLE_TEAMS",
            })
          }
        />
        <Label className={classNames.label}>{name ? name : "New Team"}</Label>
        <Stack horizontal tokens={{ childrenGap: 40 }}>
          <PrimaryButton
            text={machineId ? "Gem" : "Opret Team"}
            disabled={!isValid || !allowEdit || !machineId}
            onClick={handleSubmitAndSelectSubSystems}
            styles={primaryButtonStyles}
          />
        </Stack>
      </div>
      <div className={classNames.content}>
        <TextField
          label="NAVN"
          styles={inputStyles}
          value={name}
          name="name"
          placeholder="NAVN"
          className={classNames.input}
          onChange={handleChange}
          errorMessage={touched.name || errors.name ? errors.name : ""}
          disabled={!allowEdit}
        />
        <div
          style={{
            fontFamily: "Verdana",
            fontSize: 11,
            fontWeight: "bold",
            transform: "translate(9px, 18px)",
            marginTop: -13,
            zIndex: 2,
          }}
        >
          SEKTION
        </div>
        <Select
          placeholder="SEKTION"
          name="techDepartment"
          closeMenuOnSelect={true}
          defaultValue={textDepartmentOptionsWithLabel.find((item) =>
            ("" + techDepartments).split(";").includes("" + item.key)
          )}
          options={textDepartmentOptionsWithLabel}
          isClearable={true}
          isSearchable={true}
          onChange={(item) => setFieldValue("techDepartments", "" + item?.key)}
          className={classNames.input}
          styles={customAutoCompleteStyles(errors.techDepartment)}
          getOptionValue={(option) => option.label}
          isDisabled={!allowEdit}
        />
        <CustomInputErrorComponent
          styles={{ margin: "-20px 0 23px 0" }}
          errorMessage={
            touched.techDepartments || errors.techDepartments
              ? errors.techDepartments
              : ""
          }
        />
        {machineIndexOptions && (
          <>
            <div
              style={{
                fontFamily: "Verdana",
                fontSize: 11,
                fontWeight: "bold",
                transform: "translate(9px, 18px)",
                marginTop: -13,
                zIndex: 2,
              }}
            >
              MASKINE
            </div>
            <Select
              placeholder="VÆLG"
              name="machineId"
              closeMenuOnSelect={true}
              value={machineIndexOptions.find((item) => item.key === machineId)}
              defaultValue={machineIndexOptions.find(
                (item) => item.key === machineId
              )}
              options={machineIndexOptions}
              isClearable={true}
              isSearchable={true}
              onChange={(item) => setFieldValue("machineId", item?.key)}
              className={classNames.input}
              styles={customAutoCompleteStyles(errors.machineId)}
              getOptionValue={(option) => option.label}
              isDisabled={!allowEdit}
            />
            <CustomInputErrorComponent
              styles={{ margin: "-20px 0 23px 0" }}
              errorMessage={
                touched.machineId || errors.machineId ? errors.machineId : ""
              }
            />
          </>
        )}
        <div className={classNames.selector}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 8,
              width: "255px",
            }}
          >
            <div
              style={{
                fontSize: 11,
                marginRight: 8,
                fontWeight: 600,
                color: "#2A2A2A",
                marginTop: 5,
                marginBottom: 2,
              }}
            >
              MACHINFØRER
            </div>
            <TagPicker
              selectedItems={newList(drivers)}
              removeButtonAriaLabel="Remove"
              onChange={(items) =>
                setFieldValue(
                  "drivers",
                  items.map((v) => ({ id: v.key || v.id, name: v.name }))
                )
              }
              onResolveSuggestions={filterDriverTags}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={pickerSuggestionsProps}
              pickerCalloutProps={{ doNotLayer: true }}
              inputProps={{
                id: pickerId,
                placeholder: "MACHINFØRER",
                name: "drivers",
              }}
              disabled={!allowEdit}
            />
          </div>
        </div>
        <div className={classNames.selector}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 8,
              width: "255px",
            }}
          >
            <div
              style={{
                fontSize: 11,
                marginRight: 8,
                fontWeight: 600,
                color: "#2A2A2A",
                marginTop: 5,
                marginBottom: 2,
              }}
            >
              HÅNDMAND
            </div>
            <TagPicker
              selectedItems={newList(workers)}
              removeButtonAriaLabel="Remove"
              onChange={(items) =>
                setFieldValue(
                  "workers",
                  items.map((v) => ({ id: v.key || v.id, name: v.name }))
                )
              }
              onResolveSuggestions={filterWorkerTags}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={pickerSuggestionsProps}
              pickerCalloutProps={{ doNotLayer: true }}
              inputProps={{
                id: pickerId,
                placeholder: "HÅNDMAND",
                name: "workers",
              }}
              disabled={!allowEdit}
            />
          </div>
        </div>
        <div className={classNames.selector}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 8,
              width: "255px",
            }}
          >
            <div
              style={{
                fontSize: 11,
                marginRight: 8,
                fontWeight: 600,
                color: "#2A2A2A",
                marginTop: 5,
                marginBottom: 2,
              }}
            >
              FORMAND
            </div>
            <TagPicker
              selectedItems={newList(managers)}
              removeButtonAriaLabel="Remove"
              onChange={(items) =>
                setFieldValue(
                  "managers",
                  items.map((v) => ({ id: v.key || v.id, name: v.name }))
                )
              }
              onResolveSuggestions={filterSupervisorTags}
              getTextFromItem={getTextFromItem}
              pickerSuggestionsProps={pickerSuggestionsProps}
              pickerCalloutProps={{ doNotLayer: true }}
              inputProps={{
                id: pickerId,
                placeholder: "FORMAND",
                name: "managers",
              }}
              disabled={!allowEdit}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default MachineTeamForm;
