import { useState, useEffect, useMemo } from "react";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
  DefaultButton,
  Stack,
  IconButton,
  PrimaryButton,
  TeachingBubble,
} from "@fluentui/react";
import { primaryButtonStyles } from "../../../../utils/theme";
import { useHistory } from "react-router-dom";
import { TooltipForText } from "../../../common";
import { connect, useSelector } from "react-redux";
import { toggleDisplayWeekView } from "../../../../redux/machines/machines.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarCircleUser,
  faCalendarDay,
  faCalendarWeek,
  faCheck,
  faFilter,
  faLink,
} from "@fortawesome/pro-regular-svg-icons";
import ToggleButton from "../../../common/ToggleButton";
import { buttonStyle } from "../../../common/calendar/ButtonStyles";
import { getReqCrmLink, hasEditRight } from "../../../../utils/utils";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { COLOR_CONS_HEX } from "../../../../utils/constants";
import MachineFilters, { BLANK_MACHINE_FILTER_OPTION } from "./MachineFilters";

const iconStyle = {
  marginRight: 2,
  fontSize: 16,
};

const classNames = mergeStyleSets({
  buttonContainer: {
    width: "calc(100% - 150px)",
    margin: "20px 0",
    position: "fixed",
  },
  unSelectedIcon: {
    ...iconStyle,
    color: "#006CAD",
  },
  selectedIcon: {
    ...iconStyle,
    color: "#fff",
  },
  iconNumber: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    borderRadius: "50%",
    marginTop: -18,
    marginLeft: 28,
    color: "#FFFFFF",
    fontSize: 12,
    height: 18,
    width: 18,
    backgroundColor: "#006CAD",
  },
  heading: {
    color: "black",
  },
});

// machineType={
//     planData?.machineRequirements[+planningIndex].machineType
//   }
//   machineGroup={
//     planData?.machineRequirements[+planningIndex].sizeGroup
//   }
//   activities={
//     planData?.machineRequirements[+planningIndex].activities
//   }

const removeDuplicates = (arr) => {
  return [...new Set(arr)];
};

const CalendarControlsDetailedPlanning = ({
  projectName,
  submitUpdatePlan,
  displayWeekView,
  toggleDisplayWeekView,
  // done,
  // setDone,
  hidden,
  setHidden,
  setAnyUpdate,
  machines,
  submitAndClose,
  allowEdit,
  inactive,
  onDrawVacation,
  isDisabled,
  machineType,
  machineGroup,
  activities,
  planDescription,
  crmActivitiesId,
  onSelectMachines,
  showMachineFilter = true,
  showVacationDrawIcon = true,
  showHideButton = true,
}) => {
  const history = useHistory();
  const [scrollPosition, setScrollPosition] = useState(0);

  const [selectedMachines, setSelectedMachines] = useState(undefined);
  const [localMachinesList, setLocalMachinesList] = useState([]);

  useEffect(() => {
    const isMachinesValid = Array.isArray(machines) && machines.length;

    // Initialization
    // We want all of it be selected at the beginning
    if (isMachinesValid && !selectedMachines) {
      const initArr = machines.map((el) => el.machineName);
      // setSelectedMachines([...initArr, BLANK_MACHINE_FILTER_OPTION]);
      setSelectedMachines(
        removeDuplicates([...initArr, BLANK_MACHINE_FILTER_OPTION])
      );
      onSelectMachines([...initArr, BLANK_MACHINE_FILTER_OPTION]);
      return;
    }

    // This is only called when the machines in the plan have added or removed
    if (isMachinesValid && localMachinesList.length !== machines.length) {
      // Get the new machine added
      const newMachines = machines
        .filter((el) => !localMachinesList.includes(el.machineName))
        .map((el) => el.machineName);

      if (selectedMachines) {
        const oldMachines = selectedMachines.filter(
          (selectedMachine) =>
            !machines.find((el) => el.machineName === selectedMachine)
        );

        if (selectedMachines) {
          setSelectedMachines((prev) => {
            const newArr = removeDuplicates(
              [...prev, ...newMachines].filter(
                (el) =>
                  !oldMachines.includes(el) ||
                  el === BLANK_MACHINE_FILTER_OPTION
              )
            );
            onSelectMachines(removeDuplicates(newArr));
            return newArr;
          });
        }
      }

      setLocalMachinesList(machines.map((el) => el.machineName));
    }
  }, [machines, selectedMachines, localMachinesList]);

  // const [disableCheck, setDisableCheck] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  const [showBubble, setShowBubble] = useState(false);

  const defaultData = useSelector((state) => state.defaultData);

  const { machineTypes, sizeGroupOptions } = defaultData;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  const subText = useMemo(() => {
    let val = "";
    if (activities) {
      val += activities;
    }
    if (machineType) {
      const machineTypeName =
        machineTypes.find((type) => type.id === machineType)?.name || "";

      if (val && machineTypeName) {
        val += " - ";
      }

      if (machineTypeName) {
        val += machineTypeName;
      }
    }

    if (typeof machineGroup === "number") {
      const sizeGroupName =
        sizeGroupOptions.find((sizeGroup) => sizeGroup.key === machineGroup)
          ?.text || "";

      if (val && sizeGroupName) {
        val += " - ";
      }

      if (sizeGroupName) {
        val += sizeGroupName;
      }
    }

    return val;
  }, [machineGroup, machineType, activities]);

  const crmLink = useMemo(() => {
    if (crmActivitiesId) {
      return getReqCrmLink(crmActivitiesId);
    }

    return "";
  }, [crmActivitiesId]);

  return (
    <div
      className={classNames.buttonContainer}
      style={{ top: scrollPosition > 25 ? 0 : 25 - scrollPosition }}
    >
      {scrollPosition <= 60 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
            <IconButton
              iconProps={{ iconName: "ChromeBack" }}
              onClick={() =>
                history.length > 2 ? history.goBack() : history.push("/")
              }
            />
          </Stack>
          <Stack>
            <div
              style={{
                color: "#000",
                fontWeight: "700",
                height: 44,
                width: "100%",
                lineHeight: "44px",
                fontSize: 24,
                margin: "2px 4px",
                display: "flex",
                gap: "8px",
              }}
            >
              <div>
                {projectName} {inactive && "( Inactivated )"}
              </div>
              {crmLink ? (
                <div>
                  <a target="_blank" href={crmLink}>
                    <FontAwesomeIcon
                      icon={faLink}
                      style={{ fontSize: 16, color: COLOR_CONS_HEX.BLUE }}
                    />
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
            {subText ? (
              <div
                style={{
                  color: "#000",
                  fontWeight: "500",
                  width: "100%",
                  fontSize: 12,
                  margin: "2px 4px",
                }}
              >
                {subText}
              </div>
            ) : (
              <></>
            )}
            {planDescription ? (
              <div
                style={{
                  color: "#000",
                  fontWeight: "500",
                  width: "100%",
                  fontSize: 12,
                  margin: "2px 4px",
                }}
              >
                {planDescription}
              </div>
            ) : (
              <></>
            )}
          </Stack>
          <Stack horizontal wrap tokens={{ childrenGap: 10 }}>
            {showMachineFilter ? (
              <div id="filter-button">
                <DefaultButton
                  onClick={() => setShowBubble(!showBubble)}
                  styles={{ ...buttonStyle(false) }}
                >
                  <FontAwesomeIcon
                    icon={faFilter}
                    className={classNames.unSelectedIcon}
                  />
                  {
                    <span className={classNames.iconNumber}>
                      {(selectedMachines || []).length}
                    </span>
                  }
                </DefaultButton>
              </div>
            ) : (
              <></>
            )}
            {showVacationDrawIcon ? (
              <TooltipForText text="Draw new Staff Vacation" noWidth>
                <DefaultButton
                  id="drawNewStaffVacation"
                  styles={buttonStyle(false)}
                  onClick={() => onDrawVacation("vacation")}
                >
                  <FontAwesomeIcon
                    icon={faCalendarCircleUser}
                    className={classNames.unSelectedIcon}
                  />
                </DefaultButton>
              </TooltipForText>
            ) : (
              <></>
            )}
            <TooltipForText text={hidden ? "Show" : "Hide"} noWidth>
              {showHideButton ? (
                <DefaultButton
                  onClick={() => {
                    // if (allowEdit && !disableCheck) {
                    //     setDone(!done);
                    //     setAnyUpdate(true);
                    // }
                    if (allowEdit) {
                      // setDone(!done);
                      setHidden((prev) => !prev);
                      setAnyUpdate(true);
                    }
                  }}
                  styles={buttonStyle(false)}
                >
                  <FontAwesomeIcon
                    icon={hidden ? faEyeSlash : faEye}
                    className={classNames.unSelectedIcon}
                  />
                </DefaultButton>
              ) : (
                <></>
              )}
            </TooltipForText>
            <TooltipForText
              noWidth
              text={displayWeekView ? "Weeks View" : "Days View"}
            >
              <ToggleButton
                toggle={displayWeekView}
                onClick={() => {
                  toggleDisplayWeekView(!displayWeekView);
                }}
                LeftIcon={() => (
                  <FontAwesomeIcon
                    icon={faCalendarDay}
                    className={
                      displayWeekView
                        ? classNames.unSelectedIcon
                        : classNames.selectedIcon
                    }
                  />
                )}
                RightIcon={() => (
                  <FontAwesomeIcon
                    icon={faCalendarWeek}
                    className={
                      displayWeekView
                        ? classNames.selectedIcon
                        : classNames.unSelectedIcon
                    }
                  />
                )}
              />
            </TooltipForText>
            {allowEdit && (
              <>
                <PrimaryButton
                  disabled={isDisabled}
                  text="GEM"
                  onClick={submitUpdatePlan}
                  styles={primaryButtonStyles}
                />
                <PrimaryButton
                  disabled={isDisabled}
                  text="GEM & LUK"
                  onClick={submitAndClose}
                  styles={{ ...primaryButtonStyles, marginLeft: 20 }}
                />
              </>
            )}
          </Stack>
        </div>
      )}
      {showBubble && (
        <TeachingBubble
          closeButtonAriaLabel="Close"
          target="#filter-button"
          onDismiss={() => setShowBubble(!showBubble)}
          isWide={true}
        >
          <div style={{ margin: "11px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                width: "115%",
                marginLeft: -10,
              }}
            >
              <div>
                <span className={classNames.heading}>
                  Assigned machines and staff
                </span>
                <div style={{ marginTop: "20px" }}></div>
              </div>
              <div>
                <MachineFilters
                  machines={machines}
                  selectedMachines={selectedMachines || []}
                  onSelectMachine={(val) => {
                    setSelectedMachines(val);

                    onSelectMachines(val);
                  }}
                />
              </div>
            </div>
          </div>
        </TeachingBubble>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  displayWeekView: state.machine.displayWeekView,
});

export default connect(mapStateToProps, { toggleDisplayWeekView })(
  CalendarControlsDetailedPlanning
);
