import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import {
  PrimaryButton,
  DefaultButton,
  Stack,
  DirectionalHint,
} from "@fluentui/react";
import {
  CALENDAR_PAGE,
  COLOR_CONS_HEX,
  ZOOM_CONTROL,
  colorList,
} from "../../../utils/constants";
import { primaryButtonStyles } from "../../../utils/theme";
import { useHistory } from "react-router-dom";
import { TooltipForText } from "../";
import { Calendar, DateRangeType } from "@fluentui/react";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { useBoolean } from "@fluentui/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCalendarDay,
  faCalendarWeek,
  faUmbrellaBeach,
  faCalendarPlus,
  faPrint,
  faEllipsisV,
  faCompressAlt,
  faPlus,
  faMinus,
  faKeyboard,
  faPencil,
  faTools,
  faCalendarCircleUser,
  faPencilRuler,
  faPowerOff,
} from "@fortawesome/pro-regular-svg-icons";
import { connect } from "react-redux";
import {
  toggleDisplayMachineService,
  toggleDisplayWeekView,
} from "../../../redux/machines/machines.actions";
import { buttonStyle } from "./ButtonStyles";
import ToggleButton from "../ToggleButton";
import WeekCalendar from "./WeekCalendar";
import {
  getMonday,
  hasEditRight,
  modifyCustomFilters,
} from "../../../utils/utils";
import { changePrebookingBubbleInfo } from "../../../redux/prebooking/prebooking.actions";
import {
  changeCRMOverlay,
  setBarDrawer,
  setBarDrawingMachineService,
  setMachineServiceBubble,
  setStaffVacationBubble,
  toggleInactiveProject,
} from "../../../redux/app/app.actions";
import FixPositionBubble from "../FixPositionBubble";
import { updateSettingFilter } from "../../../redux/user/user.actions";
import { useMutation } from "react-query";
import { postNewFilterSetting } from "../../../redux/settingManagement/settingManagement.action";
import MachineServiceBubbleUI from "../../pages/machines/MachineServiceBubbleUI";

const iconStyle = {
  marginRight: 2,
  fontSize: 16,
};

const classNames = mergeStyleSets({
  controls: {
    display: "flex",
    position: "fixed",
    justifyContent: "flex-end",
    width: 730,
    zIndex: 1002,
  },
  newPrebookingSelection: {
    display: "flex",
    alignItems: "center",
    fontSize: 12,
    width: 70,
    height: 32,
    fontFamily: "Verdana",
    cursor: "pointer",
    marginLeft: -5,
    selectors: {
      ":hover": {
        backgroundColor: "#99b3ff",
        borderRadius: 2,
      },
    },
  },
  unSelectedIcon: {
    ...iconStyle,
    color: "#006CAD",
  },
  selectedIcon: {
    ...iconStyle,
    color: "#fff",
  },
  iconNumber: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    borderRadius: "50%",
    marginTop: -18,
    marginLeft: 28,
    color: "#FFFFFF",
    fontSize: 12,
    height: 18,
    width: 18,
    backgroundColor: "#006CAD",
  },
});

const zoomIconStyle = (enable) => ({
  marginRight: 2,
  fontSize: 16,
  color: enable ? "#006CAD" : "#C8C8C8",
  cursor: enable ? "pointer" : "default",
});

const CalendarControlsRight = ({
  scrollX,
  scrollY,
  calendarPage,
  onClickChooseDate,
  toggleDisplayMachineService,
  displayMachineService,
  toggleDisplayWeekView,
  displayWeekView,
  displayStaffView,
  selectedDayOnCalendar,
  techDepartment,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const allowEdit = hasEditRight(userRoles);
  const defaultFilter = useSelector(
    (state) => state?.user?.user?.defaultFilter
  );
  const { crmCounter, showInactiveProject } = useSelector((state) => state.app);
  const userZoomRatio = 1;
  const realPageWidth = document.documentElement.offsetWidth;
  const calendarStartFrom =
    useSelector(
      (state) => state?.user?.user?.defaultFilter.customFilters
    )?.find((item) => item.key === "calendarStartFrom")?.text || "month";
  const [
    teachingBubbleShowChooseMonth,
    { toggle: toggleTeachingShowChooseMonth },
  ] = useBoolean(false);
  const [showActions, { toggle: toggleShowActions }] = useBoolean(false);
  const [showMachineServiceForm, { toggle: toggleShowMachineServiceForm }] =
    useBoolean(false);
  const [showNewPrebooking, { toggle: toggleShowNewPrebooking }] =
    useBoolean(false);
  const [showZoomBubble, { toggle: toggleShowZoomBubble }] = useBoolean(false);

  const { mutate: mutateNewFilterSetting } = useMutation(
    (settingData) => dispatch(postNewFilterSetting(settingData, false)),
    {
      onSuccess: (data, settingData) => {
        if (data) {
          dispatch(updateSettingFilter(settingData));
        }
      },
    }
  );

  const handleTodayClick = () => {
    const monday = getMonday(new Date());
    onClickChooseDate(monday);
  };

  useEffect(() => {
    if (teachingBubbleShowChooseMonth) {
      // wait for calendar to be visible
      const ref = setTimeout(() => {
        const goTodayButton = document.getElementsByClassName("js-goToday");
        for (let i = 0; i < goTodayButton.length; i++) {
          goTodayButton[i].textContent = "Go to current month";
          goTodayButton[i].addEventListener("click", handleTodayClick);
        }
      }, 50);

      return () => {
        clearTimeout(ref);
      };
    }
  }, [teachingBubbleShowChooseMonth]);
  const controlTransform = {
    transform: `translate(0, ${
      scrollY > (calendarPage === CALENDAR_PAGE.DISPOSITION ? 140 : 233)
        ? -(calendarPage === CALENDAR_PAGE.DISPOSITION ? 140 : 233)
        : -scrollY
    }px)`,
  };

  const handleZoom = (type) => {
    const nextValue =
      ZOOM_CONTROL[
        ZOOM_CONTROL.indexOf(userZoomRatio) + (type === "zoom in" ? 1 : -1)
      ];
    if (nextValue) changeDefaultZoomValue(nextValue);
  };

  const changeDefaultZoomValue = (value) => {
    const newSettings = modifyCustomFilters(defaultFilter, "zoomRatio", value);
    mutateNewFilterSetting(newSettings);
  };

  return (
    <Fragment>
      {realPageWidth > 1000 ? (
        <div
          className={classNames.controls}
          style={{ ...controlTransform, right: 128 }}
        >
          <Stack horizontal wrap tokens={{ childrenGap: 30 }}>
            {calendarPage === CALENDAR_PAGE.DISPOSITION ? (
              <Stack horizontal wrap>
                {/* Now Funktionær and disponent can both open the sync project */}
                {["4", "3"].some((item) => userRoles?.includes(item)) && (
                  <TooltipForText text="Indsæt projekt" noWidth>
                    <DefaultButton
                      styles={buttonStyle(false)}
                      onClick={() => dispatch(changeCRMOverlay(true))}
                    >
                      <FontAwesomeIcon
                        icon={faPencilRuler}
                        className={classNames.unSelectedIcon}
                      />
                      {crmCounter > 0 && (
                        <span
                          style={{
                            backgroundColor: crmCounter
                              ? COLOR_CONS_HEX.RED
                              : COLOR_CONS_HEX.BLUE,
                          }}
                          className={classNames.iconNumber}
                        >
                          {crmCounter}
                        </span>
                      )}
                    </DefaultButton>
                  </TooltipForText>
                )}
                <TooltipForText text="Generate PDF" noWidth>
                  <DefaultButton
                    styles={buttonStyle(false)}
                    onClick={() => {
                      history.push(
                        `/select-week?techDepartment=${techDepartment}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPrint}
                      className={classNames.unSelectedIcon}
                    />
                  </DefaultButton>
                </TooltipForText>
                {allowEdit &&
                  (!displayStaffView ? (
                    <>
                      <TooltipForText text="New Prebooking" noWidth>
                        <DefaultButton
                          id="newPrebooking"
                          styles={buttonStyle(false)}
                          onClick={toggleShowNewPrebooking}
                        >
                          <FontAwesomeIcon
                            icon={faCalendarPlus}
                            className={classNames.unSelectedIcon}
                          />
                        </DefaultButton>
                      </TooltipForText>
                      <TooltipForText text="Draw New Machine Service" noWidth>
                        <DefaultButton
                          id="drawNewMachineService"
                          styles={buttonStyle(false)}
                          onClick={() => {
                            toggleShowMachineServiceForm();
                            toggleDisplayMachineService(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faTools}
                            className={classNames.unSelectedIcon}
                          />
                        </DefaultButton>
                      </TooltipForText>
                    </>
                  ) : (
                    <TooltipForText text="Draw New Staff Vacation" noWidth>
                      <DefaultButton
                        id="drawNewStaffVacation"
                        styles={buttonStyle(false)}
                        onClick={() => {
                          dispatch(setBarDrawer("staffVacation"));
                          toggleDisplayMachineService(true);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faCalendarCircleUser}
                          className={classNames.unSelectedIcon}
                        />
                      </DefaultButton>
                    </TooltipForText>
                  ))}
                <TooltipForText
                  text={
                    displayStaffView
                      ? displayMachineService
                        ? "Turn off Staff vacation"
                        : "Turn on Staff vacation"
                      : displayMachineService
                      ? "Turn off Machine service"
                      : "Turn on Machine service"
                  }
                  noWidth
                >
                  <DefaultButton
                    onClick={toggleDisplayMachineService}
                    styles={buttonStyle(displayMachineService)}
                  >
                    <FontAwesomeIcon
                      icon={faUmbrellaBeach}
                      className={
                        displayMachineService
                          ? classNames.selectedIcon
                          : classNames.unSelectedIcon
                      }
                    />
                  </DefaultButton>
                </TooltipForText>
                <TooltipForText
                  text={
                    showInactiveProject
                      ? "Showing inactive project"
                      : "Hiding inactive project"
                  }
                  noWidth
                >
                  <DefaultButton
                    styles={buttonStyle(showInactiveProject)}
                    onClick={() => {
                      dispatch(toggleInactiveProject());
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      className={
                        showInactiveProject
                          ? classNames.selectedIcon
                          : classNames.unSelectedIcon
                      }
                    />
                  </DefaultButton>
                </TooltipForText>
                {/* <TooltipForText height={36} text='Brug uge og månedsvisning istedet for zoom eller zoom i browser (ctrl +/-) denne knap forsvinder 1/3 2023' noWidth> */}
                {/* <TooltipForText text='Zoom (Disabled)' noWidth>
                                    <DefaultButton onClick={()=>{
                                        // toggleShowZoomBubble()
                                        }}
                                        disabled
                                        id='pageZoom' 
                                        styles={buttonStyle(false)}
                                    >
                                        <FontAwesomeIcon icon={faCompressAlt} className={classNames.unSelectedIcon} />
                                    </DefaultButton>
                                </TooltipForText> */}
              </Stack>
            ) : null}
            {calendarPage === CALENDAR_PAGE.MACHINE_SERVICE && allowEdit && (
              <PrimaryButton
                id="newMachineService"
                text="NYT SERVICE"
                onClick={() => {
                  dispatch(setMachineServiceBubble(true));
                }}
                styles={primaryButtonStyles}
              />
            )}
            {calendarPage === CALENDAR_PAGE.STAFF && (
              <PrimaryButton
                id="newStaffVacation"
                text="Add New"
                onClick={() => {
                  dispatch(setStaffVacationBubble(true));
                }}
                styles={primaryButtonStyles}
              />
            )}
            <Stack horizontal>
              <TooltipForText
                text={displayWeekView ? "Weeks View" : "Days View"}
              >
                <ToggleButton
                  styles={{ marginRight: 15 }}
                  toggle={displayWeekView}
                  onClick={() => {
                    toggleDisplayWeekView(!displayWeekView);
                  }}
                  LeftIcon={() => (
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      className={
                        displayWeekView
                          ? classNames.unSelectedIcon
                          : classNames.selectedIcon
                      }
                    />
                  )}
                  RightIcon={() => (
                    <FontAwesomeIcon
                      icon={faCalendarWeek}
                      className={
                        displayWeekView
                          ? classNames.selectedIcon
                          : classNames.unSelectedIcon
                      }
                    />
                  )}
                />
              </TooltipForText>
              <TooltipForText
                text={
                  calendarStartFrom === "month" ? "Choose month" : "Choose week"
                }
                noWidth
              >
                <DefaultButton
                  id="showChooseMonth"
                  styles={buttonStyle(false)}
                  onClick={toggleTeachingShowChooseMonth}
                >
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className={classNames.unSelectedIcon}
                  />
                </DefaultButton>
              </TooltipForText>
            </Stack>
          </Stack>
        </div>
      ) : (
        <div
          className={classNames.controls}
          style={{ ...controlTransform, right: 10 }}
        >
          <DefaultButton
            id="showActions"
            styles={buttonStyle(false)}
            onClick={toggleShowActions}
          >
            <FontAwesomeIcon
              icon={faEllipsisV}
              className={classNames.unSelectedIcon}
            />
          </DefaultButton>
        </div>
      )}

      {/* Choose month bubble */}
      {teachingBubbleShowChooseMonth && (
        <FixPositionBubble
          target={
            document.getElementById("showActions")
              ? "#showActions"
              : "#showChooseMonth"
          }
          onDismiss={toggleTeachingShowChooseMonth}
          calloutProps={{
            calloutWidth: calendarStartFrom === "month" ? 270 : 400,
          }}
          mainPositionRight={realPageWidth > 1000 ? "207.3px" : "89.3px"}
        >
          {calendarStartFrom === "month" ? (
            <Calendar
              dateRangeType={DateRangeType.Month}
              value={selectedDayOnCalendar}
              showGoToToday
              highlightSelectedMonth
              isDayPickerVisible={false}
              onSelectDate={onClickChooseDate}
            />
          ) : (
            <WeekCalendar onClickChooseDate={onClickChooseDate} />
          )}
        </FixPositionBubble>
      )}

      {/* Actions bubble */}
      {showActions && (
        <FixPositionBubble
          target="#showActions"
          onDismiss={toggleShowActions}
          mainPositionRight="89.3px"
        >
          <Stack
            horizontal
            wrap
            tokens={{ childrenGap: 5 }}
            style={{ maxWidth: "unset" }}
          >
            {calendarPage === CALENDAR_PAGE.DISPOSITION ? (
              <Stack horizontal wrap tokens={{ childrenGap: -10 }}>
                {allowEdit && (
                  <TooltipForText text="Indsæt projekt" noWidth>
                    <DefaultButton
                      styles={buttonStyle(false, "#fff")}
                      onClick={() => dispatch(changeCRMOverlay(true))}
                    >
                      <FontAwesomeIcon
                        icon={faPencilRuler}
                        className={classNames.unSelectedIcon}
                      />
                      {crmCounter > 0 && (
                        <span
                          style={{
                            backgroundColor: crmCounter
                              ? COLOR_CONS_HEX.RED
                              : COLOR_CONS_HEX.BLUE,
                          }}
                          className={classNames.iconNumber}
                        >
                          {crmCounter}
                        </span>
                      )}
                    </DefaultButton>
                  </TooltipForText>
                )}
                <TooltipForText text="Generate PDF" noWidth>
                  <DefaultButton
                    styles={buttonStyle(false, "#fff")}
                    onClick={() => {
                      history.push(
                        `/select-week?techDepartment=${techDepartment}`
                      );
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPrint}
                      className={classNames.unSelectedIcon}
                    />
                  </DefaultButton>
                </TooltipForText>
                {allowEdit && !displayStaffView && (
                  <TooltipForText text="New Prebooking" noWidth>
                    <DefaultButton
                      id="newPrebooking"
                      styles={buttonStyle(false, "#fff")}
                      onClick={() => {
                        toggleShowNewPrebooking();
                        toggleShowActions();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCalendarPlus}
                        className={classNames.unSelectedIcon}
                      />
                    </DefaultButton>
                  </TooltipForText>
                )}
                <TooltipForText
                  text={
                    displayStaffView
                      ? displayMachineService
                        ? "Turn off Staff vacation"
                        : "Turn on Staff vacation"
                      : displayMachineService
                      ? "Turn off Machine service"
                      : "Turn on Machine service"
                  }
                  noWidth
                >
                  <DefaultButton
                    onClick={toggleDisplayMachineService}
                    styles={buttonStyle(displayMachineService, "#fff")}
                  >
                    <FontAwesomeIcon
                      icon={faUmbrellaBeach}
                      className={
                        displayMachineService
                          ? classNames.selectedIcon
                          : classNames.unSelectedIcon
                      }
                    />
                  </DefaultButton>
                </TooltipForText>
                {/* <TooltipForText height={36} text='Brug uge og månedsvisning istedet for zoom eller zoom i browser (ctrl +/-) denne knap forsvinder 1/3 2023' noWidth> */}
                {/* <TooltipForText text='Zoom (Disabled)' noWidth>
                                    <DefaultButton
                                        id='pageZoom'
                                        onClick={() => {
                                            // toggleShowZoomBubble();
                                            // toggleShowActions();
                                        }}
                                        styles={buttonStyle(false, '#fff')}
                                        disabled
                                    >
                                        <FontAwesomeIcon icon={faCompressAlt} className={classNames.unSelectedIcon} />
                                    </DefaultButton>
                                </TooltipForText> */}
                <TooltipForText
                  text={
                    showInactiveProject
                      ? "Showing inactive project"
                      : "Hiding inactive project"
                  }
                  noWidth
                >
                  <DefaultButton
                    styles={buttonStyle(showInactiveProject, "#fff")}
                    onClick={() => {
                      dispatch(toggleInactiveProject());
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      className={
                        showInactiveProject
                          ? classNames.selectedIcon
                          : classNames.unSelectedIcon
                      }
                    />
                  </DefaultButton>
                </TooltipForText>
              </Stack>
            ) : null}
            {calendarPage === CALENDAR_PAGE.MACHINE_SERVICE && allowEdit && (
              <PrimaryButton
                id="newMachineService"
                text="NYT SERVICE"
                onClick={() => {
                  dispatch(setMachineServiceBubble(true));
                }}
                styles={primaryButtonStyles}
              />
            )}
            {calendarPage === CALENDAR_PAGE.STAFF && (
              <PrimaryButton
                text="Add New"
                onClick={() => {
                  dispatch(setStaffVacationBubble(true));
                }}
                styles={primaryButtonStyles}
              />
            )}
            <Stack horizontal tokens={{ childrenGap: -10 }}>
              {allowEdit &&
                calendarPage === CALENDAR_PAGE.DISPOSITION &&
                (!displayStaffView ? (
                  <TooltipForText text="Draw New Machine Service" noWidth>
                    <DefaultButton
                      id="drawNewMachineService"
                      styles={buttonStyle(false, "#fff")}
                      onClick={() => {
                        toggleShowMachineServiceForm();
                        toggleDisplayMachineService(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTools}
                        className={classNames.unSelectedIcon}
                      />
                    </DefaultButton>
                  </TooltipForText>
                ) : (
                  <TooltipForText text="Draw New Staff Vacation" noWidth>
                    <DefaultButton
                      id="drawNewStaffVacation"
                      styles={buttonStyle(false, "#fff")}
                      onClick={() => {
                        toggleShowActions();
                        dispatch(setBarDrawer("staffVacation"));
                        toggleDisplayMachineService(true);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCalendarCircleUser}
                        className={classNames.unSelectedIcon}
                      />
                    </DefaultButton>
                  </TooltipForText>
                ))}
              <TooltipForText
                text={displayWeekView ? "Weeks View" : "Days View"}
              >
                <ToggleButton
                  styles={{ marginRight: 15 }}
                  toggle={displayWeekView}
                  onClick={() => {
                    toggleDisplayWeekView(!displayWeekView);
                  }}
                  LeftIcon={() => (
                    <FontAwesomeIcon
                      icon={faCalendarDay}
                      className={
                        displayWeekView
                          ? classNames.unSelectedIcon
                          : classNames.selectedIcon
                      }
                    />
                  )}
                  RightIcon={() => (
                    <FontAwesomeIcon
                      icon={faCalendarWeek}
                      className={
                        displayWeekView
                          ? classNames.selectedIcon
                          : classNames.unSelectedIcon
                      }
                    />
                  )}
                />
              </TooltipForText>
              <TooltipForText text="Choose month" noWidth>
                <DefaultButton
                  id="showChooseMonth"
                  styles={buttonStyle(false, "#fff")}
                  onClick={() => {
                    toggleTeachingShowChooseMonth();
                    toggleShowActions();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCalendarAlt}
                    className={classNames.unSelectedIcon}
                  />
                </DefaultButton>
              </TooltipForText>
            </Stack>
          </Stack>
        </FixPositionBubble>
      )}

      {/* Zoom bubble */}
      {showZoomBubble && (
        <FixPositionBubble
          target={
            document.getElementById("showActions")
              ? "#showActions"
              : "#pageZoom"
          }
          onDismiss={toggleShowZoomBubble}
          mainPositionRight={realPageWidth > 1000 ? "278px" : "-9px"}
          beakPositionRight={realPageWidth > 1000 ? "104.5px" : "52.5px"}
          calloutProps={{
            directionalHint: DirectionalHint.bottomCenter,
            calloutWidth: 250,
            calloutheight: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              color: "#000",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "40%",
                fontFamily: "Verdana",
                fontSize: 14,
                userSelect: "none",
              }}
            >
              {userZoomRatio * 100}%
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                justifyContent: "space-around",
                marginRight: 10,
              }}
            >
              <div onClick={() => handleZoom("zoom out")}>
                <FontAwesomeIcon
                  icon={faMinus}
                  style={zoomIconStyle(
                    ZOOM_CONTROL[ZOOM_CONTROL.indexOf(userZoomRatio) - 1]
                  )}
                />
              </div>
              <div onClick={() => handleZoom("zoom in")}>
                <FontAwesomeIcon
                  icon={faPlus}
                  style={zoomIconStyle(
                    ZOOM_CONTROL[ZOOM_CONTROL.indexOf(userZoomRatio) + 1]
                  )}
                />
              </div>
            </div>
            <div style={{ width: "30%", marginRight: 10 }}>
              <DefaultButton
                onClick={() => changeDefaultZoomValue(1)}
                styles={{
                  root: {
                    borderRadius: 4,
                    height: 30,
                    width: 40,
                    borderColor: "#006CAD",
                    color: "#006CAD",
                    backgroundColor: "#fff",
                  },
                  label: { fontWeight: 200 },
                  rootHovered: {
                    backgroundColor: "#99b3ff",
                  },
                }}
              >
                Reset
              </DefaultButton>
            </div>
          </div>
        </FixPositionBubble>
      )}

      {/* New prebooking bubble */}
      {showNewPrebooking && (
        <FixPositionBubble
          target={
            document.getElementById("showActions")
              ? "#showActions"
              : "#newPrebooking"
          }
          mainPositionRight={realPageWidth > 1000 ? "606.5px" : "-9px"}
          beakPositionRight={realPageWidth > 1000 ? "47px" : "52.5px"}
          onDismiss={toggleShowNewPrebooking}
          calloutProps={{
            directionalHint: DirectionalHint.bottomCenter,
            calloutWidth: 110,
            calloutheight: 50,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              className={classNames.newPrebookingSelection}
              onClick={() => {
                toggleShowNewPrebooking();
                dispatch(
                  changePrebookingBubbleInfo({
                    showPrebookingModal: true,
                    bubbleTarget: document.getElementById("showActions")
                      ? "showActions"
                      : "newPrebooking",
                  })
                );
              }}
            >
              &ensp;
              <FontAwesomeIcon
                icon={faKeyboard}
                className={classNames.unSelectedIcon}
              />{" "}
              &ensp;Input
            </div>
            {!displayStaffView ? (
              <div
                className={classNames.newPrebookingSelection}
                onClick={() => {
                  dispatch(setBarDrawer("prebooking"));
                  toggleDisplayMachineService(true);
                  toggleShowNewPrebooking();
                }}
              >
                &ensp;
                <FontAwesomeIcon
                  icon={faPencil}
                  className={classNames.unSelectedIcon}
                />{" "}
                &ensp;Draw
              </div>
            ) : null}
          </div>
        </FixPositionBubble>
      )}
      {showMachineServiceForm ? (
        <MachineServiceBubbleUI
          targetId={"#drawNewMachineService"}
          machinesData={[]}
          onExit={() => {
            toggleShowMachineServiceForm();
          }}
          onSubmit={(val) => {
            toggleShowMachineServiceForm();

            dispatch(setBarDrawingMachineService(val));
            dispatch(setBarDrawer("machineService"));
          }}
          showMachineSelector={false}
          text="Draw"
        />
      ) : (
        <></>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  displayMachineService: state.machine.displayMachineServiceOnPlanning,
  displayWeekView: state.machine.displayWeekView,
  displayStaffView: state.machine.displayStaffOnPlanning,
  selectedDayOnCalendar: state.machine.selectedDayOnCalendar,
  techDepartment: state?.machine?.filter.techDepartment,
});

export default connect(mapStateToProps, {
  toggleDisplayMachineService,
  toggleDisplayWeekView,
})(CalendarControlsRight);
