import React from "react";
import { connect, useSelector } from "react-redux";
import { useQueryClient, useMutation } from "react-query";
import { createProject } from "../../redux/project/project.actions";
import { useHistory } from "react-router-dom";
import { ProjectForm } from "./projects/index";
import { Formik } from "formik";
import * as Yup from "yup";
import { formatDate } from "../../utils/utils";
import {
  ACTIVITY_LOG_TYPE_ENUM,
  ACTIVITY_LOG_ENUM,
} from "../../utils/constants";

const NewProject = ({ createProject, defaultData }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const currentUserId = useSelector((state) => state?.user?.user?.userId);

  const geoObjectShape = {
    latitude: Yup.number(),
    longitude: Yup.number(),
  };

  const oneOfSection = defaultData?.sectionOptions.map(
    (sizeGroup) => sizeGroup.key,
  ) ?? [220, 230, 260, 290];

  const validationSchema = Yup.object({
    hovedsagsNummer: Yup.string("Enter a name").required(
      "Hovedsagsnummer is required",
    ),
    projectNo: Yup.string("Enter a name").required("ProjectId is required"),
    projectName: Yup.string("Enter a name").required("Name is required"),
    projectDescription: Yup.string("Enter description"),
    projectType: Yup.string().required("Type is required"),
    color: Yup.string("Enter a name").required("color is required"),
    period: Yup.array().of(Yup.date()).required("Period is required"),
    section: Yup.mixed().oneOf(oneOfSection).required("Section is required"),
    isQuotation: Yup.boolean(),
    techDepartments: Yup.array()
      .of(Yup.mixed())
      .required("Department is required"),
    geoCoordinate: Yup.object().shape(geoObjectShape),
    personResponsbible: Yup.string().nullable(),
    reason: Yup.string().nullable(),
  });

  const mutationCreateProject = useMutation(
    (project) => createProject(project),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("projects");
        if (variables.redirectToMachineReq) {
          history.push(`/add-machine-requirements/${data.projectId}`);
        } else {
          history.push("/projects");
        }
      },
    },
  );

  const formSubmit = (data) => {
    const [start, end] = formatDate(data.period);
    const activityLog = {
      type: ACTIVITY_LOG_TYPE_ENUM.CREATE,
      resourceId: null,
      resourceType: ACTIVITY_LOG_ENUM.PROJECT,
      userId: currentUserId,
      comment: `Created project ${data.projectName}`,
    };
    const personResponsbible = {
      userId: null,
      name: data.personResponsbible,
    };
    const project = {
      hovedsagsNummer: data.hovedsagsNummer,
      projectNo: data.projectNo,
      projectName: data.projectName,
      projectDescription: data.projectDescription,
      color: data.color,
      projectType: String(data.projectType),
      start,
      section: data.section,
      end,
      isQuotation: data.isQuotation,
      reason: data.reason,
      personResponsbible,
      techDepartments: data.techDepartments,
      geoCoordinate: data.geoCoordinate,
      redirectToMachineReq: !!data.redirectToMachineReq,
      requiredSkills: data.requiredSkills,
      activityLog,
    };
    mutationCreateProject.mutate(project);
  };

  return (
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={formSubmit}
    >
      {(props) => <ProjectForm history={history} {...props} />}
    </Formik>
  );
};

const mapStateToProps = (state) => ({
  defaultData: state.defaultData,
});

export default connect(mapStateToProps, { createProject })(NewProject);
