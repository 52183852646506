import { Formik } from "formik";
import { Fragment } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import {
  getTeamByTeamId,
  updateTeam,
  getMachines,
} from "../../redux/machines/machines.actions";
import { MachineTeamForm } from "./machines/index";
import { getStaffs } from "../../redux/user/user.actions";
// import { techDepartmentOptions } from "../../utils/constants";

const Team = ({ updateTeam, getTeamByTeamId, getMachines, getStaffs }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { teamId } = useParams();

  const { data: machineData } = useQuery("machines", () => getMachines(), {
    staleTime: 36000,
  });
  const { data: staffData } = useQuery("staffs", () => getStaffs(), {
    staleTime: 36000,
  });
  const { data: teamData, status } = useQuery(["team", teamId], () =>
    getTeamByTeamId(teamId),
  );

  const validationSchema = Yup.object({
    name: Yup.string("Please enter a name").required("Name is required"),
    techDepartments: Yup.string()
      .min(1, "Minimum one department has to be selected.")
      .required("Department is required"),
    machineId: Yup.string().required("Machine is required"),
    drivers: Yup.array().required("Drivers is required").nullable(),
  });

  const mutationupdateTeam = useMutation(
    (machine) => updateTeam({ ...machine }),
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries("machines");
          queryClient.invalidateQueries(["machine", teamId]);
          // history.push("/machines/MACHINE_TABLE_TEAMS");
          history.push({
            pathname: "/machines",
            state: "MACHINE_TABLE_TEAMS",
          });
        }
      },
    },
  );

  const formSubmit = (data) => {
    const team = {
      id: data.id,
      name: data.name,
      machineId: data.machineId,
      techDepartments: data.techDepartments,
      drivers: data.drivers
        ? Array.isArray(data.drivers)
          ? data.drivers.map((v) => ({ id: v.id, machineId: data.machineId }))
          : [data.drivers].map((v) => ({ id: v.id, machineId: data.machineId }))
        : [],
      workers: data.workers
        ? Array.isArray(data.workers)
          ? data.workers.map((v) => ({ id: v.id, machineId: data.machineId }))
          : [data.workers].map((v) => ({ id: v.id, machineId: data.machineId }))
        : [],
      managers: data.managers
        ? Array.isArray(data.managers)
          ? data.managers.map((v) => ({ id: v.id, machineId: data.machineId }))
          : [data.managers].map((v) => ({
              id: v.id,
              machineId: data.machineId,
            }))
        : [],
    };
    mutationupdateTeam.mutate(team);
  };

  return (
    <Fragment>
      {status === "success" && teamData && (
        <Formik
          enableReinitialize
          initialValues={teamData}
          validationSchema={validationSchema}
          onSubmit={formSubmit}
        >
          {(props) => (
            <MachineTeamForm
              history={history}
              {...props}
              team={teamData}
              machineData={machineData}
              staffData={staffData}
            />
          )}
        </Formik>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  defaultData: state.defaultData,
});

export default connect(mapStateToProps, {
  updateTeam,
  getTeamByTeamId,
  getMachines,
  getStaffs,
})(Team);
