import React from 'react';
import { useSelector } from 'react-redux';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Label } from '@fluentui/react';

const CalendarHeaderLeft = ({title="", subTitle=""}) => {

    const classNames = mergeStyleSets({
        header: [{
            display: 'flex',
            justifyContent: 'space-between',
            width: 250,
            height: 72,
            margin: `40px 0 40px 40px`,
        }],
        title: [{
            fontWeight: 'bold',
            fontSize: '32px',
            lineHeight: '40px',
        }],
        subTitle: [{
            fontSize: '11px',
            lineHeight: '11px',
        }],
    });

    return (
        <div className={classNames.header}>
            <div>
                <Label className={classNames.title}>{title}</Label>
                <Label className={classNames.subTitle}>{subTitle}</Label>
            </div>
        </div>
    )
}

export default CalendarHeaderLeft;