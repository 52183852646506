import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery, useQueryClient } from 'react-query';
import { getProject } from '../../../../redux/project/project.actions';
import { getPlan, clearPlanOverlayProjectId } from '../../../../redux/plan/plan.actions';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { Panel } from '@fluentui/react';
import { UserSlots } from './';
import ProjectDetails from './ProjectDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import PictureAndCommentSection from './PictureAndCommentSection';
import ScreenSizeAndPosition from '../../../common/ScreenSizeAndPosition';
import PlanReview from './PlanReview';

const classNames = mergeStyleSets({
    buttonBottomContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    closeButtonOutside: {
        zIndex: 1,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 20,
        left: -40,
        height: 40,
        width: 40,
        background: '#DFDFDF',
        cursor: 'pointer',
        boxShadow: 'rgb(0 0 0 / 22%) -5px 0px 5px 0px, rgb(0 0 0 / 18%) -14px 0px 20px 0px',
        borderTopLeftRadius: '10%',
        borderBottomLeftRadius: '10%',
    },
    closeButtonInside: {
        width: '80%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        selectors: {
            ':hover': {
                background: 'rgba(0, 0, 0, 0.05)',
            },
        },
    },
});

const overlayStyle = {
    main: {
        overflow: 'visible',
        minWidth: '50%',
        background: '#DFDFDF',
    },
};

const PlanOverlay = ({ getProject, getPlan, overlayPlanIndex, clearPlanOverlayProjectId, overlayProjectId }) => {
    const queryClient = useQueryClient();

    const { data: projectData, refetch: refetchProject, isSuccess: projectIsSuccess } = useQuery(['project', overlayProjectId], () => getProject(overlayProjectId), {
        enabled: !!overlayProjectId,
    });
    const { data: planData, isSuccess: planIsSuccess } = useQuery(['plan', overlayProjectId, overlayPlanIndex], () => getPlan(overlayProjectId), {
        enabled: !!overlayProjectId,
        initialData: () => queryClient.getQueryData('plans')?.find((plan) => plan.projectId === overlayProjectId),
    });

    return (
        <Panel
            // To entirely disable the default dismiss behavior:
            // 1. Don't provide an onDismiss prop
            isOpen={!!overlayProjectId} // 2. Control the visibility
            hasCloseButton={false}
            customWidth='500px'
            styles={overlayStyle}
        >
            <div onClick={clearPlanOverlayProjectId} className={classNames.closeButtonOutside}>
                <div className={classNames.closeButtonInside}>
                    <FontAwesomeIcon icon={faTimes} style={{ color: projectIsSuccess && projectData && `rgb(${projectData['color']})`, fontSize: 30 }} />
                </div>
            </div>
            <div className={classNames.innerContainer}>
                {projectIsSuccess && planIsSuccess && projectData && (
                    <Fragment>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, marginRight: 12, maxWidth: '50%' }}>
                                <ProjectDetails refetchProject={refetchProject} projectData={projectData} planData={planData} overlayPlanIndex={overlayPlanIndex} clearPlanOverlayProjectId={clearPlanOverlayProjectId}/>
                            </div>
                            <div style={{ flex: 1, marginLeft: 12 }} className={classNames.descriptionAndPicture}>
                                <ScreenSizeAndPosition>
                                    <PictureAndCommentSection
                                        projectId={overlayProjectId}
                                        projectColor={projectData.color}
                                        planData={planData}
                                        overlayPlanIndex={overlayPlanIndex}
                                    />
                                </ScreenSizeAndPosition>
                            </div>
                        </div>
                        {/* {planIsSuccess && planData &&
                            <UserSlots projectId={overlayProjectId} planData={planData} index={overlayPlanIndex} projectData={projectData}/>
                        } */}
                        {/* <div className={classNames.buttonBottomContainer}>
                            <DefaultButton text="REDIGER PLANEN" onClick={onClickToDetailPage} styles={defaultButtonStyles} />
                        </div> */}
                        {planIsSuccess && planData && <PlanReview data={planData?.machineRequirements[overlayPlanIndex]} />}
                    </Fragment>
                )}
            </div>
        </Panel>
    );
};

const mapStateToProps = (state) => ({
    overlayProjectId: state.plan.overlayProjectId,
    overlayPlanIndex: state.plan.overlayPlanIndex,
});

export default connect(mapStateToProps, { getProject, getPlan, clearPlanOverlayProjectId })(PlanOverlay);
