import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { DateRange } from 'react-date-range';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import { useBoolean } from '@fluentui/react-hooks';
import { defaultButtonStyles, primaryButtonStyles } from '../../utils/theme';
import { DefaultButton, PrimaryButton } from '@fluentui/react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';
import { saveMessage } from '../../redux/message/message.actions';
import { getHolidayList } from '../../redux/app/app.actions';
import { checkTimeOverlap, getMonthNumberFromName, handleBeforeSubmitPeriod } from '../../utils/utils';

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgb(159, 159, 159)',
    marginTop: 24,
    padding: 8,
    borderRadius: 4,
    position: 'relative',
    height: 30,
};

const iconStyle = {
    fontSize: 20,
    cursor: 'pointer',
    position: 'relative',
    top: -8,
    right: 10,
};

const teachingBubbleStyle = {
    bodyContent: {
        width: 500,
    },
};

const CalendarCustom = ({
    period = [new Date(), new Date()],
    setPeriod,
    isLongVersion = true,
    targetInfo = null,
    bookedSchedules = null,
    styles,
    liteVersion = false,
    isEdit,
}) => {
    if (isEdit) {
        bookedSchedules = bookedSchedules.filter(item => item.start.slice(0, 10) !== moment(period[0]).format('YYYY-MM-DD') && item.end.slice(0, 10) !== moment(period[1]).format('YYYY-MM-DD'));
    }
    const classNames = mergeStyleSets({
        container: {
            ...containerStyle,
            height: liteVersion ? 20 : 30,
            padding: liteVersion ? 0 : 8,
        },
        shortContainer: {
            ...containerStyle,
            marginTop: 0,
            width: 300,
        },
        content: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative',
            top: -2,
        },
        textContainer: {
            width: liteVersion ? '90%' : '80%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            overflow: 'hidden',
        },
        input: {
            border: 'none',
            width: 100,
            backgroundColor: '#f1f1f1',
            fontFamily: 'Verdana',
            fontSize: 14,
        },
        label: {
            position: 'relative',
            top: -4,
            fontWeight: 600,
            fontSize: 11,
        },
        icon: {
            ...iconStyle,
        },
        shortIcon: {
            ...iconStyle,
            right: 4,
            top: liteVersion ? 0 : 8,
        },
        pickerControlContainer: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 8,
        },
        cancel: {
            marginRight: 10,
        },
    });
    const dispatch = useDispatch();
    const [openCalendar, { toggle: toggleOpenCalendar }] = useBoolean(false);
    const [start, end] = period;
    const [error, setError] = useState({ startText: false, endText: false, overlap: false });
    const formatDate = 'YYYY-MM-DD';
    const getDateText = (date) => {
        const dateText = moment(date, formatDate).format(formatDate);
        return dateText;
    };
    const [state, setState] = useState([
        {
            startDate: new Date(start).getTime() < 0 ? new Date() : start,
            startText: getDateText(start),
            endDate: new Date(end).getTime() < 0 ? new Date() : end,
            endText: getDateText(end),
            key: 'selection',
        },
    ]);
    const holidayRef = useRef(null);

    useEffect(() => {
        initPeriod();
    }, []);

    const customizeCalendar = (firstTime = false) => {
        // Change month picker icon
        const monthPicker = document.querySelectorAll('.rdrMonthPicker')[0];
        if (monthPicker) {
            monthPicker.firstChild.style = `
                width: 105px;
                margin-left: -12px;
                float: left;
                appearance: none;
                -webkit-appearance: none;
                background: url(${window.location.origin}/img/calendar.svg);
                background-repeat: no-repeat;
                background-size: 14px 16px;
                background-position: right 9px;
            `;

            // Return hover effect
            monthPicker.firstChild.onmouseover = () => {
                monthPicker.firstChild.style.backgroundColor = 'rgba(0,0,0,0.07)';
            };
            monthPicker.firstChild.onmouseleave = () => {
                monthPicker.firstChild.style.backgroundColor = 'rgb(255, 255, 255)';
            };
        }

        // Change the margin of month table
        const monthTablesContainer = document.querySelectorAll('.rdrMonths.rdrMonthsHorizontal');
        if (monthTablesContainer[0]) monthTablesContainer[0].style.marginLeft = '12px';
        const monthTables = document.querySelectorAll('.rdrMonth');
        for (let i = 0; i < monthTables.length; i++) {
            monthTables[i].style.margin = '0 12px';
        }

        // Show week on first day of week
        const dayNumber = document.querySelectorAll('.rdrDay.rdrDayStartOfWeek:not(.rdrDayPassive)');
        for (let i = 0; i < dayNumber.length; i++) {
            const day = dayNumber[i].lastChild.lastChild;
            const currentMonthYear = day.closest('div.rdrMonth').firstChild.innerHTML;
            const dayValue = day.innerText.includes('\n') ? day.innerText.split('\n')[1] : day.innerText.split(' ')[0];
            const monthValue = getMonthNumberFromName(currentMonthYear.split(' ')[0]);
            const yearValue = currentMonthYear.split(' ')[1];
            const weekNumber = moment(`${dayValue} ${monthValue} ${yearValue}`, 'DD MM YYYY').week();

            day.innerHTML = `
                <span
                    style="
                        position: fixed;
                        width: 20px;
                        color: #006CAD;
                        font-size: 9px;
                        margin-left: ${dayValue.length > 1 ? '-28px' : firstTime ? '-31px' : '-32px'};
                        border-right: 1px solid rgb(115, 115, 115, 0.3);
                    "
                >${weekNumber}</span>
                <span>${dayValue}</span>
            `;
        }

        const days = document.querySelectorAll('.rdrDay:not(.rdrDayPassive)');
        for (let i = 0; i < days.length; i++) {
            const day = days[i].lastChild.lastChild;
            const currentMonthYear = day.closest('div.rdrMonth').firstChild.innerHTML;
            const dayValue = day.innerText.includes('\n') ? day.innerText.split('\n')[1] : day.innerText.split(' ')[0];
            const monthValue = getMonthNumberFromName(currentMonthYear.split(' ')[0]);
            const yearValue = currentMonthYear.split(' ')[1];
            const date = new Date(yearValue, monthValue - 1, dayValue);
            const dateString = moment(date, 'YYYY-M-D').format('YYYY-MM-DD');
            // Highlight booked days
            if (bookedSchedules?.length) {
                for (const schedule of bookedSchedules) {
                    const currentStartDate = new Date(schedule.start);
                    const currentEndDate = new Date(schedule.end);
                    const isBooked = checkTimeOverlap(date, date, currentStartDate, currentEndDate);
                    if (isBooked) {
                        day.innerHTML = `
                            <span class="day-value ${dateString}">${day.innerHTML}</span>
                            <span 
                                style="
                                    position: fixed;
                                    width: ${dayValue.length > 1 ? '31px' : '32px'};
                                    border-bottom: 1px solid #ff0000;
                                    margin: 30px 0px 0px ${dayValue.length > 1 ? '-22px' : '-19px'};
                                "
                            />
                        `;
                        break;
                    }
                }
            }
        }

        // Add holiday tooltip
        async function getDatesWithHolidays() {
            const yearOfDates = findYearOfDates(days);
            let holidays = [];
            if (!holidayRef.current || (JSON.stringify(yearOfDates) !== JSON.stringify(holidayRef.current?.years))) {
                for (const year of yearOfDates) {
                    const currentHolidays = await dispatch(getHolidayList(year));
                    holidays = [...holidays, ...(currentHolidays?.holidays || [])];
                }
                holidayRef.current = {
                    years: yearOfDates,
                    holidays: holidays,
                }
            } else holidays = holidayRef.current.holidays;
            const dayValues = document.querySelectorAll('.day-value');
            for (let i = 0; i < dayValues.length; i++) {
                const currentDateString = dayValues[i].classList[1];
                const dayValue = parseInt(currentDateString.split('-').slice(-1)[0]);
                const holiday = holidays.find(item => item.date === currentDateString);
                if (holiday && holiday?.public) {
                    dayValues[i].innerHTML = `
                        <div class="tooltip" style="padding: ${dayValue < 10 ? '1px 5px' : '0px'}; margin: ${dayValue < 10 ? '-1px -5px' : '0px'}">${dayValue}
                            <span class="tooltiptext">${holiday.name}</span>
                        </div>
                    `
                }
            }
        }
        getDatesWithHolidays();
    };

    const findYearOfDates = (dayNodes) => {
        const firstDay = dayNodes[0].lastChild.lastChild;
        const firstDayMonthYear = firstDay.closest('div.rdrMonth').firstChild.innerHTML;
        const firstDayYearValue = firstDayMonthYear.split(' ')[1];
        const lastDay = dayNodes[dayNodes.length-1].lastChild.lastChild;
        const lastDayMonthYear = lastDay.closest('div.rdrMonth').firstChild.innerHTML;
        const lastDayYearValue = lastDayMonthYear.split(' ')[1];
        return [firstDayYearValue, lastDayYearValue];
    }

    const initPeriod = () => {
        const newPeriod = [state[0].startDate, state[0].endDate];
        setPeriod(newPeriod);
    };

    const onClickConfirm = () => {
        const newPeriod = [state[0].startDate, state[0].endDate];
        const overlap = handleBeforeSubmitPeriod(bookedSchedules, targetInfo, newPeriod, dispatch);
        if (!overlap) setPeriod(newPeriod);
        toggleOpenCalendar();
    };

    const onManualEnterDate = (key, dateText) => {
        const stateClone = { ...state[0] };
        stateClone[key] = dateText;
        const textToDate = moment(dateText, formatDate).toDate();
        if (textToDate.toString() === 'Invalid Date') {
            setError({
                ...error,
                [key]: `Invalid ${key === 'startText' ? 'start date' : 'end date'}(${formatDate})!`,
            });
        } else {
            stateClone[key === 'startText' ? 'startDate' : 'endDate'] = textToDate;
        }
        setState([stateClone]);
    };

    const checkPeriod = () => {
        if (state[0].startDate.getTime() > state[0].endDate.getTime()) {
            dispatch(saveMessage('Start date must be after end date!'));
            setState([
                {
                    ...state,
                    startText: state[0].endText,
                    startDate: state[0].endDate,
                },
            ]);
        } else {
            initPeriod();
        }
    };

    return (
        <div className={classNames.container} style={{ ...styles }}>
            {!liteVersion && <div className={classNames.label}>PERIOD</div>}
            <div className={classNames.content}>
                <div className={classNames.textContainer}>
                    <input
                        type='date'
                        className={classNames.input}
                        onChange={(e) => onManualEnterDate('startText', e.target.value)}
                        onBlur={checkPeriod}
                        value={state[0].startText}
                    />
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                    <input
                        type='date'
                        className={classNames.input}
                        onChange={(e) => onManualEnterDate('endText', e.target.value)}
                        onBlur={checkPeriod}
                        value={state[0].endText}
                    />
                </div>
                <div className={isLongVersion ? classNames.icon : classNames.shortIcon}>
                    <FontAwesomeIcon
                        id='openCalendar'
                        icon={faCalendarAlt}
                        onClick={() => {
                            toggleOpenCalendar();
                            setTimeout(() => customizeCalendar(true), 50);
                        }}
                    />
                </div>
            </div>
            {openCalendar && (
                <TeachingBubble
                    target='#openCalendar'
                    hasSmallHeadline={true}
                    onDismiss={toggleOpenCalendar}
                    closeButtonAriaLabel='Close'
                    calloutProps={{
                        calloutMinWidth: 548,
                    }}
                    styles={teachingBubbleStyle}
                >
                    <DateRange
                        onChange={(item) => {
                            setState([
                                {
                                    ...item.selection,
                                    startText: getDateText(item.selection.startDate),
                                    endText: getDateText(item.selection.endDate),
                                },
                            ]);
                            setTimeout(customizeCalendar, 50);
                        }}
                        onShownDateChange={() => {
                            setTimeout(customizeCalendar, 50);
                        }}
                        weekStartsOn={1}
                        moveRangeOnFirstSelection={false}
                        ranges={state}
                        months={2}
                        direction='horizontal'
                    />
                    <div className={classNames.pickerControlContainer}>
                        <DefaultButton styles={defaultButtonStyles} className={classNames.cancel} text='Annullere' onClick={toggleOpenCalendar} />
                        <PrimaryButton styles={primaryButtonStyles} text='Gem' onClick={onClickConfirm} />
                    </div>
                </TeachingBubble>
            )}
        </div>
    );
};

export default CalendarCustom;
