import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { PrimaryButton, DefaultButton, Stack } from "@fluentui/react";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { outlinedButtonStyles } from "../../../utils/theme";
import { setFilter } from "../../../redux/machines/machines.actions";
import { techDepartmentOptions, CALENDAR_PAGE } from "../../../utils/constants";
import {
  outlinedDropDownStyles,
  primaryButtonStyles,
} from "../../../utils/theme";
import { useHistory } from "react-router-dom";
import { Calendar, DateRangeType } from "@fluentui/react";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { useBoolean } from "@fluentui/react-hooks";

const classNames = mergeStyleSets({
  buttonContainer: {
    width: "calc(100% - 150px)",
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0",
  },
});

// const weekSelectorProps = {
//     items: [
//         { key: '8weeks', text: '8 uger', },
//         { key: '12weeks', text: '12 uger', },
//         { key: '16weeks', text: '16 uger', },
//     ],
// };
const CalendarControls = ({ calendarPage, onClickChooseDate }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { techDepartment } = useSelector((state) => state?.machine?.filter);
  const [
    teachingBubbleShowChooseMonth,
    { toggle: toggleTeachingShowChooseMonth },
  ] = useBoolean(false);
  return (
    <Fragment>
      <div className={classNames.buttonContainer}>
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          <Dropdown
            selectedKey={techDepartment}
            name="projectType"
            onChange={(event, item) =>
              dispatch(setFilter({ techDepartment: item.key }))
            }
            placeholder="Department"
            options={techDepartmentOptions}
            styles={outlinedDropDownStyles}
          />
        </Stack>
        <Stack horizontal wrap tokens={{ childrenGap: 20 }}>
          {/* <DefaultButton
                        disabled
                        text="8 uger"
                        menuProps={weekSelectorProps}
                        styles={outlinedButtonStyles}
                        onClick={() => {}}
                    /> */}
          <DefaultButton
            id="showChooseMonth"
            styles={outlinedButtonStyles}
            text="Choose Month"
            onClick={toggleTeachingShowChooseMonth}
          />
          {calendarPage === CALENDAR_PAGE.MACHINE_SERVICE && (
            <PrimaryButton
              text="NYT SERVICE"
              onClick={() => {
                history.push("/add-machine-service");
              }}
              styles={primaryButtonStyles}
            />
          )}
          {calendarPage === CALENDAR_PAGE.STAFF && (
            <PrimaryButton
              text="Add New"
              onClick={() => {
                history.push("/vacation-staff");
              }}
              styles={primaryButtonStyles}
            />
          )}
        </Stack>
      </div>
      {teachingBubbleShowChooseMonth && (
        <TeachingBubble
          target="#showChooseMonth"
          onDismiss={toggleTeachingShowChooseMonth}
          calloutProps={{
            calloutWidth: 270,
          }}
        >
          <Calendar
            dateRangeType={DateRangeType.Month}
            showGoToToday
            highlightSelectedMonth
            isDayPickerVisible={false}
            onSelectDate={onClickChooseDate}
          />
        </TeachingBubble>
      )}
    </Fragment>
  );
};

export default CalendarControls;
