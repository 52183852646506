import React, { useState, useRef, useCallback } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { REACT_APP_GOOGLE_MAPS_API_KEY } from "../../utils/constants";
import { Spinner, SpinnerSize } from "@fluentui/react";
// import { mergeStyleSets } from "@fluentui/react/lib/Styling";

// const classNames = mergeStyleSets({
//     address: {
//         marginTop: 0,
//         marginBottom: 24,
//         fontWeight: 400,
//         color: "#000",
//         display: "flex",
//     },
//     addressTitle: {
//         fontWeight: 400,
//         color: "#000",
//         fontSize: 14,
//         marginRight: 24,
//     },
//     addressContent: {
//         fontWeight: 700,
//         fontSize: 12,
//         maxWidth: 340,
//         overflowWrap: "break-word",
//         textTransform: "uppercase",
//     },
// });

const mapContainerStyle = {
    height: 350,
    width: 420,
};

// const center = {
//     lat: 55.7689,
//     lng: 12.5863,
// };

const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

const teachingBubbleStyles = {
    bodyContent: {
        padding: "20px 18px",
    },
    subText: {
        maxWidth: 420,
    },
};

const MapAddressWithTeachingBubbleComponent = ({ target, toggleTeaching, marker }) => {
    const [libraries] = useState(["places"]);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries,
    });

    const mapRef = useRef(null);

    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    if (loadError) return "Error...";
    if (!isLoaded)
        return <Spinner size={SpinnerSize.large} label="Loading..." />;

    return (
        <TeachingBubble
            styles={teachingBubbleStyles}
            target={target}
            hasSmallHeadline={true}
            onDismiss={toggleTeaching}
            closeButtonAriaLabel="Close"
            isWide
        >
            {/* TODO: This use for showing address later
            <div className={classNames.address}>
                <div className={classNames.addressTitle}>Address:</div>
                <div className={classNames.addressContent}>
                    Kalkværksvej 11A-B, 8000 Aarhus, Denmark
                </div>
            </div> */}
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                onLoad={onMapLoad}
                zoom={10}
                center={marker}
                options={options}
            >
                {marker && <Marker key={target} position={marker} />}
            </GoogleMap>
        </TeachingBubble>
    );
};

export default MapAddressWithTeachingBubbleComponent;
