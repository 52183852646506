// Import libraries
import { Calendar } from "@fluentui/react";
import { TeachingBubble } from "@fluentui/react/lib/TeachingBubble";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeftLong,
  faArrowRightLong,
  faCalendarAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useBoolean } from "@fluentui/react-hooks";
import { useState, useEffect } from "react";
import { addMonths, format, getYear } from "date-fns";

// Import utils
// import { defaultButtonStyles, primaryButtonStyles } from "../../utils/theme";

// const containerStyle = {
//   display: "flex",
//   flexDirection: "column",
//   border: "1px solid rgb(159, 159, 159)",
//   marginTop: 24,
//   padding: 8,
//   borderRadius: 4,
//   position: "relative",
//   height: 30,
// };

// const iconStyle = {
//   fontSize: 20,
//   cursor: "pointer",
//   position: "relative",
//   top: -8,
//   right: 10,
// };

type DatePickerProps = {
  liteVersion?: boolean;
  date: Date;
  onSelectDate?: (selectedDate: Date) => void;
  id: string;
  isMonthPickerVisible?: boolean;
};

const DatePicker = ({
  liteVersion = false,
  date = new Date(),
  onSelectDate,
  id,
  isMonthPickerVisible = false,
}: DatePickerProps) => {
  const [openCalendar, { toggle: toggleOpenCalendar }] = useBoolean(false);
  const [inputDate, setInputDate] = useState(date);
  // const [monthDate, setMonthDate] = useState(date);

  // useEffect(() => {
  //   setMonthDate(date);
  // }, [date]);

  useEffect(() => {
    if (openCalendar) {
      // wait for calendar to be visible
      setTimeout(() => {
        const goTodayButton = document.getElementsByClassName("js-goToday");

        // Hacky way to do this
        // As per design we don't want to see the default Month and Arrows
        // So we are going to have to manually do it
        // const headers = document.querySelectorAll('[aria-live="polite"]');

        //All of the cells have a border-right with grey border
        // const cells = document.querySelectorAll('[role="presentation"]');

        for (let i = 0; i < goTodayButton.length; i++) {
          goTodayButton[i].textContent = "TODAY";
        }

        // for (let i = 0; i < headers.length; i++) {
        //   if (headers[i].parentElement?.className.includes("header-")) {
        //     (headers[i].parentElement as HTMLElement).style.display = "none";

        //     if(headers[i].parentElement?.parentElement){
        //       (headers[i].parentElement?.parentElement as HTMLElement).style.paddingTop = "0px";
        //       (headers[i].parentElement?.parentElement as HTMLElement).style.paddingBottom = "0px";
        //     }
        //   }
        // }

        // for (let i = 0; i < cells.length; i++) {
        //   if (cells[i].className.includes("dayCell-")) {
        //     (cells[i] as HTMLElement).style.borderRight = "1px solid #DFDFDF";
        //   }
        // }

        // const days : {[k:string]: string} = {
        //   "Sunday": "Sø",
        //   "Monday": "Ma",
        //   "Tuesday": "Ti",
        //   "Wednesday W": "On",
        //   "Thursday": "To",
        //   "Friday": "Fr",
        //   "Saturday": "Lø"
        // }

        // const keys = Object.keys(days);

        // keys.forEach(key => {
        //   const el = document.querySelector(`[title="${key}"]`);

        //   if(el){
        //     el.textContent = days[key];
        //     (el as HTMLElement).style.fontSize = '13px';
        //     (el as HTMLElement).style.lineHeight = '14px';
        //     (el as HTMLElement).style.color = '#868685';
        //     (el as HTMLElement).style.fontWeight = '100';
        //   }
        // });
      }, 50);
    }
  }, [openCalendar]);

  // const classNames = mergeStyleSets({
  //   container: {
  //     ...containerStyle,
  //     height: liteVersion ? 20 : 30,
  //     padding: liteVersion ? 0 : 8,
  //   },
  //   shortContainer: {
  //     ...containerStyle,
  //     marginTop: 0,
  //     width: 300,
  //   },
  //   content: {
  //     display: "flex",
  //     width: "100%",
  //     justifyContent: "space-between",
  //     alignItems: "center",
  //     position: "relative",
  //     top: -2,
  //   },
  //   textContainer: {
  //     width: liteVersion ? "90%" : "80%",
  //     display: "flex",
  //     justifyContent: "space-between",
  //     alignItems: "center",
  //     overflow: "hidden",
  //   },
  //   input: {
  //     border: "none",
  //     width: 100,
  //     backgroundColor: "#f1f1f1",
  //     fontFamily: "Verdana",
  //     fontSize: 14,
  //   },
  //   label: {
  //     position: "relative",
  //     top: -4,
  //     fontWeight: 600,
  //     fontSize: 11,
  //   },
  //   icon: {
  //     ...iconStyle,
  //   },
  //   shortIcon: {
  //     ...iconStyle,
  //     right: 4,
  //     top: liteVersion ? 0 : 8,
  //   },
  //   pickerControlContainer: {
  //     display: "flex",
  //     justifyContent: "center",
  //     // marginRight: 8,
  //     marginTop: 10,
  //   },
  //   cancel: {
  //     marginRight: 10,
  //   },
  // });

  useEffect(() => {
    if (openCalendar) {
      setInputDate(date);
    }
  }, [openCalendar, date]);

  // Click function
  // const onClickConfirm = () => {
  //   if (onSelectDate) onSelectDate(inputDate);
  //   toggleOpenCalendar();
  // };

  // const onClickNextMonth = () => {
  //   const firstDateOfMonth = format(monthDate, "yyyy-MM-01");

  //   setMonthDate(addMonths(new Date(firstDateOfMonth), 1));
  // };

  // const onClickPreviousMonth = () => {
  //   const firstDateOfMonth = format(monthDate, "yyyy-MM-01");

  //   setMonthDate(addMonths(new Date(firstDateOfMonth), -1));
  // };

  return (
    <>
      <FontAwesomeIcon
        id={id}
        icon={faCalendarAlt as IconDefinition}
        onClick={() => {
          toggleOpenCalendar();
        }}
        style={{
          fontSize: 24,
          color: "#006CAD",
          cursor: "pointer",
        }}
      />
      {openCalendar && (
        // @ts-ignore
        <TeachingBubble
          target={`#${id}`}
          hasSmallHeadline={true}
          onDismiss={toggleOpenCalendar}
          closeButtonAriaLabel="Close"
          calloutProps={{
            calloutWidth: isMonthPickerVisible ? 540 : 270,
          }}
          styles={{
            bodyContent: {
              padding: isMonthPickerVisible ? "5px 10px" : "20px 24px",
            },
          }}
          isWide={isMonthPickerVisible}
        >
          <div>
            <div style={{ justifyContent: "center", display: "flex" }}>
              <div>
                <Calendar
                  value={inputDate}
                  onSelectDate={(selectedDate) => {
                    setInputDate(selectedDate);
                    if (onSelectDate) {
                      onSelectDate(selectedDate);
                    }
                    toggleOpenCalendar();
                  }}
                  isMonthPickerVisible={isMonthPickerVisible}
                  styles={{
                    goTodayButton: {
                      color: "#006CAD",
                      fontWeight: 700,
                      fontSize: 12,
                      letterSpacing: "1px",
                    },
                  }}
                />
              </div>
            </div>
            {/* <div className={classNames.pickerControlContainer}>
              <DefaultButton
                // @ts-ignore
                styles={defaultButtonStyles}
                className={classNames.cancel}
                text="Annullere"
                onClick={toggleOpenCalendar}
              />
              <PrimaryButton
                // @ts-ignore
                styles={primaryButtonStyles}
                text="Gem"
                onClick={onClickConfirm}
              />
            </div> */}
          </div>
        </TeachingBubble>
      )}
    </>
  );
};

export default DatePicker;
