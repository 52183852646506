import { Formik } from "formik";
import React, { Fragment } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";
import { getProject, updateProject } from "../../redux/project/project.actions";
import { formatDate, updateProjectData } from "../../utils/utils";
import { ProjectForm } from "./projects/index";
import { SKILLS_QUERY_KEY } from "../../utils/constants";
import { getSkills } from "../../redux/skill/skill.actions";

const Project = ({ updateProject, getProject, defaultData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const queryClient = useQueryClient();
  const { projectId } = useParams();
  const { data, status } = useQuery(
    ["project", projectId],
    () => getProject(projectId),
    { staleTime: 36000 },
  );

  const { data: skillsData } = useQuery(
    SKILLS_QUERY_KEY,
    () => getSkills(dispatch),
    {
      placeholderData: [],
    },
  );

  const geoObjectShape = {
    latitude: Yup.number(),
    longitude: Yup.number(),
  };

  const oneOfSection = defaultData?.sectionOptions.map(
    (sizeGroup) => sizeGroup.key,
  ) ?? [220, 230, 260, 290];

  const validationSchema = Yup.object({
    hovedsagsNummer: Yup.string("Enter a name").required(
      "Hovedsagsnummer is required",
    ),
    projectNo: Yup.string("Enter a name").required(
      "ARKIVERINGSSAG is required",
    ),
    projectName: Yup.string("Enter a name").required("Name is required"),
    projectType: Yup.string().required("Type is required"),
    color: Yup.string("Enter a name").required("color is required"),
    period: Yup.array().of(Yup.date()).required("Start date is required"),
    section: Yup.mixed().oneOf(oneOfSection).required("Section is required"),
    isQuotation: Yup.boolean(),
    techDepartments: Yup.array()
      .of(Yup.number())
      .required("Department is required"),
    geoCoordinate: Yup.object().shape(geoObjectShape),
    personResponsbible: Yup.string().nullable(),
    reason: Yup.string().nullable(),
    requiredSkills: Yup.array()
      .of(Yup.string())
      .required("Required skills is required"),
  });

  const mutationUpdateProject = useMutation(
    (project) => updateProject(project),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("projects");
        queryClient.invalidateQueries({ queryKey: ["project", projectId] });

        if (variables.redirectToMachineReq === true) {
          history.push(`/add-machine-requirements/${data.projectId}`);
        } else {
          history.push("/projects");
        }
      },
    },
  );

  const formSubmit = (newData) => {
    const [start, end] = formatDate(newData.period);
    const projectUpdatedData = updateProjectData(
      data,
      { ...newData, start, end },
      defaultData,
      currentUserId,
    );

    mutationUpdateProject.mutate(projectUpdatedData);
  };

  return (
    <Fragment>
      {status === "success" && data && (
        <Formik
          initialValues={{
            ...data,
            personResponsbible: data.personResponsbible?.name,
            period: [new Date(data.start), new Date(data.end)],
          }}
          validationSchema={validationSchema}
          onSubmit={formSubmit}
        >
          {(props) => (
            <ProjectForm skills={skillsData} history={history} {...props} />
          )}
        </Formik>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  defaultData: state.defaultData,
});

export default connect(mapStateToProps, { updateProject, getProject })(Project);
