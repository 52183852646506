import { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { Checkbox, IconButton, PrimaryButton, Stack } from "@fluentui/react";
import { Label } from "@fluentui/react/lib/Label";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import {
  dropDownStyles,
  inputStyles,
  customAutoCompleteStyles,
  primaryButtonStyles,
} from "../../../utils/theme";
import {
  stationOptions,
  workingRoleOptions,
  techDepartmentOptionsStaffPage,
  ignoreStationOptionsList,
} from "../../../utils/constants";
import { CustomInputErrorComponent, FileUpload } from "../../common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hasEditRight } from "../../../utils/utils";

const classNames = mergeStyleSets({
  selector: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "1px solid rgb(159, 159, 159)",
    borderRadius: 4,
    height: 46,
    width: 318,
    marginBottom: 24,
    selectors: {
      ":hover": {
        border: "1px solid rgb(42, 42, 42)",
      },
    },
  },
  container: {
    minHeight: "100vh",
    background: "#F1F1F1",
  },
  label: {
    fontSize: 29,
    lineHeight: 29,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    padding: "136px 124px 116px 118px",
  },
  back: {
    width: 76,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    width: 320,
    margin: "0 auto",
  },
  input: {
    marginBottom: 24,
  },
  fileErrorMessage: {
    fontSize: "12px",
    fontWeight: 400,
    color: " rgb(164, 38, 44)",
    marginBottom: 24,
  },
});

const formatOptionLabel = ({ icon, label }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }}
  >
    <FontAwesomeIcon icon={icon} />
    <div style={{ marginLeft: 8 }}>{label}</div>
  </div>
);

const StaffForm = ({
  values: {
    userId,
    firstName,
    lastName,
    workingRole,
    email,
    firma,
    techDepartment,
    techArea,
    imageUrl,
  },
  errors,
  touched,
  handleSubmit,
  setFieldValue,
  handleChange,
  isValid,
  history,
  editOnly = false,
  hideHeader = false,
  setFormData,
}) => {
  const [file, setFile] = useState(imageUrl || null);
  const userRoles = useSelector((state) => state?.user?.user?.workingRole);
  const currentUserId = useSelector((state) => state?.user?.user?.userId);
  const allowEdit = hasEditRight(userRoles);
  const workingRoleArray = workingRole
    ?.split(";")
    .map((item) => (item ? parseInt(item) : null));
  const workingRoleOptionsTransform = workingRoleOptions.map((item) => ({
    key: item.key,
    label: item.text,
    ...item,
  }));

  useEffect(() => {
    if (file) {
      setFieldValue("imageUrl", file);
    }
  }, [file]);

  useEffect(() => {
    if (handleSubmit && setFormData) {
      setFormData({
        handleSubmit,
        isValid,
      });
    }
  }, [isValid, handleSubmit, setFormData]);

  return (
    <form className={classNames.container}>
      {!hideHeader ? (
        <div className={classNames.header}>
          <div className={classNames.back}>
            <IconButton
              iconProps={{ iconName: "ChromeBack" }}
              onClick={() => history.push("/staff")}
            />
          </div>
          <Label className={classNames.label}>
            {editOnly
              ? `${firstName} ${lastName}`
              : "Opret nyt person til Bemanding"}
          </Label>
          <Stack horizontal tokens={{ childrenGap: 40 }}>
            <PrimaryButton
              text="GEM"
              disabled={!isValid}
              onClick={handleSubmit}
              styles={primaryButtonStyles}
            />
          </Stack>
        </div>
      ) : (
        <></>
      )}
      <div className={classNames.content}>
        <FileUpload
          imageUrl={file}
          setFile={setFile}
          placeholder="UPLOAD BILLEDE"
        />
        {touched.imageUrl || errors.imageUrl ? (
          <span className={classNames.fileErrorMessage}>{errors.imageUrl}</span>
        ) : (
          ""
        )}
        <TextField
          label="FORNAVN"
          styles={inputStyles}
          value={firstName}
          name="firstName"
          placeholder="FORNAVN"
          className={classNames.input}
          onChange={handleChange}
          errorMessage={
            touched.firstName || errors.firstName ? errors.firstName : ""
          }
          disabled={!allowEdit}
        />
        <TextField
          label="EFTERNAVN"
          styles={inputStyles}
          value={lastName}
          name="lastName"
          placeholder="EFTERNAVN"
          className={classNames.input}
          onChange={handleChange}
          errorMessage={
            touched.lastName || errors.lastName ? errors.lastName : ""
          }
          disabled={!allowEdit}
        />
        <div
          style={{
            fontFamily: "Verdana",
            fontSize: 11,
            fontWeight: "bold",
            transform: "translate(9px, 18px)",
            marginTop: -13,
            zIndex: 2,
          }}
        >
          ROLLE
        </div>
        <Select
          placeholder="ROLLE"
          name="workingRole"
          closeMenuOnSelect={true}
          defaultValue={workingRoleOptionsTransform.filter((item) =>
            workingRoleArray?.includes(item.key)
          )}
          options={workingRoleOptionsTransform}
          isSearchable={true}
          onChange={(event, item) => {
            let newRoles;
            if (item.action === "remove-value") {
              newRoles = workingRoleArray.slice(
                0,
                workingRoleArray.indexOf(item.removedValue.key)
              );
            } else if (item.action === "select-option") {
              newRoles =
                workingRoleArray && workingRoleArray[0]
                  ? [...workingRoleArray, item.option.key]
                  : [item.option.key];
            } else {
              newRoles = [];
            }
            setFieldValue("workingRole", newRoles.join(";"));
          }}
          className={classNames.input}
          styles={customAutoCompleteStyles(errors.machineId)}
          getOptionValue={(option) => option.label}
          isDisabled={!allowEdit || userId === currentUserId}
          formatOptionLabel={formatOptionLabel}
          isMulti
        />
        <CustomInputErrorComponent
          styles={{ margin: "-20px 0 23px 0" }}
          errorMessage={
            touched.workingRole || errors.workingRole ? errors.workingRole : ""
          }
        />
        <Dropdown
          label="SEKTION"
          selectedKey={techDepartment}
          name="techDepartment"
          onChange={(event, item) => setFieldValue("techDepartment", item.key)}
          placeholder="SEKTION"
          options={techDepartmentOptionsStaffPage}
          styles={dropDownStyles}
          className={classNames.input}
          errorMessage={
            touched.techDepartment || errors.techDepartment
              ? errors.techDepartment
              : ""
          }
          disabled={!allowEdit}
        />
        {!ignoreStationOptionsList.includes(techDepartment) ? (
          <Dropdown
            label="DISPONENT"
            selectedKey={techArea}
            name="techArea"
            onChange={(event, item) => setFieldValue("techArea", item.key)}
            placeholder="DISPONENT"
            options={
              techDepartment && techDepartment >= 0
                ? stationOptions[techDepartment]["options"]
                : []
            }
            styles={dropDownStyles}
            className={classNames.input}
            errorMessage={
              touched.techArea || errors.techArea ? errors.techArea : ""
            }
            disabled={!allowEdit}
          />
        ) : (
          <></>
        )}
        <TextField
          label="FIRMA EMAIL"
          disabled={editOnly}
          styles={inputStyles}
          value={email}
          name="email"
          placeholder="EMAIL"
          className={classNames.input}
          onChange={handleChange}
          errorMessage={touched.email || errors.email ? errors.email : ""}
        />
        <TextField
          label="FIRMA"
          disabled={editOnly}
          styles={inputStyles}
          value={firma}
          name="firma"
          placeholder="FIRMA"
          className={classNames.input}
          onChange={handleChange}
          errorMessage={touched.firma || errors.firma ? errors.firma : ""}
        />
      </div>
    </form>
  );
};

export default StaffForm;
