// Import libraries
import {
  Dropdown,
  FontIcon,
  IDropdownStyleProps,
  IDropdownStyles,
  IStyleFunctionOrObject,
  mergeStyleSets,
  SpinButton,
  TextField,
  Toggle,
} from "@fluentui/react";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useCallback, useMemo, useState, useEffect, ReactElement} from "react";
import { useDispatch, useSelector } from "react-redux";

// import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
// import { faPlus } from "@fortawesome/pro-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Import redux
import { saveMessage } from "../../../redux/message/message.actions";

// Import components
import SkillControls from "./SkillControl";

// Import types
import { NewSkill, Skill, SkillTypeEnum } from "../../../types/settings";

// Import utils
import {
  SKILL_TYPE_KEY,
  SKILL_TYPE_OPTIONS,
  techDepartmentOptions,
} from "../../../utils/constants";
import { TooltipForText } from "../../common";
import TextWithTeachingBubble from "../../common/TextWithTeachingBubble";

const dropDownStyles: IStyleFunctionOrObject<
  IDropdownStyleProps,
  IDropdownStyles
> = {
  dropdownItem: {
    height: 48,
  },
  dropdownItemSelected: {
    height: 48,
  },
  title: {
    border: "none",
  },
  root: {
    width: "100%",
  },
  dropdown: {
    maxWidth: "155px",
    caretDownWrapper: {
      marginTop: 8,
    },
  },
  caretDown: {
    color: "#006CAD",
    fontWeight: "1000",
  },
};

const classNames = mergeStyleSets({
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  textAreaInput: {
    marginRight: 10,
    borderRadius: 5,
    width: "95%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 0,
    background: "#FFFFFF",
    flex: "none",
    order: 1,
    flexGrow: 0,
    textOverflow: "ellipsis",
    overflow: "hidden",
    minWidth: 312,
    maxWidth: 312,
    resize: "none",
    wordWrap: "break-word",
  },
  iconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    fontSize: 22,
    color: "rgb(0, 108, 173)",
    cursor: "pointer",
    marginRight: 12,
  },
  tr: {
    textAlign: "left",
    height: 55,
    borderBottom: "1px solid #DBDBDB",
  },
  td: {
    whiteSpace: "noWrap",
    padding: "0px 0px 0px 16px",
  },
  th: {
    boxSizing: "border-box",
    padding: "0px 0px 0px 16px",
    fontSize: 11,
    lineHeight: 13.37,
    fontWeight: 700,
    color: "#000",
  },
  tableContainer: {
    width: 1054,
    // overflowX: "auto",
  },
  input: {
    marginBottom: 24,
    fontWeight: "400 !important",
  },
  ekstraContainer: {
    paddingTop: 9,
    paddingBottom: 9,
  },
  ekstra: {
    fontWeight: "400",
    fontSize: 11,
    fontFamily: "Verdana",
    letterSpacing: 1,
    color: "#575756",
    lineHeight: 12,
    height: 16,
    marginTop: 2,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: "#DFDFDF",
    marginBottom: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  iconClass: {
    margin: "0 25px",
    color: "#868685",
    opacity: 1,
  },
  pointerCursor: {
    cursor: "pointer",
  },
});

type SkillsTableProps = {
  skills: Skill[];
  canEdit: boolean;
  setAddNewSkillFunc:
    | undefined
    | React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
  onUpdatedSkills: (updatedSkills: (Skill | NewSkill)[]) => void;
  onDeleteSkill: (id: string) => void;
};

const SkillsTable = ({
  skills,
  canEdit,
  setAddNewSkillFunc,
  onUpdatedSkills,
  onDeleteSkill,
}: SkillsTableProps) => {
  const dispatch = useDispatch();

  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editingSkill, setEditingSkill] = useState<Skill | null>(null);
  const [isNewSkill, setIsNewSkill] = useState<boolean>(false);
  const [popupData, setPopupData] = useState<{
    id: string;
    value: string;
  }>();
  const [isSortByNameAsc, setIsSortByNameAsc] = useState<boolean>(true);

  const techDepartment = useSelector(
    (state: any) => state?.machine?.filter.techDepartment
  );
  const filter = useSelector((state: any) => state?.skill?.filter);

  const sortSkillsAlphabetically = (skills: Skill[]) => {
    return isSortByNameAsc
      ? skills.sort((a, b) => a.name.localeCompare(b.name))
      : skills.sort((a, b) => b.name.localeCompare(a.name));
  };

  const [skillsCopy, setSkillsCopy] = useState<Skill[]>(
    sortSkillsAlphabetically(skills)
  );

  const handleSortByName = () => {
    setIsSortByNameAsc((prevState) => !prevState);
    setSkillsCopy(sortSkillsAlphabetically(skillsCopy));
  };

  //apply filter
  useEffect(() => {
    const filterSkills = (filter: any) => {
      let filteredSkills = skills?.filter((skill: Skill) => {
        // Check if skill name matches the filter
        const skillNameMatches =
          filter && filter["skillNames"]
            ? skill.name
                .toLowerCase()
                .includes(filter["skillNames"].toLowerCase())
            : true;

        // Check if skill type matches any selected type
        const typeMatches =
          filter && filter["selectedTypes"]
            ? filter["selectedTypes"].includes(skill.type)
            : true;

        // Check if skill sections match any selected sections
        const sectionsMatch =
          filter && filter["selectedSections"]
            ? filter["selectedSections"].some((section: any) =>
                skill.sections.includes(section)
              )
            : true;

        // Return true only if all conditions match
        return skillNameMatches && typeMatches && sectionsMatch;
      });

      // Sort the filtered skills alphabetically by name
      filteredSkills = filteredSkills?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      return filteredSkills;
    };

    setSkillsCopy(filterSkills(filter));
  }, [filter, skills, techDepartment]);

  //================   Function definitions ===================================

  const isEditing = useMemo(
    () => editIndex !== null && !!editingSkill,
    [editIndex, editingSkill]
  );

  const onAddClick = useCallback(() => {
    return () => {
      if (!isEditing) {
        const copy = [...skillsCopy];

        const newSkill: NewSkill = {
          expiryPeriod: 1,
          inactive: false,
          name: "",
          description: "",
          type: SKILL_TYPE_KEY.UNDEFINED,
          sections: [],
        };

        copy.push(newSkill as Skill);

        setSkillsCopy(copy);
        setIsNewSkill(true);
        setEditingSkill(newSkill as Skill);
        setEditIndex(copy.length - 1);
      } else {
        dispatch(
          saveMessage(
            "You must complete the editing process before you create new one."
          )
        );
      }
    };
  }, [dispatch, isEditing, skillsCopy]);

  const resetEditState = useCallback(() => {
    setEditIndex(null);
    setEditingSkill(null);
    setIsNewSkill(false);
  }, [setEditIndex, setEditingSkill, setIsNewSkill]);

  const onClickInactiveSkill = useCallback(
    (index: number) => {
      if (isEditing) {
        dispatch(
          saveMessage("You can only inactive a skill when you are not editing")
        );
      } else {
        const copySkill = skillsCopy[index];

        if (copySkill.inactive !== null && copySkill.inactive !== undefined) {
          copySkill.inactive = !copySkill.inactive;

          const copy = [...skillsCopy];

          onUpdatedSkills(copy);
          setSkillsCopy(copy);
        }
      }
    },
    [dispatch, isEditing, onUpdatedSkills, skillsCopy]
  );

  const onClickEditGeneralSkill = useCallback(
    (index: number) => {
      if (isEditing) {
        dispatch(saveMessage("You can only edit one item at a time!"));
      } else {
        if (skillsCopy[index]) {
          setEditingSkill({
            ...skillsCopy[index],
          });
          setEditIndex(index);
        }
      }
    },
    [dispatch, isEditing, skillsCopy]
  );

  const onApplyChange = useCallback(() => {
    // Edit Index and Editing Skill has to be added in the check
    // although isEditing is comprised of it because
    // Typescript is not able to infer that if isEditing is true
    // then editIndex and editingSkill are not null
    if (editIndex !== null && isEditing && editingSkill) {
      const { name, type, sections, expiryPeriod } = editingSkill;

      const missingInfo =
        !name ||
        type === SKILL_TYPE_KEY.UNDEFINED ||
        !Array.isArray(sections) ||
        (expiryPeriod < 1 && expiryPeriod !== -1);

      if (missingInfo) {
        dispatch(saveMessage("Please provide missing information!"));
      } else {
        const copy = [...skillsCopy];
        copy[editIndex] = editingSkill;
        setSkillsCopy(copy);
        onUpdatedSkills(copy);
        resetEditState();
      }
    }
  }, [
    dispatch,
    editIndex,
    editingSkill,
    isEditing,
    resetEditState,
    skillsCopy,
    onUpdatedSkills,
  ]);

  const onClickRemoveGeneralSkill = useCallback(
    (index: number) => {
      if (typeof skillsCopy[index] !== "undefined") {
        if (isNewSkill || (!editIndex && !editingSkill)) {
          const copy = [...skillsCopy];
          copy.splice(index, 1);

          resetEditState();

          // If the skill has an id, it means we need to remove it from the db as well
          if (skillsCopy[index].id) {
            onDeleteSkill(skillsCopy[index].id);
          }

          setSkillsCopy(copy);
        } else {
          resetEditState();
        }
      }
    },
    [
      editIndex,
      editingSkill,
      isNewSkill,
      resetEditState,
      skillsCopy,
      onDeleteSkill,
    ]
  );

  const renderSkills = useCallback((): ReactElement[] => {
    const renderName = ({
      name,
      isEdit,
      inactive,
    }: {
      name: string;
      isEdit: boolean;
      inactive: boolean;
    }) => {
      if (isEdit) {
        return (
          <td
            style={{
              maxWidth: 244,
            }}
            className={classNames.td}
          >
            <TextField
              label=""
              value={name}
              name="name"
              onChange={(_, str) => {
                if (str !== undefined) {
                  setEditingSkill((values) => ({
                    ...(values as Skill),
                    name: str,
                  }));
                }
              }}
              style={{
                backgroundColor: "white",
              }}
            />
          </td>
        );
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          {name}
        </td>
      );
    };

    const renderType = ({
      isEdit,
      type,
      inactive,
    }: {
      isEdit: boolean;
      type: SkillTypeEnum;
      inactive: boolean;
    }) => {
      if (isEdit) {
        return (
          <td
            style={{
              minWidth: 155,
            }}
            className={classNames.td}
          >
            <Dropdown
              selectedKey={type}
              onChange={(_, item) => {
                if (item?.key) {
                  setEditingSkill((values) => ({
                    ...(values as Skill),
                    type: +item.key as SkillTypeEnum,
                  }));
                }
              }}
              options={SKILL_TYPE_OPTIONS}
              styles={dropDownStyles}
            />
          </td>
        );
      }

      const label = type
        ? SKILL_TYPE_OPTIONS.find((item) => item.key === type)?.label || ""
        : "";

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          {label}
        </td>
      );
    };

    const renderSection = ({
      sections,
      isEdit,
      inactive,
    }: {
      sections: number[];
      isEdit: boolean;
      inactive: boolean;
    }) => {
      if (isEdit) {
        return (
          <td
            style={{
              minWidth: 86,
            }}
            className={classNames.td}
          >
            <Dropdown
              selectedKey={sections}
              onChange={(_, item) => {
                if (item?.key) {
                  if (item.selected) {
                    setEditingSkill((values) => ({
                      ...(values as Skill),
                      sections: [
                        ...new Set([...(values?.sections || []), +item.key]),
                      ],
                    }));
                  } else {
                    setEditingSkill((values) => ({
                      ...(values as Skill),
                      sections:
                        values?.sections?.filter(
                          (el) => "" + el === item.key
                        ) || [],
                    }));
                  }
                }
              }}
              options={techDepartmentOptions.map((el) => ({
                ...el,
                key: el.key + "",
              }))}
              selectedKeys={sections.map((el) => el + "")}
              styles={dropDownStyles}
              multiSelect
            />
          </td>
        );
      }

      const labels: string[] =
        sections
          ?.map(
            (el) => techDepartmentOptions.find((item) => item.key === el)?.label
          )
          .filter((el): el is string => !!el) || [];

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={`${classNames.td} ${classNames.ekstraContainer}`}
        >
          {labels.map((label) => (
            <p className={classNames.ekstra} key={label}>
              {label}
            </p>
          ))}
        </td>
      );
    };

    const renderExpiryPeriod = ({
      expiryPeriod,
      isEdit,
      inactive,
    }: {
      expiryPeriod: number;
      isEdit: boolean;
      inactive: boolean;
    }) => {
      if (isEdit) {
        return (
          <td style={{ maxWidth: 180 }} className={classNames.td}>
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
              }}
            >
              <SpinButton
                style={{
                  backgroundColor: "white",
                }}
                styles={{
                  input: {
                    backgroundColor: "white",
                    width: 30,
                  },
                }}
                value={"" + expiryPeriod}
                min={1}
                max={1000}
                step={1}
                onChange={(_, value) => {
                  setEditingSkill((values) => ({
                    ...(values as Skill),
                    expiryPeriod: value ? +value : expiryPeriod,
                  }));
                }}
              />
              <Toggle
                checked={expiryPeriod === -1}
                onChange={(e, checked) => {
                  setEditingSkill((values) => ({
                    ...(values as Skill),
                    expiryPeriod: checked ? -1 : 1,
                  }));
                }}
                styles={{
                  root: {
                    margin: 0,
                  },
                }}
                onText="Never"
                offText="Never"
              />
            </span>
          </td>
        );
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          {expiryPeriod === -1 ? "None" : `${expiryPeriod} måneder`}
        </td>
      );
    };

    const renderDescription = ({
      description,
      isEdit,
      inactive,
      index,
    }: {
      description: string;
      isEdit: boolean;
      inactive: boolean;
      index: number;
    }) => {
      if (isEdit) {
        const id = `edit-skill-${index}`;

        return (
          <td
            style={{
              maxWidth: 312,
            }}
            className={classNames.td}
          >
            <textarea
              className={classNames.textAreaInput}
              value={description}
              onChange={(e) => {
                setEditingSkill((values) => ({
                  ...(values as Skill),
                  description: e.target.value,
                }));
              }}
              id={id}
              style={inactive ? { color: "#868685" } : {}}
              onClick={() => [
                setPopupData({
                  id,
                  value: description,
                }),
              ]}
            />
          </td>
        );
      }

      return (
        <td
          style={inactive ? { color: "#868685" } : {}}
          className={classNames.td}
        >
          {description.split("\n").length >= 3 ||
          description?.trim()?.length >= 85 ? (
            <TooltipForText text={description} noWidth>
              <textarea
                className={classNames.textAreaInput}
                value={description}
                onChange={(e) => {
                  setEditingSkill((values) => ({
                    ...(values as Skill),
                    description: e.target.value,
                  }));
                }}
                style={inactive ? { color: "#868685" } : {}}
              />
            </TooltipForText>
          ) : (
            <></>
          )}
          {description.split("\n").length <= 2 &&
          description?.trim()?.length <= 84 ? (
            <textarea
              className={classNames.textAreaInput}
              value={description}
              onChange={(e) => {
                setEditingSkill((values) => ({
                  ...(values as Skill),
                  description: e.target.value,
                }));
              }}
            />
          ) : (
            <></>
          )}
        </td>
      );
    };

    const renderAction = ({
      index,
      isEdit,
      inactive,
    }: {
      index: number;
      isEdit: boolean;
      inactive: boolean;
    }) => {
      if (!canEdit) {
        return <td className={classNames.td}></td>;
      }

      if (isEdit) {
        return (
          <td
            style={{
              maxWidth: 96,
            }}
            className={classNames.td}
          >
            <div className={classNames.iconContainer}>
              <FontAwesomeIcon
                icon={faCheck as IconDefinition}
                className={classNames.icon}
                onClick={onApplyChange}
              />
              <FontAwesomeIcon
                icon={faTimes as IconDefinition}
                className={classNames.icon}
                onClick={() => {
                  onClickRemoveGeneralSkill(index);
                }}
              />
            </div>
          </td>
        );
      }

      return (
        <td className={classNames.td}>
          <SkillControls
            id={"" + index}
            inactive={inactive}
            onClickInactive={() => {
              onClickInactiveSkill(index);
            }}
            onClickEdit={() => {
              onClickEditGeneralSkill(index);
            }}
            onClickDelete={() => {
              onClickRemoveGeneralSkill(index);
            }}
            showEdit={!inactive}
            showDelete={false}
          />
        </td>
      );
    };

    const arr: ReactElement[] = [];

    skillsCopy.forEach((skill, index) => {
      const isEdit = index === editIndex;

      const name: string = !isEdit ? skill.name : editingSkill?.name || "";
      const sections: number[] = !isEdit
        ? skill.sections
        : editingSkill?.sections || [];
      const type: SkillTypeEnum = !isEdit
        ? skill.type
        : editingSkill?.type || 0;
      const expiryPeriod: number = !isEdit
        ? skill.expiryPeriod
        : editingSkill?.expiryPeriod || 0;
      const description: string = !isEdit
        ? skill.description
        : editingSkill?.description || "";

      const inactive: boolean = !isEdit ? skill.inactive : false;

      arr.push(
        <tr key={index} className={classNames.tr}>
          {renderName({ isEdit, name, inactive })}
          {renderType({ isEdit, type, inactive })}
          {renderSection({ isEdit, sections, inactive })}
          {renderExpiryPeriod({ isEdit, expiryPeriod, inactive })}
          {renderDescription({ isEdit, description, inactive, index })}
          {renderAction({ index, isEdit, inactive })}
        </tr>
      );
    });
    return arr.reverse();
  }, [
    canEdit,
    editIndex,
    editingSkill,
    onClickEditGeneralSkill,
    onClickRemoveGeneralSkill,
    skillsCopy,
    onApplyChange,
    onClickInactiveSkill,
  ]);

  // Use Effect Hooks
  // We want to pass the add new skill function to the parent
  useEffect(() => {
    if (setAddNewSkillFunc) {
      setAddNewSkillFunc(onAddClick);
    }
  }, [onAddClick, setAddNewSkillFunc]);

  useEffect(() => {
    resetEditState();
    setSkillsCopy(skills);
  }, [skills, resetEditState]);

  return (
    <div className={classNames.tableContainer}>
      <table className={classNames.table}>
        <tbody>
          <tr className={classNames.tr}>
            <th
              style={{
                width: 244,
              }}
              className={`${classNames.th} ${classNames.pointerCursor}`}
              onClick={handleSortByName}
            >
              NAME
              <span>
                {isSortByNameAsc ? (
                  <FontIcon
                    iconName="sortup"
                    className={classNames.iconClass}
                  />
                ) : (
                  <FontIcon
                    iconName="sortdown"
                    className={classNames.iconClass}
                  />
                )}
              </span>
            </th>
            <th
              style={{
                width: 155,
              }}
              className={classNames.th}
            >
              TYPE
            </th>
            <th
              style={{
                width: 86,
              }}
              className={classNames.th}
            >
              SEKTION
            </th>
            <th
              style={{
                width: 180,
              }}
              className={classNames.th}
            >
              EXPIRY PERIOD MONTH
            </th>
            <th
              style={{
                width: 312,
              }}
              className={classNames.th}
            >
              DESCRIPTION
            </th>
            <th
              style={{
                width: 96,
              }}
              className={classNames.th}
            >
              ACTION
            </th>
          </tr>
          {renderSkills()}
        </tbody>
      </table>
      {popupData ? (
        <TextWithTeachingBubble
          target={`#${popupData?.id}`}
          onDismiss={() => {
            setPopupData(undefined);
          }}
          value={popupData?.value}
          onChangeValue={(newVal) => {
            if (popupData && popupData?.id) {
              const { id } = popupData;

              if (id.split("-")[2]) {
                const idNumber = parseInt(id.split("-")[2]);

                if (typeof idNumber === "number") {
                  const values = skillsCopy[idNumber];

                  if (values) {
                    setEditingSkill((values) => ({
                      ...(values as Skill),
                      description: newVal,
                    }));
                  }
                }
              }
            }
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SkillsTable;
