// Import libraries
import { authentication, app } from "@microsoft/teams-js";
import { PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { Image, ImageFit } from "@fluentui/react/lib/Image";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

// Import redux
import { loginTeams } from "../../redux/user/user.actions";

// Import utils
import { primaryButtonStyles } from "../../utils/theme";
import { COLOR_CONS_HEX } from "../../utils/constants";

// Import hooks
import useFrameContext from "../../hooks/useFrameContext";

const classNames = mergeStyleSets({
  image: {
    margin: "0 10px 100px 10px",
  },
  input: {
    marginBottom: 12,
  },
  login: {
    marginTop: 24,
    width: 320,
  },
  spinner: {
    marginTop: 24,
  },
  linkDiv: {
    paddingTop: 12,
    textAlign: "center",
  },
  link: {
    textDecoration: "none",
    color: COLOR_CONS_HEX.BLUE,
  },
});

type LoginProps = {
  isAuthenticated: boolean;
  isLoading: boolean;
  loginTeams: (accessToken: string) => void;
  tokenExpired: boolean;
  autoLogin: boolean;
};

const LoginTeams = (
  props: // tokenExpired,
  // autoLogin,
  LoginProps
) => {
  const {
    // isAuthenticated,
    // isLoading,
    isAuthenticated,
    loginTeams,
  } = props;

  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  // const [token, setToken] = useState<string>();
  const [error, setError] = useState<string>();
  // const [tid, setTid] = useState<string>();
  // const [ctxData, setCtxData] = useState<any>({});
  // const [ctxFrameData, setCtxFrameData] = useState<any>({});

  // const search = useUrlQuery().get();

  const { checkedFrameContext, isInTeamsApp } = useFrameContext();

  // const [localStorageToken, setLocalStorageToken] = useState<string>("");

  useEffect(() => {
    const initialize = async () => {
      try {
        setIsInitializing(true);
        await app.initialize();
        // const ctx = await app.getContext();

        // setCtxFrameData(app.getFrameContext());

        // if (ctx.user?.tenant?.id) {
        //   setTid(ctx.user.tenant.id);
        //   setCtxData(ctx.user);
        // }

        setIsInitialized(true);
      } catch (error) {
        setError((error as Error)?.message || "Failed to initialize");
      } finally {
        setIsInitializing(false);
      }
    };

    initialize();
  }, []);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setLocalStorageToken(localStorage.getItem("token") || "");
  //   }
  // }, [isAuthenticated]);

  const onLogin = useCallback(() => {
    if (isInitialized && !isAuthenticating) {
      try {
        setIsAuthenticating(true);

        const requestToken = async () => {
          const token = await authentication.getAuthToken();
          // setToken(token);
          loginTeams(token);
        };

        requestToken();
      } catch (error) {
        setError((error as Error)?.message || "Failed to get auth token");
      } finally {
        setIsAuthenticating(false);
      }
    }
  }, [isInitialized, isAuthenticating,loginTeams]);

  if (!isInTeamsApp && checkedFrameContext) {
    return <Redirect to="/login" />;
  } else if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        marginTop: "12%",
      }}
    >
      <form style={{ width: 320, height: 530, margin: "auto" }}>
        <Image
          src="./img/Logo.svg"
          imageFit={ImageFit.contain}
          alt="Aarsleff"
          width={300}
          height={163}
          className={classNames.image}
        />
        <div
          style={{
            fontSize: 32,
            fontWeight: "bold",
            marginBottom: 46,
            textAlign: "center",
          }}
        >
          Disponering
        </div>
        {/* {token} */}
        <div
          style={{
            fontSize: 10,
            color: "red",
            textAlign: "center",
            marginBottom: -10,
          }}
        >
          {error}
        </div>
        {
          // !isLoading &&
          !isAuthenticating && !isInitializing && (
            <Fragment>
              <PrimaryButton
                //@ts-ignore
                styles={primaryButtonStyles}
                className={classNames.login}
                text="Teams Login"
                onClick={onLogin}
              />
              {/* {
                <div className={classNames.linkDiv}>
                  <Link to="/login" className={classNames.link}>
                    Login In With Browser
                  </Link>
                </div>
              } */}
            </Fragment>
          )
        }
        {isAuthenticating ||
          !checkedFrameContext ||
          (isInitializing && (
            <Spinner
              className={classNames.spinner}
              size={SpinnerSize.large}
              label="Logging in..."
              labelPosition="right"
            />
          ))}
        {/* <div>{JSON.stringify(props)}</div>
        <div>TID: {tid}</div>
        <div>TID Data: {JSON.stringify(ctxData)}</div>
        <div>Frame Context Data Data: {JSON.stringify(ctxFrameData || {})}</div>
        <div>localStorageToken: {localStorageToken}</div>
        <div>search: {JSON.stringify(search)}</div>
        <div>Source API: {SOURCE_API}</div>
        <div>Window location: {window?.location?.href}</div> */}
      </form>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.user.isAuthenticated,
  isLoading: state.user.isLoading,
  tokenExpired: state.user.tokenExpired,
  autoLogin: state.user.autoLogin,
});

export default connect(mapStateToProps, { loginTeams })(LoginTeams);
